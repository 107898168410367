export interface RangeInterface {
  id: number;
  skill: string;
  skill_category: string;
  ev_correction: number;
  bat_velocity_min: number;
  bat_velocity_max: number;
  exit_velocity_min: number;
  exit_velocity_max: number;
  age_max: number;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export const EmptyRangeInterface: RangeInterface = {
  id: -1,
  skill: "",
  skill_category: "",
  ev_correction: -1,
  bat_velocity_min: -1,
  bat_velocity_max: -1,
  exit_velocity_min: -1,
  exit_velocity_max: -1,
  age_max: -1
}