import React from "react";
import history from "../../history";
import Header from "../../components/Header";
import Users from "./Users";
import Organizations from "./Organizations";

import { Tabs, Tab } from "react-bootstrap";

import UserRequest from "../../api/requests/UserRequest";
import OrganizationRequest from "../../api/requests/OrganizationRequest";
import { getUserRole } from "../../utils/userUtils";
import { ExitToApp } from "@material-ui/icons";

type Team = {
  id: number;
  org_id: number;
  name: string;
  createdAt?: string;
};
type Organization = {
  id: number;
  name: string;
  teams: Array<Team>;
};
type AdminDashboardState = {
  organizations: Array<Organization>;
  users: Array<any>;
  page: number;
  role?: string;
};

class AdminDashboard extends React.Component<any, AdminDashboardState> {
  constructor(props: any) {
    super(props);
  }

  state: AdminDashboardState = {
    organizations: [],
    users: [],
    page: 0,
    role: ''
  };

  componentDidMount = () => {
    const userRole = getUserRole();
    var tempState = this.state;
    tempState.role = userRole.name.toLowerCase();
    OrganizationRequest.get({
      limit: 100,
      offset: 100 * tempState.page,
    }).then((result: any) => {
      if (result.data) {
        var tempState = this.state;
        tempState.organizations = Array.isArray(result.data.organizations)
          ? result.data.organizations
          : [result.data.organizations];
        this.setState(tempState);
      }
    });
    UserRequest.get({
      limit: 100,
      offset: 100 * tempState.page,
    }).then((result: any) => {
      if (result.data) {
        var tempState = this.state;
        tempState.users = Array.isArray(result.data)
          ? result.data
          : [result.data];
        this.setState(tempState);
      }
    });
  };

  handleClose = (type: "org" | "user") => {
    let tempState = this.state;
    if (type === "user") {
      UserRequest.get({
        limit: 100,
        offset: 100 * tempState.page,
      }).then((result: any) => {
        if (result.data) {
          var tempState = this.state;
          tempState.users = Array.isArray(result.data)
            ? result.data
            : [result.data];
          this.setState(tempState);
        }
      });
    } else if (type === "org") {
      OrganizationRequest.get({
        limit: 100,
        offset: 100 * tempState.page,
      }).then((result: any) => {
        if (result.data) {
          var tempState = this.state;
          tempState.organizations = Array.isArray(result.data.organizations)
            ? result.data.organizations
            : [result.data.organizations];
          this.setState(tempState);
        }
      });
    }
  };

  render() {
    if (this.state?.role !== 'admin') {
        return <div><Header /></div>
    }
    return (
      <>
        <Header />
        <div className="playerLibrary">
          <Tabs
            defaultActiveKey="users"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="users" title="Users">
              <Users
                users={this.state.users}
                organizations={this.state.organizations}
                handleClose={this.handleClose}
              />
            </Tab>
            <Tab eventKey="organizations" title="Organization">
              <Organizations
                organizations={this.state.organizations}
                handleClose={this.handleClose}
              />
            </Tab>
          </Tabs>
        </div>
      </>
    );
  }
}

export default AdminDashboard;
