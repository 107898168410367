import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "whatwg-fetch";
import history from "../../history";
import {
  isEmptyObj,
  getUserRole,
  getUserOrganization,
} from "../../utils/userUtils";
import { EmptyBatInterfaceDto } from "../../interfaces/apiInterfaces/BatInterface";
import { EmptyCoachAccountInterface } from "../../interfaces/apiInterfaces/CoachAccountInterface";
import AuthPingRequest, {
  AuthPingGetResponseInterface,
} from "../../api/requests/AuthPingRequest";
import BatRequest, {
  BatGetResponseInterface,
  BatPostResponseInterface,
} from "../../api/requests/BatRequest";
import UserRequest from "../../api/requests/UserRequest";

type BatType = {
  label: string;
  value: string;
};

const BatSchema = Yup.object().shape({
  bpi: Yup.number().min(0).max(100).required("Required"),
  length: Yup.number().min(0).max(300).required("Required"),
  weight: Yup.number().min(0).max(300).required("Required"),
  model: Yup.string().required("Required"),
  matrix_id: Yup.number().notRequired().nullable(),
  brand: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  user_id: Yup.number().notRequired(),
});

class NewBat extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    AuthPingRequest.get().then((result: AuthPingGetResponseInterface) => {
      if (!result.email) {
        history.push("/login");
      } else {
        var tempState = this.state;
        tempState.coach = result;
        this.setState(tempState);
      }
    });
  }

  state: any = {
    loading: false,
    coach: EmptyCoachAccountInterface,
    bat: {
      ...EmptyBatInterfaceDto,
    },
    users: [],
    custom_brand: false,
    is_gamer: false
  };

  componentDidMount = () => {
    let tempState = this.state;
    const { batId, mode, player, fromScreen } = this.props;
    const org = getUserOrganization();
    if (org && !isEmptyObj(org)) {
      UserRequest.get({
        limit: 100,
        offset: 0,
        filter_key: "org_id",
        filter_value: org.id?.toString(),
      }).then((result: any) => {
        if (result.data) {
          var tempState = this.state;
          tempState.users = Array.isArray(result.data)
            ? result.data
            : [result.data];
          this.setState(tempState);
        }
      });
    }

    if (batId && mode === "edit") {
      BatRequest.get({
        id: batId,
      })
        .then((result: BatGetResponseInterface) => {
          if (result.data && !Array.isArray(result.data)) {
            var tempState = this.state;
            tempState.bat = result.data;
            console.log(result.data);
            if (result.data?.player_id) {
                tempState.is_gamer = 'checked';
            }
            this.setState(tempState);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  createAndChooseCustomBat = (values: any) => {
    var tempState = this.state;
    const org = getUserOrganization();
    if (this.props.mode === "edit") {
      this.setState({ loading: true });
      BatRequest.put({
        id: this.props.batId,
        bat: {
          user_id: tempState.coach.id,
          bpi: parseFloat(values.bpi),
          length: parseFloat(values.length),
          weight: parseFloat(values.weight),
          model:
            values.type !== "matrix"
              ? values.model
              : values.matrix_id?.toString(),
          matrix_id: values.matrix_id ? values.matrix_id : null,
          brand: values.brand,
          type: values.type,
          category: "wood",
          purchase_url: "",
          bat_image_url: "",
          org_id: org?.id,
          player_id: this.props.player ? this.props.player.id : null
        },
      })
        .then((result: BatPostResponseInterface) => {
          this.setState({ loading: false });
          if (result.data) {
            this.props.handleClose();
          }
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          toast.error("Server Error");
          console.log(err);
        });
    } else if (this.props.mode === "new") {
      this.setState({ loading: true });
      BatRequest.post({
        bat: {
          user_id: tempState.coach.id,
          bpi: values.bpi,
          length: values.length,
          weight: values.weight,
          model:
            values.type !== "matrix"
              ? values.model
              : values.matrix_id?.toString(),
          matrix_id: values.matrix_id ? values.matrix_id : null,
          brand: values.brand,
          type: values.type,
          category: "wood",
          purchase_url: "",
          bat_image_url: "",
          org_id: org.id,
        },
      })
        .then((result: BatPostResponseInterface) => {
          this.setState({ loading: false });
          if (result.data) {
            this.props.handleClose();
          }
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          toast.error("Server Error");
          console.log(err);
        });
    } else if (this.props.mode === "custom") {
      this.setState({ loading: true });
      BatRequest.post({
        bat: {
          user_id: tempState.coach.id,
          bpi: values.bpi,
          length: values.length,
          weight: values.weight,
          model:
            values.type !== "matrix"
              ? values.model
              : values.matrix_id?.toString(),
          matrix_id: values.matrix_id ? values.matrix_id : null,
          brand: values.brand,
          type: values.type,
          category: "wood",
          purchase_url: "",
          bat_image_url: "",
          org_id: org.id,
          player_id: this.state.is_gamer ? this.props.player?.id : null,
        },
      })
        .then((result: BatPostResponseInterface) => {
          this.setState({ loading: false });
          if (result.message && !result.data) {
            toast.error(result.message);
          }
          if (result.data) {
            this.props.createAndChooseCustomBat(result.data.id);
          }
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          toast.error("Server Error");
          console.log(err);
        });
    }
  };

  clearForm = () => {
    var tempState = this.state;
    tempState.bat = {
      ...EmptyBatInterfaceDto,
    };
    this.setState(tempState);
  };

  componentWillUnmount = () => {
    this.clearForm();
  };


  render() {
    const { mode, player, fromScreen } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <h4 className="fw-bold">
              {mode === "new" ? "New" : mode === "edit" ? "Edit" : "Custom"} Bat
            </h4>
          </div>
          {
            fromScreen && fromScreen !== 'bats' && <div className="col-sm-12 col-md-4">
                <label>Player</label>
                <input
                    type="text"
                    disabled={true}
                    value={player ? `${player.last_name}, ${player.first_name}` : ''}
                    />
            </div>
          }
          <div className="col-sm-12 col-md-4 float-right">
            <div className="row">
            {
                fromScreen && fromScreen !== 'bats' && <div className="col-sm-12">
                <label>Gamer</label>
                <input
                  className="ml-2 custom-brand-checkbox"
                  type="checkbox"
                  checked={this.state.is_gamer}
                  onChange={(e) =>
                    this.setState({ is_gamer: e.target.checked, bat: {...this.state.bat, model: e.target.checked ? `Gamer - ${this.props.player.last_name}, ${this.props.player.first_name}` : ''} })
                  }
                />
              </div>
            }
              <div className="col-sm-12 float-right">
                <label>Custom Brand</label>
                <input
                  className="ml-2 custom-brand-checkbox"
                  type="checkbox"
                  onChange={(e) =>
                    this.setState({ custom_brand: e.target.checked })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            bpi: this.state.bat.bpi,
            length: this.state.bat.length,
            weight: this.state.bat.weight,
            model:
              this.state.bat.type !== "matrix"
                ? this.state.bat.model
                : this.state.bat.matrix_id?.toString(),
            matrix_id: this.state.bat.matrix_id
              ? this.state.bat.matrix_id
              : null,
            brand: this.state.bat.brand,
            type: this.state.bat.type,
            purchase_url: this.state.purchase_url,
            bat_image_url: this.state.bat_image_url,
            user_id: this.state.user_id,
          }}
          validate={(values) => {
            const tempState = this.state;
            tempState.bat = {
              ...values,
              model:
                values.type !== "matrix"
                  ? values.model
                  : values.matrix_id?.toString(),
              matrix_id: values.type === "matrix" ? values.matrix_id : null,
            };
            this.setState(tempState);
            console.log(tempState.bat);
          }}
          validationSchema={BatSchema}
          onSubmit={(values) => {
            this.createAndChooseCustomBat(values);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label>Bat Type</label>
                    <Field
                      as="select"
                      name="type"
                      onChange={(e: { target: { value: any } }) => {
                        var tempState = this.state;
                        tempState.bat.type = e.target.value;
                        this.setState(tempState);
                      }}
                      className={`form-control ${
                        errors.type && touched.type
                          ? "is-invalid"
                          : touched.type
                          ? "is-valid"
                          : ""
                      }`}
                    >
                      <option value="-1">-- Select --</option>
                      {this.props.batModalTypes.map(
                        (batType: BatType, i: number) => {
                          return (
                            <option key={i} value={batType.value}>
                              {batType.label}
                            </option>
                          );
                        }
                      )}
                    </Field>
                    {errors.type && touched.type ? (
                      <span className="small text-danger">{errors.type}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label>Brand</label>
                    {this.state.custom_brand ? (
                      <Field
                        type="text"
                        name="brand"
                        className={`form-control ${
                          errors.brand && touched.brand
                            ? "is-invalid"
                            : touched.brand
                            ? "is-valid"
                            : ""
                        }`}
                      />
                    ) : (
                      <Field
                        as="select"
                        name="brand"
                        className={`form-control ${
                          errors.brand && touched.brand
                            ? "is-invalid"
                            : touched.brand
                            ? "is-valid"
                            : ""
                        }`}
                      >
                        <option value="-1">-- Select --</option>
                        {this.props.batModalBrands.map(
                          (batBrand: any, i: number) => {
                            return (
                              <option key={i} value={batBrand}>
                                {batBrand}
                              </option>
                            );
                          }
                        )}
                      </Field>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label>Length</label>
                    <Field
                      type="number"
                      name="length"
                      className={`form-control ${
                        errors.length && touched.length
                          ? "is-invalid"
                          : touched.length
                          ? "is-valid"
                          : ""
                      }`}
                    />
                    {errors.length && touched.length ? (
                      <span className="small text-danger">{errors.length}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label>Weight</label>
                    <Field
                      type="number"
                      name="weight"
                      className={`form-control ${
                        errors.weight && touched.weight
                          ? "is-invalid"
                          : touched.weight
                          ? "is-valid"
                          : ""
                      }`}
                    />
                    {errors.weight && touched.weight ? (
                      <span className="small text-danger">{errors.weight}</span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label>
                      {this.state.bat.type !== "matrix" ? "Model" : "Matrix Id"}
                    </label>
                    {this.state.bat.type !== "matrix" && (
                      <>
                        <Field
                          type="text"
                          name="model"
                          className={`form-control ${
                            errors.model && touched.model
                              ? "is-invalid"
                              : touched.model
                              ? "is-valid"
                              : ""
                          }`}
                        />
                        {errors.model && touched.model ? (
                          <span className="small text-danger">
                            {errors.model}
                          </span>
                        ) : null}
                      </>
                    )}
                    {this.state.bat.type === "matrix" && (
                      <>
                        <Field
                          type="number"
                          name="matrix_id"
                          className={`form-control ${
                            errors.matrix_id && touched.matrix_id
                              ? "is-invalid"
                              : touched.matrix_id
                              ? "is-valid"
                              : ""
                          }`}
                        />
                        {errors.matrix_id && touched.matrix_id ? (
                          <span className="small text-danger">
                            {errors.matrix_id}
                          </span>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label>BPI</label>
                    <Field
                      type="number"
                      name="bpi"
                      className={`form-control ${
                        errors.bpi && touched.bpi
                          ? "is-invalid"
                          : touched.bpi
                          ? "is-valid"
                          : ""
                      }`}
                    />
                    {errors.bpi && touched.bpi ? (
                      <span className="small text-danger">{errors.bpi}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="text-center my-3">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleClose();
                  }}
                  className="btn btn-outline-darkblue px-xl-4 mr-3"
                >
                  Cancel
                </button>
                <button
                  disabled={this.state.loading}
                  type="submit"
                  className="btn btn-darkblue text-white px-4"
                >
                  Confirm
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default NewBat;
