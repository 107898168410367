import React, { ChangeEvent } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Formik, Form as FormikForm, Field } from "formik";
import * as Yup from "yup";
import "whatwg-fetch";
// import swal from "sweetalert";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Modal } from "react-bootstrap";
import NewBat from "./Forms/NewBat";
import Form from "react-bootstrap/Form";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ExitVelocity from "../components/Gauges/ExitVelocity";
// import LaunchAngle from "../components/Gauges/LaunchAngle";
import LaunchAngleDynamic from "../components/Gauges/LaunchAngleDynamic";
import SprayDirection from "../components/Gauges/SprayDirection";

import FittingSummary from "./FittingSummary";

import history from "../history";
import Cookies from "universal-cookie";

import PlayerInterface from "../interfaces/apiInterfaces/PlayerInterface";
import FittingSessionInterface from "../interfaces/apiInterfaces/FittingSessionInterface";
import PrescriptionMatrixInterface from "../interfaces/apiInterfaces/PrescriptionMatrixInterface";
import BattingSessionInterface, {
  BatPreferenceDto,
} from "../interfaces/apiInterfaces/BattingSessionInterface";
import BatInterface, {
  BatInterfaceDto,
  EmptyBatInterfaceDto,
} from "../interfaces/apiInterfaces/BatInterface";
import HitInterface, {
  HitInterfaceDto,
} from "../interfaces/apiInterfaces/HitInterface";
import PlayerBiometricInterface from "../interfaces/apiInterfaces/PlayerBiometricInterface";
import BarrelStatControlInterface from "../interfaces/apiInterfaces/BarrelStatControlInterface";
import BatBrandRequest, {
  BatBrandGetResponseInterface,
} from "../api/requests/BatBrandRequest";
import BatRequest, {
  BatGetResponseInterface,
  BatGetOptionsInterface,
  BatPostResponseInterface,
} from "../api/requests/BatRequest";
import BattingSessionRequest, {
  BattingSessionDeleteResponseInterface,
} from "../api/requests/BattingSessionRequest";
import BatPreferenceRequest from "../api/requests/ProcessBatPreferenceRequest";
import RangeRequest, {
  RangeGetResponseInterface,
} from "../api/requests/RangeRequest";
import {
  RangeInterface,
  EmptyRangeInterface,
} from "../interfaces/apiInterfaces/RangeInterface";
import HitRequest, {
  HitDeleteResponseInterface,
  HitPutResponseInterface,
} from "../api/requests/HitRequest";
import ProcessHitRequest, {
  ProcessHitPutResponseInterface,
} from "../api/requests/ProcessHitRequest";
import HittraxRequest, {
  HittraxGetResponseInterface,
} from "../api/requests/HittraxRequest";
import { AppContext } from "../utils/appState/AppState";
import { getUserOrganization, getUserRole } from "../utils/userUtils";
import { getBarGraphColors } from "../utils/playerUtils";

const cookies = new Cookies();
const { SearchBar } = Search;

// a little function to help us with reordering the result
const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const CreateHitSchema = Yup.object().shape({
  exit_velocity: Yup.number().min(0).max(300).required("Required"),
  launch_angle: Yup.number().min(-90).max(90).required("Required"),
  spray_direction: Yup.number().min(-45).max(45).required("Required"),
  player_rating: Yup.number().min(0).max(10).notRequired(),
  custom_metric_value: Yup.number().notRequired(),
});

type BatType = {
  label: string;
  value: string;
};

type HittraxHitData = {
  id: number;
  exit_velocity: number;
  launch_angle: number;
  spray_direction: number;
}

interface NewFittingSessionBattingSessionInterface {
  batting_session: BattingSessionInterface;
  hits: Array<HitInterface>;
  bat: BatInterface;
  average: HitInterface | null;
  cns: HitInterface | null;
  currentHit: HitInterfaceDto | null;
  openOptions: null | HTMLElement;
  openEditHit: null | HTMLElement;
  currentEditingHit: HitInterface | null;
  openEditHitCollapse: HitInterface | null;
}

interface NewFittingSessionProps { }
interface NewFittingSessionState {
  columns: ColumnDescription<any, any>[];
  loading: boolean;
  player: PlayerInterface;
  selectedBatInSessions?: number;
  range: RangeInterface;
  player_bio_metric: PlayerBiometricInterface;
  custom_bat_velocity_min: number;
  custom_bat_velocity_max: number;
  custom_exit_velocity_min: number;
  custom_exit_velocity_max: number;
  player_rating_enabled: boolean;
  manual_data_collection: boolean;
  all_swings_entry: boolean;
  custom_metric_label: string;
  submitted_fitting_session: boolean;
  fitting_session: FittingSessionInterface | null;
  barrel_stat_control: BarrelStatControlInterface | null;
  prescription_matrix: PrescriptionMatrixInterface | null;
  batting_sessions: Array<NewFittingSessionBattingSessionInterface>;
  showChooseBatModal: boolean;
  showPreferenceModal: boolean;
  batModalBrands: Array<string>;
  batModalSearch: string;
  batModalDropSearch: string | null;
  batModalBrandSearch: string;
  batModalSeachPage: number;
  batsInSearch: Array<BatInterface>;
  selectedBat: number;
  customBat: BatInterfaceDto | null;
  showChooseBatColorModal: boolean;
  showFittingSummary: boolean;
  batColorModalColor: string;
  batColorModalColorBattingSession: {
    batting_session: BattingSessionInterface;
    hits: Array<HitInterfaceDto>;
    bat: BatInterface;
    average: HitInterface | null;
    cns: HitInterface | null;
    currentHit: HitInterfaceDto | null;
  } | null;
  useOpenExtendedEdit: boolean;
  showBatEditor?: boolean;
  openExtendedEdit: boolean;
  currentExtendedEditView: string;
  currentExtendedEditCurrentHit: HitInterfaceDto | null;
  currentExtendedEditBattingSession: NewFittingSessionBattingSessionInterface | null;
  batModalTypes: Array<BatType>;
  batModalTypesSearch: Array<BatType>;
  newBatModalTypes?: Array<BatType>;
  hittraxHitData: HittraxHitData
}
type Mode = "edit" | "new";


class NewFittingSession extends React.Component<
  NewFittingSessionProps,
  NewFittingSessionState
> {
  constructor(props: NewFittingSessionProps) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
    const userRole = getUserRole();
    const actionsFormatter = (cell: any, row: any) => {
        if ((userRole.name?.toString().toLowerCase().includes('admin')) || row.type !== 'matrix') {
            return (
                <>
                  <button
                    title="Edit"
                    className="btn btn-outline-darkblue text-white"
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleShow();
                      console.log(this.state.showBatEditor);
                    }}
                  >
                    <FontAwesomeIcon icon="pencil-alt" />
                  </button>
                </>
              );
        }
        return <> </>;
        
      };
    const columns = [
        {
          dataField: "model",
          text: "Model",
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "weight",
          text: "Weight",
          align: "center",
          headerAlign: "center",
          formatter: (cell: any, row: any) => Math.round(cell * 10) / 10,
        },
        {
          dataField: "length",
          text: "Length",
          align: "center",
          headerAlign: "center",
          formatter: (cell: any, row: any) => Math.round(cell * 10) / 10,
        },
        {
          dataField: "bpi",
          text: "BPI",
          align: "center",
          headerAlign: "center",
          formatter: (cell: any, row: any) => Math.round(cell * 10) / 10,
        },
        {
          dataField: "actions",
          text: "Actions",
          align: "center",
          style: { backgroundColor: "#fff" },
          headerStyle: {
            width: 80,
          },
          formatter: actionsFormatter,
        },
    ];
    this.state = {...this.state, columns};    
  }

  state: NewFittingSessionState = {
    loading: false,
    useOpenExtendedEdit: false,
    openExtendedEdit: false,
    currentExtendedEditView: "bat_velocity",
    currentExtendedEditCurrentHit: null,
    currentExtendedEditBattingSession: null,
    selectedBatInSessions: undefined,
    player: {
      id: 0,
      range_id: 1,
      user_id: 0,
      first_name: "",
      last_name: "",
      email: "",
      dob:
        "" +
        new Date(
          new Date().setFullYear(new Date().getFullYear() - 10)
        ).toISOString(),
      avatar: "",
      biometrics: [],
      movement_screenings: [],
      power_testings: [],
    },
    range: EmptyRangeInterface,
    player_bio_metric: {
      id: 0,
      player_id: 0,
      weight: 0,
      height: 0,
      hand_width: 0,
      hand_length: 0,
      forearm_length: 0,
      arm_length: 0,
      wrist_to_floor: 0,
      hip_to_floor: 0,
      wingspan: 0,
      bats: "",
      throws: "",
      writing_hand: "",
      eating_hand: "",
      dominant_eye: "",
      dominant_leg: "",
    },
    custom_bat_velocity_min: 20,
    custom_bat_velocity_max: 120,
    custom_exit_velocity_min: 20,
    custom_exit_velocity_max: 120,
    player_rating_enabled: true,
    manual_data_collection: true,
    all_swings_entry: false,
    custom_metric_label: "",
    submitted_fitting_session: false,
    fitting_session: {
      id: 0,
      player_id: 0,
      player: {
        id: 0,
        range_id: 1,
        user_id: 0,
        first_name: "",
        last_name: "",
        email: "",
        dob:
          "" +
          new Date(
            new Date().setFullYear(new Date().getFullYear() - 10)
          ).toISOString(),
        avatar: "",
        biometrics: [],
        movement_screenings: [],
        power_testings: [],
      },
      user_id: 0,
      custom_bat_velocity_min: 0,
      custom_bat_velocity_max: 90,
      custom_exit_velocity_min: 0,
      custom_exit_velocity_max: 120,
      player_rating_enabled: true,
      manual_data_collection: true,
      all_swings_entry: false,
      custom_metric_label: "",
      range_id: 0,
      complete: false,
      lookup_hash: "",
    },
    barrel_stat_control: null,
    prescription_matrix: null,
    batting_sessions: [],
    showPreferenceModal: false,
    showChooseBatModal: false,
    batModalSearch: "",
    columns: [],
    batModalDropSearch: null,
    batModalBrandSearch: "",
    batModalSeachPage: 0,
    batsInSearch: [],
    selectedBat: -1,
    customBat: null,
    showChooseBatColorModal: false,
    showFittingSummary: false,
    batColorModalColor: "#000",
    showBatEditor: false,
    batColorModalColorBattingSession: null,
    batModalTypes: [
      { label: "Wood", value: "wood" },
      { label: "Metal", value: "metal" },
      { label: "Soft Ball", value: "softball" },
      { label: 'Matrix', value: 'matrix' }
    ],
    newBatModalTypes: [
        { label: "Wood", value: "wood" },
      { label: "Metal", value: "metal" },
      { label: "Soft Ball", value: "softball" },
    ],
    batModalTypesSearch: [
        { label: "Wood", value: "wood" },
        { label: "Metal", value: "metal" },
        { label: "Soft Ball", value: "softball" },
        { label: "Matrix", value: "matrix" }
    ],
    batModalBrands: [],
    hittraxHitData: {
      id: -1,
      exit_velocity: 0,
      launch_angle: 0,
      spray_direction: 0
    }
  };

  componentDidMount = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const playerId = urlParams.get("player");
    const fittingSessionId = urlParams.get("fitting_session");
    const navigatingFromReviewSessionPage = urlParams.get("from");

    if (navigatingFromReviewSessionPage === 'review' || navigatingFromReviewSessionPage === 'fitting') {
        const tempState = this.state;
        tempState.submitted_fitting_session = true;
        this.setState({...tempState, loading: true });
    }
    // get bat brands
    BatBrandRequest.get().then((result: BatBrandGetResponseInterface) => {
      if (result.data && Array.isArray(result.data)) {
        var tempState = this.state;
        tempState.batModalBrands = result.data;
        var victusIndex = tempState.batModalBrands.indexOf("Victus");
        if (victusIndex != -1) {
          tempState.batModalBrands.splice(victusIndex, 1);
          tempState.batModalBrands.unshift("Victus");
        }
        var marucciIndex = tempState.batModalBrands.indexOf("Marucci");
        if (marucciIndex != -1) {
          tempState.batModalBrands.splice(marucciIndex, 1);
          tempState.batModalBrands.unshift("Marucci");
        }
        this.setState(tempState);
      }
    });

    // get player
    const requestPlayer = fetch(
      `${process.env.REACT_APP_API_URL}/players/${playerId}`,
      {
        method: "GET",
        headers: {
          Authorization: `${cookies.get("user")}`,
        },
      }
    );
    requestPlayer
      .then((raw) => {
        return raw.json();
      })
      .then((ans) => {
        var tempState = this.state;
        tempState.player = ans.data;
        this.setState(tempState);
        RangeRequest.get({
          id: parseInt(ans.data.range_id.toString()),
        })
          .then((result: RangeGetResponseInterface) => {
            if (result.data && !Array.isArray(result.data)) {
              var tempState = this.state;
              tempState.range = result.data;
              tempState.custom_bat_velocity_min = tempState.range.bat_velocity_min;
              tempState.custom_bat_velocity_max = tempState.range.bat_velocity_max;
              tempState.custom_exit_velocity_min = tempState.range.exit_velocity_min;
              tempState.custom_exit_velocity_max = tempState.range.exit_velocity_max;
              this.setState(tempState);
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
      });

    // Get player bio metrics
    const requestPlayerBiometrics = fetch(
      `${process.env.REACT_APP_API_URL}/player_bio_metrics/${playerId}`,
      {
        method: "GET",
        headers: {
          Authorization: `${cookies.get("user")}`,
        },
      }
    );
    requestPlayerBiometrics
      .then((rawpbm) => {
        return rawpbm.json();
      })
      .then((anspbm) => {
        var tempState = this.state;
        tempState.player_bio_metric = anspbm.data;
        this.setState(tempState);
      });

    // Get session
    if (fittingSessionId) {
      const requestFittingSession = fetch(
        `${process.env.REACT_APP_API_URL}/fitting_sessions/${fittingSessionId}`,
        {
          method: "GET",
          headers: {
            Authorization: `${cookies.get("user")}`,
          },
        }
      );
      requestFittingSession
        .then(async (raw) => {
          return raw.json();
        })
        .then(async (ans) => {
          var tempState = this.state;
          tempState.fitting_session = ans.data;
          tempState.submitted_fitting_session = true;
          tempState.player_rating_enabled = ans.data.player_rating_enabled;
          tempState.manual_data_collection = ans.data.manual_data_collection;
          tempState.all_swings_entry = ans.data.all_swings_entry;
          tempState.custom_metric_label = ans.data.custom_metric_label;
          this.setState(tempState);

          const requestBattingSessions = fetch(
            `${process.env.REACT_APP_API_URL}/batting_sessions?limit=100&offset=0&filter_key=fitting_session_id&filter_value=${ans.data.id}`,
            {
              method: "GET",
              headers: {
                Authorization: `${cookies.get("user")}`,
              },
            }
          );
          await requestBattingSessions
            .then((raw) => {
              return raw.json();
            })
            .then((battingSessionsRaw) => {
              var battingSessions = battingSessionsRaw;

              var tempState = this.state;

              battingSessions.data.forEach(async (sess: any, i: number) => {
                const requestBat = fetch(
                  `${process.env.REACT_APP_API_URL}/bats/${sess.bat_id}`,
                  {
                    method: "GET",
                    headers: {
                      Authorization: `${cookies.get("user")}`,
                    },
                  }
                );
                requestBat
                  .then((raw) => {
                    return raw.json();
                  })
                  .then((bat) => {
                    var newestBattingSession: NewFittingSessionBattingSessionInterface =
                    {
                      hits: [],
                      batting_session: sess,
                      bat: bat.data,
                      average: null,
                      cns: null,
                      currentHit: null,
                      openOptions: null,
                      openEditHit: null,
                      currentEditingHit: null,
                      openEditHitCollapse: null,
                    };
                    const requestHits = fetch(
                      `${process.env.REACT_APP_API_URL}/hits?limit=100&offset=0&filter_key=batting_session_id&filter_value=${sess.id}`,
                      {
                        method: "GET",
                        headers: {
                          Authorization: `${cookies.get("user")}`,
                        },
                      }
                    );
                    requestHits
                      .then((raw) => {
                        return raw.json();
                      })
                      .then((hits) => {
                        if (
                          newestBattingSession.bat != null &&
                          newestBattingSession.batting_session != null &&
                          newestBattingSession.hits != null
                        ) {
                          const requestHitResults = fetch(
                            `${process.env.REACT_APP_API_URL}/batting_session_results?limit=100&offset=0&filter_key=batting_session_id&filter_value=${sess.id}`,
                            {
                              method: "GET",
                              headers: {
                                Authorization: `${cookies.get("user")}`,
                              },
                            }
                          );
                          requestHitResults
                            .then((raw) => {
                              return raw.json();
                            })
                            .then((hitResults) => {
                              var tempState = this.state;
                              newestBattingSession.hits = hits.data;
                              if (hits.data.length) {
                                let resObj: any = {};
                                for (const index in hitResults.data) {
                                  const item = hitResults.data[index];
                                  resObj[
                                    item.metric_name + "_" + item.metric_type
                                  ] = item;
                                }

                                newestBattingSession.average = {
                                  id: hits.data[hits.data.length - 1].id,
                                  batting_session_id:
                                    hits.data[hits.data.length - 1]
                                      .batting_session_id,
                                  fitting_session_id:
                                    hits.data[hits.data.length - 1]
                                      .fitting_session_id,
                                  bat_id:
                                    hits.data[hits.data.length - 1].bat_id,
                                  player_id:
                                    hits.data[hits.data.length - 1].player_id,
                                  hit_index:
                                    hits.data[hits.data.length - 1].hit_index,
                                  bat_velocity:
                                    resObj.bat_velocity_average?.metric_value,
                                  angle_of_attack:
                                    resObj.angle_of_attack_average
                                      ?.metric_value,
                                  exit_velocity:
                                    resObj.exit_velocity_average?.metric_value,
                                  launch_angle:
                                    resObj.launch_angle_average?.metric_value,
                                  spray_direction:
                                    resObj.spray_direction_average
                                      ?.metric_value,
                                  player_rating:
                                    resObj.player_rating_average?.metric_value,
                                  coach_rating:
                                    resObj.coach_rating_average?.metric_value,
                                  custom_metric_value:
                                    resObj.custom_metric_value_average
                                      ?.metric_value,
                                  foul_miss: false,
                                };
                                newestBattingSession.cns = {
                                  id: hits.data[hits.data.length - 1].id,
                                  batting_session_id:
                                    hits.data[hits.data.length - 1]
                                      .batting_session_id,
                                  fitting_session_id:
                                    hits.data[hits.data.length - 1]
                                      .fitting_session_id,
                                  bat_id:
                                    hits.data[hits.data.length - 1].bat_id,
                                  player_id:
                                    hits.data[hits.data.length - 1].player_id,
                                  hit_index:
                                    hits.data[hits.data.length - 1].hit_index,
                                  bat_velocity:
                                    resObj.bat_velocity_consistency
                                      ?.metric_value,
                                  angle_of_attack:
                                    resObj.angle_of_attack_consistency
                                      ?.metric_value,
                                  exit_velocity:
                                    resObj.exit_velocity_consistency
                                      ?.metric_value,
                                  launch_angle:
                                    resObj.launch_angle_consistency
                                      ?.metric_value,
                                  spray_direction:
                                    resObj.spray_direction_consistency
                                      ?.metric_value,
                                  player_rating:
                                    resObj.player_rating_consistency
                                      ?.metric_value,
                                  coach_rating:
                                    resObj.coach_rating_consistency
                                      ?.metric_value,
                                  custom_metric_value:
                                    resObj.custom_metric_value_consistency
                                      ?.metric_value,
                                  foul_miss: false,
                                };
                              }

                              if (
                                newestBattingSession.bat != null &&
                                newestBattingSession.batting_session != null &&
                                newestBattingSession.hits != null
                              ) {
                                tempState.batting_sessions.push(newestBattingSession);
                              }

                              // Opening the hit form as soon as everything's loaded
                            //   this.addNewHit(tempState.batting_sessions[tempState.batting_sessions.length - 1]);

                              this.setState({ ...tempState, loading: false });
                            });
                          requestHitResults.catch((e) => {
                            console.log(e);
                            this.setState({ loading: false });
                          });
                        }
                      });
                    requestHits.catch((e) => {
                      console.log(e);
                      this.setState({ loading: false });
                    });
                  });               
              });
            });
            this.setState({ loading: false });
        });
    }
  };

  onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items: any[] = reorder(
      this.state.batting_sessions,
      result.source.index,
      result.destination.index
    );

    this.setState({ batting_sessions: items });
  }

  // round number upto two decimal places
  roundNumber(num: number) {
    return Math.round(num * 10) / 10;
  }

  getLaunchAngleSpread = (items: Array<HitInterface>) => {
    let result: any = {
      three5_to_ninety: 0,
      two5_to_35: 0,
      ten_to_25: 0,
      zero_to_ten: 0,
      minus_ninety_to_zero: 0
    };
    items.map((item) => {
      let la: number =
        typeof item["launch_angle"] === "string"
          ? parseFloat(item["launch_angle"])
          : item["launch_angle"];

      if (la >= -90 && la < 0) {
        result["minus_ninety_to_zero"] = result["minus_ninety_to_zero"] + 1;
      } else if (la >= 0 && la <= 10) {
        result["zero_to_ten"] = result["zero_to_ten"] + 1;
      } else if (la > 10 && la <= 25) {
        result["ten_to_25"] = result["ten_to_25"] + 1;
      } else if (la > 25 && la <= 35) {
        result["two5_to_35"] = result["two5_to_35"] + 1;
      } else if (la > 35 && la <= 90) {
        result["three5_to_ninety"] = result["three5_to_ninety"] + 1;
      }
    });
    return result;
  };

  getSprayDirectionSpread = (items: Array<HitInterface>) => {
    let result: any = {
      l2: 0,
      l1: 0,
      mid: 0,
      r1: 0,
      r2: 0,
    };
    items.map((item) => {
      let sd: number =
        typeof item["spray_direction"] === "string"
          ? parseFloat(item["spray_direction"])
          : item["spray_direction"];
      if (sd >= -45 && sd < -27) {
        result["l2"] = result["l2"] + 1;
      } else if (sd >= -27 && sd < -9) {
        result["l1"] = result["l1"] + 1;
      } else if (sd >= -9 && sd < 9) {
        result["mid"] = result["mid"] + 1;
      } else if (sd >= 9 && sd < 27) {
        result["r1"] = result["r1"] + 1;
      } else if (sd >= 27 && sd <= 45) {
        result["r2"] = result["r2"] + 1;
      }
    });
    return result;
  };

  getMaxValue = (array_of_objects: Array<any>) => {
    if (array_of_objects.length) {
        const max_velocity = array_of_objects.reduce(
            (max: number, p: any) =>
              Math.max(max, p.exit_velocity),
            array_of_objects[0].exit_velocity
          );
        console.log(max_velocity);
      return max_velocity;
    } else {
      return 300;
    }
  };

  attemptProcessFittingSession = () => {
    fetch(`${process.env.REACT_APP_API_URL}/process/fitting_session`, {
      method: "POST",
      headers: new Headers({
        Authorization: `${cookies.get("user")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        player_id: this.state.player.id,
        user_id: this.state.player.user_id,
        range_id: this.state.player.range_id,
        custom_bat_velocity_min: this.state.custom_bat_velocity_min,
        custom_bat_velocity_max: this.state.custom_bat_velocity_max,
        custom_exit_velocity_min: this.state.custom_exit_velocity_min,
        custom_exit_velocity_max: this.state.custom_exit_velocity_max,
        player_rating_enabled: this.state.player_rating_enabled,
        manual_data_collection: this.state.manual_data_collection,
        all_swings_entry: this.state.all_swings_entry,
        custom_metric_label: this.state.custom_metric_label,
      }),
    })
      .then((raw) => {
        return raw.json();
      })
      .then((results) => {
        var tempState = this.state;
        tempState.fitting_session = results.data.fitting_session;
        tempState.submitted_fitting_session = true;
        this.setState(tempState);
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set("fitting_session", "" + results.data.fitting_session.id);
        var newRelativePathQuery =
          window.location.pathname + "?" + urlParams.toString();
        history.push(newRelativePathQuery);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleClose = () => {
    let tempState = this.state;
    tempState.customBat = null;
    var batRequestOptions: BatGetOptionsInterface = {
      limit: 500,
      offset: 100 * this.state.batModalSeachPage,
    };
    if (this.state.batModalDropSearch) {
      batRequestOptions.type = this.state.batModalDropSearch;
    }
    if (this.state.batModalBrandSearch.trim()) {
      batRequestOptions["brand"] = this.state.batModalBrandSearch.trim();
    }
    // Add filter_key and filter_value to bat request query from localstorage user object
    const org = getUserOrganization();
    if (org) {
        batRequestOptions["filter_key"] = "org_id";
        batRequestOptions["filter_value"] = org.id;
    }
    BatRequest.get(batRequestOptions)
      .then((result: BatGetResponseInterface) => {
        if (result.data && Array.isArray(result.data)) {
          var tempState = this.state;
          tempState.batsInSearch = result.data;
          this.setState(tempState);
        } else if (result.data) {
          var tempState = this.state;
          tempState.batsInSearch = [result.data];
          this.setState(tempState);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    this.setState({ showBatEditor: false });
  };

  handleUseOpenExtendedEditChange = (e: ChangeEvent<HTMLInputElement>) => {
    var tempState = this.state;
    tempState.useOpenExtendedEdit = e.currentTarget.checked;
    this.setState(tempState);
  };

  handleBatModalDropSearchChange = (
    e: React.ChangeEvent<{ name?: string; value: any }>
  ) => {
    var tempState = this.state;
    let modelColIndex = tempState.columns.findIndex((o) => o.dataField === 'model');
    let matrixModelColIndex = tempState.columns.findIndex((o) => o.dataField === 'matrix_id');
    tempState.batModalDropSearch = e.target.value.toString();
    if (tempState.batModalDropSearch === 'matrix' && tempState.columns.findIndex((o) => o.dataField === 'actions') > -1) {
        tempState.columns = tempState.columns.filter((o) => o.dataField !== 'actions');
        // update column header from Model to Matrix_Id
        tempState.columns[modelColIndex].text = 'Matrix_Id';
        tempState.columns[modelColIndex].dataField = 'matrix_id';
    } else if (tempState.batModalDropSearch !== 'matrix' && tempState.columns.findIndex((o) => o.dataField === 'actions') < 0) {
        tempState.columns[matrixModelColIndex].text = 'Model';
        tempState.columns[matrixModelColIndex].dataField = 'model';
        const actionsFormatter = (cell: any, row: any) => {
            return (
              <>
                <button
                  title="Edit"
                  className="btn btn-outline-darkblue text-white"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleShow();
                  }}
                >
                  <FontAwesomeIcon icon="pencil-alt" />
                </button>
              </>
            );
          };
        tempState.columns.push({
            dataField: "actions",
            text: "Actions",
            align: "center",
            style: { backgroundColor: "#fff" },
            headerStyle: {
              width: 80,
            },
            formatter: actionsFormatter,
          });
    }
    this.setState(tempState);
    this.searchBatModal(true);
  };

  handleBatModalBrandSearchChange = (
    e: React.ChangeEvent<{ name?: string; value: any }>
  ) => {
    var tempState = this.state;
    tempState.batModalBrandSearch = e.target.value.toString();
    this.setState(tempState);
    this.searchBatModal(true);
  };

  deleteBattingSession = (o: NewFittingSessionBattingSessionInterface) => {
    var tempState = this.state;
    BattingSessionRequest.delete({ id: o.batting_session.id })
      .then((result: BattingSessionDeleteResponseInterface) => {
        if (result.message) {
          for (let i = 0; i < tempState.batting_sessions.length; i++) {
            if (
              tempState.batting_sessions[i].batting_session.id ==
              o.batting_session.id
            ) {
              tempState.batting_sessions.splice(i, 1);
              this.setState(tempState);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // TODO
  handleCloseOpenEditHitMenu = (
    battingSession: NewFittingSessionBattingSessionInterface
  ) => {
    var tempState = this.state;
    for (let i in tempState.batting_sessions) {
      if (
        tempState.batting_sessions[i].batting_session.id ==
        battingSession.batting_session.id
      ) {
        tempState.batting_sessions[i].openEditHit = null;
      }
    }
    this.setState(tempState);
  };

  // TODO
  handleOpenOpenEditHitMenu = (
    event: React.MouseEvent<HTMLElement>,
    o: NewFittingSessionBattingSessionInterface
  ) => {
    var tempState = this.state;
    for (let i in tempState.batting_sessions) {
      if (
        tempState.batting_sessions[i].batting_session.id == o.batting_session.id
      ) {
        tempState.batting_sessions[i].openEditHit = event.currentTarget;
      }
    }
    this.setState(tempState);
  };

  deleteHit = (hit: HitInterface) => {
    var tempState = this.state;
    HitRequest.delete({ id: hit.id })
      .then((result: HitDeleteResponseInterface) => {
        if (!result.message) {
          for (let i = 0; i < tempState.batting_sessions.length; i++) {
            if (
              tempState.batting_sessions[i].batting_session.id ==
              hit.batting_session_id
            ) {
              for (
                let k = 0;
                k < tempState.batting_sessions[i].hits.length;
                k++
              ) {
                if (tempState.batting_sessions[i].hits[k].id == hit.id) {
                  tempState.batting_sessions[i].hits.splice(k, 1);
                  this.setState({...tempState, batting_sessions: tempState.batting_sessions});
                }
              }
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // TODO
  editHit = (hit: HitInterface, values: any) => {
    var tempState = this.state;
    var editHitPayload = hit;
    editHitPayload = {
      ...editHitPayload,
      id: parseInt(editHitPayload.id.toString()),
      hit_index: parseInt(editHitPayload.hit_index.toString()),
      bat_id: parseInt(editHitPayload.bat_id.toString()),
      batting_session_id: parseInt(
        editHitPayload.batting_session_id.toString()
      ),
      player_id: parseInt(editHitPayload.player_id.toString()),
      angle_of_attack: 0,
      bat_velocity: 0,
      coach_rating: 0,
      exit_velocity: values.exit_velocity,
      launch_angle: values.launch_angle,
      spray_direction: values.spray_direction,
      player_rating: values.player_rating,
      custom_metric_value: 0,
      scaled_exit_velocity: 0,
      corrected_exit_velocity: 0,
      corrected_launch_angle: 0,
    };

    ProcessHitRequest.put({ id: hit.id, hit: editHitPayload })
      .then((result: ProcessHitPutResponseInterface) => {
        if (result.data) {
          for (let i = 0; i < tempState.batting_sessions.length; i++) {
            if (
              tempState.batting_sessions[i].batting_session.id ==
              editHitPayload.batting_session_id
            ) {
              for (
                let k = 0;
                k < tempState.batting_sessions[i].hits.length;
                k++
              ) {
                if (
                  tempState.batting_sessions[i].hits[k].id == editHitPayload.id
                ) {
                  tempState.batting_sessions[i].hits[k] = editHitPayload;
                  this.setState(tempState);
                }
              }
            }
          }
        }
        this.handleCloseOpenEditHitCollapse(hit);
      })
      .catch((err) => {
        console.log(err);
        this.handleCloseOpenEditHitCollapse(hit);
      });
  };

  handleOpenOpenEditHitCollapse = (hit: HitInterface) => {
    var tempState = this.state;
    for (let i in tempState.batting_sessions) {
      if (
        tempState.batting_sessions[i].batting_session.id ==
        hit.batting_session_id
      ) {
        tempState.batting_sessions[i].openEditHitCollapse = hit;
      }
    }
    this.setState(tempState);
  };

  handleCloseOpenEditHitCollapse = (hit: HitInterface) => {
    var tempState = this.state;
    for (let i in tempState.batting_sessions) {
      if (
        tempState.batting_sessions[i].batting_session.id ==
        hit.batting_session_id
      ) {
        tempState.batting_sessions[i].openEditHitCollapse = null;
      }
    }
    this.setState(tempState);
  };

  searchBatModal = (e?: any) => {
    if (e) {
      var tempState = this.state;
      tempState.batModalSeachPage = 0;
      this.setState(tempState);
    }

    var tempState = this.state;
    var batRequestOptions: BatGetOptionsInterface = {
      limit: 500,
      offset: 100 * tempState.batModalSeachPage,
    };

    if (this.state.batModalDropSearch) {
      batRequestOptions.type = this.state.batModalDropSearch;
    }
    if (this.state.batModalBrandSearch.trim()) {
      batRequestOptions["brand"] = this.state.batModalBrandSearch.trim();
    }

    // Add filter_key and filter_value to bat request query from localstorage user object
    const org = getUserOrganization();
    if (org) {
        batRequestOptions["filter_key"] = "org_id";
        batRequestOptions["filter_value"] = org.id;
    }

    BatRequest.get(batRequestOptions)
      .then((result: BatGetResponseInterface) => {
        if (result.data && Array.isArray(result.data)) {
          var tempState = this.state;
          tempState.batsInSearch = result.data;
          this.setState(tempState);
        } else if (result.data) {
          var tempState = this.state;
          tempState.batsInSearch = [result.data];
          this.setState(tempState);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  selectBatFromBatsInSearch = (bat: BatInterface) => {
    var tempState = this.state;
    tempState.selectedBat = bat.id;
    this.setState(tempState);
  };

  chooseBatFromModal = () => {
    if (this.state.fitting_session && this.state.selectedBat != -1) {

      if (this.state.batting_sessions.findIndex(bs => bs.bat.id === this.state.selectedBat) > -1) {
        toast.error("Bat already exists in session")
        this.closeChooseBatModal();
        return;
      }
      const alreadyUsedBarColors: string[] = this.state.batting_sessions.map(sess => sess.batting_session.color);

      var submitBattingSession = fetch(
        `${process.env.REACT_APP_API_URL}/process/batting_session`,
        {
          method: "POST",
          headers: {
            Authorization: `${cookies.get("user")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            color: getBarGraphColors(alreadyUsedBarColors),
            bat_id: this.state.selectedBat,
            fitting_session_id: this.state.fitting_session.id,
            player_id: this.state.fitting_session.player_id,
          }),
        }
      );
      submitBattingSession
        .then((raw) => {
          return raw.json();
        })
        .then((ans) => {
          const requestBat = fetch(
            `${process.env.REACT_APP_API_URL}/bats/${this.state.selectedBat}`,
            {
              method: "GET",
              headers: {
                Authorization: `${cookies.get("user")}`,
              },
            }
          );
          requestBat
            .then((raw) => {
              return raw.json();
            })
            .then((bat) => {
              var tempState = this.state;
              tempState.batting_sessions.push({
                batting_session: ans.data!.batting_session,
                hits: [],
                bat: bat.data,
                average: null,
                cns: null,
                currentHit: null,
                openOptions: null,
                openEditHit: null,
                currentEditingHit: null,
                openEditHitCollapse: null,
              });

              // Opening the hit form as soon as new bat chosen
            //   this.addNewHit(tempState.batting_sessions[tempState.batting_sessions.length - 1]);

              this.setState({ ...tempState, loading: false });
              this.closeChooseBatModal();
            });
        });
      submitBattingSession.catch((e) => {
        console.log(e);
      });
    } else {
      toast.error("Something went wrong!");
    }
  };

  openChooseBatModal = () => {
    var tempState = this.state;
    tempState.showChooseBatModal = true;
    this.setState(tempState);
  };

  closeChooseBatModal = () => {
    var tempState = this.state;
    tempState.showChooseBatModal = false;
    tempState.selectedBat = -1;
    tempState.batModalBrandSearch = "";
    tempState.batModalDropSearch = "";
    tempState.batModalSeachPage = 0;
    tempState.batModalSearch = "";
    tempState.batsInSearch = [];
    tempState.customBat = null;
    this.setState(tempState);
  };

  // create custom bat
  createAndChooseCustomBat = (id: any) => {
    var tempState = this.state;
    tempState.selectedBat = id;
    tempState.customBat = null;
    this.setState(tempState);
    this.chooseBatFromModal();
  };

  openChooseBatColorModal = (
    battingSession: NewFittingSessionBattingSessionInterface
  ) => {
    var tempState = this.state;
    tempState.showChooseBatColorModal = true;
    tempState.batColorModalColorBattingSession = battingSession;
    this.setState(tempState);
  };

  closeChooseBatColorModal = () => {
    var tempState = this.state;
    tempState.showChooseBatColorModal = false;
    this.setState(tempState);
  };

  // update batting_session
  selectBatColor = () => {
    if (this.state.batColorModalColorBattingSession) {
      var tempState = this.state;
      for (let i in tempState.batting_sessions) {
        if (
          tempState.batting_sessions[i].batting_session.id ==
          tempState.batColorModalColorBattingSession?.batting_session.id
        ) {
          tempState.batColorModalColorBattingSession.batting_session.color =
            tempState.batColorModalColor;
          fetch(
            `${process.env.REACT_APP_API_URL}/process/batting_session/${tempState.batColorModalColorBattingSession.batting_session.id}?calc=true`,
            {
              method: "PUT",
              headers: {
                Authorization: `Basic ${btoa(
                  "caasilemroh@gmail.com:password"
                )}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                color:
                  tempState.batColorModalColorBattingSession.batting_session
                    .color,
                bat_id:
                  tempState.batColorModalColorBattingSession.batting_session
                    .bat_id,
                fitting_session_id:
                  tempState.batColorModalColorBattingSession.batting_session
                    .fitting_session_id,
                player_id:
                  tempState.batColorModalColorBattingSession.batting_session
                    .player_id,
              }),
            }
          )
            .then((raw) => {
              return raw.text();
            })
            .then((fittingSession) => {
              //?
            });
          var z = {
            color:
              tempState.batColorModalColorBattingSession.batting_session.color,
            bat_id:
              tempState.batColorModalColorBattingSession.batting_session.bat_id,
            fitting_session_id:
              tempState.batColorModalColorBattingSession.batting_session
                .fitting_session_id,
            player_id:
              tempState.batColorModalColorBattingSession.batting_session
                .player_id,
          };
        }
      }
      tempState.batColorModalColorBattingSession = null;
      this.setState(tempState);
      this.closeChooseBatColorModal();
    }
  };

  getMaxFromArrayObjectField = (arr: any[], field: string) => {
    const maxValue = arr.reduce((max, currentObj) => {
        return currentObj[field] > max ? currentObj[field] : max;
      }, arr?.length ? arr[0][field] : 0);
    
      return maxValue+1;
  }


  addNewHit = (battingSession: NewFittingSessionBattingSessionInterface) => {
    const { manual_data_collection } = this.state;
    let exit_velocity: string | number = 0;
    let launch_angle: string | number = 0;
    let spray_direction: string | number = 0;
    if (!manual_data_collection) {
      this.setState({ loading: true })
      HittraxRequest.get()
        .then((response: HittraxGetResponseInterface) => {
          this.setState({ loading: false });
          const { data } = response;
          if (data && data.id) {
            exit_velocity = this.roundNumber(data.exit_velocity) || 0;
            launch_angle = this.roundNumber(data.launch_angle) || 0;
            spray_direction = this.roundNumber(data.spray_direction) || 0;

            var currentHit: HitInterfaceDto = {
              batting_session_id: battingSession.batting_session.id,
              fitting_session_id: battingSession.batting_session.fitting_session_id,
              bat_id: battingSession.bat.id,
              player_id: battingSession.batting_session.player_id,
              hit_index: this.getMaxFromArrayObjectField(battingSession.hits, 'hit_index'),
              bat_velocity: 0,
              angle_of_attack: 0,
              exit_velocity: Number(exit_velocity) || 0,
              launch_angle: Number(launch_angle) || 0,
              spray_direction: Number(spray_direction) || 0,
              player_rating: 0,
              coach_rating: 0,
              custom_metric_value: 0,
              foul_miss: false,
            };
            var tempState = this.state;
            tempState.hittraxHitData.id = data.id;
            tempState.hittraxHitData.exit_velocity = Number(data.exit_velocity);
            tempState.hittraxHitData.launch_angle = Number(data.launch_angle);
            tempState.hittraxHitData.spray_direction = Number(data.spray_direction);

            tempState.batting_sessions.forEach((o, i) => {
              if (o.batting_session.id == battingSession.batting_session.id) {
                tempState.batting_sessions[i].currentHit = currentHit;
                tempState.currentExtendedEditCurrentHit = currentHit;
                tempState.currentExtendedEditBattingSession = battingSession;
              }
            });
            this.setState(tempState, () => (document as any).querySelector("#exit_velocity_input").focus());

            toast.success('Hit data pull success');
          } else {
            toast.error('Hit data pull failure');
          }
        })
        .catch((error: any) => {
          this.setState({ loading: false })
          toast.error('Hit data pull failure');
        })
    } else {
      var currentHit: HitInterfaceDto = {
        batting_session_id: battingSession.batting_session.id,
        fitting_session_id: battingSession.batting_session.fitting_session_id,
        bat_id: battingSession.bat.id,
        player_id: battingSession.batting_session.player_id,
        hit_index: this.getMaxFromArrayObjectField(battingSession.hits, 'hit_index'),
        bat_velocity: 0,
        angle_of_attack: 0,
        exit_velocity: Number(exit_velocity) || 0,
        launch_angle: Number(launch_angle) || 0,
        spray_direction: Number(spray_direction) || 0,
        player_rating: 0,
        coach_rating: 0,
        custom_metric_value: 0,
        foul_miss: false,
      };
      var tempState = this.state;
      tempState.batting_sessions.forEach((o, i) => {
        if (o.batting_session.id == battingSession.batting_session.id) {
          tempState.batting_sessions[i].currentHit = currentHit;
          tempState.currentExtendedEditCurrentHit = currentHit;
          tempState.currentExtendedEditBattingSession = battingSession;
        }
      });
      this.setState(tempState, () => (document as any).querySelector("#exit_velocity_input").focus());
    }
  };

  submitNewHit = (
    battingSession: NewFittingSessionBattingSessionInterface,
    foul_miss: boolean = false,
    values: any
  ) => {
    const { manual_data_collection } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const playerId = urlParams.get("player");
    const fittingSessionId = urlParams.get("fitting_session");
    fetch(`${process.env.REACT_APP_API_URL}/process/hit`, {
      method: "POST",
      headers: {
        "Authorization": cookies.get("user"),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        batting_session_id: battingSession.batting_session.id,
        fitting_session_id: battingSession.batting_session.fitting_session_id,
        bat_id: battingSession.bat.id,
        player_id: battingSession.batting_session.player_id,
        hit_index: this.getMaxFromArrayObjectField(battingSession.hits, 'hit_index'),
        bat_velocity: battingSession.currentHit?.bat_velocity,
        angle_of_attack: battingSession.currentHit?.angle_of_attack,
        exit_velocity: Number(values.exit_velocity),
        launch_angle: Number(values.launch_angle),
        spray_direction: Number(values.spray_direction),
        player_rating: values.player_rating || 0,
        coach_rating: battingSession.currentHit?.coach_rating,
        custom_metric_value: values.custom_metric_value
          ? values.custom_metric_value
          : 0,
        foul_miss: foul_miss,
      }),
    })
      .then((raw) => {
        return raw.json();
      })
      .then((ans) => {
        var tempState = this.state;
        for (let i in tempState.batting_sessions) {
          if (
            tempState.batting_sessions[i].currentHit != null &&
            tempState.batting_sessions[i].batting_session.id ==
            battingSession.batting_session.id
          ) {
            tempState.batting_sessions[i].currentHit = null;
            tempState.batting_sessions[i].hits.push(ans.data.hit);
            tempState.batting_sessions[i].average = {
              id: ans.data.hit.id,
              batting_session_id: ans.data.hit.batting_session_id,
              fitting_session_id:
                ans.data.batting_session_results.fitting_session_id,
              bat_id: ans.data.hit.bat_id,
              player_id: ans.data.hit.player_id,
              hit_index: ans.data.hit.hit_index,
              bat_velocity: ans.data.batting_session_results[0].metric_value,
              angle_of_attack: ans.data.batting_session_results[2].metric_value,
              exit_velocity: ans.data.batting_session_results[4].metric_value,
              launch_angle: ans.data.batting_session_results[6].metric_value,
              spray_direction: ans.data.batting_session_results[8].metric_value,
              player_rating: ans.data.batting_session_results[10].metric_value,
              coach_rating: ans.data.batting_session_results[12].metric_value,
              custom_metric_value:
                ans.data.batting_session_results[14].metric_value,
              foul_miss: foul_miss,
            };
            tempState.batting_sessions[i].cns = {
              id: ans.data.hit.id,
              batting_session_id: ans.data.hit.batting_session_id,
              fitting_session_id:
                ans.data.batting_session_results.fitting_session_id,
              bat_id: ans.data.hit.bat_id,
              player_id: ans.data.hit.player_id,
              hit_index: ans.data.hit.hit_index,
              bat_velocity: ans.data.batting_session_results[1].metric_value,
              angle_of_attack: ans.data.batting_session_results[3].metric_value,
              exit_velocity: ans.data.batting_session_results[5].metric_value,
              launch_angle: ans.data.batting_session_results[7].metric_value,
              spray_direction: ans.data.batting_session_results[9].metric_value,
              player_rating: ans.data.batting_session_results[11].metric_value,
              coach_rating: ans.data.batting_session_results[13].metric_value,
              custom_metric_value:
                ans.data.batting_session_results[15].metric_value,
              foul_miss: foul_miss,
            };
            tempState.currentExtendedEditBattingSession = null;
            tempState.openExtendedEdit = false;

            // Confirm Hit to Hittrax
            if (!manual_data_collection) {
              this.setState({ loading: true })
              HittraxRequest.put({ id: this.state.hittraxHitData.id, BatRxHitId: ans.data.hit.id })
                .then(() => {
                  this.setState({ loading: false })
                  toast.success('Hit data confirmed')
                })
                .catch(() => {
                  this.setState({ loading: false })
                  toast.error('Hit data confirm failure')
                })
            }

            this.setState(tempState);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  submitNewHitAndAddNewHit = (
    battingSession: NewFittingSessionBattingSessionInterface,
    foul_miss: boolean = false,
    values: any
  ) => {
    const urlParams = new URLSearchParams(window.location.search);
    const playerId = urlParams.get("player");
    const fittingSessionId = urlParams.get("fitting_session");
    fetch(`${process.env.REACT_APP_API_URL}/process/hit`, {
      method: "POST",
      headers: {
        "Authorization": cookies.get("user"),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        batting_session_id: battingSession.batting_session.id,
        fitting_session_id: battingSession.batting_session.fitting_session_id,
        bat_id: battingSession.bat.id,
        player_id: battingSession.batting_session.player_id,
        hit_index: this.getMaxFromArrayObjectField(battingSession.hits, 'hit_index'),
        bat_velocity: battingSession.currentHit?.bat_velocity,
        angle_of_attack: battingSession.currentHit?.angle_of_attack,
        exit_velocity: values.exit_velocity,
        launch_angle: values.launch_angle,
        spray_direction: values.spray_direction,
        player_rating: values.player_rating || 0,
        coach_rating: battingSession.currentHit?.coach_rating,
        custom_metric_value: values.custom_metric_value
          ? values.custom_metric_value
          : 0,
        foul_miss: foul_miss,
      }),
    })
      .then((raw) => {
        return raw.json();
      })
      .then((ans) => {
        var tempState = this.state;
        for (let i in tempState.batting_sessions) {
          if (
            tempState.batting_sessions[i].currentHit != null &&
            tempState.batting_sessions[i].batting_session.id ==
            battingSession.batting_session.id
          ) {
            tempState.batting_sessions[i].currentHit = null;
            tempState.batting_sessions[i].hits.push(ans.data.hit);
            tempState.batting_sessions[i].average = {
              id: ans.data.hit.id,
              batting_session_id: ans.data.hit.batting_session_id,
              fitting_session_id:
                ans.data.batting_session_results.fitting_session_id,
              bat_id: ans.data.hit.bat_id,
              player_id: ans.data.hit.player_id,
              hit_index: ans.data.hit.hit_index,
              bat_velocity: ans.data.batting_session_results[0].metric_value,
              angle_of_attack: ans.data.batting_session_results[2].metric_value,
              exit_velocity: ans.data.batting_session_results[4].metric_value,
              launch_angle: ans.data.batting_session_results[6].metric_value,
              spray_direction: ans.data.batting_session_results[8].metric_value,
              player_rating: ans.data.batting_session_results[10].metric_value,
              coach_rating: ans.data.batting_session_results[12].metric_value,
              custom_metric_value:
                ans.data.batting_session_results[14].metric_value,
              foul_miss: foul_miss,
            };
            tempState.batting_sessions[i].cns = {
              id: ans.data.hit.id,
              batting_session_id: ans.data.hit.batting_session_id,
              fitting_session_id:
                ans.data.batting_session_results.fitting_session_id,
              bat_id: ans.data.hit.bat_id,
              player_id: ans.data.hit.player_id,
              hit_index: ans.data.hit.hit_index,
              bat_velocity: ans.data.batting_session_results[1].metric_value,
              angle_of_attack: ans.data.batting_session_results[3].metric_value,
              exit_velocity: ans.data.batting_session_results[5].metric_value,
              launch_angle: ans.data.batting_session_results[7].metric_value,
              spray_direction: ans.data.batting_session_results[9].metric_value,
              player_rating: ans.data.batting_session_results[11].metric_value,
              coach_rating: ans.data.batting_session_results[13].metric_value,
              custom_metric_value:
                ans.data.batting_session_results[15].metric_value,
              foul_miss: foul_miss,
            };

            // Ready up for new hit
            var currentHit: HitInterfaceDto = {
              batting_session_id: battingSession.batting_session.id,
              fitting_session_id:
                battingSession.batting_session.fitting_session_id,
              bat_id: battingSession.bat.id,
              player_id: battingSession.batting_session.player_id,
              hit_index: this.getMaxFromArrayObjectField(battingSession.hits, 'hit_index'),
              bat_velocity: 0,
              angle_of_attack: 0,
              exit_velocity: 0,
              launch_angle: 0,
              player_rating: 0,
              coach_rating: 0,
              spray_direction: 0,
              custom_metric_value: 0,
              foul_miss: false,
            };
            tempState.currentExtendedEditBattingSession = battingSession;
            tempState.batting_sessions[i].currentHit = currentHit;
            tempState.currentExtendedEditCurrentHit = currentHit;
            this.setState(tempState, (document as any).querySelector("#exit_velocity_input").focus());
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  cancelNewHit = (battingSession: NewFittingSessionBattingSessionInterface) => {
    const urlParams = new URLSearchParams(window.location.search);
    const playerId = urlParams.get("player");
    const fittingSessionId = urlParams.get("fitting_session");
    var tempState = this.state;
    tempState.batting_sessions.forEach((o, i) => {
      if (o.batting_session.id == battingSession.batting_session.id) {
        tempState.batting_sessions[i].currentHit = null;
      }
    });
    tempState.currentExtendedEditBattingSession = null;
    tempState.openExtendedEdit = false;
    this.setState(tempState);
  };

  handleCurrentExtendedEditViewChange = (
    newCurrentExtendedEditView: string
  ) => {
    var tempState = this.state;
    tempState.currentExtendedEditView = newCurrentExtendedEditView;
    this.setState(tempState);
  };

  canFinishFittingSession = () => {
    for (let i in this.state.batting_sessions) {
      if (
        this.state.batting_sessions[i].hits.length < 1 ||
        this.state.batting_sessions[i].currentHit != null
      ) {
        return false;
      }
    }
    return true;
  };

  finishFittingSession = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const playerId = urlParams.get("player");
    const fittingSessionId = urlParams.get("fitting_session");
    const { batting_sessions } = this.state;

    var tempState = this.state;
    let payload: BatPreferenceDto[] = [];
    tempState.batting_sessions.map((item, index) => {
      payload.push({
        id: item.batting_session.id,
        order: index + 1,
      });
    });

    tempState.batting_sessions.forEach((o, i) => {
      if (o.batting_session.id == (batting_sessions as any)[batting_sessions.length - 1].batting_session.id) {
        tempState.batting_sessions[i].currentHit = null;
      }
    });
    tempState.currentExtendedEditBattingSession = null;
    tempState.openExtendedEdit = false;
    this.setState(tempState, () => history.push(`/review-session?player=${playerId}&fitting_session=${fittingSessionId}`));

    // BatPreferenceRequest.post({ batting_session: payload })
    //   .then((result: any) => {
    //     console.log(result);
    //   })
    //   .catch((err: any) => {
    //     console.log(err);
    //   });
  };

  handleShow()  {
      const tempState = this.state;
      tempState.showBatEditor = true;
    this.setState(tempState);
  };

  handleCloseBatEdit = () => {
      const tempState = this.state;
      tempState.showBatEditor = false;
      tempState.customBat = null;
    this.setState(tempState);
    this.searchBatModal();
  }

  handleBatSelectionInSessions = (e) => {
    const tempState = this.state;
    this.setState({...tempState, selectedBatInSessions: e.target.value});
  };

  render() {
    const { batting_sessions, selectedBatInSessions } = this.state;
    const userRole = getUserRole();

    let launch_angle_spread;
    let spray_direction_spread;
    if (batting_sessions[batting_sessions.length - 1]) {
      if (batting_sessions[batting_sessions.length - 1].hits) {
        launch_angle_spread = this.getLaunchAngleSpread(
          batting_sessions[batting_sessions.length - 1].hits
        );
        spray_direction_spread = this.getSprayDirectionSpread(
          batting_sessions[batting_sessions.length - 1].hits
        );
      }
    }

    const pagination = paginationFactory({});

    const selectRow: any = {
      mode: "radio",
      clickToSelect: true,
      onSelect: this.selectBatFromBatsInSearch,
    };

    return (
      <>
        <div className="playerLibrary bg-white" style={{ height: "100vh" }}>

          <div className="container-fluid">
            {!this.state.openExtendedEdit && (
              <>
                <div className="row py-4">
                  {this.state.submitted_fitting_session ? (
                    <>
                      <div className="container-fluid">
                        <div className="row">
                          {batting_sessions.length ? (
                            <>
                              <div
                                className="col"
                                title="Length | Brand | Model | BPI"
                              >
                                {parseFloat(
                                  batting_sessions[
                                    selectedBatInSessions || batting_sessions.length - 1
                                  ].bat["length"].toString()
                                ).toFixed(2)}{" "}
                                |{" "}
                                {
                                  batting_sessions[
                                    selectedBatInSessions || batting_sessions.length - 1
                                  ].bat.brand
                                }{" "}
                                |{" "}
                                {
                                  batting_sessions[
                                    selectedBatInSessions || batting_sessions.length - 1
                                  ].bat.model
                                }{" "}
                                |{" "}
                                {
                                  batting_sessions[
                                    selectedBatInSessions || batting_sessions.length - 1
                                  ].bat.bpi
                                }
                              </div>
                              <div
                                className="col"
                                title="Hit for current bat | Total hits in session"
                              >
                                Bat -{" "}
                                <span className="hitCount">
                                  {
                                    batting_sessions[
                                        selectedBatInSessions || batting_sessions.length - 1
                                    ].hits.length
                                  }
                                </span>{" "}
                                Hits | Session -{" "}
                                <span className="hitCount">
                                  {batting_sessions.reduce(
                                    (total: number, item: any) =>
                                      total + item.hits.length,
                                    0
                                  )}
                                </span>{" "}
                                Hits
                              </div>
                              <div
                                className="col-sm-2"
                                title="Select Bat model name to show corresponding last hit"
                              >
                                {
                                    <Form.Control
                                    as="select"
                                    value={
                                      this.state.selectedBatInSessions != null
                                        ? this.state.selectedBatInSessions
                                        : undefined
                                    }
                                    onChange={
                                      this.handleBatSelectionInSessions
                                    }
                                  >
                                    <option value="">-- Previous Bats --</option>
                                    {batting_sessions.map(
                                      (curSession, sessionIndex: number) => {
                                        return (
                                          <option
                                            key={sessionIndex}
                                            value={sessionIndex}
                                          >
                                            {curSession.bat.model}
                                          </option>
                                        );
                                      }
                                    )}
                                  </Form.Control>
                                }
                              </div>
                            </>
                          ) : null}
                          <div className="col">
                            <div className="float-right">
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip-top">Back</Tooltip>
                                }
                              >
                                <button
                                  onClick={() => history.push(this.state.player.id ? `/player?id=${this.state.player.id}&tab=fitting_sessions`: "/get-fitted")}
                                  className="btn btn-outline-darkblue mr-2"
                                >
                                  <FontAwesomeIcon icon="arrow-left" />
                                </button>
                              </OverlayTrigger>
                              {this.state.submitted_fitting_session &&
                                this.state.fitting_session &&
                                !this.state.fitting_session.complete && (
                                  // <OverlayTrigger
                                  //   placement="bottom"
                                  //   overlay={
                                  //     <Tooltip id="tooltip-top">
                                  //       Add Bat
                                  //     </Tooltip>
                                  //   }
                                  // >
                                  <button
                                    disabled={
                                      batting_sessions.length
                                        ? batting_sessions[
                                            selectedBatInSessions || batting_sessions.length - 1
                                        ].hits.length
                                          ? false
                                          : true
                                        : false
                                    }
                                    onClick={this.openChooseBatModal}
                                    className="btn btn-darkblue text-white mr-2"
                                  >
                                    Add Bat
                                  </button>
                                  // </OverlayTrigger>
                                )}
                              <button
                                disabled={!batting_sessions.length}
                                className="btn btn-darkblue text-white mr-2"
                                onClick={() =>
                                  this.setState({ showFittingSummary: true })
                                }
                              >
                                Summary
                              </button>
                              <button
                                // disabled={!this.canFinishFittingSession()}
                                onClick={this.finishFittingSession}
                                className="btn btn-lg btn-green btn-primary"
                              >
                                Review
                              </button>
                            </div>
                          </div>
                        </div>

                        {batting_sessions.length === 0 && (
                          <h4 className="centered">
                            Add your first bat for the session, click "Add Bat"
                          </h4>
                        )}
                        {batting_sessions.length ? (
                          <>
                            <div
                              className="row"
                              style={{ marginTop: 30 }}
                            >
                              <div className="col-sm-4 col-md-4 position-relative">
                                  <ExitVelocity
                                    average={
                                      (batting_sessions as any)[
                                        selectedBatInSessions || batting_sessions.length - 1
                                      ].average?.exit_velocity
                                    }
                                    consistency={
                                      (batting_sessions as any)[
                                        selectedBatInSessions || batting_sessions.length - 1
                                      ].cns?.exit_velocity
                                    }
                                    max_velocity={this.getMaxValue(
                                      (batting_sessions as any)[
                                        selectedBatInSessions || batting_sessions.length - 1
                                      ].hits
                                    )}
                                    roundNumber={this.roundNumber}
                                  />
                              </div>
                              <div className="col-sm-4 col-md-4 position-relative">
                                  <LaunchAngleDynamic
                                    hitCount={
                                      (batting_sessions as any)[
                                        selectedBatInSessions || batting_sessions.length - 1
                                      ].hits.length
                                    }
                                    average={
                                      (batting_sessions as any)[
                                        selectedBatInSessions || batting_sessions.length - 1
                                      ].average?.launch_angle
                                    }
                                    consistency={
                                      (batting_sessions as any)[
                                        selectedBatInSessions || batting_sessions.length - 1
                                      ].cns?.launch_angle
                                    }
                                    launch_angle_spread={launch_angle_spread}
                                    roundNumber={this.roundNumber}
                                  />
                              </div>
                              <div className="col-sm-4 col-md-4 position-relative">
                                <SprayDirection
                                  hitCount={
                                    (batting_sessions as any)[
                                        selectedBatInSessions || batting_sessions.length - 1
                                    ].hits.length
                                  }
                                  average={
                                    (batting_sessions as any)[
                                        selectedBatInSessions || batting_sessions.length - 1
                                    ].average?.spray_direction
                                  }
                                  consistency={
                                    (batting_sessions as any)[
                                        selectedBatInSessions || batting_sessions.length - 1
                                    ].cns?.spray_direction
                                  }
                                  spray_direction_spread={
                                    spray_direction_spread
                                  }
                                  roundNumber={this.roundNumber}
                                />
                              </div>
                            </div>
                            {/* Display Last Hit */}
                            <div className="row lastHit">
                              <div className="col-md-12">
                                <span>Last Hit</span>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-4">
                                <div
                                  className="card text-center px-3"
                                  style={{
                                    borderRadius: 8,
                                    border: "5px solid #D9DFEA",
                                  }}
                                >
                                  <div className="card-header border-0 bg-white exit">
                                    EXIT
                                    <div className="border"></div>
                                    <div className="card-body p-0">
                                      <div className="speed">
                                        <b>
                                          {" "}
                                          {batting_sessions[
                                            selectedBatInSessions || batting_sessions.length - 1
                                          ].hits.length
                                            ? this.roundNumber(
                                              batting_sessions[
                                                selectedBatInSessions || batting_sessions.length - 1
                                              ].hits[
                                              batting_sessions[
                                                selectedBatInSessions || batting_sessions.length -
                                                1
                                              ].hits.length - 1
                                              ]["exit_velocity"]
                                            )
                                            : "-"}
                                        </b>{" "}
                                        <span className="unit">mph</span>
                                      </div>
                                    </div>
                                    <div className="border"></div>
                                    <div className="card-footer border-0 bg-white velocity">
                                      <span>VELOCITY</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-4">
                                <div
                                  className="card text-center px-3"
                                  style={{
                                    borderRadius: 8,
                                    border: "5px solid #D9DFEA",
                                  }}
                                >
                                  <div className="card-header border-0 bg-white exit">
                                    LAUNCH
                                    <div className="border"></div>
                                    <div className="card-body p-0">
                                      <div className="speed">
                                        <b>
                                          {" "}
                                          {batting_sessions[
                                            selectedBatInSessions || batting_sessions.length - 1
                                          ].hits.length
                                            ? this.roundNumber(
                                              batting_sessions[
                                                selectedBatInSessions || batting_sessions.length - 1
                                              ].hits[
                                              batting_sessions[
                                                selectedBatInSessions || batting_sessions.length -
                                                1
                                              ].hits.length - 1
                                              ]["launch_angle"]
                                            )
                                            : "-"}
                                        </b>{" "}
                                        <span className="unit">deg</span>{" "}
                                      </div>
                                    </div>
                                    <div className="border"></div>
                                    <div className="card-footer border-0 bg-white velocity">
                                      ANGLE
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-4">
                                <div
                                  className="card text-center px-3"
                                  style={{
                                    borderRadius: 8,
                                    border: "5px solid #D9DFEA",
                                  }}
                                >
                                  <div className="card-header border-0 bg-white exit">
                                    SPRAY
                                    <div className="border"></div>
                                    <div className="card-body  p-0">
                                      <div className="speed">
                                        {" "}
                                        <b>
                                          {" "}
                                          {batting_sessions[
                                            selectedBatInSessions || batting_sessions.length - 1
                                          ].hits.length
                                            ? this.roundNumber(
                                              batting_sessions[
                                                selectedBatInSessions || batting_sessions.length - 1
                                              ].hits[
                                              batting_sessions[
                                                selectedBatInSessions || batting_sessions.length -
                                                1
                                              ].hits.length - 1
                                              ]["spray_direction"]
                                            )
                                            : "-"}
                                        </b>{" "}
                                        <span className="unit">deg</span>
                                      </div>
                                    </div>
                                    <div className="border"></div>
                                    <div className="card-footer border-0 bg-white velocity">
                                      DIRECTION
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {batting_sessions[batting_sessions.length - 1][
                              "currentHit"
                            ] ? (
                              <Formik
                                enableReinitialize
                                initialValues={{
                                  exit_velocity: (batting_sessions as any)[batting_sessions.length - 1]["currentHit"]["exit_velocity"] || "",
                                  launch_angle: (batting_sessions as any)[batting_sessions.length - 1]["currentHit"]["launch_angle"] || "",
                                  spray_direction: (batting_sessions as any)[batting_sessions.length - 1]["currentHit"]["spray_direction"] || "",
                                  player_rating: "",
                                  custom_metric_value: "",
                                }}
                                validationSchema={CreateHitSchema}
                                onSubmit={(values, { resetForm }) => {
                                  if (this.state.manual_data_collection) {
                                    this.submitNewHitAndAddNewHit(
                                      (batting_sessions as any)[
                                      batting_sessions.length - 1
                                      ],
                                      false,
                                      values
                                    );
                                  } else {
                                    this.submitNewHit(
                                      (batting_sessions as any)[
                                      batting_sessions.length - 1
                                      ],
                                      false,
                                      values
                                    );
                                  }
                                  resetForm();
                                }}
                              >
                                {({ errors, touched, setFieldValue }) => (
                                  <FormikForm>
                                    <div className="row mt-2">
                                      <div className="col-sm-6 col-md-4 col-lg-4">
                                        <div className="input-group input-group-lg px-5">
                                          <Field
                                            id="exit_velocity_input"
                                            name="exit_velocity"
                                            className={`form-control ${errors.exit_velocity &&
                                              touched.exit_velocity
                                              ? "is-invalid"
                                              : touched.exit_velocity
                                                ? "is-valid"
                                                : ""
                                              }`}
                                            placeholder="Exit Velocity"
                                            type="number"
                                            onBlur={(v: any) => setFieldValue('exit_velocity', this.roundNumber(v.target.value))}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-sm-6 col-md-4 col-lg-4">
                                        <div className="input-group input-group-lg px-5">
                                        <Field
                                            className={`form-control ${errors.launch_angle &&
                                              touched.launch_angle
                                              ? "is-invalid"
                                              : touched.launch_angle
                                                ? "is-valid"
                                                : ""
                                              }`}
                                            placeholder="Launch Angle"
                                            type="number"
                                            name="launch_angle"
                                            onBlur={(v: any) => setFieldValue('launch_angle', this.roundNumber(v.target.value))}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-sm-6 col-md-4 col-lg-4">
                                        <div className="input-group input-group-lg px-5">
                                        <Field
                                            className={`form-control ${errors.spray_direction &&
                                              touched.spray_direction
                                              ? "is-invalid"
                                              : touched.spray_direction
                                                ? "is-valid"
                                                : ""
                                              }`}
                                            placeholder="Spray Direction"
                                            type="number"
                                            name="spray_direction"
                                            onBlur={(v: any) => setFieldValue('spray_direction', this.roundNumber(v.target.value))}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row mt-2">
                                      <div className="col-sm-6 col-md-4 col-lg-4">
                                        <div className="px-5">
                                          {this.state.fitting_session
                                            ?.all_swings_entry && (
                                              <button
                                                className="btn btn-outline-darkblue mt-2"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                  if (this.state.manual_data_collection) {
                                                    this.submitNewHitAndAddNewHit((batting_sessions as any)[batting_sessions.length - 1],
                                                      true,
                                                      {
                                                        exit_velocity: 0,
                                                        launch_angle: 0,
                                                        spray_direction: 0,
                                                        player_rating: 0,
                                                        custom_metric_value: 0,
                                                      }
                                                    );
                                                  } else {
                                                    this.submitNewHit((batting_sessions as any)[batting_sessions.length - 1],
                                                      true,
                                                      {
                                                        exit_velocity: 0,
                                                        launch_angle: 0,
                                                        spray_direction: 0,
                                                        player_rating: 0,
                                                        custom_metric_value: 0,
                                                      }
                                                    );
                                                  }
                                                }}
                                              >
                                                Foul Ball / Swing Miss
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                      <div className="col-sm-6 col-md-4 col-lg-4">
                                        {this.state.fitting_session
                                          ?.custom_metric_label && (
                                            <div className="fourth_metric">
                                              <div className="input-group input-group-lg px-5">
                                                <Field
                                                  className={`form-control ${errors.custom_metric_value &&
                                                    touched.custom_metric_value
                                                    ? "is-invalid"
                                                    : touched.custom_metric_value
                                                      ? "is-valid"
                                                      : ""
                                                    }`}
                                                  placeholder={
                                                    this.state.fitting_session
                                                      ?.custom_metric_label
                                                  }
                                                  name="custom_metric_value"
                                                  type="number"
                                                />
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      <div className="col-sm-6 col-md-4 col-lg-4">
                                        <div className="row">
                                          <div className="col">
                                            {this.state.fitting_session
                                              ?.player_rating_enabled && (
                                                <div className="input-group input-group-lg">
                                                  <Field
                                                    className={`form-control ${errors.player_rating &&
                                                      touched.player_rating
                                                      ? "is-invalid"
                                                      : touched.player_rating
                                                        ? "is-valid"
                                                        : ""
                                                      }`}
                                                    placeholder="Player Rating"
                                                    type="number"
                                                    name="player_rating"
                                                  />
                                                </div>
                                              )}
                                          </div>
                                          <div className="col">
                                            <div className="pr-5 float-right">
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={
                                                  <Tooltip id="tooltip-top">
                                                    Save Hit
                                                  </Tooltip>
                                                }
                                              >
                                                <button
                                                  disabled={this.state.loading}
                                                  type="submit"
                                                  className="btn btn-lg btn-green btn-primary"
                                                >
                                                  <FontAwesomeIcon icon="check" />
                                                </button>
                                              </OverlayTrigger>
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={
                                                  <Tooltip id="tooltip-top">
                                                    Stop
                                                  </Tooltip>
                                                }
                                              >
                                                <button
                                                  disabled={this.state.loading}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.cancelNewHit(
                                                      (
                                                        batting_sessions as any
                                                      )[
                                                      batting_sessions.length -
                                                      1
                                                      ]
                                                    );
                                                  }}
                                                  className="btn btn-lg btn-dark btn-primary ml-2"
                                                >
                                                  <FontAwesomeIcon icon="stop" />
                                                </button>
                                              </OverlayTrigger>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </FormikForm>
                                )}
                              </Formik>
                            ) : (
                              <div className="text-center mt-2">
                                {/* {(batting_sessions as any)[
                                  batting_sessions.length - 1
                                ].hits.length ? (
                                  <button
                                    className="btn btn-outline-darkblue mr-2"
                                    onClick={() => {
                                      this.deleteHit(
                                        (batting_sessions as any)[
                                        batting_sessions.length - 1
                                        ]["hits"][0]
                                      );
                                    }}
                                  >
                                    Undo Last Hit
                                  </button>
                                ) : null} */}
                                <button
                                  disabled={this.state.loading}
                                  className="btn btn-darkblue text-white"
                                  onClick={() => this.addNewHit(batting_sessions[batting_sessions.length - 1])}
                                >
                                  <FontAwesomeIcon icon={this.state.manual_data_collection ? 'keyboard' : 'cloud-download-alt'} />{" "}
                                  {this.state.manual_data_collection ? 'Add' : 'Pull'} New Hit
                                </button>
                              </div>
                            )}
                          </>
                        ) : null}
                      </div>

                      {/* Bats Drag */}
                      {/* <Modal
                        size="lg"
                        show={this.state.showPreferenceModal}
                        onHide={this.closeChooseBatModal}
                        contentClassName="addPlayermodal addBatModal"
                        backdrop="static"
                        centered={true}
                      >
                        <Modal.Body>
                          <div className="tabWrapper">
                            <div className="d-flex justify-content-between">
                              <FontAwesomeIcon icon="arrow-up" />
                              <p
                                style={{ fontSize: 20 }}
                                className="font-weight-bold"
                              >
                                Drag your bats up or down in order of
                                preference
                              </p>
                              <FontAwesomeIcon icon="arrow-down" />
                            </div>
                            <div className="row mt-2">
                              <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                  {(provided, snapshot) => (
                                    <div
                                      className="col-md-10 offset-1"
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      style={getListStyle(
                                        snapshot.isDraggingOver
                                      )}
                                    >
                                      {batting_sessions.map(
                                        (item: any, index: number) => (
                                          <Draggable
                                            key={`item-${item.batting_session.id}`}
                                            draggableId={`item-${item.batting_session.id}`}
                                            index={index}
                                          >
                                            {(provided, snapshot) => (
                                              <div
                                                className="printingBlock mb-4"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                              >
                                                {item.content}

                                                <div className="d-flex justify-content-between">
                                                  <div className="userDetails">
                                                    <div className="d-flex justify-content-between align-content-center">
                                                      <span>
                                                        {Math.round(
                                                          item.bat.length
                                                        )}{" "}
                                                        | {item.bat.brand}{" "}
                                                        {item.bat.model}{" "}
                                                      </span>
                                                    </div>
                                                    <small className="text-muted">
                                                      {item.bat.bpi}
                                                    </small>
                                                  </div>
                                                  <div className="userDetails">
                                                      <small>
                                                        {item.hits.length}
                                                      </small>
                                                    </div>
                                                  <div className="userDetails">
                                                    <button
                                                        className="btn btn-sm"
                                                        onClick={() =>
                                                          this.deleteBattingSession(
                                                            item
                                                          )
                                                        }
                                                      >
                                                        <FontAwesomeIcon
                                                          color="#FF5252"
                                                          icon="trash"
                                                        />
                                                      </button>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        )
                                      )}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </div>
                            <div className="text-center mt-4">
                              <button
                                className="btn btn-lg btn-outline-darkblue mr-2"
                                onClick={() =>
                                  this.setState({
                                    showPreferenceModal: false,
                                  })
                                }
                              >
                                Back
                              </button>

                              <button
                                disabled={!this.canFinishFittingSession()}
                                className="btn btn-lg btn-darkblue text-white"
                                onClick={this.finishFittingSession}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal> */}

                      <Modal
                        size="xl"
                        show={this.state.showFittingSummary}
                        contentClassName="addPlayermodal"
                        backdrop="static"
                        centered={true}
                      >
                        <Modal.Body>
                          <div style={{ height: 500 }}>
                            <div style={{ overflowY: "scroll" }}>
                              <div
                                className="tabWrapper mb-2"
                                style={{ height: 450 }}
                              >
                                <FittingSummary
                                  fittingSession={this.state.fitting_session}
                                  battingSessions={
                                    this.state.batting_sessions
                                  }
                                  deleteHit={this.deleteHit}
                                  deleteBattingSession={
                                    this.deleteBattingSession
                                  }
                                  handleOpenOpenEditHitCollapse={
                                    this.handleOpenOpenEditHitCollapse
                                  }
                                  handleCloseOpenEditHitCollapse={
                                    this.handleCloseOpenEditHitCollapse
                                  }
                                  editHit={this.editHit}
                                />
                              </div>
                            </div>
                            <div className="text-center">
                              <button
                                className="mt-2 btn btn-darkblue text-white"
                                onClick={() =>
                                  this.setState({ showFittingSummary: false })
                                }
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </>
                  ) : (
                    <>
                      {/* Configure Session */}
                      <div className="container p-5">
                        {this.state.player.first_name}{" "}{this.state.player.last_name}
                        <h4>Configure Session</h4>
                        <div className="mt-4">
                          <div className="row my-3">
                            <div className="col-8">
                              Keep player rating enabled for this session?
                            </div>
                            <div className="col-4">
                              <div className="switchBtn">
                                <BootstrapSwitchButton
                                  checked={this.state.player_rating_enabled}
                                  onlabel="Yes"
                                  offlabel="No"
                                  onstyle="primary"
                                  offstyle="danger"
                                  width={150}
                                  onChange={(checked: boolean) =>
                                    this.setState({
                                      player_rating_enabled: checked,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row my-3">
                          <div className="col-8">
                            How would you like to collect hit data?
                          </div>
                          <div className="col-4">
                            <div className="switchBtn">
                              <BootstrapSwitchButton
                                checked={this.state.manual_data_collection}
                                onlabel="Manual"
                                offlabel="Auto"
                                onstyle="primary"
                                offstyle="danger"
                                width={150}
                                onChange={(checked: boolean) =>
                                  this.setState({
                                    manual_data_collection: checked,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row my-3">
                          <div className="col-8">
                            Want to consider all swings for this session?
                          </div>
                          <div className="col-4">
                            <div className="switchBtn">
                              <BootstrapSwitchButton
                                checked={this.state.all_swings_entry}
                                onlabel="Yes"
                                offlabel="In-play Only"
                                onstyle="primary"
                                offstyle="danger"
                                width={150}
                                onChange={(checked: boolean) =>
                                  this.setState({ all_swings_entry: checked })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row my-3">
                          <div className="col-8">
                            A 4th custom metric you want to track (Optional)
                          </div>
                          <div className="col-4">
                            <div className="input-group w-50">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Label"
                                value={this.state.custom_metric_label}
                                onChange={(e) =>
                                  this.setState({
                                    custom_metric_label: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="text-center mt-3">
                          <button
                            onClick={this.attemptProcessFittingSession}
                            className="btn btn-lg btn-green"
                          >
                            Create Session
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            <Modal show={this.state.loading} centered={true}>
                <Modal.Body className="p-0">
                    <h3>Loading...</h3>
                </Modal.Body>
            </Modal>

            {/* Bat Search */}
            <Modal
              size="lg"
              show={this.state.showChooseBatModal}
              onHide={this.closeChooseBatModal}
              contentClassName="addPlayermodal addBatModal"
              backdrop="static"
              centered={true}
            >
              <Modal.Body className="p-0">
                <div className="row">
                  <div className="col-md-12">
                    <div className="tabWrapper">
                      {this.state.customBat ? (
                        <NewBat fromScreen='fitting'
                          mode="custom"
                          player={this.state.player}
                          batId={null}
                          batModalTypes={userRole.name?.toString().toLowerCase().includes('admin') ? this.state.batModalTypes : this.state.newBatModalTypes}
                          batModalBrands={this.state.batModalBrands}
                          handleClose={this.handleCloseBatEdit}
                          createAndChooseCustomBat={
                            this.createAndChooseCustomBat
                          }
                        />
                      ) : (
                        <div className="batSearchBlock">
                          <ToolkitProvider
                            keyField="id"
                            data={this.state.batsInSearch}
                            columns={this.state.columns}
                            search
                          >
                            {(props) => (
                              <>
                                <SearchBar
                                  style={{ width: 300, fontSize: 22 }}
                                  className="form-control"
                                  placeholder="Search"
                                  {...props.searchProps}
                                />

                                <button
                                  className="float-right btn btn-darkblue text-white"
                                  onClick={() => {
                                    var tempState = this.state;
                                    tempState.customBat =
                                      EmptyBatInterfaceDto;
                                    this.setState(tempState);
                                  }}
                                >
                                  Custom Bat
                                </button>

                                <div className="row">
                                  <div className="mb-3 col-md-6">
                                    <label>Type</label>
                                    <Form.Control
                                      as="select"
                                      value={
                                        this.state.batModalDropSearch != null
                                          ? this.state.batModalDropSearch
                                          : undefined
                                      }
                                      onChange={
                                        this.handleBatModalDropSearchChange
                                      }
                                    >
                                      <option value="">-- Select --</option>
                                      {this.state.batModalTypesSearch.map(
                                        (batType: BatType, i: number) => {
                                          return (
                                            <option
                                              key={i}
                                              value={batType.value}
                                            >
                                              {batType.label}
                                            </option>
                                          );
                                        }
                                      )}
                                    </Form.Control>
                                  </div>
                                  <div className="mb-3 col-md-6">
                                    <label>Brand</label>
                                    <Form.Control
                                      as="select"
                                      value={this.state.batModalBrandSearch}
                                      onChange={
                                        this.handleBatModalBrandSearchChange
                                      }
                                    >
                                      <option value="">-- Select --</option>
                                      {this.state.batModalBrands.map(
                                        (batBrand, i) => {
                                          return (
                                            <option key={i} value={batBrand}>
                                              {batBrand}
                                            </option>
                                          );
                                        }
                                      )}
                                    </Form.Control>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="table-wrapper">
                                      <BootstrapTable
                                        pagination={pagination}
                                        selectRow={selectRow}
                                        wrapperClasses="table-responsive batsTable"
                                        {...props.baseProps}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </ToolkitProvider>

                          <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className="text-left">
                              <button
                                onClick={this.chooseBatFromModal}
                                className="btn btn-darkblue text-white m-2"
                              >
                                Choose
                              </button>
                              <button
                                onClick={this.closeChooseBatModal}
                                className="btn btn-outline-darkblue m-2"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
        
        <Modal
          size="lg"
          show={this.state.showBatEditor}
          onHide={this.handleClose}
          contentClassName="addPlayermodal"
          backdrop="static"
          centered={true}
        >
          <Modal.Body className="p-0">
            <div className="row">
              <div className="col-md-12 m-0">
                <ul
                  className="nav nav-tabs d-flex justify-content-center"
                  id="myTab"
                  role="tablist"
                  style={{ visibility: "hidden" }}
                >
                  <li role="presentation">
                    <a
                      className="nav-link p-0 active "
                      id="playerInfo-tab"
                      data-toggle="tab"
                      href="#playerInfo"
                      role="tab"
                      aria-controls="playerInfo"
                      aria-selected="false"
                    >
                      Player Info{" "}
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      className="nav-link p-0"
                      id="biometrics-tab"
                      data-toggle="tab"
                      href="#biometrics"
                      role="tab"
                      aria-controls="biometrics"
                      aria-selected="true"
                    >
                      {" "}
                      Biometrics Info{" "}
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      className="nav-link p-0"
                      id="review-tab"
                      data-toggle="tab"
                      href="#review"
                      role="tab"
                      aria-controls="review"
                      aria-selected="false"
                    >
                      {" "}
                      Review Info{" "}
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade active show"
                    id="playerInfo"
                    role="tabpanel"
                    aria-labelledby="playerInfo-tab"
                  >
                    <div className="tabWrapper">
                      <NewBat fromScreen='fitting'
                        player={this.state.player}
                        mode={'edit'}
                        batId={this.state.selectedBat}
                        batModalTypes={userRole.name?.toString().toLowerCase().includes('admin') ? this.state.batModalTypes : this.state.newBatModalTypes}
                        batModalBrands={this.state.batModalBrands}
                        handleClose={this.handleCloseBatEdit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default NewFittingSession;
