/* 
  Isaac Hormel
  5/27/2020
  Sends a request to the NodeJS API server with an email and password to attempt to login. If successful it sets the user cookie.
*/

import BasicRequest from "../classes/BasicRequest";

import Cookies from "universal-cookie";
import CoachAccountInterface from "../../interfaces/apiInterfaces/CoachAccountInterface";
import { compareDates } from '../../utils/dateUtils';
const cookies = new Cookies();

interface AuthLoginPostOptionsInterface{
  email: string;
  password: string;
};
interface AuthLoginPostBodyInterface{
  email: string;
  password: string;
};

export interface AuthLoginPostResponseInterface{
  data?: CoachAccountInterface
  message: "login"|string;
}

export default class AuthLoginRequest extends BasicRequest{
  static post(options: AuthLoginPostOptionsInterface): Promise<AuthLoginPostResponseInterface>|any{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var body: AuthLoginPostBodyInterface = {
        ...options
      };
      var authLoginPostRequest = fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      authLoginPostRequest.then(rawRequest => {
        return rawRequest.json();
      }).then((requestJson: AuthLoginPostResponseInterface) => {
        if(requestJson.message === 'login') {
          let settings = requestJson?.data?.settings;
          if (settings?.usage_type === 'basic') {
            if (settings?.account_enabled) {
              cookies.set("user", `Basic ${btoa(options.email+":"+options.password)}`);
              localStorage.setItem('user', JSON.stringify(requestJson.data));
              resolvePromise(requestJson);
            }else{
              resolvePromise({
                data: null,
                message: "Your account is deactivated. Please contact administrator"
              });
            }
          } else if (settings?.usage_type === 'sessions_based') {
            if (settings.sessions_left > 0) {
              cookies.set("user", `Basic ${btoa(options.email+":"+options.password)}`);
              localStorage.setItem('user', JSON.stringify(requestJson.data));
              resolvePromise(requestJson);
            }else{
              resolvePromise({
                data: null,
                message: "No sessions left on your account"
              });
            }
          } else if (settings?.usage_type === 'time_based') {
            if(compareDates(settings.end_date) !== -1) {
              cookies.set("user", `Basic ${btoa(options.email+":"+options.password)}`);
              localStorage.setItem('user', JSON.stringify(requestJson.data));
              resolvePromise(requestJson);
            }else{
              resolvePromise({
                data: null,
                message: "Your account is expired. Please contact administrator"
              });
            }
          }
        } else {
          resolvePromise(requestJson);
        }
      });
      authLoginPostRequest.catch(err => {
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}