/* 
  Isaac Hormel
  5/29/2020
  Sends a request to the NodeJS API server with an with a playerDto and a playerBiometricDto to create a new player. 
  Also, we can edit a player using the interface on the put route.
*/

import BasicRequest from "../classes/BasicRequest";

import Cookies from "universal-cookie";
import CoachAccountInterface from "../../interfaces/apiInterfaces/CoachAccountInterface";
import PlayerInterface, { PlayerInterfaceDto } from "../../interfaces/apiInterfaces/PlayerInterface";
import PlayerBiometricInterface, { PlayerBiometricInterfaceDto } from "../../interfaces/apiInterfaces/PlayerBiometricInterface";
import PlayerPowerTestingInterface, { PlayerPowerTestingInterfaceDto  } from "../../interfaces/apiInterfaces/PlayerPowerTestingInterface";
import PlayerMovementScreeningInterface, { PlayerMovementScreeningInterfaceDto } from "../../interfaces/apiInterfaces/PlayerMovementScreeningInterface";
const cookies = new Cookies();

export interface ProcessPlayerPostOptionsInterface{
  id?: number;
  player: PlayerInterfaceDto;
  player_bio_metric: PlayerBiometricInterfaceDto;
  player_movement_screening: PlayerMovementScreeningInterfaceDto;
  player_power_testing: PlayerPowerTestingInterfaceDto;
};
export interface ProcessPlayerPutOptionsInterface{
  id: number;
  player: PlayerInterface;
  player_bio_metric: PlayerBiometricInterfaceDto;
  player_movement_screening: PlayerMovementScreeningInterfaceDto;
  player_power_testing: PlayerPowerTestingInterfaceDto;
};
interface ProcessPlayerPostBodyInterface{
  player: PlayerInterfaceDto;
  player_bio_metric: PlayerBiometricInterfaceDto;
  player_movement_screening: PlayerMovementScreeningInterfaceDto;
  player_power_testing: PlayerPowerTestingInterfaceDto;
};

export interface ProcessPlayerPostResponseInterface{
  data?: {
    player: PlayerInterface,
    player_bio_metric: PlayerBiometricInterface,
    player_movement_screening: PlayerMovementScreeningInterface;
    player_power_testing: PlayerPowerTestingInterface;
  }
  message: "created"|string;
}
export interface ProcessPlayerPutResponseInterface{
  data?: {
    player: PlayerInterface,
    player_bio_metric: PlayerBiometricInterface,
    player_movement_screening: PlayerMovementScreeningInterface;
    player_power_testing: PlayerPowerTestingInterface;
  }
  message: "updated"|string;
}

export default class ProcessPlayerRequest extends BasicRequest{
  static post(options: ProcessPlayerPostOptionsInterface): Promise<ProcessPlayerPostResponseInterface|any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/process/player`);
      var body: ProcessPlayerPostBodyInterface = {
        ...options
      };
      var ProcessPlayerPostRequest = fetch(destination.toString(), {
        method: "POST",
        headers: {
          "Authorization": cookies.get("user"),
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      ProcessPlayerPostRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: ProcessPlayerPostResponseInterface)=>{
        resolvePromise(requestJson);
      });
      ProcessPlayerPostRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
  static put(options: ProcessPlayerPostOptionsInterface): Promise<ProcessPlayerPostResponseInterface|any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/process/player`);
      destination.pathname += `/${options.id}`;
      var body: ProcessPlayerPostBodyInterface = {
        ...options
      };
      var ProcessPlayerPostRequest = fetch(destination.toString(), {
        method: "PUT",
        headers: {
          "Authorization": cookies.get("user"),
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      ProcessPlayerPostRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: ProcessPlayerPutResponseInterface)=>{
        resolvePromise(requestJson);
      });
      ProcessPlayerPostRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}