import BasicRequest from "../classes/BasicRequest";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export interface ExportDataGetOptionsInterface {
  url: string;
}
export interface ExportDataPostResponseInterface {
  data?: any; //Getting a 500 atm so just leave it as a generic object. To be updated later
  message: "export" | string;
}

export default class ExportDataRequest extends BasicRequest {
  static get(endpoint: string)  {
    var promise = new Promise((resolvePromise, rejectPromise) => {
      let url = `${process.env.REACT_APP_API_URL}/${endpoint}`;
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: cookies.get("user"),
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "Transfer-Encoding": "chunked",
        },
      })
        .then((response) => response.blob())
        .then((blob) => resolvePromise(blob))
        .catch((err) => {
          if (err.message) {
            rejectPromise(err.message);
          } else {
            rejectPromise(new Error(err));
          }
        });
    });
    return promise;
  }
}
