import React, { ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "whatwg-fetch";
import { Modal } from "react-bootstrap";
import BatRXTextLogo from "../assets/images/Logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import history from "../history";

import CoachAccountInterface, {
  CoachAccountInterfaceDto,
  EmptyCoachAccountInterfaceDto,
} from "../interfaces/apiInterfaces/CoachAccountInterface";
import AuthSignupRequest, {
  AuthSignupPostResponseInterface,
} from "../api/requests/AuthSignupRequest";
import AuthPingRequest, {
  AuthPingGetResponseInterface,
} from "../api/requests/AuthPingRequest";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "Password is too short - should be 8 chars minimum."),
  passwordConfirm: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  termsOfService: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions"
  ),
});

interface RegisterProps {}
interface RegisterState {
  loading: boolean;
  coach: CoachAccountInterfaceDto;
  passwordChecker: string;
  successCreation: CoachAccountInterface | null;
  failedCreation: string;
  showTosModal: boolean;
}

type RegisterForm = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  termsOfService: boolean;
};

class Register extends React.Component<RegisterProps, RegisterState> {
  state: RegisterState = {
    loading: false,
    coach: EmptyCoachAccountInterfaceDto,
    passwordChecker: "",
    successCreation: null,
    failedCreation: "",
    showTosModal: false,
  };

  attemptRegister = (values: RegisterForm) => {
    this.setState({ loading: true });
    let tempState = this.state;
    AuthSignupRequest.post({
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      password: values.password,
      tos_agree: values.termsOfService,
    })
      .then((result: AuthSignupPostResponseInterface) => {
        if (result.data) {
          toast.success(`Welcome ${result.data.first_name}`);
          tempState.successCreation = result.data;
          tempState.failedCreation = "";
          tempState.loading = false;
          this.setState(tempState);
          setTimeout(() => {
            history.push("/login");
          }, 1500);
        } else {
          toast.error(result.message, {
            autoClose: 5000,
          });
          tempState.successCreation = null;
          tempState.loading = false;
          tempState.failedCreation = result.message;
          this.setState(tempState);
        }
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        toast.error(err.message, {
          autoClose: 5000,
        });
        tempState.successCreation = null;
        tempState.failedCreation = err.message;
        this.setState(tempState);
      });
  };

  constructor(props: RegisterProps) {
    super(props);
    AuthPingRequest.get().then((result: AuthPingGetResponseInterface) => {
      if (result.email) {
        history.push("/dash");
      }
    });
  }

  render() {
    if (cookies.get("user")) {
      return <></>;
    }
    return (
      <div className={`reg-form text-center d-flex align-items-center`}>
        <div className={`wrapper form-signin`}>
          <div className="logo mb-4">
            <img src={BatRXTextLogo} className="img-fluid" />
          </div>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              passwordConfirm: "",
              termsOfService: false,
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              this.attemptRegister(values);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                {/* First Name */}
                <div className="mb-3">
                  <div className="input-group">
                    <span className={`iconBorder input-group-text`}>
                      <FontAwesomeIcon icon="user" />
                    </span>
                    <Field
                      placeholder="First Name"
                      className={`inputBorder form-control`}
                      name="firstName"
                    />
                  </div>
                  {errors.firstName && touched.firstName ? (
                    <p className="small text-danger">{errors.firstName}</p>
                  ) : null}
                </div>

                {/* Last Name */}
                <div className="mb-3">
                  <div className="input-group">
                    <span className={`iconBorder input-group-text`}>
                      <FontAwesomeIcon icon="user" />
                    </span>
                    <Field
                      placeholder="Last Name"
                      // onChange={this.handleLastnameChange}
                      className={`inputBorder form-control`}
                      name="lastName"
                    />
                  </div>
                  {errors.lastName && touched.lastName ? (
                    <p className="small text-danger">{errors.lastName}</p>
                  ) : null}
                </div>

                {/* Email */}
                <div className="mb-3">
                  <div className="input-group">
                    <span className={`iconBorder input-group-text`}>
                      {" "}
                      <FontAwesomeIcon icon="envelope" />
                    </span>
                    <Field
                      placeholder="Email"
                      className={`inputBorder form-control ${
                        errors.email && touched.email
                          ? "is-invalid"
                          : touched.email
                          ? "is-valid"
                          : ""
                      }`}
                      name="email"
                    />
                  </div>
                  {errors.email && touched.email ? (
                    <p className="small text-danger">{errors.email}</p>
                  ) : null}
                </div>

                {/* Password */}
                <div className="mb-3">
                  <div className="input-group">
                    <span className={`iconBorder input-group-text`}>
                      <FontAwesomeIcon icon="key" />
                    </span>
                    <Field
                      type="password"
                      className={`inputBorder form-control`}
                      placeholder="Password"
                      name="password"
                    />
                  </div>
                  {errors.password && touched.password ? (
                    <p className="small text-danger">{errors.password}</p>
                  ) : null}
                </div>

                {/* Password Confirm */}
                <div className="mb-3">
                  <div className="input-group">
                    <span className={`iconBorder input-group-text`}>
                      <FontAwesomeIcon icon="key" />
                    </span>
                    <Field
                      type="password"
                      className={`inputBorder form-control`}
                      placeholder="Password Check"
                      name="passwordConfirm"
                    />
                  </div>
                  {errors.passwordConfirm && touched.passwordConfirm ? (
                    <p className="small text-danger">
                      {errors.passwordConfirm}
                    </p>
                  ) : null}
                </div>

                <div className="mb-3" style={{ fontSize: 14 }}>
                  <div className="text-left d-flex align-items-center">
                    <Field
                      type="checkbox"
                      className={`mr-2`}
                      name="termsOfService"
                    />
                    I accept the{" "}
                    <a
                      href="#"
                      style={{ color: "#00a8ff" }}
                      onClick={() => this.setState({ showTosModal: true })}
                    >
                      Terms of Service
                    </a>
                  </div>
                  {errors.termsOfService && touched.termsOfService ? (
                    <p className="small text-danger">{errors.termsOfService}</p>
                  ) : null}
                </div>

                <div className="reg-btn mb-3">
                  <button
                    disabled={this.state.loading}
                    style={{
                      backgroundColor: "#007dbc",
                      border: "1px solid #007dbc",
                    }}
                    type="submit"
                    className="btn btn-primary btn-bkey btn-block"
                  >
                    REGISTER
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="go-to-back">
            <Link to="/login" className="pb-1">
              BACK{" "}
            </Link>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.showTosModal}
          contentClassName="addPlayermodal addBatModal"
          centered={true}
        >
          <Modal.Body>
            <div className="scrollableTab">
              <div className="row">
                <div className="col-md-12">
                  <div className="bg-white p-4">
                    <p>
                      “The Bat Performance Lab provides the
                      https://app.bat-rx.com/ website as a service to the
                      public. The Bat Performance Lab is not responsible for,
                      and expressly disclaims all liability for, damages of any
                      kind arising out of use, reference to, or reliance on any
                      information contained within this website.
                    </p>
                    <p>
                      While the information contained within this website is
                      periodically updated, no guarantee is given that the
                      information provided in this website is correct, complete,
                      and up-to-date.
                    </p>
                    <p>
                      Although The Bat Performance Lab website may include links
                      providing direct access to other Internet resources,
                      including websites, The Bat Performance Lab is not
                      responsible for the accuracy or content of information
                      contained in these sites.
                    </p>
                    <p>
                      Links from The Bat Performance Lab to third-party sites do
                      not constitute an endorsement by The Bat Performance Lab
                      of the parties or their products and services. The
                      appearance on this website of advertisements and product
                      or service information does not constitute an endorsement
                      by The Bat Performance Lab, and The Bat Performance Lab
                      has not investigated the claims made by any advertiser.
                      Product information is based solely on material received
                      from suppliers.”{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-4">
              <button
                className="btn btn-lg btn-darkblue text-white"
                onClick={() =>
                  this.setState({
                    showTosModal: false,
                  })
                }
              >
                I have read the Terms of Service
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Register;
