import React, { useState } from "react";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

export enum AscDescSvg {
  asc = "arrow_downward",
  desc = "arrow_upward",
}

export const SortButton = (props: {
  title: string;
  icon?: AscDescSvg;
  onClick?: any;
}) => {
  const [ascending, setAscending] = useState(props.icon ? props.icon : AscDescSvg.asc);

  React.useEffect(() => {
    setAscending(props.icon)
  },[props.icon]);
  
  return (
    <>
      <IconButton title={props.title} aria-label={props.title} onClick={props.onClick}>
        {props.title}
        <Icon>{ascending}</Icon>
      </IconButton>
    </>
  );
};
