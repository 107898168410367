import React, {createContext, Dispatch, useReducer} from "react";
import { AppActions, appReducer, AppType } from "./reducers";

export const initialValues = {
    user: null,
    setUser: (payload: any) => {}
}

// const AppContext = createContext<{
//     state: AppType;
//     dispatch: Dispatch<AppActions>;
//   }>({
//     state: initialValues,
//     dispatch: () => null
//   });
  
const AppContext = createContext(initialValues);
  

// const mainReducer = (app: AppType, action: AppActions) => ({
//     mainApp: appReducer({ ...initialValues, user: app?.user }, action),
//   });
  
  
//   const AppProvider: React.FC = ({ children }) => {
//     // const [state, dispatch] = useReducer(mainReducer, { initialValues, action: null });
  
//     return (
//       <AppContext.Provider value={ { state: initialValues } }>
//         {children}
//       </AppContext.Provider>
//     )
//   }
  
  export { AppContext };