export const isEmptyObj = (obj: any) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const getUser = () => {
    const user: any = localStorage.getItem('user');
    return JSON.parse(user);
}

export const getUserOrganization = () => {
    const user: any = localStorage.getItem('user');
    if (user) {
        return JSON.parse(user).organization;
    }
}

export const getUserRole = () => {
    const user: any = localStorage.getItem('user');
    if (user) {
        return JSON.parse(user).role;
    }
}

