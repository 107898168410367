import {
  BattingSessionsBarGraphChartDataState,
  BattingSessionsBarGraphChartDataStateBattingSession,
  BattingSessionsBarGraphProps,
  BattingSessionsBarGraphState,
} from "../components/BattingSessionsBarGraph/BattingSessionsBarGraph";
import BatInterface from "../interfaces/apiInterfaces/BatInterface";
import BattingSessionInterface from "../interfaces/apiInterfaces/BattingSessionInterface";
import FittingSessionResultInterface from "../interfaces/apiInterfaces/FittingSessionResultInterface";
import { HitInterfaceDto } from "../interfaces/apiInterfaces/HitInterface";

export const getFittingSessionData = ({
  sessionData,
  result,
}: {
  sessionData: BattingSessionsBarGraphProps;
  result: BattingSessionsBarGraphState;
}) => {
  var tempState = result;
  let custom_metric_avgs = [];
  let custom_metric_stds = [];

  for (let k in sessionData.batting_sessions) {
    let avg = {
      title: `${sessionData.batting_sessions[k].bat.brand} ${sessionData.batting_sessions[k].bat.model}`,
      value: Number(
        (sessionData.batting_sessions[k] as any).average?.custom_metric_value
      ),
    };
    let std = {
      title: `${sessionData.batting_sessions[k].bat.brand} ${sessionData.batting_sessions[k].bat.model}`,
      value: Number(
        (sessionData.batting_sessions[k] as any).cns?.custom_metric_value
      ),
    };
    custom_metric_avgs.push(avg);
    custom_metric_stds.push(std);
  }

  tempState.custom_metric_avgs = custom_metric_avgs;
  tempState.custom_metric_stds = custom_metric_stds;

  var chartData: Array<{ [index: string]: any }> = [];
  for (let i in sessionData.fitting_session_results) {
    /* WHY TF DO I HAVE TO CALL rawFSR A {[index: string]: any} ? */
    var rawFSR: { [index: string]: any } =
      sessionData.fitting_session_results[i].fitting_session_result;
    var keys: Array<string> = Object.keys(rawFSR);
    for (let r in keys) {
      if (
        typeof rawFSR[keys[r]] == "number" ||
        isNaN(rawFSR[keys[r]]) == false
      ) {
        rawFSR[keys[r]] = Math.abs(rawFSR[keys[r]]);
      }
    }
    var batColor = "#000";
    var batModel = "";
    for (let k in sessionData.batting_sessions) {
      if (
        sessionData.batting_sessions[k].batting_session.bat_id ==
        sessionData.fitting_session_results[i].bat.id
      ) {
        batColor = sessionData.batting_sessions[k].batting_session.color;
        batModel = sessionData.batting_sessions[k].bat.model;
      }
    }
    var battingSession: BattingSessionInterface = {
      id: -1,
      bat_id: -1,
      fitting_session_id: -1,
      player_id: -1,
      color: "",
      order: -1,
    };
    var hits: Array<HitInterfaceDto> = [];
    var averageBatVelocity = 0;
    var averageAngleOfAttack = 0;
    var averageExitVelocity = 0;
    var averageLaunchAngle = 0;
    for (let p in sessionData.batting_sessions) {
      if (
        sessionData.batting_sessions[p].batting_session.bat_id ==
        sessionData.fitting_session_results[i].bat.id
      ) {
        battingSession = sessionData.batting_sessions[p].batting_session;
        hits = sessionData.batting_sessions[p].hits;
        for (let j in hits) {
          averageBatVelocity += parseFloat("" + hits[j].bat_velocity);
          averageAngleOfAttack += parseFloat("" + hits[j].angle_of_attack);
          averageExitVelocity += parseFloat(
            "" +
              ((hits[j].corrected_exit_velocity != 0
                ? hits[j].corrected_exit_velocity
                : hits[j].exit_velocity) || hits[j].corrected_exit_velocity)
          );
          averageLaunchAngle += parseFloat(
            "" +
              (hits[j].corrected_launch_angle != 0
                ? hits[j].corrected_launch_angle
                : hits[j].launch_angle)
          );
        }
        averageBatVelocity /= hits.length;
        averageAngleOfAttack /= hits.length;
        averageExitVelocity /= hits.length;
        averageLaunchAngle /= hits.length;
      }
    }
    var battingSessionsBarGraph: BattingSessionsBarGraphChartDataStateBattingSession =
      {
        batting_session: battingSession,
        hits: hits,
      };
    console.log("bargraph", battingSession);
    chartData.push({
      bpi: sessionData.fitting_session_results[i].bat.bpi,
      bat: sessionData.fitting_session_results[i].bat.brand,
      color: battingSession?.color ? battingSession.color : batColor,
      model: batModel,
      designation: `${sessionData.fitting_session_results[i].bat.brand} ${
        sessionData.fitting_session_results[i].bat.model
      } ${sessionData.fitting_session_results[i].bat.type} ${
        Math.round(sessionData.fitting_session_results[i].bat.length * 10) / 10
      }"/${
        Math.round(sessionData.fitting_session_results[i].bat.weight * 10) / 10
      }oz`,
      ...rawFSR,
      ...battingSessionsBarGraph,
      averageBatVelocity,
      averageAngleOfAttack,
      averageExitVelocity,
      averageLaunchAngle,
    });
  }
  chartData.sort((a, b) => {
    return (
      Math.abs(a[tempState.chart_filter]) - Math.abs(b[tempState.chart_filter])
    );
  });

  tempState.chart_data =
    chartData as Array<BattingSessionsBarGraphChartDataState>;
  return tempState;
};

export const consistencyMetricAvgs = (batting_sessions: {
  [x: string]: any;
}) => {
  let custom_metric_avgs = [];
  let custom_metric_stds = [];

  for (let k in batting_sessions) {
    let avg = {
      title: `${batting_sessions[k].bat.brand} ${batting_sessions[k].bat.model}`,
      value: Number((batting_sessions[k] as any).average?.custom_metric_value),
    };
    let std = {
      title: `${batting_sessions[k].bat.brand} ${batting_sessions[k].bat.model}`,
      value: Number((batting_sessions[k] as any).cns?.custom_metric_value),
    };
    custom_metric_avgs.push(avg);
    custom_metric_stds.push(std);
  }
  return { custom_metric_avgs, custom_metric_stds };
};

const getHighest = (arr: any[], prop: string | number) => {
  return Math.max.apply(
    Math,
    arr.map(function (o) {
      return o[prop] || 0;
    })
  );
};

export const getConsistency = (
  batting_session: BattingSessionsBarGraphChartDataState,
  best_bat: { fitting_session_result?: FittingSessionResultInterface; bat?: BatInterface; batting_session: any; },
  cd: any[],
  chart_filter: string = "overall_fit_score"
) => {
  let averageBatVelocity = 1,
    averageAngleOfAttack = 1,
    averageExitVelocity = 1,
    averageLaunchAngle = 1;
  const highest = getHighest(cd, chart_filter);

  if (best_bat.batting_session.id == batting_session.id) {
    if (batting_session.averageBatVelocity > averageBatVelocity) {
      averageBatVelocity = batting_session.averageBatVelocity;
    }
    if (batting_session.averageAngleOfAttack > averageAngleOfAttack) {
      averageAngleOfAttack = batting_session.averageAngleOfAttack;
    }
    if (batting_session.averageExitVelocity > averageExitVelocity) {
      averageExitVelocity = batting_session.averageExitVelocity;
    }
    if (batting_session.averageLaunchAngle > averageLaunchAngle) {
      averageLaunchAngle = batting_session.averageLaunchAngle;
    }
  }
  const value = batting_session[chart_filter];
  
  if (
    chart_filter == "overall_fit_score" ||
    chart_filter == "barrel_stat" ||
    chart_filter == "bat_stat"
  ) {
    return ((value / highest) * 100).toFixed(2);
  } else {
    return value.toFixed(2);
  }
};
