/* 
  Isaac Hormel
  5/27/2020
  Sends a request to the NodeJS API server to create a new coach account.
*/

import BasicRequest from "../classes/BasicRequest";
import CoachAccountInterface, { CoachAccountInterfaceDto } from "../../interfaces/apiInterfaces/CoachAccountInterface";

interface AuthSignupPostOptionsInterface{
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  avatar?: string;
  tos_agree: boolean;
};
interface AuthSignupPostBodyInterface extends CoachAccountInterfaceDto{
};

export interface AuthSignupPostResponseInterface{
  data?: CoachAccountInterface; //Getting a 500 atm so just leave it as a generic object. To be updated later
  message: "This account already exists"|string;
}

export default class AuthSignupRequest extends BasicRequest{
  static post(options: AuthSignupPostOptionsInterface): Promise<AuthSignupPostResponseInterface>|any{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var body: AuthSignupPostBodyInterface = {
        ...options,
        avatar: (options.avatar!=undefined)? options.avatar : ""
      };
      var authSignupPostRequest = fetch(`${process.env.REACT_APP_API_URL}/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      authSignupPostRequest.then(rawRequest=>{
        if(rawRequest.status >= 200 && rawRequest.status < 300){
          return rawRequest.json();
        }else{
          resolvePromise({message: "This account already exists"});
        }
        
      }).then((requestJson: any)=>{
        resolvePromise(requestJson);
      });
      authSignupPostRequest.catch(err=>{
        rejectPromise(new Error(err));
      });
    });
    return promise;
  }
}