export default interface PlayerInterface{
  id: number;
  range_id: number;
  user_id: number;
  first_name: string;
  last_name: string;
  email: string;
  dob: string;
  avatar: string;
  biometrics: Array<any>;
  movement_screenings: Array<any>;
  power_testings: Array<any>;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  org_id?: number;
}

export const EmptyPlayerInterface: PlayerInterface = {
  id: -1,
  range_id: -1,
  user_id: -1,
  first_name: "",
  last_name: "",
  email: "",
  dob: "",
  avatar: "",
  biometrics: [],
  movement_screenings: [],
  power_testings: [],
  org_id: -1
}

export interface PlayerInterfaceDto{
  id?: number;
  range_id: number;
  user_id: number;
  first_name: string;
  last_name: string;
  email: string;
  dob: string;
  avatar: string;
  org_id?: number;
}

export const EmptyPlayerInterfaceDto: PlayerInterfaceDto = {
  range_id: -1,
  user_id: -1,
  first_name: "",
  last_name: "",
  email: "",
  dob: "",
  avatar: "",
  org_id: -1
}