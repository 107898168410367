/* 
  Isaac Hormel
  5/28/2020
  Sends a request with an email to the NodeJS API server to tell it that you forgot your password and would like to reset it.
*/

import BasicRequest from "../classes/BasicRequest";

interface AuthResetPostOptionsInterface{
  email: string;
};
interface AuthResetPostBodyInterface{
  email: string;
};

export interface AuthResetPostResponseInterface{
  data?: any; //Getting a 500 atm so just leave it as a generic object. To be updated later
  message: "reset"|string;
}

export default class AuthResetRequest extends BasicRequest{
  static post(options: AuthResetPostOptionsInterface){
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var body: AuthResetPostBodyInterface = {
        ...options
      };
      var authResetPostRequest = fetch(`${process.env.REACT_APP_API_URL}/auth/reset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      authResetPostRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: AuthResetPostResponseInterface)=>{
        resolvePromise(requestJson);
      });
      authResetPostRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}