import React, { Component } from "react";

import { formatDateToDisplay } from "../../utils/dateUtils";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type BiometricsProps = {
  biometrics: Array<any>;
};
type BiometricsState = {};

class Biometrics extends Component<BiometricsProps, BiometricsState> {
  constructor(props: BiometricsProps) {
    super(props);
  }

  render() {
    const pagination = paginationFactory({ sizePerPage: 5 });
    const dateFormatter = (cell: any, row: any) => {
      return formatDateToDisplay(cell, "do MMM yyyy hh:mm aa");
    };

    const columns = [
      {
        dataField: "createdAt",
        text: "Recorded At",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 375,
        },
        sort: true,
        formatter: dateFormatter,
      },
      {
        dataField: "height",
        text: "Height",
        style: { backgroundColor: "#fff" },
        align: "center",
        headerStyle: {
          width: 125,
        },
      },
      {
        dataField: "weight",
        text: "Weight",
        style: { backgroundColor: "#fff" },
        align: "center",
        headerStyle: {
          width: 125,
        },
      },
      {
        dataField: "hand_width",
        text: "Hand Width",
        style: { backgroundColor: "#fff" },
        align: "center",
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "hand_length",
        text: "Hand Length",
        style: { backgroundColor: "#fff" },
        align: "center",
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "forearm_length",
        text: "Forearm Length",
        style: { backgroundColor: "#fff" },
        align: "center",
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "arm_length",
        text: "Arm Length",
        style: { backgroundColor: "#fff" },
        align: "center",
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "wrist_to_floor",
        text: "Wrist to Floor",
        style: { backgroundColor: "#fff" },
        align: "center",
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "hip_to_floor",
        text: "Hip to Floor",
        style: { backgroundColor: "#fff" },
        align: "center",
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "wingspan",
        text: "Wingspan",
        style: { backgroundColor: "#fff" },
        align: "center",
        headerStyle: {
          width: 150,
        },
      },
    ];

    const expandRow = {
      renderer: (row: any) => (
        <div className="row p-3">
          <div className="col">
            <span className="small">Bats:</span> <b>{row.bats}</b>
          </div>
          <div className="col">
            <span className="small">Throws:</span> <b>{row.throws}</b>
          </div>
          <div className="col">
            <span className="small">Writing Hand:</span>{" "}
            <b>{row.writing_hand}</b>
          </div>
          <div className="col">
            <span className="small">Eating Hand:</span> <b>{row.eating_hand}</b>
          </div>
          <div className="col">
            <span className="small">Dominant Eye:</span>{" "}
            <b>{row.dominant_eye}</b>
          </div>
          <div className="col">
            <span className="small">Dominant Leg:</span>{" "}
            <b>{row.dominant_leg}</b>
          </div>
        </div>
      ),
    };

    return (
      <div className="container-fluid">
        <ToolkitProvider
          keyField="id"
          data={this.props.biometrics.map((item) => ({
            ...item,
            height: Number(item.height).toFixed(2),
            weight: Number(item.weight).toFixed(2),
            hand_width: Number(item.hand_width).toFixed(2),
            hand_length: Number(item.hand_length).toFixed(2),
            forearm_length: Number(item.forearm_length).toFixed(2),
            arm_length: Number(item.arm_length).toFixed(2),
            wrist_to_floor: Number(item.wrist_to_floor).toFixed(2),
            hip_to_floor: Number(item.hip_to_floor).toFixed(2),
            wingspan: Number(item.wingspan).toFixed(2),
          }))}
          columns={columns}
        >
          {(props) => (
            <div className="row">
              <div className="col-lg-12">
                <div className="table-wrapper">
                  <BootstrapTable
                    pagination={pagination}
                    wrapperClasses="table-responsive"
                    expandRow={expandRow}
                    {...props.baseProps}
                  />
                </div>
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

export default Biometrics;
