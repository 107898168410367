/* 
  Isaac Hormel
  5/28/2020
  Sends a request to the NodeJS API server and to get the bats. Can be filtered and paginated depending on the options.
*/

import BasicRequest from "../classes/BasicRequest";

import Cookies from "universal-cookie";
import BatInterface, { BatInterfaceDto } from "../../interfaces/apiInterfaces/BatInterface";
import PaginationAndFilterInterface from "../../interfaces/apiInterfaces/PaginationAndFilterInterface";
const cookies = new Cookies();

export interface BatGetOptionsInterface extends PaginationAndFilterInterface{
  id?: number;
  brand?: string;
  category?: string;
  type?: string;
};
export interface BatPostOptionsInterface {
  id?: number;
  bat: BatInterfaceDto;
}

interface BatGetBodyInterface{
  
}; 
interface BatPostResponseBodyInterface extends BatInterfaceDto{

}

export interface BatGetResponseInterface{
  data?: Array<BatInterface>|BatInterface;
  message: "findAll"|"findOne";
}
export interface BatPostResponseInterface{
  data?: BatInterface;
  message: string;
}

export default class BatRequest extends BasicRequest{
  static get(options?: BatGetOptionsInterface): Promise<BatGetResponseInterface|any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/bats`);
      if(options?.filter_key || options?.limit || options?.offset || options?.brand || options?.category){
        if(options?.filter_key){
          destination.searchParams.append("filter_key", options.filter_key);
          destination.searchParams.append("filter_value", options?.filter_value||"");
        }
        if(options?.offset){
          destination.searchParams.append("offset", options.offset.toString());
        }
        if(options?.limit){
          destination.searchParams.append("limit", options.limit.toString());
        }
        if(options?.brand){
          destination.searchParams.append("brand", options.brand);
        }
        if(options?.category){
          destination.searchParams.append("category", options.category);
        }
        if(options?.type){
          destination.searchParams.append("type", options.type);
        }
      }
      if(options?.id){
        destination.pathname = `bats/${options?.id}`;
      }
      var BatGetRequest = fetch(destination.toString(), {
        method: "GET",
        headers: {
          "Authorization": cookies.get("user")
        }
      });
      BatGetRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: BatGetResponseInterface)=>{
        resolvePromise(requestJson);
      });
      BatGetRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
  static post(options: BatPostOptionsInterface): Promise<BatPostResponseInterface|any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/bats`);
      var body: BatPostResponseBodyInterface = {
        ...options.bat
      };
      var BatPostRequest = fetch(destination.toString(), {
        method: "POST",
        headers: {
          "Authorization": cookies.get("user"),
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      BatPostRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: BatPostResponseInterface)=>{
        resolvePromise(requestJson);
      });
      BatPostRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
  static put(options: BatPostOptionsInterface): Promise<BatPostResponseInterface|any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/bats`);
      destination.pathname += `/${options.id}`;
      var body: BatPostResponseBodyInterface = {
        ...options.bat
      };
      var BatPutRequest = fetch(destination.toString(), {
        method: "PUT",
        headers: {
          "Authorization": cookies.get("user"),
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      BatPutRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: BatPostResponseInterface)=>{
        resolvePromise(requestJson);
      });
      BatPutRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}