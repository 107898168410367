/* 
  Isaac Hormel
  5/28/2020
  Sends a request to the NodeJS API server and uses the authorization header to tell if the user is valid or not.
*/

import BasicRequest from "../classes/BasicRequest";

import Cookies from "universal-cookie";
import CoachAccountInterface from "../../interfaces/apiInterfaces/CoachAccountInterface";
const cookies = new Cookies();

interface AuthPingGetOptionsInterface{
  
};
interface AuthPingGetBodyInterface{
  
};

export interface AuthPingGetResponseInterface extends CoachAccountInterface{
  
}

export default class AuthPingRequest extends BasicRequest{
  static get(): Promise<AuthPingGetResponseInterface|{message: string}>|any{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var authPingGetRequest = fetch(`${process.env.REACT_APP_API_URL}/auth/ping`, {
        method: "GET",
        headers: {
          "Authorization": cookies.get("user")
        }
      });
      authPingGetRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: AuthPingGetResponseInterface)=>{
        resolvePromise(requestJson);
      });
      authPingGetRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}