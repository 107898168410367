import React from "react";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';

export const CircularProgressGuage: React.FC<{val: number, title: string | undefined, externalTitle?: string}> = ({
  val,
  title,
  externalTitle
}) => {
  return (
    <CircularProgressbarWithChildren
      value={val}
      styles={buildStyles({
        // Rotation of path and trail, in number of turns (0-1)
        rotation: 0.25,

        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        strokeLinecap: "round",

        // Text size
        textSize: "10px",

        // How long animation takes to go from one percentage to another, in seconds
        pathTransitionDuration: 0.5,

        // Can specify path transition in more detail, or remove it entirely
        // pathTransition: 'none',

        // Colors
        // pathColor: `rgba(105, 50, 100, ${val * 10 / 100})`,
        // textColor: "#f88",
        // trailColor: `rgba(0, 123, 10, ${val * 10 / 100})`,
        // backgroundColor: "#3e98c7",
      })}
    >
        <div>{externalTitle}</div>
        <div>{title}</div>
    </CircularProgressbarWithChildren>
  );
};
