/* 
  Isaac Hormel
  5/28/2020
  Sends a request to the NodeJS API server and to get batting session results. Can be filtered and paginated depending on the options.
*/

import BasicRequest from "../classes/BasicRequest";

import Cookies from "universal-cookie";
import PaginationAndFilterInterface from "../../interfaces/apiInterfaces/PaginationAndFilterInterface";
import PlayerBiometricInterface from "../../interfaces/apiInterfaces/PlayerBiometricInterface";
const cookies = new Cookies();

interface PlayerBiometricGetOptionsInterface extends PaginationAndFilterInterface{
  id?: number;
};
interface PlayerBiometricGetBodyInterface{
  
}; 

export interface PlayerBiometricGetResponseInterface{
  data?: Array<PlayerBiometricInterface>|PlayerBiometricInterface;
  message: "findAll"|"findOne";
}

export default class PlayerBiometricRequest extends BasicRequest{
  static get(options?: PlayerBiometricGetOptionsInterface): Promise<PlayerBiometricGetResponseInterface|any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/player_bio_metrics`);
      if(options?.filter_key || options?.limit || options?.offset){
        if(options?.filter_key){
          destination.searchParams.append("filter_key", options.filter_key);
          destination.searchParams.append("filter_value", options?.filter_value||"");
        }
        if(options?.offset){
          destination.searchParams.append("offset", options.offset.toString());
        }
        if(options?.limit){
          destination.searchParams.append("limit", options.limit.toString());
        }
      }
      if(options?.id){
        destination.pathname += `/${options?.id}`;
      }
      var PlayerBiometricGetRequest = fetch(destination.toString(), {
        method: "GET",
        headers: {
          "Authorization": cookies.get("user")
        }
      });
      PlayerBiometricGetRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: PlayerBiometricGetResponseInterface)=>{
        resolvePromise(requestJson);
      });
      PlayerBiometricGetRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}