import React, { Component } from "react";
import 'whatwg-fetch';
import Header from "../components/Header";
import Biometrics from "./PlayerHistory/Biometrics";
import MovementScreenings from "./PlayerHistory/MovementScreenings";
import PowerTestings from "./PlayerHistory/PowerTestings";
import FittingSessions from "./PlayerHistory/FittingSessions";
import { Tabs, Tab } from "react-bootstrap";
import history from "../history";
import Cookies from "universal-cookie";
import PlayerInterface, { EmptyPlayerInterface } from "../interfaces/apiInterfaces/PlayerInterface";
import PlayerBiometricInterface from "../interfaces/apiInterfaces/PlayerBiometricInterface";
import CoachAccountInterface, { EmptyCoachAccountInterface } from "../interfaces/apiInterfaces/CoachAccountInterface";
import AuthPingRequest, { AuthPingGetResponseInterface } from "../api/requests/AuthPingRequest";
import PlayerRequest, { PlayerGetResponseInterface } from "../api/requests/PlayerRequest";

const cookies = new Cookies();

interface PlayerProps{ 

}
interface PlayerState{
  coach: CoachAccountInterface;
  player: PlayerInterface;
}

class Player extends Component<PlayerProps, PlayerState> {

  state: PlayerState = {
    coach: EmptyCoachAccountInterface,
    player: {
      ...EmptyPlayerInterface,
      dob: ""+(new Date((new Date()).setFullYear( (new Date()).getFullYear() - 10 ))).toISOString(),
      avatar: "",
      biometrics: [],
      movement_screenings: [],
      power_testings: []
    },
  }

  createNewSession = ()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const playerId = urlParams.get("id");
    history.push(`/new-session?player=${playerId}`);
  }

  componentDidMount = ()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const playerId = urlParams.get("id");
    AuthPingRequest.get().then((result: AuthPingGetResponseInterface)=>{
      if(!result.email){
        history.push("/login");
      }else{
        var tempState = this.state;
        tempState.coach = result;
        this.setState(tempState);
      }
    });
    if(playerId){
      PlayerRequest.get({
        id: parseInt(playerId),
        withInfo: true
      }).then((result: PlayerGetResponseInterface)=>{
        if(result.data && !Array.isArray(result.data)){
          var tempState = this.state;
          tempState.player = result.data;
          this.setState(tempState);
        }
      });
    }
  }

  constructor(props: PlayerProps){
    super(props);
  }

  render() {
    const urlParams = new URLSearchParams(window.location.search);
    const urlTab: string = urlParams.get("tab") || "biometrics";
    const { player } = this.state;
    return (
      <>
        <Header/>
        <div className="playerLibrary">
          <h2 style={{ fontWeight: 700, padding: 10 }}>{player.first_name} {player.last_name}</h2>
          <Tabs
            defaultActiveKey={urlTab}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="biometrics" title="Biometrics">
              <Biometrics biometrics={this.state.player.biometrics} />
            </Tab>
            <Tab eventKey="movement_screenings" title="Movement Screenings" >
              <MovementScreenings movement_screenings={this.state.player.movement_screenings} />
            </Tab>
            <Tab eventKey="power_testings" title="Power Testings">
              <PowerTestings power_testings={this.state.player.power_testings} />
            </Tab>
            <Tab eventKey="fitting_sessions" title="Fitting Sessions">
              <FittingSessions />
            </Tab>
          </Tabs>
        </div>
      </>
    );
  }
}

export default Player;
