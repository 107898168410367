import _ from "lodash";

const toggleBioMetricsUnits = (
  useMetricSystem: boolean,
  player_bio_metric: any
) => {
  if (!useMetricSystem) {
    player_bio_metric.weight = Math.ceil(player_bio_metric.weight * 2.2);
    player_bio_metric.height = Math.ceil(player_bio_metric.height * 0.39);
    player_bio_metric.hand_width = Math.round(
      player_bio_metric.hand_width * 0.39
    );
    player_bio_metric.hand_length = Math.round(
      player_bio_metric.hand_length * 0.39
    );
    player_bio_metric.forearm_length = Math.round(
      player_bio_metric.forearm_length * 0.39
    );
    player_bio_metric.arm_length = Math.round(
      player_bio_metric.arm_length * 0.39
    );
    player_bio_metric.wrist_to_floor = Math.round(
      player_bio_metric.wrist_to_floor * 0.39
    );
    player_bio_metric.hip_to_floor = Math.round(
      player_bio_metric.hip_to_floor * 0.39
    );
    player_bio_metric.wingspan = Math.round(player_bio_metric.wingspan * 0.39);
  } else {
    player_bio_metric.weight = Math.round(player_bio_metric.weight / 2.2);
    player_bio_metric.height = Math.round(player_bio_metric.height / 0.39);
    player_bio_metric.hand_width = Math.round(
      player_bio_metric.hand_width / 0.39
    );
    player_bio_metric.hand_length = Math.round(
      player_bio_metric.hand_length / 0.39
    );
    player_bio_metric.forearm_length = Math.round(
      player_bio_metric.forearm_length / 0.39
    );
    player_bio_metric.arm_length = Math.round(
      player_bio_metric.arm_length / 0.39
    );
    player_bio_metric.wrist_to_floor = Math.round(
      player_bio_metric.wrist_to_floor / 0.39
    );
    player_bio_metric.hip_to_floor = Math.round(
      player_bio_metric.hip_to_floor / 0.39
    );
    player_bio_metric.wingspan = Math.round(player_bio_metric.wingspan / 0.39);
  }
  return player_bio_metric;
};

export const AVAILABLE_BAR_COLORS: string[] = [
  "rgb(250, 22, 22)",
  "rgb(34, 164, 245)",
  "rgb(245, 156, 0)",
  "rgb(119, 24, 137)",
  "rgb(17, 245, 222)",
  "rgb(189, 210, 0)",
  "rgb(245, 10, 112)",
  "rgb(0, 46, 198)",
  "rgb(194, 123, 0)",
  "rgb(234, 0, 234)",
  "rgb(0, 166, 197)",
  "rgb(0, 204, 7)",
  "rgb(185, 62, 0)",
];

export const getBarGraphColors = (alreadySelectedColors: string[]) => {
    return _.xor(AVAILABLE_BAR_COLORS, alreadySelectedColors)[0];
};

export default toggleBioMetricsUnits;
