import { Box, Grid } from "@material-ui/core";
import * as React from "react";
import { SprayDirectionProps } from "../../../components/Gauges/SprayDirection";
import { CardDisplay, useDefaultGraphStyles } from "./GridBasedGraphs";

const SprayDirectionDraw: React.FC<{ props: SprayDirectionProps }> = ({
  props,
}) => {
  const getShadeOfRed = (count: number) => {
    const { hitCount } = props;
    const shades = ["#FFCDD2", "#FF8A80", "#FF5252", "#FF1744", "#D50000"];
    let percent = count ? (count / hitCount) * 100 : 0;
    if (percent === 0) {
      return "#FFEBEE";
    } else if (percent >= 0 && percent < 20) {
      return shades[0];
    } else if (percent >= 20 && percent < 40) {
      return shades[1];
    } else if (percent >= 40 && percent < 60) {
      return shades[2];
    } else if (percent >= 60 && percent < 80) {
      return shades[3];
    } else if (percent >= 80 && percent <= 100) {
      return shades[4];
    }
  };

  const classes = useDefaultGraphStyles();

  return (
    <>
      <Box display="flex" flexDirection="row" paddingLeft={'15%'} p={1} m={1}>
        <Box p={1}>
          <div className={classes.colorWheel}>
            <div
              className={classes.pointer_spray_direction}
              style={{
                transform: `rotate( ${
                  props.average === undefined ? 0 : props.average
                }deg)`,
              }}
            ></div>
            {[
              [-36, "l2"],
              [-18, "l1"],
              [0, "mid"],
              [18, "r1"],
              [36, "r2"],
            ].map((val) => {
              const rotate = `rotate(${val[0]}deg)`;
              return (
                <span key={val[1]}
                  style={{
                    WebkitTransform: rotate,
                    MozTransformOrigin: rotate,
                    msTransform: rotate,
                    OTransform: rotate,
                    transform: rotate,
                    borderColor: `${getShadeOfRed(
                      props.spray_direction_spread[val[1]]
                    )} transparent transparent transparent`,
                  }}
                ></span>
              );
            })}
          </div>
        </Box>
      </Box>
    </>
  );
};

const GridSprayDirection: React.FC<{ props: SprayDirectionProps }> = ({
  props,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SprayDirectionDraw props={props} />
      </Grid>
      <Grid item xs={12} style={{paddingTop: '18%'}} >
        <CardDisplay
          props={{
            top: "SPRAY",
            center: `${props.roundNumber(props.average)} deg (+/- ${props.consistency === undefined
              ? 0
              : props.roundNumber(props.consistency)}
            )`,
            bottom: "DIRECTION",
          }}
        ></CardDisplay>
      </Grid>
    </Grid>
  );
};

export default GridSprayDirection;
