export default interface PlayerPowerTestingInterface {
  id: number;
  player_id: number;
  grip_strength_lh: number;
  grip_strength_rh: number;
  vertical_jump: number;
  med_ball_situp: number;
  med_ball_chest: number;
  horsepower: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export const EmptyPlayerPowerTesting: PlayerPowerTestingInterface = {
  id: 0,
  player_id: 0,
  grip_strength_lh: 0,
  grip_strength_rh: 0,
  vertical_jump: 0,
  med_ball_situp: 0,
  med_ball_chest: 0,
  horsepower: 0
}

export interface PlayerPowerTestingInterfaceDto {
  id?: number;
  player_id?: number;
  grip_strength_lh: number;
  grip_strength_rh: number;
  vertical_jump: number;
  med_ball_situp: number;
  med_ball_chest: number;
  horsepower: number;
}

export const EmptyPlayerPowerTestingDto: PlayerPowerTestingInterfaceDto = {
  grip_strength_lh: 0,
  grip_strength_rh: 0,
  vertical_jump: 0,
  med_ball_situp: 0,
  med_ball_chest: 0,
  horsepower: 0
}