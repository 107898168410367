import React, { Component } from "react";

import { formatDateToDisplay } from "../../utils/dateUtils";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

type PowerTestingsProps = {
  power_testings: Array<any>;
};

type PowerTestingsState = {};

class PowerTestings extends Component<PowerTestingsProps, PowerTestingsState> {
  constructor(props: PowerTestingsProps) {
    super(props);
  }
  render() {
    const pagination = paginationFactory({ sizePerPage: 5 });

    const dateFormatter = (cell: any, row: any) => {
      return formatDateToDisplay(cell, "do MMM yyyy hh:mm aa");
    };

    const columns = [
      {
        dataField: "createdAt",
        text: "Recorded At",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 250,
        },
        sort: true,
        formatter: dateFormatter,
      },
      {
        dataField: "grip_strength_lh",
        text: "Grip Strength LH",
        style: { backgroundColor: "#fff" },
        align: "center",
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "grip_strength_rh",
        text: "Grip Strength RH",
        style: { backgroundColor: "#fff" },
        align: "center",
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "vertical_jump",
        text: "Vertical Jump",
        style: { backgroundColor: "#fff" },
        align: "center",
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "med_ball_situp",
        text: "Med Ball Situp",
        style: { backgroundColor: "#fff" },
        align: "center",
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "med_ball_chest",
        text: "Med Ball Chest",
        style: { backgroundColor: "#fff" },
        align: "center",
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "horsepower",
        text: "Horsepower",
        style: { backgroundColor: "#fff" },
        align: "center",
        headerStyle: {
          width: 150,
        },
      },
    ];
    return (
      <div className="container-fluid">
        <ToolkitProvider
          keyField="id"
          data={this.props.power_testings.map((item) => ({
            ...item,
            grip_strength_lh: Number(item.grip_strength_lh).toFixed(2),
            grip_strength_rh: Number(item.grip_strength_rh).toFixed(2),
            vertical_jump: Number(item.vertical_jump).toFixed(2),
            med_ball_situp: Number(item.med_ball_situp).toFixed(2),
            med_ball_chest: Number(item.med_ball_chest).toFixed(2),
            horsepower: Number(item.horsepower).toFixed(2),
          }))}
          columns={columns}
          // search
        >
          {(props) => (
            <div className="row">
              <div className="col-lg-12">
                <div className="table-wrapper">
                  <BootstrapTable
                    pagination={pagination}
                    wrapperClasses="table-responsive"
                    {...props.baseProps}
                  />
                </div>
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

export default PowerTestings;
