export default interface CoachAccountInterface {
  id?: number;
  user_id?: number;
  first_name: string;
  last_name: string;
  email: string;
  settings: any;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export const EmptyCoachAccountInterface: CoachAccountInterface = {
  id: -1,
  user_id: -1,
  first_name: "",
  last_name: "",
  email: "",
  settings: {}
}

export interface CoachAccountInterfaceDto {
  id?: number;
  user_id?: number;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  avatar: string;
  tos_agree: boolean;
} 

export const EmptyCoachAccountInterfaceDto: CoachAccountInterfaceDto = {
  id: -1,
  user_id: -1,
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  avatar: "",
  tos_agree: false
} 