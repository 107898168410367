import React, { Component } from "react";

import { formatDateToDisplay } from "../../utils/dateUtils";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "whatwg-fetch";

import history from "../../history";
import Cookies from "universal-cookie";
import CoachAccountInterface, {
  EmptyCoachAccountInterface,
} from "../../interfaces/apiInterfaces/CoachAccountInterface";
import FittingSessionInterface from "../../interfaces/apiInterfaces/FittingSessionInterface";
import PlayerInterface from "../../interfaces/apiInterfaces/PlayerInterface";
import ExportDataRequest from "../../api/requests/ExportDataRequest";

const cookies = new Cookies();
const { SearchBar } = Search;

interface FittingSessionsProps {}
interface FittingSessionsState {
  coach: CoachAccountInterface;
  fittingSessions: Array<FittingSessionInterface>;
  page: number;
}

class FittingSessions extends Component<
  FittingSessionsProps,
  FittingSessionsState
> {
  state: FittingSessionsState = {
    coach: EmptyCoachAccountInterface,
    fittingSessions: [],
    page: 0,
  };

  goToFittingSessionPage = (fittingSession: {
    fitting_session: FittingSessionInterface;
    player: PlayerInterface;
  }) => {
    setTimeout(() => {
      history.push(
        `/new-session?player=${fittingSession.player.id}&fitting_session=${fittingSession.fitting_session.id}&from=fitting`
      );
    }, 500);
  };

  componentDidMount = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const playerId = urlParams.get("id");
    var tempState = this.state;
    fetch(`${process.env.REACT_APP_API_URL}/auth/ping`, {
      method: "Get",
      headers: {
        Authorization: `${cookies.get("user")}`,
      },
    })
      .then((raw) => {
        return raw.json();
      })
      .then((ans) => {
        var tempState = this.state;
        tempState.coach = ans;
        this.setState(tempState);
        if (this.state.coach) {
          fetch(
            `${
              process.env.REACT_APP_API_URL
            }/fitting_sessions?limit=100&offset=${
              100 * this.state.page
            }&filter_key=player_id&filter_value=${playerId}`,
            {
              method: "Get",
              headers: {
                Authorization: `${cookies.get("user")}`,
              },
            }
          )
            .then((raw) => {
              return raw.json();
            })
            .then((ans) => {
              var tempState = this.state;
              tempState.fittingSessions = ans.data;
              this.setState(tempState);
            })
            .catch((er) => {
              console.log(er);
            });
        }
      })
      .catch((er) => {
        console.log(er);
      });
    this.setState(tempState);
  };

  constructor(props: FittingSessionsProps) {
    super(props);
  }

  render() {
    const actionsFormatter = (cell: any, row: any) => {
      return (
        <>
          <button
            title="View"
            className="btn btn-outline-darkblue"
            onClick={() =>
              this.goToFittingSessionPage({
                fitting_session: row,
                player: row.player,
              })
            }
          >
            <FontAwesomeIcon icon="search" />
          </button>
        </>
      );
    };
    const checkFormatter = (cell: any, row: any) => {
      return cell ? (
        <FontAwesomeIcon color="lightgreen" icon="check-square" />
      ) : (
        <></>
      );
    };
    const dateFormatter = (cell: any, row: any) => {
      return formatDateToDisplay(cell, "do MMM yyyy hh:mm aa");
    };

    const pagination = paginationFactory({ sizePerPage: 5 });

    const columns = [
      {
        dataField: "createdAt",
        text: "Recorded At",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 375,
        },
        sort: true,
        formatter: dateFormatter,
      },
      {
        dataField: "player_rating_enabled",
        text: "Player Rating",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
        sort: true,
        formatter: (cell: any, row: any) => cell ? 'Enabled' : 'Disabled',
      },
      {
        dataField: "manual_data_collection",
        text: "Hit Data",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
        sort: true,
        formatter: (cell: any, row: any) => cell ? 'Manual' : 'Auto',
      },
      {
        dataField: "all_swings_entry",
        text: "Bat Swings",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
        sort: true,
        formatter: (cell: any, row: any) => cell ? 'Yes' : 'In-play Only',
      },
      {
        dataField: "custom_metric_label",
        text: "Custom Metric",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
        sort: true,
        formatter: (cell: any, row: any) => cell,
      },
      {
        dataField: "complete",
        text: "Completed",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 100,
        },
        align: "center",
        formatter: checkFormatter,
      },
      {
        dataField: "actions",
        text: "Actions",
        align: "center",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 200,
        },
        formatter: actionsFormatter,
      },
    ];
    const urlParams = new URLSearchParams(window.location.search);
    const playerId = urlParams?.get("id");
    return (
      <div className="container-fluid">
        <ToolkitProvider
          keyField="id"
          data={this.state.fittingSessions}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div className="row">
                {playerId && <div className="col-lg-12">
                <button className="btn export solid d-inline-flex" onClick={() => {
                    ExportDataRequest.get(`players/${playerId}/report`).then((blob: any) => {
                        const url: string = window.URL.createObjectURL(new Blob([blob]));
                        let link: HTMLAnchorElement;
                        link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `players_fitting_sessions_${playerId}.xlsx`);
                        link.click();
                      });
                }}>
                    <FontAwesomeIcon icon="upload" className="mr-1" />
                    Export
                  </button>
                </div>
  }
                {/* <div className="col-md-12 col-lg-6 mb-2">
                  <button
                      className="btn export mr-2 solid d-inline-flex"
                      onClick={() => this.handleShow("new")}
                    >
                      <FontAwesomeIcon icon="user-plus" className="mr-1" />
                      Add Player
                    </button>
                </div> */}
                <div className="col-lg-12">
                  <div className="table-wrapper">
                    <BootstrapTable
                      pagination={pagination}
                      wrapperClasses="table-responsive"
                      {...props.baseProps}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

export default FittingSessions;
