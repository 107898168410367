import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import history from "../history";
import {
  AccountCircle,
} from "@material-ui/icons";
import arrowIcon from "../assets/images/Arrow.svg";
import leftArrow from "../assets/images/left-arrow.png";
import BatRXTextLogo from "../assets/images/Logo.svg";

import CoachAccountInterface, {
  CoachAccountInterfaceDto,
  EmptyCoachAccountInterfaceDto,
} from "../interfaces/apiInterfaces/CoachAccountInterface";
import AuthResetRequest, {
  AuthResetPostResponseInterface
} from "../api/requests/AuthResetRequest";
import AuthPingRequest, {
  AuthPingGetResponseInterface,
} from "../api/requests/AuthPingRequest";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required')
});

interface IndexProps {}
interface IndexState {
  coach: CoachAccountInterfaceDto;
  successLogin: CoachAccountInterface | null;
  failedLogin: string;
  emailSent: boolean;
}

type LoginForm = {
  email: string;
}

class ForgotPassword extends Component<IndexProps, IndexState> {
  state: IndexState = {
    coach: EmptyCoachAccountInterfaceDto,
    successLogin: null,
    failedLogin: "",
    emailSent: false
  };

  attemptLogin = (values: LoginForm) => {
    AuthResetRequest.post({ email: values.email })
      .then((result: any) => {
        this.setState({emailSent: true});
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  // If logged in redirect to dashboard
  constructor(props: IndexProps) {
    super(props);
    AuthPingRequest.get().then((result: AuthPingGetResponseInterface) => {
      if (result.email) {
        history.push("/dash");
      }
    });
  }

  render() {
    // This is used to render nothing when the user is logged in and hits the index page while they wait to be transfered to /dash
    if (cookies.get("user") && !this.state.successLogin) {
      return <></>;
    }
    return (
      <div className="loginForm Forgetpassword text-center d-flex align-items-center">
        <div className="wrapper form-signin position-relative">
            <Link to="/"> <img src={leftArrow} alt="" width="16" className="leftArrow" /></Link>
            <div className="logo mb-4"> <img src={BatRXTextLogo} className="img-fluid" />
            </div>
            <div className="text-center my-4">
                <h3>Forgot Password</h3>
                {this.state.emailSent ? <div className="mt-3 d-flex justify-content-space">
                  <span> 
                    {/* <img src={emailIcon} alt="email" width="34" /> */}
                  </span>
                  <span>
                    <p className="text-left ml-3 mb-0">
                      Please check your email and click on the provided link to reset your password.
                    </p>
                  </span>
                </div> :null }
            </div>

            {this.state.emailSent ? <>
              {/* <div className="form-group resendEmail">
                  <button className="btn btn-block"> Resend email 
                  <img 
                    src={arrowIcon}
                    alt="Arrow"
                    className="ml-2"
                  /> 
                  </button>
              </div> */}
              <div className="login-btn">
                <button 
                  type="button" 
                  className="btn btn-primary btn-block" 
                  onClick={() => history.push('login')}
                > Return To Login </button>
              </div>
            </> : <>
            <Formik
              initialValues={{
                email: ''
              }}
              validationSchema={LoginSchema}
              onSubmit={values => {
                this.attemptLogin(values);
              }}
            >
            {({ errors, touched }) => (
              <Form>
              <div className="mb-3">
                <div className="input-group">
                    <span className="input-group-text"> <AccountCircle fontSize="small" /></span>
                    <Field 
                      type="email"
                      name="email"
                      className="form-control border-left-0" 
                      placeholder="Enter username or email"
                    />
                </div>
                  {errors.email && touched.email ? (
                    <p className="small text-danger">{errors.email}</p>
                  ) : null}
              </div>
              <div className="login-btn">
                <button type="submit" className="btn btn-primary btn-block">Send Recovery Email</button>
              </div>
              </Form>
            )} 
            </Formik>
            </>
            }
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
