import React from "react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import BatRXTextLogo from "../assets/images/Logo-White.svg";
import {
  DoubleArrow, ExitToApp, AccountCircle, Settings, Menu as MenuIcon
} from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import {
  MenuItem
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fade from '@material-ui/core/Fade'

import userImg from "../assets/images/userImg.png";
import playerLibrary from "../assets/images/player-library.svg";
import getFitted from "../assets/images/get-fitted.svg";
import batLibrary from "../assets/images/bat-library.svg";

import history from "../history";
import AuthLogoutRequest from "../api/requests/AuthLogoutRequest";
import { getUserRole } from "../utils/userUtils";

const StyledMenu = withStyles({
  paper: {
    // border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    TransitionComponent={Fade}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

class Header extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
      }
      
  state: any = {
    role: '',
    drawerOpen: false,
		anchorEl: null
  };
  componentDidMount = () => {
      const userRole = getUserRole();
    this.setState({...this.state, role: userRole?.name?.toLowerCase()});
  };

	handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
    this.setState({anchorEl: event.currentTarget});
  }

	handleClose() {
    this.setState({anchorEl: null});
  }

  toggleDrawer(status: boolean) {
    this.setState({ drawerOpen: status });
  }

	logout() {
    AuthLogoutRequest.post().then(() => {
      history.push("/login");
    });
  }

  render() {
    const { location } = this.props;
    const userStr = localStorage.getItem("user");
    const userRole = getUserRole();
    const loggedIn: any = userStr ? JSON.parse(userStr) : {};
    return (
      <>
        <header className="d-flex justify-content-between align-items-center">
          <button className="btn p-0 openbtn">
            {" "}
            <MenuIcon
              fontSize="large"
              style={{ color: "#fff" }}
              onClick={() => this.toggleDrawer(true)}
            />{" "}
          </button>
          <div className="logo" onClick={() => history.push('/dash')} style={{ cursor: 'pointer' }}>
            <img
              src={BatRXTextLogo}
              width="120"
              className=" "
              alt="BatRx Logo"
            />
          </div>
          <div className="userProfile">
            <div className="dropdown">
              <a
                className="text-white text-decoration-none dropdown-toggle"
                data-toggle="dropdown"
								onClick={(event) => this.handleClick(event)}
              >
                <img src={userImg} className="userprofileImg" /> {loggedIn?.first_name} {loggedIn?.last_name}{" "}
              </a>
              <StyledMenu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={() => this.handleClose()}
              >
                {/* <MenuItem>
                  <AccountCircle fontSize="small" className="mr-2" /> Profile
                </MenuItem> */}
                {this.state?.role === 'admin' && <MenuItem onClick={() => history.push('/admin')}>
                  <Settings fontSize="small" className="mr-2" /> Settings
                </MenuItem>}
                <MenuItem onClick={this.logout}>
                  <ExitToApp fontSize="small" className="mr-2" /> Logout
                </MenuItem>
              </StyledMenu>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="#">
                  Log out
                </a>
              </div>
            </div>
          </div>
        </header>

        <div
          id="mySidepanel"
          className="leftSidebar"
          style={{ width: this.state.drawerOpen ? "300px" : "0px" , zIndex: 1000 }}
        >
          <a
            className="closebtn text-decoration-none"
            onClick={() => this.toggleDrawer(false)}
          >
            ×
          </a>
          <ul className="navbar-nav list-unstyled playerLabMenu">
            <li className={`nav-item ${location.pathname === '/players' && 'active'}`}>
              <Link to="/players" className="nav-link">
                {" "}
                <img
                  src={playerLibrary}
                  alt="Player Library"
                />
                Player Library{" "}
                <FontAwesomeIcon icon="angle-double-right" className="ml-2 mt-2 float-right" />
              </Link>
            </li>
            <li className={`nav-item ${location.pathname === '/get-fitted' && 'active'}`}>
              <Link to="/get-fitted" className="nav-link">
                {" "}
                <img
                  src={getFitted}
                  alt="Get Fitted"
                />
                Get Fitted{" "}
                <FontAwesomeIcon icon="angle-double-right" className="ml-2 mt-2 float-right" />
              </Link>
            </li>
            <li className={`nav-item ${location.pathname === '/bats' && 'active'}`}>
              <Link to="/bats" className="nav-link">
                {" "}
                <img
                  src={batLibrary}
                  alt="Bat Database"
                />
                Bat Database{" "}
                <FontAwesomeIcon icon="angle-double-right" className="ml-2 mt-2 float-right" />
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default withRouter(Header);
