import React, { ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import history from "../history";
import { Collapse, IconButton } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  CloseOutlined,
  VpnKeyOutlined,
} from "@material-ui/icons";
import leftArrow from "../assets/images/left-arrow.png";
import BatRXTextLogo from "../assets/images/Logo.svg";

import CoachAccountInterface, {
  CoachAccountInterfaceDto,
  EmptyCoachAccountInterfaceDto,
} from "../interfaces/apiInterfaces/CoachAccountInterface";

import PasswordUpdateRequest, {
  PasswordUpdatePostResponseInterface
} from '../api/requests/PasswordUpdateRequest';
import AuthPingRequest, {
  AuthPingGetResponseInterface,
} from "../api/requests/AuthPingRequest";

import Cookies from "universal-cookie";
import { tokenToString } from "typescript";
const cookies = new Cookies();

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required') 
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  passwordConfirm: Yup.string()
    .required('Required') 
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

interface IndexProps {}
interface IndexState {
  coach: CoachAccountInterfaceDto;
  successLogin: CoachAccountInterface | null;
  failedLogin: string;
}

type ResetPasswordForm = {
  password: string;
}

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("t");

class ForgotPassword extends React.Component<IndexProps, IndexState> {
  state: IndexState = {
    coach: EmptyCoachAccountInterfaceDto,
    successLogin: null,
    failedLogin: ""
  };

  attemptLogin = (values: ResetPasswordForm) => {
    let tempState = this.state;

    PasswordUpdateRequest.post({token: token, password: values.password})
      .then((result: any) => {
        tempState.successLogin = result.data;
        this.setState(tempState);
        setTimeout(() => {
          history.push("/");
        }, 1500);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  // If logged in redirect to dashboard
  constructor(props: IndexProps) {
    super(props);
    AuthPingRequest.get().then((result: AuthPingGetResponseInterface) => {
      if (result.email) {
        history.push("/dash");
      }
    });
  }

  render() {
    // This is used to render nothing when the user is logged in and hits the index page while they wait to be transfered to /dash
    if (cookies.get("user") && !this.state.successLogin) {
      return <></>;
    }
    return (
      <div className="loginForm Forgetpassword text-center d-flex align-items-center">
        <div className="wrapper form-signin position-relative">
            <Link to="/"> <img src={leftArrow} alt="" width="16" className="leftArrow" /></Link>
            <div className="logo mb-4"> <img src={BatRXTextLogo} className="img-fluid" />
            </div>
            <div className="text-center my-4">
                <h3>Reset Password</h3>
            </div>

            <Formik
              initialValues={{
                password: '',
                passwordConfirm: ''
              }}
              validationSchema={ResetPasswordSchema}
              onSubmit={values => {
                this.attemptLogin({password: values.password});
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="mb-3">
                    <div className="input-group">
                        <span className="input-group-text"> <VpnKeyOutlined fontSize="small" /></span>
                        <Field 
                          type="password"
                          name="password"
                          className="form-control border-left-0" 
                          placeholder="Enter a new password"
                        />
                    </div>
                      {errors.password && touched.password ? (
                        <p className="small text-danger">{errors.password}</p>
                      ) : null}
                  </div>
                  <div className="mb-3">
                    <div className="input-group">
                        <span className="input-group-text"> <VpnKeyOutlined fontSize="small" /></span>
                        <Field 
                          type="password"
                          name="passwordConfirm"
                          className="form-control border-left-0" 
                          placeholder="Confirm password"
                        />
                    </div>
                      {errors.passwordConfirm && touched.passwordConfirm ? (
                        <p className="small text-danger">{errors.passwordConfirm}</p>
                      ) : null}
                  </div>
                  <div className="login-btn">
                    <button type="submit" className="btn btn-primary btn-block">Submit</button>
                  </div>
                </Form>
              )} 
            </Formik>

            <Collapse in={this.state.successLogin != null ? true : false}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    var tempState = this.state;
                    tempState.successLogin = null;
                    this.setState(tempState);
                  }}
                >
                  <CloseOutlined fontSize="inherit" />
                </IconButton>
              }
            >
              Reset Successful
            </Alert>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
