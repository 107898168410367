/* 
  Isaac Hormel
  5/27/2020
  Sends a request to the NodeJS API server to tell it that you are going to logout the coach. 
  Currently this changes nothign on the server and is a placeholder for future use cases when we move away from basic auth. 
  This removes the user cookie whether or not the request was successful.
*/

import BasicRequest from "../classes/BasicRequest";

import Cookies from "universal-cookie";
const cookies = new Cookies();

interface AuthLogoutPostOptionsInterface{

};
interface AuthLogoutPostBodyInterface{

};

interface AuthLogoutPostResponseInterface{
  data?: string; //email of the account
  message: "logout"|string;
}

export default class AuthLogoutRequest extends BasicRequest{
  static post(){
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var authLogoutPostRequest = fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
        method: "POST",
        headers: {
          "Authorization": cookies.get("user"),
          "Content-Type": "application/json"
        }
      });
      cookies.remove("user");
      authLogoutPostRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: AuthLogoutPostResponseInterface)=>{
        localStorage.removeItem("user");
        resolvePromise(requestJson);
      });
      authLogoutPostRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}