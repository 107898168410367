/* 
  Isaac Hormel
  6/1/2020
  Sends a request to the NodeJS API server and to get, create, or change a range. GET can be filtered and paginated depending on the options.
*/

import BasicRequest from "../classes/BasicRequest";

import Cookies from "universal-cookie";
import PaginationAndFilterInterface from "../../interfaces/apiInterfaces/PaginationAndFilterInterface";
import { RangeInterface } from "../../interfaces/apiInterfaces/RangeInterface";
const cookies = new Cookies();

interface RangeGetOptionsInterface extends PaginationAndFilterInterface{
  id?: number;
};
interface RangeGetBodyInterface{

}; 

export interface RangeGetResponseInterface{
  data?: Array<RangeInterface>|RangeInterface;
  message: "findAll"|"findOne";
}

export default class RangeRequest extends BasicRequest{
  static get(options?: RangeGetOptionsInterface): Promise<RangeGetResponseInterface>|any{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/ranges`);
      if(options?.filter_key || options?.limit || options?.offset){
        if(options?.filter_key){
          destination.searchParams.append("filter_key", options.filter_key);
          destination.searchParams.append("filter_value", options?.filter_value||"");
        }
        if(options?.offset){
          destination.searchParams.append("offset", options.offset.toString());
        }
        if(options?.limit){
          destination.searchParams.append("limit", options.limit.toString());
        }
      }
      if(options?.id){
        destination.pathname += `/${options?.id}`;
      }
      var RangeGetRequest = fetch(destination.toString(), {
        method: "GET",
        headers: {
          "Authorization": cookies.get("user")
        }
      });
      RangeGetRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: RangeGetResponseInterface)=>{
        resolvePromise(requestJson);
      });
      RangeGetRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}