import React, { ChangeEvent } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "whatwg-fetch";
import history from "../history";
import Cookies from "universal-cookie";
import PlayerInterface from "../interfaces/apiInterfaces/PlayerInterface";
import PlayerBiometricRequest from "../api/requests/PlayerBiometricRequest";
import FittingSessionInterface from "../interfaces/apiInterfaces/FittingSessionInterface";
import PrescriptionMatrixInterface from "../interfaces/apiInterfaces/PrescriptionMatrixInterface";
import BattingSessionInterface from "../interfaces/apiInterfaces/BattingSessionInterface";
import HitInterface, {
  HitInterfaceDto,
} from "../interfaces/apiInterfaces/HitInterface";
import BatInterface, {
  EmptyBatInterfaceDto,
} from "../interfaces/apiInterfaces/BatInterface";
import FittingSessionResultInterface from "../interfaces/apiInterfaces/FittingSessionResultInterface";
import SummaryList from "./Fitting/components/SummaryList";
import { Modal } from "react-bootstrap";
const cookies = new Cookies();

interface ReviewSessionProps {}
interface ReviewSessionState {
  loading: boolean;
  player: PlayerInterface;
  player_bio_metric: PlayerBiometricRequest;
  fitting_session: FittingSessionInterface | null;
  prescription_matrix: PrescriptionMatrixInterface;
  batting_sessions: Array<{
    batting_session: BattingSessionInterface;
    hits: Array<HitInterfaceDto>;
    bat: BatInterface;
    average: HitInterface | null;
    cns: HitInterface | null;
    currentHit: HitInterfaceDto | null;
  }>;
  bestFittingSession: {
    fitting_session_result: FittingSessionResultInterface;
    bat: BatInterface;
    batting_session: BattingSessionInterface;
  };
  fitting_session_results: Array<{
    fitting_session_result: FittingSessionResultInterface;
    bat: BatInterface;
  }>;
  bat_delivery: number;
  barrel_stat: number;
  player_preference: number;
  bat_deliveryString: string;
  barrel_statString: string;
  player_preferenceString: string;
}

class ReviewSession extends React.Component<
  ReviewSessionProps,
  ReviewSessionState
> {
  state: ReviewSessionState = {
    loading: false,
    player: {
      id: 0,
      range_id: 1,
      user_id: 0,
      first_name: "",
      last_name: "",
      email: "",
      dob:
        "" +
        new Date(
          new Date().setFullYear(new Date().getFullYear() - 10)
        ).toISOString(),
      avatar: "",
      biometrics: [],
      movement_screenings: [],
      power_testings: [],
    },
    player_bio_metric: {
      id: 0,
      player_id: 0,
      weight: 0,
      height: 0,
      hand_width: 0,
      hand_length: 0,
      forearm_length: 0,
      arm_length: 0,
      wrist_to_floor: 0,
      hip_to_floor: 0,
    },
    fitting_session: null,
    batting_sessions: [],
    prescription_matrix: {
      id: -1,
      fitting_session_id: -1,
      bat_delivery: -1,
      barrel_stat: -1,
      player_preference: -1,
    },
    bestFittingSession: {
      fitting_session_result: {
        id: 0,
        bat_id: -1,
        fitting_session_id: -1,
        player_id: -1,
        user_id: -1,
        bat_stat: -1,
        barrel_stat: -1,
        subjective: -1,
        overall_fit_score: -1,
        custom_metric_value: -1,
      },
      bat: {
        ...EmptyBatInterfaceDto,
        id: -1,
      },
      batting_session: {
        id: -1,
        bat_id: -1,
        fitting_session_id: -1,
        player_id: -1,
        color: "",
        order: -1,
      },
    },
    fitting_session_results: [],
    bat_delivery: 2,
    barrel_stat: 4,
    player_preference: 3,
    bat_deliveryString: "2",
    barrel_statString: "4",
    player_preferenceString: "3",
  };

  attemptSubmit = () => {
    this.setState({ loading: true });
    if (this.state.fitting_session != null) {
      fetch(
        `${process.env.REACT_APP_API_URL}/process/prescription_matrix/${this.state.prescription_matrix.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `${cookies.get("user")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fitting_session_id: this.state.fitting_session.id,
            bat_delivery: this.state.bat_delivery,
            barrel_stat: this.state.barrel_stat,
            player_preference: this.state.player_preference,
          }),
        }
      )
        .then((raw) => {
          return raw.json();
        })
        .then((prescriptionMatrix) => {
          if (this.state.fitting_session != null) {
            //Not sure why I have to check if the fitting session is not null twice, but ts throws a fit if i don't

            var ReviewSessionState = this.state.fitting_session;
            ReviewSessionState.complete = true;

            fetch(
              `${process.env.REACT_APP_API_URL}/process/fitting_session/${this.state.fitting_session.id}?calc=true`,
              {
                method: "PUT",
                headers: {
                  Authorization: `${cookies.get("user")}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(ReviewSessionState),
              }
            )
              .then((raw) => {
                return raw.json();
              })
              .then((fittingSession) => {
                toast.success("Email sent to player");
                if (this.state.fitting_session != null) {
                  fetch(
                    `${process.env.REACT_APP_API_URL}/fitting_sessions/${this.state.fitting_session.id}`,
                    {
                      method: "GET",
                      headers: {
                        Authorization: `${cookies.get("user")}`,
                      },
                    }
                  )
                    .then((raw) => {
                      return raw.json();
                    })
                    .then((fittingSessionActual) => {
                      this.setState({ loading: false });
                      history.push(
                        `/get-fitted`
                      );
                    });
                }
              });
          }
        })
        .catch((e) => {
            this.setState({ loading: false });
          console.log(e);
        });
    }
  };

  handlePrescriptionMatrixBatDeliveryChange = (
    e: ChangeEvent<HTMLInputElement>,
    newValue: number | number[] | undefined
  ) => {
    var tempState = this.state;
    if (newValue != undefined) {
      tempState.bat_delivery = parseInt("" + newValue);
    } else {
      tempState.bat_delivery = parseInt("" + (e.currentTarget.value || 0)) || 0;
    }
    if (tempState.bat_delivery > 10) {
      tempState.bat_delivery = 10;
    }
    if (tempState.bat_delivery < 0) {
      tempState.bat_delivery = 0;
    }
    tempState.bat_deliveryString = "" + tempState.bat_delivery;
    this.setState(tempState);
  };

  handlePrescriptionMatrixBarrelStatChange = (
    e: ChangeEvent<HTMLInputElement>,
    newValue: number | number[] | undefined
  ) => {
    var tempState = this.state;
    if (newValue != undefined) {
      tempState.barrel_stat = parseInt("" + newValue);
    } else {
      tempState.barrel_stat = parseInt("" + (e.currentTarget.value || 0)) || 0;
    }
    if (tempState.barrel_stat > 10) {
      tempState.barrel_stat = 10;
    }
    if (tempState.barrel_stat < 0) {
      tempState.barrel_stat = 0;
    }
    tempState.barrel_statString = "" + tempState.barrel_stat;
    this.setState(tempState);
  };

  handleFittingSessionResultPlayerPreferenceChange = (
    e: ChangeEvent<HTMLInputElement>,
    newValue: number | number[] | undefined
  ) => {
    var tempState = this.state;
    if (newValue != undefined) {
      tempState.player_preference = parseInt("" + newValue);
    } else {
      tempState.player_preference =
        parseInt("" + (e.currentTarget.value || 0)) || 0;
    }
    if (tempState.player_preference > 10) {
      tempState.player_preference = 10;
    }
    if (tempState.player_preference < 0) {
      tempState.player_preference = 0;
    }
    tempState.player_preferenceString = "" + tempState.player_preference;
    this.setState(tempState);
  };

  handlePrescriptionMatrixBatDeliveryStringChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    var tempState = this.state;
    tempState.bat_deliveryString = e.currentTarget.value || "0";
    this.setState(tempState);
  };

  handlePrescriptionMatrixBarrelStatStringChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    var tempState = this.state;
    tempState.barrel_statString = e.currentTarget.value || "0";
    this.setState(tempState);
  };

  handleFittingSessionResultPlayerPreferenceStringChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    var tempState = this.state;
    tempState.player_preferenceString = e.currentTarget.value || "0";
    this.setState(tempState);
  };

  componentDidMount = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const playerId = urlParams.get("player");
    const fittingSessionId = urlParams.get("fitting_session");
    this.setState({loading: true});

    var user;
    try {
      user = await (
        await fetch(`${process.env.REACT_APP_API_URL}/auth/ping`, {
          method: "GET",
          headers: {
            Authorization: `${cookies.get("user")}`,
          },
        })
      ).json();

      var tempState = this.state;

      const player = await (
        await fetch(`${process.env.REACT_APP_API_URL}/players/${playerId}`, {
          method: "GET",
          headers: {
            Authorization: `${cookies.get("user")}`,
          },
        })
      ).json();
      tempState.player = player.data;

      var playerBiometrics = await (
        await fetch(
          `${process.env.REACT_APP_API_URL}/player_bio_metrics/${player.data.id}`,
          {
            method: "GET",
            headers: {
              Authorization: `${cookies.get("user")}`,
            },
          }
        )
      ).json();
      tempState.player_bio_metric = playerBiometrics.data;

      const fittingSession = await (
        await fetch(
          `${process.env.REACT_APP_API_URL}/fitting_sessions/${fittingSessionId}`,
          {
            method: "GET",
            headers: {
              Authorization: `${cookies.get("user")}`,
            },
          }
        )
      ).json();
      tempState.fitting_session = fittingSession.data;

      const battingSessions = await (
        await fetch(
          `${process.env.REACT_APP_API_URL}/batting_sessions?limit=100&offset=0&filter_key=fitting_session_id&filter_value=${fittingSessionId}`,
          {
            method: "GET",
            headers: {
              Authorization: `${cookies.get("user")}`,
            },
          }
        )
      ).json();

      battingSessions.data.forEach(async (sess: any, i: number) => {
        /* First lets define a new batting session obj that we will change the values of later. */
        const bat = await (
          await fetch(`${process.env.REACT_APP_API_URL}/bats/${sess.bat_id}`, {
            method: "GET",
            headers: {
              Authorization: `${cookies.get("user")}`,
            },
          })
        ).json();
        var newestBattingSession: {
          batting_session: BattingSessionInterface;
          hits: Array<HitInterfaceDto>;
          bat: BatInterface;
          average: HitInterface | null;
          cns: HitInterface | null;
          currentHit: HitInterfaceDto | null;
        } = {
          hits: [],
          batting_session: sess,
          bat: bat.data,
          average: null,
          cns: null,
          currentHit: null,
        };
        newestBattingSession.bat = bat.data;

        const hits = await (
          await fetch(
            `${process.env.REACT_APP_API_URL}/hits?limit=100&offset=0&filter_key=batting_session_id&filter_value=${sess.id}`,
            {
              method: "GET",
              headers: {
                Authorization: `${cookies.get("user")}`,
              },
            }
          )
        ).json();
        newestBattingSession.hits = hits.data;
        if (
          newestBattingSession.bat != null &&
          newestBattingSession.batting_session != null &&
          newestBattingSession.hits != null
        ) {
          const requestHitResults = fetch(
            `${process.env.REACT_APP_API_URL}/batting_session_results?limit=100&offset=0&filter_key=batting_session_id&filter_value=${sess.id}`,
            {
              method: "GET",
              headers: {
                Authorization: `${cookies.get("user")}`,
              },
            }
          );
          requestHitResults
            .then((raw) => {
              return raw.json();
            })
            .then((hitResults) => {
              newestBattingSession.hits = hits.data;
              if (hits.data.length) {
                let resObj: any = {};
                for (const index in hitResults.data) {
                  const item = hitResults.data[index];
                  resObj[item.metric_name + "_" + item.metric_type] = item;
                }
                newestBattingSession.average = {
                  id: hits.data[hits.data.length - 1].id,
                  batting_session_id:
                    hits.data[hits.data.length - 1].batting_session_id,
                  fitting_session_id:
                    hits.data[hits.data.length - 1].fitting_session_id,
                  bat_id: hits.data[hits.data.length - 1].bat_id,
                  player_id: hits.data[hits.data.length - 1].player_id,
                  hit_index: hits.data[hits.data.length - 1].hit_index,
                  bat_velocity: resObj.bat_velocity_average?.metric_value,
                  angle_of_attack: resObj.angle_of_attack_average?.metric_value,
                  exit_velocity: resObj.exit_velocity_average?.metric_value,
                  launch_angle: resObj.launch_angle_average?.metric_value,
                  spray_direction: resObj.spray_direction_average?.metric_value,
                  player_rating: resObj.player_rating_average?.metric_value,
                  coach_rating: resObj.coach_rating_average?.metric_value,
                  custom_metric_value:
                    resObj.custom_metric_value_average?.metric_value,
                  foul_miss: false,
                };
                newestBattingSession.cns = {
                  id: hits.data[hits.data.length - 1].id,
                  batting_session_id:
                    hits.data[hits.data.length - 1].batting_session_id,
                  fitting_session_id:
                    hits.data[hits.data.length - 1].fitting_session_id,
                  bat_id: hits.data[hits.data.length - 1].bat_id,
                  player_id: hits.data[hits.data.length - 1].player_id,
                  hit_index: hits.data[hits.data.length - 1].hit_index,
                  bat_velocity: resObj.bat_velocity_consistency?.metric_value,
                  angle_of_attack:
                    resObj.angle_of_attack_consistency?.metric_value,
                  exit_velocity: resObj.exit_velocity_consistency?.metric_value,
                  launch_angle: resObj.launch_angle_consistency?.metric_value,
                  spray_direction:
                    resObj.spray_direction_consistency?.metric_value,
                  player_rating: resObj.player_rating_consistency?.metric_value,
                  coach_rating: resObj.coach_rating_consistency?.metric_value,
                  custom_metric_value:
                    resObj.custom_metric_value_consistency?.metric_value,
                  foul_miss: false,
                };
              }
            });
          if (
            newestBattingSession.bat != null &&
            newestBattingSession.batting_session != null &&
            newestBattingSession.hits != null
          ) {
            tempState.batting_sessions.push(newestBattingSession);
          }
        }
      });

      /* only get a message if there is an error, since the result of /auth/ping is just an obj of the user. */
      if (!user.message) {
        var fittingSessionCalcs = await (
          await fetch(
            `${process.env.REACT_APP_API_URL}/process/fitting_session/${fittingSessionId}?calc=true`,
            {
              method: "PUT",
              headers: {
                Authorization: `${cookies.get("user")}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                id: parseInt("" + fittingSessionId),
                player_id: parseInt("" + playerId),
                user_id: user.id,
                custom_bat_velocity_min:
                  tempState.fitting_session?.custom_bat_velocity_min,
                custom_bat_velocity_max:
                  tempState.fitting_session?.custom_bat_velocity_max,
                custom_exit_velocity_min:
                  tempState.fitting_session?.custom_exit_velocity_min,
                custom_exit_velocity_max:
                  tempState.fitting_session?.custom_exit_velocity_max,
                player_rating_enabled:
                  tempState.fitting_session?.player_rating_enabled,
                complete: fittingSession.complete,
                range_id: tempState.fitting_session?.range_id,
              }),
            }
          )
        ).json();
        var bestFittingSession = this.state.bestFittingSession;
        fittingSessionCalcs.data.fitting_session_results.forEach(
          async (fsr: FittingSessionResultInterface, indexFSR: number) => {
            const batForFSR = await (
              await fetch(
                `${process.env.REACT_APP_API_URL}/bats/${fsr.bat_id}`,
                {
                  method: "GET",
                  headers: {
                    Authorization: `${cookies.get("user")}`,
                  },
                }
              )
            ).json();
            tempState.fitting_session_results.push({
              fitting_session_result: fsr,
              bat: batForFSR.data,
            });
            if (
              Math.abs(fsr.overall_fit_score) >
              Math.abs(
                bestFittingSession.fitting_session_result.overall_fit_score
              )
            ) {
              bestFittingSession.fitting_session_result = fsr;
              bestFittingSession.bat = batForFSR.data;
              tempState.batting_sessions.forEach(
                async (battingSessionForBFS, indexBSR: number) => {
                  if (battingSessionForBFS.bat.id == batForFSR.data.id) {
                    bestFittingSession.batting_session =
                      battingSessionForBFS.batting_session;
                  }
                }
              );
            }
          }
        );
        tempState.bestFittingSession = bestFittingSession;
        tempState.prescription_matrix =
          fittingSessionCalcs.data.prescription_matrix;
      }
      tempState.loading = false;
      this.setState(tempState);
    } catch (e) {
      console.error(e);
    }
    /* Weird bug occurs where state is changed but render is not called. This helps prevent that. */
    setTimeout(() => {
      this.setState(this.state);
    }, 1000);
  };

  constructor(props: ReviewSessionProps) {
    super(props);
  }

  render() {
    const {
      player,
      batting_sessions,
      fitting_session,
      fitting_session_results,
      bestFittingSession,
      loading
    } = this.state;

    return (
          <div className="container-fluid" style={{ backgroundColor: "white", height: "calc(100vh - 78px)"}}>
            <Modal show={this.state.loading} centered={true}>
                <Modal.Body className="p-0">
                    <h3>Loading...</h3>
                </Modal.Body>
            </Modal>
            <div className="row py-4 border">
              <div className="col-md-6 col-lg-6 col-xl-6">
                <h3 className="mb-0">{player.first_name} {player.last_name}</h3>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-6">
                <div className="float-right">
                  <button
                    onClick={() => {
                        window.location.href = `${window.location.origin}/new-session?player=${player?.id}&fitting_session=${fitting_session?.id}&from=review`;
                    }}
                    className="btn btn-outline-darkblue mr-2"
                  >
                    Back
                  </button>
                  {this.state.fitting_session != null &&
                    this.state.fitting_session.id != -1 && (
                      <button
                        disabled={this.state.loading}
                        onClick={this.attemptSubmit}
                        className="btn btn-darkblue text-white"
                      >
                        Submit
                      </button>
                    )}
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-lg-4">
                <div className="card h-100  bg-transparent border-0">
                  <div className="card-body p-0">
                    {this.state.batting_sessions
                      .sort((a, b) =>
                        a.batting_session.order > b.batting_session.order
                          ? 1
                          : -1
                      )
                      .map((item: any, index: number) => (
                        <div className="printingBlock mb-3" key={index}>
                          <div className="d-flex justify-content-between align-content-center">
                            <span>
                              {Math.round(item.bat.length)} | {item.bat.brand}{" "}
                              {item.bat.model}{" "}
                            </span>
                          </div>
                          <small className="text-muted">{item.bat.bpi}</small>
                        </div>
                      ))}
                  </div>
                </div>
              </div> */}


               <div className="col-sm-12">
              {/*  <div className="card h-100 performanceBox">
                  <BattingSessionsBarGraph
                    custom_metric_label={
                      fitting_session?.custom_metric_label || ""
                    }
                    best_bat={bestFittingSession}
                    batting_sessions={batting_sessions}
                    fitting_session_results={fitting_session_results}
                  />
                </div> */}
                <div className="container-fluid" style={{ backgroundColor: "white", height: "calc(100vh - 78px)"}}>
      { /* New Review session component */ }
        
            {
                fitting_session_results.length && (
                    <SummaryList custom_metric_label={
                      fitting_session?.custom_metric_label || ""
                    }
                    best_bat={bestFittingSession}
                    batting_sessions={batting_sessions}
                    fitting_session_results={fitting_session_results}></SummaryList>
                )
            }
        
              </div> 
            </div>
          </div>
        </div>
    );
  }
}

export default ReviewSession;
