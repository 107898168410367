import React from "react";
import { Router, Switch, Route, Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import history from "./history";

import "whatwg-fetch";

import "bootstrap/dist/css/bootstrap.css";
import "./style.scss";

import Cookies from "universal-cookie";
import Login from "./views/Login";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import Register from "./views/Register";
import TosBox from "./views/TosBox";
import ReviewSession from "./views/ReviewSession";
import Dash from "./views/Dash";
import Player from "./views/Player";
import Players from "./views/Players";
import Bats from "./views/Bats";
import GetFitted from "./views/GetFitted";
import NewFittingSession from "./views/NewFittingSession";
// import FittingSessions from "./views/PlayerHistory/FittingSessions";
import ViewSession from "./views/ViewSession";
import AdminDashboard from "./views/Admin/AdminDashboard";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ErrorBoundary from "./components/ErrorBoundary";
import { getUserRole } from "./utils/userUtils";

library.add(fas);

const cookies = new Cookies();

interface AppProps {}
interface AppState{ user: any, setUser: (payload: any) => {} }

class App extends React.Component<AppProps, AppState> {
  publicPages = [
    "/",
    "/register",
    "/login",
    "/forgot-password",
    "/reset-password",
  ];
  sharedPages = ["/results"];

  constructor(props: AppProps) {
    super(props);
    var page = window.location.pathname;
    
    if (cookies.get("user")) {
      var redirectToDash = false;
      for (let i in this.publicPages) {
        if (page == this.publicPages[i]) {
          redirectToDash = true;
        }
      }
      for (let i in this.sharedPages) {
        if (page == this.sharedPages[i]) {
          redirectToDash = false;
        }
      }
      if (redirectToDash) {
        history.push("/dash");
      }
    }
  }

  render() {
    const userRole = getUserRole();
    return (
        <ErrorBoundary fallback={(<div>Error. <a href="/login">Login</a></div>)}>
      <Router history={history}>
        <Switch>
          {/* Auth Pages */}
          <Route path="/" exact>
            <Login />
          </Route>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/forgot-password" exact>
            <ForgotPassword />
          </Route>
          <Route path="/reset-password" exact>
            <ResetPassword />
          </Route>
          <Route path="/register" exact>
            <Register />
          </Route>
          <Route path="/tos" exact>
            <TosBox />
          </Route>
          
          <Route path="/dash" exact>
            <Dash />
          </Route>
          {
            userRole?.name?.toString().toLowerCase().includes('admin') ? 
            <Route path="/admin" exact>
                <AdminDashboard />
            </Route>
            : ''
          }
          <Route path="/player" exact>
            <Player />
          </Route>
          <Route path="/players" exact>
            <Players />
          </Route>
          <Route path="/bats" exact>
            <Bats />
          </Route>
          <Route path="/get-fitted" exact>
            <GetFitted />
          </Route>
          {/* <Route path="/sessions" exact>
            <FittingSessions />
          </Route> */}
          <Route path="/new-session" exact>
            <NewFittingSession />
          </Route>
          <Route path="/review-session" exact>
            <ReviewSession />
          </Route>
          <Route path="/results/:hash" exact component={ViewSession} />
          <Route path="/results">
            <h1>Oops, looks like you are viewing an older results page?</h1>
            <a
              href={(() => {
                const urlParams = new URLSearchParams(window.location.search);
                const myParam = urlParams.get("hash") || "";
                return "/results/" + myParam;
              })()}
            >
              Try this link instead
            </a>
          </Route>
        </Switch>
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        
        
      </Router>
      </ErrorBoundary>
    );
  }
}

export default App;
