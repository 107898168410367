import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import history from "../history";
import BatRXTextLogo from "../assets/images/Logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CoachAccountInterface, {
  CoachAccountInterfaceDto,
  EmptyCoachAccountInterfaceDto,
} from "../interfaces/apiInterfaces/CoachAccountInterface";
import AuthLoginRequest, {
  AuthLoginPostResponseInterface,
} from "../api/requests/AuthLoginRequest";
import AuthPingRequest, {
  AuthPingGetResponseInterface,
} from "../api/requests/AuthPingRequest";

import Cookies from "universal-cookie";
import { AppContext } from "../utils/appState/AppState";
const cookies = new Cookies();

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(4, "Password should be 4 chars minimum.")
    .required("Required"),
});

interface IndexProps {}
interface IndexState {
  coach: CoachAccountInterfaceDto;
  successLogin: CoachAccountInterface | null;
  failedLogin: string;
  user?: any
}

type LoginForm = {
  email: string;
  password: string;
};

class Login extends React.Component<IndexProps, IndexState> {
  state: IndexState = {
    coach: EmptyCoachAccountInterfaceDto,
    successLogin: null,
    failedLogin: "",
    user: null
  };

  attemptLogin = (values: LoginForm, setUser: any) => {
    let tempState = this.state;

    AuthLoginRequest.post({
      email: values.email,
      password: values.password,
    })
      .then((result: AuthLoginPostResponseInterface) => {
        if (result?.data) {
          toast.success(`Hello, ${result.data.first_name}`);

          tempState.successLogin = result.data;
          tempState.failedLogin = "";
          this.setState(tempState);
          console.log(setUser);
          setUser({ user: result.data});

          history.push("/dash");
        //   setTimeout(() => {
            
        //   }, 1500);
        } else {
          toast.error(result.message, {
            autoClose: 5000,
          });
          tempState.successLogin = null;
          tempState.failedLogin = result.message;
          this.setState(tempState);
        }
      })
      .catch((err: AuthLoginPostResponseInterface) => {
        toast.error(err.message, {
          autoClose: 5000,
        });
        tempState.successLogin = null;
        tempState.failedLogin = err.message;
        this.setState(tempState);
      });
  };

  // If logged in redirect to dashboard
  constructor(props: IndexProps) {
    super(props);
    AuthPingRequest.get().then((result: AuthPingGetResponseInterface) => {
      if (result.email) {
        history.push("/dash");
      }
    });
  }

  render() {
      let { user, setUser } = this.context;
    // This is used to render nothing when the user is logged in and hits the index page while they wait to be transfered to /dash
    if (cookies.get("user") && !this.state.successLogin) {
      return <></>;
    }
    return (
      <div className="loginForm text-center d-flex align-items-center">
        <div className="wrapper form-signin">
          <div className="logo mb-4">
            <img src={BatRXTextLogo} className="img-fluid" />
          </div>
          <div className="text-center my-4">
              <FontAwesomeIcon icon="user-circle" size="4x" />
            </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={LoginSchema}
            onSubmit={(values) => {
              this.attemptLogin(values, setUser);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="mb-3">
                  <div className="input-group">
                    <span className="iconBorder input-group-text">
                      {" "}
                      <FontAwesomeIcon icon="envelope" />
                    </span>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email"
                      className={`iconBorder form-control ${
                        errors.email && touched.email ? "is-invalid" : ""
                      }`}
                    />
                  </div>
                  {errors.email && touched.email ? (
                    <span className="small text-danger">{errors.email}</span>
                  ) : null}
                </div>

                <div>
                  <div className="input-group">
                    <span className="iconBorder input-group-text">
                      <FontAwesomeIcon icon="key" />
                    </span>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password"
                      className={`iconBorder form-control ${
                        errors.password && touched.password ? "is-invalid" : ""
                      }`}
                    />

                    <i className="fa fa-eye-slash eye-icon" id="eyeText"></i>
                  </div>
                  {errors.password && touched.password ? (
                    <span className="small text-danger">{errors.password}</span>
                  ) : null}
                </div>

                <div className="mb-3 text-right">
                  <Link to="forgot-password" className="small">
                    {" "}
                    Forgot password?
                  </Link>
                </div>

                <div className="login-btn mb-3">
                  <button
                    style={{
                      backgroundColor: "#007dbc",
                      border: "1px solid #007dbc",
                    }}
                    type="submit"
                    className="btn btn-primary btn-block"
                  >
                    LOGIN
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="new-account">
            <Link to="/register" className="pb-1">
              Register a New Account{" "}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Login.contextType = AppContext;
export default Login;
