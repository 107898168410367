import { 
    differenceInYears, 
    parseISO, 
    format,
    compareAsc
} from "date-fns";

export const formatDateToDisplay = (isoDate: string, dateFormat='do MMM yyyy') => {
    let date = parseISO(isoDate);
    let formattedDate = format(date, dateFormat);
    return formattedDate;
}

export const calculateAge = (isoDate: string, dateFormat='do MMM yyyy') => {
    let date = parseISO(isoDate);
    let age = differenceInYears(new Date(), date);
    return age;
}

export const compareDates = (firstDate: any, secondDate: any = null) => {
    let first = parseISO(firstDate);
    let second = secondDate ? parseISO(secondDate) : new Date();
    let result = compareAsc(first, second);
    return result; // 1 if the first date is after the second, -1 if the first date is before the second or 0 if dates are equal.
}