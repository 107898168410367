/* 
  Isaac Hormel
  5/26/2020
  The class that all files in the ./src/api/requests will inherit that lays out the functionality for them.
*/

interface BasicRequestOptions {
  host?: string;
  fetchInit?: RequestInit;
}

export default class BasicRequest{
  static get(options: any): Promise<any>{
    throw new Error("This request does not contain a GET route.");
  }
  static post(options: any): Promise<any>{
    throw new Error("This request does not contain a POST route.");
  }
  static put(options: any): Promise<any>{
    throw new Error("This request does not contain a PUT route.");
  }
  static delete(options: any): Promise<any>{
    throw new Error("This request does not contain a DELETE route.");
  }
}