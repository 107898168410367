import BasicRequest from "../classes/BasicRequest";

import Cookies from "universal-cookie";
const cookies = new Cookies();

export interface HittraxPutOptionsInterface {
  id: number;
  BatRxHitId: number;
}

export interface HittraxGetResponseInterface{
  data?: any;
  message: "findOne";
}
export interface HittraxPutResponseInterface{
  data?: any;
  message: string;
}

export default class HittraxRequest extends BasicRequest {
  static get(): Promise<HittraxGetResponseInterface | any> {
    var promise = new Promise((resolvePromise, rejectPromise) => {
      var destination = new URL(`${process.env.REACT_APP_API_URL}/hittrax/play`);
      var HitGetRequest = fetch(destination.toString(), {
        method: "GET",
        headers: {
          Authorization: cookies.get("user"),
        },
      });
      HitGetRequest.then((rawRequest) => {
        return rawRequest.json();
      }).then((requestJson: HittraxGetResponseInterface) => {
        resolvePromise(requestJson as HittraxGetResponseInterface);
      });
      HitGetRequest.catch((err) => {
        if (err.message) {
          rejectPromise(err.message);
        } else {
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }

  static put(
    options: HittraxPutOptionsInterface
  ): Promise<HittraxPutResponseInterface | any> {
    var promise = new Promise((resolvePromise, rejectPromise) => {
      var destination = new URL(`${process.env.REACT_APP_API_URL}/hittrax/play`);
      var body: any = {
        BatRxHitId: options.BatRxHitId
      };
      destination.pathname += `/${options.id}`;
      var HitPutRequest = fetch(destination.toString(), {
        method: "PUT",
        headers: {
          Authorization: cookies.get("user"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      HitPutRequest.then((rawRequest) => {
        return rawRequest.json();
      }).then((requestJson: HittraxPutResponseInterface) => {
        resolvePromise(requestJson as HittraxPutResponseInterface);
      });
      HitPutRequest.catch((err) => {
        if (err.message) {
          rejectPromise(err.message);
        } else {
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}
