import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import history from "../history";
// import BatRXTextLogo from "../assets/BATRX-Logo.png";

import CoachAccountInterface, {
  CoachAccountInterfaceDto,
  EmptyCoachAccountInterfaceDto,
} from "../interfaces/apiInterfaces/CoachAccountInterface";
import AuthLoginRequest, {
  AuthLoginPostResponseInterface,
} from "../api/requests/AuthLoginRequest";
import AuthPingRequest, {
  AuthPingGetResponseInterface,
} from "../api/requests/AuthPingRequest";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

interface IndexProps {}
interface IndexState {
  coach: CoachAccountInterfaceDto;
  successLogin: CoachAccountInterface | null;
  failedLogin: string;
}

type LoginForm = {
  email: string;
  password: string;
};

class TosBox extends Component<IndexProps, IndexState> {
  state: IndexState = {
    coach: EmptyCoachAccountInterfaceDto,
    successLogin: null,
    failedLogin: "",
  };

  attemptLogin = (values: LoginForm) => {
    let tempState = this.state;

    AuthLoginRequest.post({
      email: values.email,
      password: values.password,
    })
      .then((result: AuthLoginPostResponseInterface) => {
        if (result?.data) {
          tempState.successLogin = result.data;
          tempState.failedLogin = "";
          this.setState(tempState);
          setTimeout(() => {
            history.push("/dash");
          }, 1500);
        } else {
          tempState.successLogin = null;
          tempState.failedLogin = result.message;
          this.setState(tempState);
        }
      })
      .catch((err: AuthLoginPostResponseInterface) => {
        tempState.successLogin = null;
        tempState.failedLogin = err.message;
        this.setState(tempState);
      });
  };

  // If logged in redirect to dashboard
  constructor(props: IndexProps) {
    super(props);
    AuthPingRequest.get().then((result: AuthPingGetResponseInterface) => {
      if (result.email) {
        history.push("/dash");
      }
    });
  }

  render() {
    // This is used to render nothing when the user is logged in and hits the index page while they wait to be transfered to /dash
    if (cookies.get("user") && !this.state.successLogin) {
      return <></>;
    }
    return (
      <div className="terms-and-conditions text-center d-flex align-items-center">
        <div className="wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="bg-white scrollable-tnc p-4">
                <p>
                  “The Bat Performance Lab provides the https://app.bat-rx.com/
                  website as a service to the public. The Bat Performance Lab is
                  not responsible for, and expressly disclaims all liability
                  for, damages of any kind arising out of use, reference to, or
                  reliance on any information contained within this website.
                </p>
                <p>
                  While the information contained within this website is
                  periodically updated, no guarantee is given that the
                  information provided in this website is correct, complete, and
                  up-to-date.
                </p>
                <p>
                  Although The Bat Performance Lab website may include links
                  providing direct access to other Internet resources, including
                  websites, The Bat Performance Lab is not responsible for the
                  accuracy or content of information contained in these sites.
                </p>
                <p>
                  Links from The Bat Performance Lab to third-party sites do not
                  constitute an endorsement by The Bat Performance Lab of the
                  parties or their products and services. The appearance on this
                  website of advertisements and product or service information
                  does not constitute an endorsement by The Bat Performance Lab,
                  and The Bat Performance Lab has not investigated the claims
                  made by any advertiser. Product information is based solely on
                  material received from suppliers.”{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-2">
              <div className="logo">
                {" "}
                {/* <img src={BatRXTextLogo} className="img-fluid" /> */}
              </div>
            </div>
            <div className="col-md-10 text-right">
              <button className="btn btn-outline-dark px-5 mr-2">
                Disagree and Exit
              </button>
              <button className="btn btn-primary px-5">Agree</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TosBox;
