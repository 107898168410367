import React, { ChangeEvent } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "whatwg-fetch";

import { Grid, Box } from "@material-ui/core";

import {
  Form,
  Button,
  Card,
  OverlayTrigger,
  Popover,
  Accordion,
} from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "@date-io/moment";

import history from "../../history";
import Cookies from "universal-cookie";
import { formatDateToDisplay } from "../../utils/dateUtils";
import {
  PlayerBiometricInterfaceDto,
  EmptyPlayerBiometricDto,
} from "../../interfaces/apiInterfaces/PlayerBiometricInterface";
import {
  PlayerMovementScreeningInterfaceDto,
  EmptyPlayerMovementScreeningDto,
} from "../../interfaces/apiInterfaces/PlayerMovementScreeningInterface";
import {
  PlayerPowerTestingInterfaceDto,
  EmptyPlayerPowerTestingDto,
} from "../../interfaces/apiInterfaces/PlayerPowerTestingInterface";
import {
  RangeInterface,
  EmptyRangeInterface,
} from "../../interfaces/apiInterfaces/RangeInterface";
import {
  PlayerInterfaceDto,
  EmptyPlayerInterfaceDto,
} from "../../interfaces/apiInterfaces/PlayerInterface";
import Navbar from "../../components/Navbar/Navbar";
import CoachAccountInterface, {
  EmptyCoachAccountInterface,
} from "../../interfaces/apiInterfaces/CoachAccountInterface";
import AuthPingRequest, {
  AuthPingGetResponseInterface,
} from "../../api/requests/AuthPingRequest";
import RangeRequest, {
  RangeGetResponseInterface,
} from "../../api/requests/RangeRequest";
import PlayerRequest, {
  PlayerGetResponseInterface,
} from "../../api/requests/PlayerRequest";
import OrganizationRequest from "../../api/requests/OrganizationRequest";

import ProcessPlayerRequest, {
  ProcessPlayerPostResponseInterface,
  ProcessPlayerPostOptionsInterface,
  ProcessPlayerPutResponseInterface,
  ProcessPlayerPutOptionsInterface,
} from "../../api/requests/ProcessPlayerRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// biometric images
import HipToFloor from "../../assets/images/hip_to_floor.gif";
import WristToFloor from "../../assets/images/wrist_to_floor.gif";
import ForearmLength from "../../assets/images/forearm_length.gif";
import HandLength from "../../assets/images/hand_length.jpg";
import HandWidth from "../../assets/images/hand_width.jpg";
import Wingspan from "../../assets/images/wingspan.jpg";
import UpperArmLength from "../../assets/images/upper_arm_length.jpg";

// movement screening images
import AnkleInversion from "../../assets/images/ankle_inversion.jpg";
import PelvicRotation from "../../assets/images/pelvic_rotation.gif";
import PelvicTilt from "../../assets/images/pelvic_tilt.gif";
import HipInternalRotation from "../../assets/images/hip_internal_rotation.jpg";
import HipExternalRotation from "../../assets/images/hip_external_rotation.gif";
import ShoulderExternalRotation from "../../assets/images/shoulder_external_rotation.gif";
import ThoracicSpineRotation from "../../assets/images/thoracic_spine_rotation.gif";
import WristSupination from "../../assets/images/wrist_supination.jpg";
import WristPronation from "../../assets/images/wrist_pronation.jpg";
import WideSquat from "../../assets/images/wide_squat.jpg";
import LateralStep from "../../assets/images/lateral_step.jpg";
import SeparationTest from "../../assets/images/separation_test.jpg";
import PlayerBiometricRequest from "../../api/requests/PlayerBiometricRequest";
import toggleBioMetricsUnits from "../../utils/playerUtils";
import { getUserOrganization, getUserRole } from "../../utils/userUtils";

const cookies = new Cookies();
let interval = 0;

const bio_metric_options = {
  bats: {
    title: "Bats",
    options: ["Left", "Right", "Both"],
  },
  throws: {
    title: "Throws",
    options: ["Left", "Right", "Both"],
  },
  writing_hand: {
    title: "Writing Hand",
    options: ["Left", "Right", "Both"],
  },
  eating_hand: {
    title: "Eating Hand",
    options: ["Left", "Right", "Both"],
  },
  dominant_eye: {
    title: "Dominant Eye",
    options: ["Left", "Right", "Both"],
  },
  dominant_leg: {
    title: "Dominant Leg",
    options: ["Left", "Right", "Even"],
  },
};

const bio_metric_options_2 = {
  hand_width: {
    title: "Hand Width",
    img: HandWidth,
    desc: "Distance from outside of knuckle of the index finger to outside of knuckle of the pinky finger",
  },
  hand_length: {
    title: "Hand Length",
    img: HandLength,
    desc: "Distance from the tip of the longest finger to the crease under the palm",
  },
  forearm_length: {
    title: "Forearm Length",
    img: ForearmLength,
    desc: "Distance from the middle knuckle with closed fist to the lateral epicondyle (elbow)",
  },
  arm_length: {
    title: "Upper Arm Length",
    img: UpperArmLength,
    desc: "Distance from the lateral epicondyle (elbow) to shoulder AC Joint",
  },
  wrist_to_floor: {
    title: "Wrist to Floor",
    img: WristToFloor,
    desc: "Distance from the crease below the palm to the ground with arms relaxed and feet together",
  },
  hip_to_floor: {
    title: "Hip to Floor",
    img: HipToFloor,
    desc: "Distance from the Iliac Crest to the ground with arms relaxed and feet together",
  },
  wingspan: {
    title: "Wingspan",
    img: Wingspan,
    desc: "Distance from the tips of each hands longest finger with arms stretched as wide as possible",
  },
};

const movement_screening_options = {
  left_hip_internal_rotation: {
    title: "Left Hip Int. Rotation",
    img: HipInternalRotation,
    options: ["Short of Bat", "Touches Bat"],
    desc: "Stand with both feet facing forward and a foot length between your feet.  Lift left toe while leaving heel on ground and try to rotate foot to the midpoint between your feet (this is 45 degrees).",
  },
  right_hip_internal_rotation: {
    title: "Right Hip Int. Rotation",
    img: HipInternalRotation,
    options: ["Short of Bat", "Touches Bat"],
    desc: "Stand with both feet facing forward and a foot length between your feet.  Lift right toe while leaving heel on ground and try to rotate foot to the midpoint between your feet (this is 45 degrees).",
  },
  left_hip_external_rotation: {
    title: "Left Hip Ext. Rotation",
    img: HipExternalRotation,
    options: [
      "Greater than 45 degrees",
      "Equal to 45 degrees",
      "Less than 45 degrees",
    ],
    desc: "Stand with heels together and feet making a 45 degree arc (the back of home plate is useful for lining up feet).  Have 100% weight in left foot and rotate hips to the right as much as possible.  Hips in line with left foot is 45 degrees.",
  },
  right_hip_external_rotation: {
    title: "Right Hip Ext. Rotation",
    img: HipExternalRotation,
    options: [
      "Greater than 45 degrees",
      "Equal to 45 degrees",
      "Less than 45 degrees",
    ],
    desc: "Stand with heels together and feet making a 45 degree arc (the back of home plate is useful for lining up feet).  Have 100% weight in right foot and rotate hips to the left as much as possible.  Hips in line with right foot is 45 degrees.",
  },
  pelvic_tilt: {
    title: "Pelvic Tilt",
    img: PelvicTilt,
    options: [
      "Can pelvic tilt both directions",
      "Cannot flatten back",
      "Cannot arch back",
      "Cannot tilt in either direction",
    ],
    desc: "Stand in athletic posture with knees bent and upper body at 45 degree angle (fielding position) with hands across chest.  Have player try to tilt pelvis back and forth with as little upper and lower body movement as possible.  Assess if player can tilt pelvis backwards (posterior) and forwards (anterior).",
  },
  pelvic_rotation: {
    title: "Pelvic Rotation",
    img: PelvicRotation,
    options: [
      "Good bilaterally",
      "Limited turning left",
      "Limited turning right",
      "Limited bilaterally",
    ],
    desc: "Stand in athletic posture with knees bent and upper body at 45 degree angle (fielding position) with hands across chest.  Have player try to rotate pelvis left and right with as little upper and lower body movement as possible.  Assess if player can rotate both directions or if the movement is more lateral.",
  },
  thoracic_spine_rotation: {
    title: "Thoracic Spine Rotation",
    img: ThoracicSpineRotation,
    options: [
      "Greater than 45 degrees bilaterally",
      "Limited turning right",
      "Limited turning left",
      "Limited bilaterally",
    ],
    desc: "Begin by sitting with knees and feet together and bat over shoulders behind the head.  You can use homeplate right in front of player to create 45 degree lines.  Keeping feet and knees together, rotate upper body as far to the right as possible and assess if they can get to or go past 45 degrees.  Repeat turning to the left.",
  },
  lateral_step: {
    title: "Lateral Step",
    img: LateralStep,
    options: [
      "Stable bilaterally",
      "Unstable stepping right",
      "Unstable stepping left",
      "Unstable bilaterally",
    ],
    desc: "Stand tall both feet together to the right of home plate.  Place bat across shoulders behind head.  Raise the left foot up to knee height then reach foot to other side of home plate then return to starting position.  Repeat on left side of home plate stepping to the right side.",
  },
  wide_squat: {
    title: "Wide Squat",
    img: WideSquat,
    options: [
      "Good depth and stable",
      "Good depth and unstable",
      "Limited depth",
    ],
    desc: "Stand with feet hip width apart.  Extend arms out in front and keep feet pointed straight ahead throughout test.  Squat down as far as possible with heels on ground.  Once at bottom of squat, drop arms to the side and assess stability (Do you fall over?)",
  },
  ankle_inversion: {
    title: "Ankle Inversion",
    img: AnkleInversion,
    options: [
      "Good bilaterally",
      "Right ankle limited",
      "Left ankle limited",
      "Limited bilaterally",
    ],
    desc: "Begin by sitting with knees at 90 degrees and legs hip width apart.  Attempt to roll ankles outward without moving the knees.  If they cannot, hold the knees and see if movement improves.",
  },
  ankle_eversion: {
    title: "Ankle Eversion",
    img: AnkleInversion,
    options: [
      "Good bilaterally",
      "Right ankle limited",
      "Left ankle limited",
      "Limited bilaterally",
    ],
    desc: "Begin by sitting with knees at 90 degrees and legs hip width apart.  Attempt to roll ankles inward without moving the knees.  If they cannot, hold the knees and see if movement improves.",
  },
  left_shoulder_er: {
    title: "Left Shoulder ER",
    img: ShoulderExternalRotation,
    options: [
      "Greater than 45 degrees",
      "Equal to 45 degrees",
      "Less than 45 degrees",
    ],
    desc: "Using home plate as a guideline, stand with left elbow directly above back corner of home plate.  See if the left arm can externally rotate (outward) to 45 degrees or more)",
  },
  right_shoulder_er: {
    title: "Right Shoulder ER",
    img: ShoulderExternalRotation,
    options: [
      "Greater than 45 degrees",
      "Equal to 45 degrees",
      "Less than 45 degrees",
    ],
    desc: "Using home plate as a guideline, stand with right elbow directly above back corner of home plate.  See if the right arm can externally rotate (outward) to 45 degrees or more)",
  },
  pronation: {
    title: "Pronation",
    img: WristPronation,
    options: [
      "Greater than or equal to 80 degrees bilaterally",
      "Right forearm less than 80 degrees",
      "Left forearm less than 80 degrees",
      "Less than 80 degrees bilaterally",
    ],
    desc: "Stand with elbows tucked against side of body, arms at a 90 degree angle and thumbs pointing to sky.  Rotate left wrist inward and assess if thumb can go parallel to floor or at least 80 degrees.",
  },
  supination: {
    title: "Supination",
    img: WristSupination,
    options: [
      "Greater than or equal to 80 degrees bilaterally",
      "Right forearm less than 80 degrees",
      "Left forearm less than 80 degrees",
      "Less than 80 degrees bilaterally",
    ],
    desc: "Stand with elbows tucked against side of body, arms at a 90 degree angle and thumbs pointing to sky.  Rotate left wrist outward and assess if thumb can go parallel to floor or at least 80 degrees.",
  },
  right_shoulder_adduction: {
    title: "Right Shoulder Adduction",
    img: SeparationTest,
    options: ["Hands past elbow", "Hands equal to elbow", "Hands inside elbow"],
    desc: "Stand with bat at right shoulder with right hand even with armpit like you are hitting right handed.  Keep the bat vertical throughout the test and lift right elbow as high as you can.  Keep elbow up and bat vertical while extending bat back laterally from the body as far as possible.  While looking at the sternum of the tested player, see if the hands are in one of three positions (inside elbow, over elbow, outside elbow)",
  },
  left_shoulder_adduction: {
    title: "Left Shoulder Adduction",
    img: SeparationTest,
    options: ["Hands past elbow", "Hands equal to elbow", "Hands inside elbow"],
    desc: "Stand with bat at left shoulder with left hand even with armpit like you are hitting left handed.  Keep the bat vertical throughout the test and lift left elbow as high as you can.  Keep elbow up and bat vertical while extending bat back laterally from the body as far as possible.  While looking at the sternum of the tested player, see if the hands are in one of three positions (inside elbow, over elbow, outside elbow)",
  },
};

type Mode = "view" | "edit" | "new";

interface NewPlayerState {
  coach: CoachAccountInterface;
  activeStep: number;
  stepperLabels: Array<string>;
  player: PlayerInterfaceDto;
  player_bio_metric: PlayerBiometricInterfaceDto;
  player_movement_screening: PlayerMovementScreeningInterfaceDto;
  player_power_testing: PlayerPowerTestingInterfaceDto;
  player_bio_metric_range_min: PlayerBiometricInterfaceDto;
  player_bio_metric_range_max: PlayerBiometricInterfaceDto;
  player_power_testing_range_min: {
    grip_strength_lh: number;
    grip_strength_rh: number;
    vertical_jump: number;
    med_ball_situp: number;
    med_ball_chest: number;
  };
  player_power_testing_range_max: {
    grip_strength_lh: number;
    grip_strength_rh: number;
    vertical_jump: number;
    med_ball_situp: number;
    med_ball_chest: number;
  };
  ranges: Array<RangeInterface>;
  canCreatePlayer: boolean;
  canCreatePlayerBioMetric: boolean;
  canCreatePlayerPowerTesting: boolean;
  useMetricSystem: boolean;
  msInput: string | null;
  bioInput: string | null;
  organizations?: Array<any>;
}

class NewPlayer extends React.Component<any, NewPlayerState> {
  constructor(props: any) {
    super(props);
    AuthPingRequest.get().then((result: AuthPingGetResponseInterface) => {
      if (!result.email) {
        history.push("/login");
      } else {
        var tempState = this.state;
        tempState.coach = result;
        this.setState(tempState);
      }
    });
  }

  state: NewPlayerState = {
    bioInput: null,
    msInput: null,
    useMetricSystem: false,
    coach: EmptyCoachAccountInterface,
    activeStep: 0,
    organizations: [],
    stepperLabels: [
      "User",
      "Biometrics",
      "Movement Screening",
      "Power Testing",
      "Finalize",
    ],
    player: {
      ...EmptyPlayerInterfaceDto,
      user_id: 0,
      dob:
        "" +
        new Date(
          new Date().setFullYear(new Date().getFullYear() - 10)
        ).toISOString(),
    },
    player_bio_metric: {
      player_id: 0,
      weight: 0,
      height: 0,
      hand_width: 0,
      hand_length: 0,
      forearm_length: 0,
      arm_length: 0,
      wrist_to_floor: 0,
      hip_to_floor: 0,
      wingspan: 0,
      bats: "",
      throws: "",
      writing_hand: "",
      eating_hand: "",
      dominant_eye: "",
      dominant_leg: "",
    },
    player_movement_screening: {
      ...EmptyPlayerMovementScreeningDto,
      player_id: 0,
    },
    player_power_testing: {
      ...EmptyPlayerPowerTestingDto,
      player_id: 0,
    },
    player_bio_metric_range_min: {
      player_id: -Infinity,
      weight: 50,
      height: 44,
      hand_width: 2,
      hand_length: 4,
      forearm_length: 8,
      arm_length: 8,
      wrist_to_floor: 20,
      hip_to_floor: 30,
      wingspan: 1,
      bats: "",
      throws: "",
      writing_hand: "",
      eating_hand: "",
      dominant_eye: "",
      dominant_leg: "",
    },
    player_bio_metric_range_max: {
      player_id: Infinity,
      weight: 350,
      height: 84,
      hand_width: 8,
      hand_length: 14,
      forearm_length: 20,
      arm_length: 21,
      wrist_to_floor: 120,
      hip_to_floor: 140,
      wingspan: 84,
      bats: "",
      throws: "",
      writing_hand: "",
      eating_hand: "",
      dominant_eye: "",
      dominant_leg: "",
    },
    player_power_testing_range_min: {
      grip_strength_lh: 22,
      grip_strength_rh: 22,
      vertical_jump: 5,
      med_ball_situp: 5,
      med_ball_chest: 5,
    },
    player_power_testing_range_max: {
      grip_strength_lh: 220,
      grip_strength_rh: 220,
      vertical_jump: 50,
      med_ball_situp: 40,
      med_ball_chest: 40,
    },
    ranges: [],
    canCreatePlayer: false,
    canCreatePlayerBioMetric: true,
    canCreatePlayerPowerTesting: true,
  };

  componentDidMount = async () => {
    const { playerId, mode } = this.props;
    const org = getUserOrganization();
    const userRole = getUserRole();

    if (userRole?.name?.toString().toLowerCase().includes('admin')) {
        await OrganizationRequest.get().then(orgs => {
            orgs?.data?.organizations?.map((org: any) => {
                this.state.organizations?.push({ id: org?.id, name: org?.name })
            });
        }).catch(ex => {
            console.log(ex, 'organizations');
            this.state.organizations = [];
        })
    } else {
        this.state.organizations?.push({ id: org.id, name: org.name});
    }

    // For skills dropdown list
    await RangeRequest.get({ limit: 100 })
      .then((result: RangeGetResponseInterface) => {
        if (result.data) {
          var tempState = this.state;
          if (Array.isArray(result.data)) {
            var sortedRanges = result.data;
            sortedRanges.sort(function (a: RangeInterface, b: RangeInterface) {
              var answer = 0;
              if (parseFloat(a.skill) && parseFloat(b.skill)) {
                answer = parseFloat(a.skill) - parseFloat(b.skill);
              } else if (parseFloat(a.skill) && parseFloat(b.skill)) {
                answer = a.skill.length - b.skill.length;
              } else if (parseFloat(a.skill) == NaN) {
                answer = b.skill.length - a.skill.length;
              } else {
                answer = a.skill.length - b.skill.length;
              }
              return answer;
            });
            tempState.ranges = sortedRanges;
          } else {
            tempState.ranges = [result.data];
          }
          this.setState(tempState);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
    if (playerId && mode === "edit") {
      PlayerRequest.get({
        id: playerId,
        withInfo: true,
      })
        .then((result: PlayerGetResponseInterface) => {
          if (result.data && !Array.isArray(result.data)) {
            var tempState = this.state;
            tempState.player = (({
              biometrics,
              movement_screenings,
              power_testings,
              ...o
            }) => o)(result.data); // remove b and c
            if (result.data?.biometrics.length) {
              tempState.player_bio_metric = {
                ...result.data?.biometrics[0],
                player_id: playerId,
              };
              // convert to imperial units if metric system is not selected
              tempState.player_bio_metric = toggleBioMetricsUnits(
                tempState.useMetricSystem,
                tempState.player_bio_metric
              );
              console.log(tempState.player_bio_metric_range_max);
            }
            if (result.data?.movement_screenings.length)
              tempState.player_movement_screening = {
                ...result.data?.movement_screenings[0],
                player_id: playerId,
              };
            if (result.data?.power_testings.length)
              tempState.player_power_testing = {
                ...result.data?.power_testings[0],
                player_id: playerId,
              };
            this.setState(tempState);
          }
          this.updateCanCreatePlayer();
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (playerId && mode === "view") {
      PlayerRequest.get({
        id: playerId,
      })
        .then(async (result: PlayerGetResponseInterface) => {
          if (result.data && !Array.isArray(result.data)) {
            var tempState = this.state;
            tempState.player = result.data;
            const _biometrics = await PlayerBiometricRequest.get({
              filter_key: "player_id",
              filter_value: playerId,
            });
            if (_biometrics?.data?.length > 0) {
              tempState.player_bio_metric = _biometrics?.data[0];
              // convert to imperial units if metric system is not selected
              tempState.player_bio_metric = toggleBioMetricsUnits(
                tempState.useMetricSystem,
                tempState.player_bio_metric
              );
            }
            this.setState(tempState);
          }
          this.updateCanCreatePlayer();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleUseMetricSystemChange = (checked: boolean) => {
    var useMetricSystem = checked;

    var tempState = this.state;
    tempState.useMetricSystem = useMetricSystem;
    this.setState(tempState);

    if (!useMetricSystem) {
      var tempState = this.state;
      //PlayerBio
      tempState.player_bio_metric.height = this.toInches(
        tempState.player_bio_metric.height
      );
      tempState.player_bio_metric.weight = this.toPounds(
        tempState.player_bio_metric.weight
      );
      tempState.player_bio_metric.arm_length = this.toInches(
        tempState.player_bio_metric.arm_length
      );
      tempState.player_bio_metric.forearm_length = this.toInches(
        tempState.player_bio_metric.forearm_length
      );
      tempState.player_bio_metric.hand_length = this.toInches(
        tempState.player_bio_metric.hand_length
      );
      tempState.player_bio_metric.hand_width = this.toInches(
        tempState.player_bio_metric.hand_width
      );
      tempState.player_bio_metric.hip_to_floor = this.toInches(
        tempState.player_bio_metric.hip_to_floor
      );
      tempState.player_bio_metric.wrist_to_floor = this.toInches(
        tempState.player_bio_metric.wrist_to_floor
      );
      tempState.player_bio_metric.wingspan = this.toInches(
        tempState.player_bio_metric.wingspan
      );
      // player power testing
      tempState.player_power_testing.grip_strength_lh = this.toPounds(
        tempState.player_power_testing.grip_strength_lh
      );
      tempState.player_power_testing.grip_strength_rh = this.toPounds(
        tempState.player_power_testing.grip_strength_rh
      );
      tempState.player_power_testing.vertical_jump = this.toInches(
        tempState.player_power_testing.vertical_jump
      );
      tempState.player_power_testing.med_ball_situp = this.cmToFt(
        tempState.player_power_testing.med_ball_situp
      );
      tempState.player_power_testing.med_ball_chest = this.cmToFt(
        tempState.player_power_testing.med_ball_chest
      );
      //The default values are now loaded, since converting multiple times can introduce errors
      tempState = {
        ...tempState,
        player_bio_metric_range_min: {
          player_id: -Infinity,
          weight: 50,
          height: 44,
          hand_width: 4,
          hand_length: 4,
          forearm_length: 8,
          arm_length: 30,
          wrist_to_floor: 8,
          hip_to_floor: 30,
          wingspan: 1,
          bats: "",
          throws: "",
          writing_hand: "",
          eating_hand: "",
          dominant_eye: "",
          dominant_leg: "",
        },
        player_bio_metric_range_max: {
          player_id: Infinity,
          weight: 350,
          height: 84,
          hand_width: 12,
          hand_length: 14,
          forearm_length: 20,
          arm_length: 90,
          wrist_to_floor: 120,
          hip_to_floor: 140,
          wingspan: 84,
          bats: "",
          throws: "",
          writing_hand: "",
          eating_hand: "",
          dominant_eye: "",
          dominant_leg: "",
        },
        player_power_testing_range_min: {
          grip_strength_lh: 22,
          grip_strength_rh: 22,
          vertical_jump: 5,
          med_ball_situp: 5,
          med_ball_chest: 5,
        },
        player_power_testing_range_max: {
          grip_strength_lh: 220,
          grip_strength_rh: 220,
          vertical_jump: 50,
          med_ball_situp: 40,
          med_ball_chest: 40,
        },
      };
      this.setState(tempState);
    } else {
      var tempState = this.state;
      console.log("use metric system", this.state);
      //PlayerBio
      tempState.player_bio_metric.height = this.toCentimeters(
        tempState.player_bio_metric.height
      );
      tempState.player_bio_metric.weight = this.toKilograms(
        tempState.player_bio_metric.weight
      );
      tempState.player_bio_metric.arm_length = this.toCentimeters(
        tempState.player_bio_metric.arm_length
      );
      tempState.player_bio_metric.forearm_length = this.toCentimeters(
        tempState.player_bio_metric.forearm_length
      );
      tempState.player_bio_metric.hand_length = this.toCentimeters(
        tempState.player_bio_metric.hand_length
      );
      tempState.player_bio_metric.hand_width = this.toCentimeters(
        tempState.player_bio_metric.hand_width
      );
      tempState.player_bio_metric.hip_to_floor = this.toCentimeters(
        tempState.player_bio_metric.hip_to_floor
      );
      tempState.player_bio_metric.wrist_to_floor = this.toCentimeters(
        tempState.player_bio_metric.wrist_to_floor
      );
      tempState.player_bio_metric.wingspan = this.toCentimeters(
        tempState.player_bio_metric.wingspan
      );

      // Player Power
      tempState.player_power_testing.grip_strength_lh = this.toKilograms(
        tempState.player_power_testing.grip_strength_lh
      );
      tempState.player_power_testing.grip_strength_rh = this.toKilograms(
        tempState.player_power_testing.grip_strength_rh
      );
      tempState.player_power_testing.vertical_jump = this.toCentimeters(
        tempState.player_power_testing.vertical_jump
      );
      tempState.player_power_testing.med_ball_situp = this.ftToCm(
        tempState.player_power_testing.med_ball_situp
      );
      tempState.player_power_testing.med_ball_chest = this.ftToCm(
        tempState.player_power_testing.med_ball_chest
      );

      //PlayerBioMinRange
      tempState.player_bio_metric_range_min.height = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_min.height
        ).toString()
      );
      tempState.player_bio_metric_range_min.weight = parseInt(
        this.toKilograms(
          tempState.player_bio_metric_range_min.weight
        ).toString()
      );
      tempState.player_bio_metric_range_min.arm_length = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_min.arm_length
        ).toString()
      );
      tempState.player_bio_metric_range_min.forearm_length = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_min.forearm_length
        ).toString()
      );
      tempState.player_bio_metric_range_min.hand_length = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_min.hand_length
        ).toString()
      );
      tempState.player_bio_metric_range_min.hand_width = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_min.hand_width
        ).toString()
      );
      tempState.player_bio_metric_range_min.hip_to_floor = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_min.hip_to_floor
        ).toString()
      );
      tempState.player_bio_metric_range_min.wrist_to_floor = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_min.wrist_to_floor
        ).toString()
      );
      tempState.player_bio_metric_range_min.wingspan = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_min.wingspan
        ).toString()
      );
      //PlayerBioMaxRange
      tempState.player_bio_metric_range_max.height = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_max.height
        ).toString()
      );
      tempState.player_bio_metric_range_max.weight = parseInt(
        this.toKilograms(
          tempState.player_bio_metric_range_max.weight
        ).toString()
      );
      tempState.player_bio_metric_range_max.arm_length = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_max.arm_length
        ).toString()
      );
      tempState.player_bio_metric_range_max.forearm_length = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_max.forearm_length
        ).toString()
      );
      tempState.player_bio_metric_range_max.hand_length = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_max.hand_length
        ).toString()
      );
      tempState.player_bio_metric_range_max.hand_width = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_max.hand_width
        ).toString()
      );
      tempState.player_bio_metric_range_max.hip_to_floor = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_max.hip_to_floor
        ).toString()
      );
      tempState.player_bio_metric_range_max.wrist_to_floor = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_max.wrist_to_floor
        ).toString()
      );
      tempState.player_bio_metric_range_max.wingspan = parseInt(
        this.toCentimeters(
          tempState.player_bio_metric_range_max.wingspan
        ).toString()
      );

      // PlayerPowerMinRange
      tempState.player_power_testing_range_min.grip_strength_lh = parseInt(
        this.toKilograms(
          tempState.player_power_testing_range_min.grip_strength_lh
        ).toString()
      );
      tempState.player_power_testing_range_min.grip_strength_rh = parseInt(
        this.toKilograms(
          tempState.player_power_testing_range_min.grip_strength_rh
        ).toString()
      );
      tempState.player_power_testing_range_min.med_ball_chest = parseInt(
        this.toCentimeters(
          tempState.player_power_testing_range_min.med_ball_chest
        ).toString()
      );
      tempState.player_power_testing_range_min.med_ball_situp = parseInt(
        this.toCentimeters(
          tempState.player_power_testing_range_min.med_ball_situp
        ).toString()
      );
      tempState.player_power_testing_range_min.vertical_jump = parseInt(
        this.toCentimeters(
          tempState.player_power_testing_range_min.vertical_jump
        ).toString()
      );
      // PlayerPowerMaxRange
      tempState.player_power_testing_range_max.grip_strength_lh = parseInt(
        this.toKilograms(
          tempState.player_power_testing_range_max.grip_strength_lh
        ).toString()
      );
      tempState.player_power_testing_range_max.grip_strength_rh = parseInt(
        this.toKilograms(
          tempState.player_power_testing_range_max.grip_strength_rh
        ).toString()
      );
      tempState.player_power_testing_range_max.med_ball_chest = parseInt(
        this.toCentimeters(
          tempState.player_power_testing_range_max.med_ball_chest
        ).toString()
      );
      tempState.player_power_testing_range_max.med_ball_situp = parseInt(
        this.toCentimeters(
          tempState.player_power_testing_range_max.med_ball_situp
        ).toString()
      );
      tempState.player_power_testing_range_max.vertical_jump = parseInt(
        this.toCentimeters(
          tempState.player_power_testing_range_max.vertical_jump
        ).toString()
      );
    }
  };

  ftToCm = (feet: number) => {
    return Math.round(feet * 30.48);
  };

  cmToFt = (cm: number) => {
    return Math.round(cm / 30.48);
  };

  toCentimeters = (toConvert: number) => {
    return Math.round(toConvert * 2.54);
  };

  toInches = (toConvert: number) => {
    return parseFloat(Math.round(toConvert * 0.3937).toFixed(2)); //auto fix to 2 decimal places
  };

  toKilograms = (toConvert: number) => {
    return parseFloat(Math.round(toConvert / 2.20462262).toFixed(2));
  };

  toPounds = (toConvert: number) => {
    return parseFloat(Math.round(toConvert / 0.45359237).toFixed(2));
  };

  distanceMeasurement = () => {
    if (this.state.useMetricSystem) {
      return "cm";
    } else {
      return "in";
    }
  };

  distanceMeasurement2 = () => {
    if (this.state.useMetricSystem) {
      return "cm";
    } else {
      return "ft";
    }
  };

  weightMeasurement = () => {
    if (this.state.useMetricSystem) {
      return "kg";
    } else {
      return "lb";
    }
  };

  attemptProcessPlayer = () => {
    var tempState = this.state;
    if (this.state.canCreatePlayer && this.state.canCreatePlayerBioMetric) {
      var processPlayerOptions: ProcessPlayerPostOptionsInterface = {
        player: {
          ...tempState.player,
        },
        player_bio_metric: {
          ...this.state.player_bio_metric,
          player_id: this.state.player.id,
        },
        player_movement_screening: {
          ...this.state.player_movement_screening,
          player_id: this.state.player.id,
        },
        player_power_testing: {
          ...this.state.player_power_testing,
          player_id: this.state.player.id,
          horsepower:
            Number(
              this.state?.player_power_testing?.vertical_jump
                ? this.state.player_power_testing.vertical_jump
                : 0
            ) +
            Number(
              this.state?.player_power_testing?.med_ball_situp
                ? this.state.player_power_testing.med_ball_situp
                : 0
            ) +
            Number(
              this.state?.player_power_testing?.med_ball_chest
                ? this.state.player_power_testing.med_ball_chest
                : 0
            ),
        },
      };
      if (!this.state.useMetricSystem) {
        processPlayerOptions.player_bio_metric.height = this.toCentimeters(
          processPlayerOptions.player_bio_metric.height
        );
        processPlayerOptions.player_bio_metric.weight = this.toKilograms(
          processPlayerOptions.player_bio_metric.weight
        );
        processPlayerOptions.player_bio_metric.arm_length = this.toCentimeters(
          processPlayerOptions.player_bio_metric.arm_length
        );
        processPlayerOptions.player_bio_metric.forearm_length =
          this.toCentimeters(
            processPlayerOptions.player_bio_metric.forearm_length
          );
        processPlayerOptions.player_bio_metric.hand_length = this.toCentimeters(
          processPlayerOptions.player_bio_metric.hand_length
        );
        processPlayerOptions.player_bio_metric.hand_width = this.toCentimeters(
          processPlayerOptions.player_bio_metric.hand_width
        );
        processPlayerOptions.player_bio_metric.hip_to_floor =
          this.toCentimeters(
            processPlayerOptions.player_bio_metric.hip_to_floor
          );
        processPlayerOptions.player_bio_metric.wrist_to_floor =
          this.toCentimeters(
            processPlayerOptions.player_bio_metric.wrist_to_floor
          );
        processPlayerOptions.player_bio_metric.wingspan = this.toCentimeters(
          processPlayerOptions.player_bio_metric.wingspan
        );
      }
      if (this.props.mode === "edit" && this.props.playerId) {
        processPlayerOptions.id = this.props.playerId;
        console.log("edit player", processPlayerOptions);
        ProcessPlayerRequest.put(processPlayerOptions)
          .then((result: ProcessPlayerPutResponseInterface) => {
            if (result && result.data) {
              this.props.handleClose();
              this.clearForm();
              if (this.props.usage === "getFitted") {
                history.push(`/new-session?player=${this.props.playerId}`);
              }
              toast.success("Player updated successfully");
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
      } else {
        ProcessPlayerRequest.post(processPlayerOptions)
          .then((result: ProcessPlayerPostResponseInterface) => {
            if (result && result.data) {
              this.props.handleClose();
              this.clearForm();
              toast.success("Player created successfully");
            } else if (result && result.message) {
              toast.error(`${result.message}`, { autoClose: 5000 });
              if (result.message.startsWith("Email already exists")) {
                this.clearForm();
                this.props.handleClose();
              }
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    }
  };

  goForwardStep = () => {
    var tempState = this.state;
    if (tempState.activeStep < tempState.stepperLabels.length) {
      tempState.activeStep++;
    } else if (tempState.activeStep > 0) {
      this.attemptProcessPlayer();
    }
    this.setState(tempState);
  };

  goBackStep = () => {
    var tempState = this.state;
    if (tempState.activeStep > 0) {
      tempState.activeStep--;
    }
    this.setState(tempState);
  };
  skipBiometrics = () => {
    var tempState = this.state;
    tempState.player_bio_metric = {
      player_id: 0,
      height: 0,
      weight: 0,
      hand_width: 0,
      hand_length: 0,
      forearm_length: 0,
      arm_length: 0,
      wrist_to_floor: 0,
      hip_to_floor: 0,
      wingspan: 0,
      bats: "",
      throws: "",
      writing_hand: "",
      eating_hand: "",
      dominant_eye: "",
      dominant_leg: "",
    };
    this.setState(tempState);
    this.updateCanCreatePlayerBioMetric();
    this.goForwardStep();
  };
  clearForm = () => {
    var tempState = this.state;
    tempState.player = {
      ...EmptyPlayerInterfaceDto,
      user_id: 0,
      dob:
        "" +
        new Date(
          new Date().setFullYear(new Date().getFullYear() - 10)
        ).toISOString(),
    };
    tempState.player_bio_metric = {
      player_id: 0,
      height: 0,
      weight: 0,
      hand_width: 0,
      hand_length: 0,
      forearm_length: 0,
      arm_length: 0,
      wrist_to_floor: 0,
      hip_to_floor: 0,
      wingspan: 0,
      bats: "",
      throws: "",
      writing_hand: "",
      eating_hand: "",
      dominant_eye: "",
      dominant_leg: "",
    };
    this.setState(tempState);
  };

  getNextKey = (obj: any, inputKey: string) => {
    var keys = Object.keys(obj),
      idIndex = keys.indexOf(inputKey),
      nextIndex = (idIndex += 1);
    if (nextIndex >= keys.length) {
      //we're at the end, there is no next
      return null;
    }
    var nextKey = keys[nextIndex];
    return nextKey;
  };

  getPrevKey = (obj: any, inputKey: string) => {
    var keys = Object.keys(obj),
      idIndex = keys.indexOf(inputKey),
      nextIndex = (idIndex -= 1);
    if (idIndex === 0) {
      //we're at the beginning, there is no previous
      return null;
    }
    var nextKey = keys[nextIndex];
    return nextKey;
  };

  playerNameAndEmailContent = () => {
    return (
      <>
        <div className="tabWrapper" style={{ minHeight: "70vh" }}>
          <div className="row mb-4">
            <div className="col-md-12">
              <div>
                <h4 className="fw-bold">Player Information</h4>
                <button
                  className="float-right close"
                  onClick={() => this.props.handleClose()}
                >
                  &times;
                </button>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="mb-3 col-md-6">
              <label>First Name</label>
              <Form.Control
                size="lg"
                type="text"
                className="form-control"
                value={this.state.player.first_name}
                onChange={this.handleFirstnameChange}
              />
            </div>
            <div className="mb-3 col-md-6 ">
              <label>Last Name</label>
              <Form.Control
                size="lg"
                type="text"
                className="form-control"
                value={this.state.player.last_name}
                onChange={this.handleLastnameChange}
              />
            </div>
            <div className="mb-3 col-md-6 ">
              <label>Email</label>
              <Form.Control
                size="lg"
                type="email"
                className="form-control"
                value={this.state.player.email}
                onChange={this.handleEmailChange}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>Birth Date</label>
              <MuiPickersUtilsProvider utils={moment}>
                <DatePicker
                  value={this.state.player.dob}
                  onChange={this.handleDobChange}
                  disableFuture={true}
                  labelFunc={() => {
                    return (
                      "" +
                      (new Date(this.state.player.dob).getMonth() + 1) +
                      "/" +
                      new Date(this.state.player.dob).getDate() +
                      "/" +
                      new Date(this.state.player.dob).getFullYear()
                    );
                  }}
                  className="w-100"
                />
              </MuiPickersUtilsProvider>
            </div>
            {
                this.state?.organizations?.length && (
                    <div className="mb-3 col-md-6">
                    <label>Organization</label>
                    <Form.Control
                      size="lg"
                      as="select"
                      value={this.state.player.org_id}
                      onChange={this.handleOrganizationChange}
                    >
                      <option value="-1">-- Select --</option>
                      {this.state.organizations.map(org => {
                        return (
                          <option key={org.id} value={org.id}>
                            {org.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </div>      
                )
            }
            {this.state.ranges.length && (
              <div className="mb-3 col-md-6">
                <label>Skill Level</label>
                <Form.Control
                  size="lg"
                  as="select"
                  value={this.state.player.range_id}
                  onChange={this.handleSkillChange}
                >
                  <option value="-1">-- Select --</option>
                  {this.state.ranges.map((o, i) => {
                    return (
                      <option key={i} value={this.state.ranges[i].id}>
                        {this.state.ranges[i].skill}
                      </option>
                    );
                  })}
                </Form.Control>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  playerBiometrics = () => {
    const {
      useMetricSystem,
      player,
      player_bio_metric,
      player_bio_metric_range_min,
      player_bio_metric_range_max,
    } = this.state;
    return (
      <>
        <div className="tabWrapper" style={{ minHeight: "70vh" }}>
          <div className="row">
            <div className="col-md-12">
              <h4 className="fw-bold">Biometrics (1/2)</h4>
              <button
                className="float-right close"
                onClick={() => this.props.handleClose()}
              >
                &times;
              </button>

              <div className="float-right">
                <BootstrapSwitchButton
                  checked={this.state.useMetricSystem}
                  onlabel="Metric"
                  offlabel="Imperial"
                  width={110}
                  onChange={(checked: boolean) =>
                    this.handleUseMetricSystemChange(checked)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="mb-3 col-md-6 ">
              <label>Weight</label>
              <Form.Control
                size="lg"
                type="number"
                className="form-control"
                value={player_bio_metric.weight}
                onChange={this.handleWeightChange}
                isInvalid={
                  (player_bio_metric.weight <
                    player_bio_metric_range_min.weight ||
                    player_bio_metric.weight >
                      player_bio_metric_range_max.weight) &&
                  player_bio_metric.weight != 0
                }
                aria-describedby="weightHelpBlock"
              />
              <Form.Text id="weightHelpBlock" muted>
                {player_bio_metric_range_min.weight}-
                {player_bio_metric_range_max.weight} {this.weightMeasurement()}
              </Form.Text>
            </div>
            <div className="mb-3 col-md-6 ">
              <label>Height</label>
              <Form.Control
                size="lg"
                type="number"
                className="form-control"
                value={player_bio_metric.height}
                onChange={this.handleHeightChange}
                isInvalid={
                  (player_bio_metric.height <
                    player_bio_metric_range_min.height ||
                    player_bio_metric.height >
                      player_bio_metric_range_max.height) &&
                  player_bio_metric.height != 0
                }
                aria-describedby="heightHelpBlock"
              />
              <Form.Text id="heightHelpBlock" muted>
                {player_bio_metric_range_min.height}-
                {player_bio_metric_range_max.height}{" "}
                {this.distanceMeasurement()}
              </Form.Text>
            </div>

            {Object.entries(bio_metric_options).map(([key, value]) => {
              return (
                <div className="mb-3 col-md-6 ">
                  <label>{value.title}</label>
                  <Form>
                    <div className="mb-3 bioInputRadio row mx-2">
                      {value.options.map((i: string) => (
                        <Form.Check
                          key={`${key}-${i}`}
                          className="col"
                          onChange={(event) =>
                            this.updateBioMetric(key, event.target.value)
                          }
                          inline
                          type="radio"
                          name={key}
                          label={i}
                          value={i}
                          checked={(player_bio_metric as any)[key] === i}
                        />
                      ))}
                    </div>
                  </Form>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  playerBiometrics2 = () => {
    const {
      bioInput,
      useMetricSystem,
      player_bio_metric,
      player_bio_metric_range_min,
      player_bio_metric_range_max,
    } = this.state;
    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Popover right</Popover.Title>
        <Popover.Content>
          And here's some <strong>amazing</strong> content. It's very engaging.
          right?
        </Popover.Content>
      </Popover>
    );
    return (
      <>
        <div className="tabWrapper" style={{ minHeight: "70vh" }}>
          <div className="row">
            <div className="col-md-12">
              <h4 className="fw-bold">Biometrics (2/2)</h4>
              <button
                className="float-right close"
                onClick={() => this.props.handleClose()}
              >
                &times;
              </button>

              <div className="float-right">
                <BootstrapSwitchButton
                  checked={useMetricSystem}
                  onlabel="Metric"
                  offlabel="Imperial"
                  width={110}
                  onChange={(checked: boolean) =>
                    this.handleUseMetricSystemChange(checked)
                  }
                />
              </div>
            </div>
          </div>

          {bioInput ? (
            <div>
              <div className="row movementScreening">
                <div className="col-md-4">
                  <div className="shadow">
                    <img
                      src={(bio_metric_options_2 as any)[bioInput]["img"]}
                      alt=""
                      className="w-100"
                    />
                  </div>
                </div>

                <div className="col-md-8 mb-3">
                  <div className="h-100">
                    <h2 className="mb-0">
                      {(bio_metric_options_2 as any)[bioInput]["title"]}
                    </h2>

                    <hr className="my-1" />

                    <p>{(bio_metric_options_2 as any)[bioInput]["desc"]}</p>
                  </div>
                </div>
              </div>

              <div className="mt-2">
                <p className="mb-1">Enter your measurement</p>
                <div className="row">
                  {bioInput === "hand_width" && (
                    <div className="col">
                      <Form.Control
                        size="lg"
                        type="number"
                        className="form-control"
                        value={player_bio_metric.hand_width}
                        onChange={this.handleHandWidthChange}
                        isInvalid={
                          (player_bio_metric.hand_width <
                            player_bio_metric_range_min.hand_width ||
                            player_bio_metric.hand_width >
                              player_bio_metric_range_max.hand_width) &&
                          player_bio_metric.hand_width != 0
                        }
                        aria-describedby="handwidthHelpBlock"
                      />
                      <Form.Text id="handwidthHelpBlock">
                        {player_bio_metric_range_min.hand_width}-
                        {player_bio_metric_range_max.hand_width}{" "}
                        {this.distanceMeasurement()}
                      </Form.Text>
                    </div>
                  )}

                  {bioInput === "hand_length" && (
                    <div className="col">
                      <Form.Control
                        size="lg"
                        type="number"
                        className="form-control"
                        value={player_bio_metric.hand_length}
                        onChange={this.handleHandLengthChange}
                        isInvalid={
                          (player_bio_metric.hand_length <
                            player_bio_metric_range_min.hand_length ||
                            player_bio_metric.hand_length >
                              player_bio_metric_range_max.hand_length) &&
                          player_bio_metric.hand_length != 0
                        }
                        aria-describedby="handlengthHelpBlock"
                      />
                      <Form.Text id="handlengthHelpBlock">
                        {player_bio_metric_range_min.hand_length}-
                        {player_bio_metric_range_max.hand_length}{" "}
                        {this.distanceMeasurement()}
                      </Form.Text>
                    </div>
                  )}

                  {bioInput === "forearm_length" && (
                    <div className="col">
                      <Form.Control
                        size="lg"
                        type="number"
                        className="form-control"
                        value={player_bio_metric.forearm_length}
                        onChange={this.handleForearmLengthChange}
                        isInvalid={
                          (player_bio_metric.forearm_length <
                            player_bio_metric_range_min.forearm_length ||
                            player_bio_metric.forearm_length >
                              player_bio_metric_range_max.forearm_length) &&
                          player_bio_metric.forearm_length != 0
                        }
                        aria-describedby="forearmlengthHelpBlock"
                      />
                      <Form.Text id="forearmlengthHelpBlock">
                        {player_bio_metric_range_min.forearm_length}-
                        {player_bio_metric_range_max.forearm_length}{" "}
                        {this.distanceMeasurement()}
                      </Form.Text>
                    </div>
                  )}

                  {bioInput === "arm_length" && (
                    <div className="col">
                      <Form.Control
                        size="lg"
                        type="number"
                        className="form-control"
                        value={player_bio_metric.arm_length}
                        onChange={this.handleArmLengthChange}
                        isInvalid={
                          (player_bio_metric.arm_length <
                            player_bio_metric_range_min.arm_length ||
                            player_bio_metric.arm_length >
                              player_bio_metric_range_max.arm_length) &&
                          player_bio_metric.arm_length != 0
                        }
                        aria-describedby="armlengthHelpBlock"
                      />
                      <Form.Text id="armlengthHelpBlock">
                        {player_bio_metric_range_min.arm_length}-
                        {player_bio_metric_range_max.arm_length}{" "}
                        {this.distanceMeasurement()}
                      </Form.Text>
                    </div>
                  )}

                  {bioInput === "wrist_to_floor" && (
                    <div className="col">
                      <Form.Control
                        size="lg"
                        type="number"
                        className="form-control"
                        value={player_bio_metric.wrist_to_floor}
                        onChange={this.handleWristToFloorChange}
                        isInvalid={
                          (player_bio_metric.wrist_to_floor <
                            player_bio_metric_range_min.wrist_to_floor ||
                            player_bio_metric.wrist_to_floor >
                              player_bio_metric_range_max.wrist_to_floor) &&
                          player_bio_metric.wrist_to_floor != 0
                        }
                        aria-describedby="wristtofloorHelpBlock"
                      />
                      <Form.Text id="wristtofloorHelpBlock">
                        {player_bio_metric_range_min.wrist_to_floor}-
                        {player_bio_metric_range_max.wrist_to_floor}{" "}
                        {this.distanceMeasurement()}
                      </Form.Text>
                    </div>
                  )}

                  {bioInput === "hip_to_floor" && (
                    <div className="col">
                      <Form.Control
                        size="lg"
                        type="number"
                        className="form-control"
                        value={player_bio_metric.hip_to_floor}
                        onChange={this.handleHipToFloorChange}
                        isInvalid={
                          (player_bio_metric.hip_to_floor <
                            player_bio_metric_range_min.hip_to_floor ||
                            player_bio_metric.hip_to_floor >
                              player_bio_metric_range_max.hip_to_floor) &&
                          player_bio_metric.hip_to_floor != 0
                        }
                        aria-describedby="hiptofloorHelpBlock"
                      />
                      <Form.Text id="hiptofloorHelpBlock">
                        {player_bio_metric_range_min.hip_to_floor}-
                        {player_bio_metric_range_max.hip_to_floor}{" "}
                        {this.distanceMeasurement()}
                      </Form.Text>
                    </div>
                  )}

                  {bioInput === "wingspan" && (
                    <div className="col">
                      <Form.Control
                        size="lg"
                        type="number"
                        className="form-control"
                        value={player_bio_metric.wingspan}
                        onChange={this.handleWingspanChange}
                        isInvalid={
                          (player_bio_metric.wingspan <
                            player_bio_metric_range_min.wingspan ||
                            player_bio_metric.wingspan >
                              player_bio_metric_range_max.wingspan) &&
                          player_bio_metric.wingspan != 0
                        }
                        aria-describedby="hiptofloorHelpBlock"
                      />
                      <Form.Text id="hiptofloorHelpBlock">
                        {player_bio_metric_range_min.wingspan}-
                        {player_bio_metric_range_max.wingspan}{" "}
                        {this.distanceMeasurement()}
                      </Form.Text>
                    </div>
                  )}

                  <div className="col">
                    <button
                      className="btn btn-outline-darkblue btn-lg float-right"
                      onClick={() =>
                        this.setState({
                          bioInput: this.getNextKey(
                            bio_metric_options_2,
                            bioInput
                          ),
                        })
                      }
                    >
                      {this.getNextKey(bio_metric_options_2, bioInput)
                        ? "Next"
                        : "Finish"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="scrollableTab">
              <div className="mt-2 row">
                {Object.entries(bio_metric_options_2).map(([key, value]) => {
                  return (
                    <div className="col-6 col-md-4 col-lg-3 mb-3">
                      <div
                        className={`card shadow ${
                          (player_bio_metric as any)[key] && "successBlock"
                        }`}
                        onClick={() => this.setState({ bioInput: key })}
                      >
                        <FontAwesomeIcon
                          className="info-circle-icon"
                          color="grey"
                          icon="info-circle"
                        />
                        <img className="card-img-top" src={value.img} alt=" " />

                        <div className="card-footer px-0 text-center">
                          {value.title}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  playerMovementScreening = () => {
    const { player_movement_screening, msInput } = this.state;
    return (
      <>
        <div className="tabWrapper" style={{ minHeight: "70vh" }}>
          <div className="row mb-4">
            <div className="col-md-12">
              <h4 className="fw-bold">Movement Screening</h4>
              <button
                className="float-right close"
                onClick={() => this.props.handleClose()}
              >
                &times;
              </button>
            </div>
          </div>

          {msInput ? (
            <div>
              <div className="row movementScreening">
                <div className="col-md-4">
                  <div className="shadow">
                    <img
                      src={(movement_screening_options as any)[msInput]["img"]}
                      alt=""
                      className="w-100"
                    />
                  </div>
                </div>

                <div className="col-md-8 mb-3">
                  <div className="h-100">
                    <h2 className="mb-0">
                      {(movement_screening_options as any)[msInput]["title"]}
                    </h2>

                    <hr className="my-1" />

                    <p>
                      {(movement_screening_options as any)[msInput]["desc"]}
                    </p>
                  </div>
                </div>
              </div>
              <div className="my-2">
                {/* Did an amazing 10x code reduction */}
                {Object.entries(movement_screening_options).map(
                  ([key, value]) => {
                    return (
                      key === msInput && (
                        <div className="col bg-light py-1">
                          <div className="bioInputRadio row px-2">
                            {value.options.map((i: string) => (
                              <Form.Check
                                key={`${key}-${i}`}
                                className="col-12 my-2"
                                onChange={(event) =>
                                  this.updateMovementScreening(
                                    key,
                                    event.target.value
                                  )
                                }
                                inline
                                type="radio"
                                name={key}
                                label={i}
                                value={i}
                                checked={
                                  (player_movement_screening as any)[key] === i
                                }
                              />
                            ))}
                          </div>
                        </div>
                      )
                    );
                  }
                )}

                <div className="text-center mt-2">
                  <button
                    className="btn btn-outline-darkblue"
                    onClick={() =>
                      this.setState({
                        msInput: this.getNextKey(
                          movement_screening_options,
                          msInput
                        ),
                      })
                    }
                  >
                    {this.getNextKey(movement_screening_options, msInput)
                      ? "Next"
                      : "Finish"}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="scrollableTab">
              <div className="row">
                {Object.entries(movement_screening_options).map(
                  ([key, value]) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-3 mb-3">
                        <div
                          className={`card shadow ${
                            (player_movement_screening as any)[key] &&
                            "successBlock"
                          }`}
                          onClick={() => this.setState({ msInput: key })}
                        >
                          <FontAwesomeIcon
                            className="info-circle-icon"
                            color="grey"
                            icon="info-circle"
                          />
                          <img
                            className="card-img-top"
                            src={value.img}
                            alt=" "
                          />

                          <div className="card-footer px-0 text-center">
                            {value.title}
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  playerPowerTesting = () => {
    const {
      useMetricSystem,
      player_power_testing,
      player_power_testing_range_min,
      player_power_testing_range_max,
    } = this.state;
    const grip_strength_lh_pop = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Grip Strength LH</Popover.Title>
        <Popover.Content>{`In ${this.weightMeasurement()} with use of a dynamometer`}</Popover.Content>
      </Popover>
    );
    const grip_strength_rh_pop = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Grip Strength RH</Popover.Title>
        <Popover.Content>{`In ${this.weightMeasurement()} with use of a dynamometer`}</Popover.Content>
      </Popover>
    );
    const med_ball_situp = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Med Ball Situp</Popover.Title>
        <Popover.Content>{`Distance of Sit Up and Throw Test in ${
          this.distanceMeasurement() === "in" ? "feet" : "centimeters"
        }`}</Popover.Content>
      </Popover>
    );
    const med_ball_chest = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Med Ball Chest</Popover.Title>
        <Popover.Content>{`Distance of Chest Pass Test in ${
          this.distanceMeasurement() === "in" ? "feet" : "centimeters"
        }`}</Popover.Content>
      </Popover>
    );
    const vertical_jump = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Vertical Jump</Popover.Title>
        <Popover.Content>{`Height of jump in ${
          this.distanceMeasurement() === "in" ? "inches" : "centimeters"
        }`}</Popover.Content>
      </Popover>
    );
    const horsepower = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Horsepower</Popover.Title>
        <Popover.Content>{`Sum of Vertical Jump (${
          this.distanceMeasurement() === "in" ? "in" : "cm"
        }), Med Ball Sit Up (${
          this.distanceMeasurement() === "in" ? "ft" : "cm"
        }) and Med Ball Chest Pass (${
          this.distanceMeasurement() === "in" ? "ft" : "cm"
        })`}</Popover.Content>
      </Popover>
    );
    return (
      <>
        <div className="tabWrapper" style={{ minHeight: "70vh" }}>
          <div className="row">
            <div className="col-md-12">
              <h4 className="fw-bold">Power Testing</h4>
              <button
                className="float-right close"
                onClick={() => this.props.handleClose()}
              >
                &times;
              </button>
              <div className="float-right">
                <BootstrapSwitchButton
                  checked={useMetricSystem}
                  onlabel="Metric"
                  offlabel="Imperial"
                  width={110}
                  onChange={(checked: boolean) =>
                    this.handleUseMetricSystemChange(checked)
                  }
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="mb-3 col-md-6">
              <label>Grip Strength LH</label>
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={grip_strength_lh_pop}
              >
                <FontAwesomeIcon className="ml-1" icon="question-circle" />
              </OverlayTrigger>
              <Form.Control
                size="lg"
                type="number"
                className="form-control"
                value={player_power_testing.grip_strength_lh}
                onChange={(event) =>
                  this.updatePowerTesting(
                    "grip_strength_lh",
                    parseFloat(event.target.value)
                  )
                }
              />
              <Form.Text id="handwidthHelpBlock">
                {player_power_testing_range_min.grip_strength_lh}-
                {player_power_testing_range_max.grip_strength_lh}{" "}
                {this.weightMeasurement()}
              </Form.Text>
            </div>

            <div className="mb-3 col-md-6">
              <label>Grip Strength RH</label>
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={grip_strength_rh_pop}
              >
                <FontAwesomeIcon className="ml-1" icon="question-circle" />
              </OverlayTrigger>
              <Form.Control
                size="lg"
                type="number"
                className="form-control"
                value={player_power_testing.grip_strength_rh}
                onChange={(event) =>
                  this.updatePowerTesting(
                    "grip_strength_rh",
                    parseFloat(event.target.value)
                  )
                }
              />
              <Form.Text id="handwidthHelpBlock">
                {player_power_testing_range_min.grip_strength_rh}-
                {player_power_testing_range_max.grip_strength_rh}{" "}
                {this.weightMeasurement()}
              </Form.Text>
            </div>

            <div className="mb-3 col-md-6">
              <label>Vertical Jump</label>
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={vertical_jump}
              >
                <FontAwesomeIcon className="ml-1" icon="question-circle" />
              </OverlayTrigger>
              <Form.Control
                size="lg"
                type="number"
                className="form-control"
                value={player_power_testing.vertical_jump}
                onChange={(event) =>
                  this.updatePowerTesting(
                    "vertical_jump",
                    parseFloat(event.target.value)
                  )
                }
              />
              <Form.Text id="handwidthHelpBlock">
                {player_power_testing_range_min.vertical_jump}-
                {player_power_testing_range_max.vertical_jump}{" "}
                {this.distanceMeasurement()}
              </Form.Text>
            </div>

            <div className="mb-3 col-md-6">
              <label>Med Ball Situp</label>
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={med_ball_situp}
              >
                <FontAwesomeIcon className="ml-1" icon="question-circle" />
              </OverlayTrigger>
              <Form.Control
                size="lg"
                type="number"
                className="form-control"
                value={player_power_testing.med_ball_situp}
                onChange={(event) =>
                  this.updatePowerTesting(
                    "med_ball_situp",
                    parseFloat(event.target.value)
                  )
                }
              />
              <Form.Text id="handwidthHelpBlock">
                {player_power_testing_range_min.med_ball_situp}-
                {player_power_testing_range_max.med_ball_situp}{" "}
                {this.distanceMeasurement2()}
              </Form.Text>
            </div>

            <div className="mb-3 col-md-12">
              <label>Med Ball Chest</label>
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={med_ball_chest}
              >
                <FontAwesomeIcon className="ml-1" icon="question-circle" />
              </OverlayTrigger>
              <Form.Control
                size="lg"
                type="number"
                className="form-control"
                value={player_power_testing.med_ball_chest}
                onChange={(event) =>
                  this.updatePowerTesting(
                    "med_ball_chest",
                    parseFloat(event.target.value)
                  )
                }
              />
              <Form.Text id="handwidthHelpBlock">
                {player_power_testing_range_min.med_ball_chest}-
                {player_power_testing_range_max.med_ball_chest}{" "}
                {this.distanceMeasurement2()}
              </Form.Text>
            </div>

            <div className="text-center col-md-12">
              <label>Horsepower</label>
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={horsepower}
              >
                <FontAwesomeIcon className="ml-1" icon="question-circle" />
              </OverlayTrigger>
              <p style={{ fontSize: 36, fontWeight: 900 }}>
                {Number(player_power_testing.vertical_jump) +
                  Number(player_power_testing.med_ball_situp) +
                  Number(player_power_testing.med_ball_chest)}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  finalizeScreen = () => {
    return (
      <>
        <div className="tabWrapper" style={{ minHeight: "70vh" }}>
          <div className="row">
            <div className="col-md-12">
              <h3 className="fw-bold">Review Information</h3>
              <button
                className="float-right close"
                onClick={() => this.props.handleClose()}
              >
                &times;
              </button>
              {this.props.usage === "getFitted" && this.props.mode === "view" && (
                <div className="float-right">
                  <button
                    className="btn btn-outline-darkblue mr-3"
                    onClick={() => this.props.handleSwitch("edit")}
                  >
                    Update Info
                  </button>
                  <button
                    className="btn btn-darkblue text-white"
                    onClick={() =>
                      history.push(`/new-session?player=${this.props.playerId}`)
                    }
                  >
                    Continue
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="scrollableTab mt-3">
            <h5 className="fw-bold">Basic Information</h5>
            <div className="row reviewInfo">
              <div className="mb-3 col-md-6">
                <label>First Name</label>
                <div className="border-bottom infoField">
                  {this.state.player.first_name}
                </div>
              </div>
              <div className="mb-3 col-md-6">
                <label>Last Name</label>
                <div className="border-bottom infoField">
                  {this.state.player.last_name}
                </div>
              </div>
              <div className="mb-3 col-md-6">
                <label>Email</label>
                <div className="border-bottom infoField">
                  {this.state.player.email}
                </div>
              </div>
              <div className="mb-3 col-md-6">
                <label>Birth Date</label>
                <div className="border-bottom infoField">
                  {formatDateToDisplay(this.state.player.dob)}
                </div>
              </div>
              {this.state.organizations?.length && this.state?.player?.org_id && this.state.player.org_id > -1 && (
                <div className="mb-3 col-md-6">
                  <label>Organization</label>
                  <div className="border-bottom infoField">
                    {this.state.organizations &&
                      this.state.player.org_id &&
                      this.state.organizations.filter(
                        (item) => item.id === this.state.player.org_id
                      )[0]["name"]}
                  </div>
                </div>
              )}
              {this.state.ranges.length && this.state.player.range_id > -1 && (
                <div className="mb-3 col-md-6">
                  <label>Skill Level</label>
                  <div className="border-bottom infoField">
                    {this.state.ranges &&
                      this.state.player.range_id &&
                      this.state.ranges.filter(
                        (item) => item.id === this.state.player.range_id
                      )[0]["skill"]}
                  </div>
                </div>
              )}
            </div>

            <Accordion>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    View Full Profile
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <h5 className="fw-bold">Biometrics</h5>
                          <div className="float-right">
                            <BootstrapSwitchButton
                              checked={this.state.useMetricSystem}
                              onlabel="Metric"
                              offlabel="Imperial"
                              width={110}
                              onChange={(checked: boolean) =>
                                this.handleUseMetricSystemChange(checked)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row reviewInfo biometricsInfo ">
                        <div className="mb-3 col-md-6 ">
                          <label>Weight</label>
                          <div className="border-bottom infoField ">
                            {this.state.player_bio_metric.weight}{" "}
                            {this.weightMeasurement()}
                          </div>
                        </div>
                        <div className="mb-3 col-md-6 ">
                          <label>Height</label>
                          <div className="border-bottom infoField ">
                            {this.state.player_bio_metric.height}{" "}
                            {this.distanceMeasurement()}
                          </div>
                        </div>
                        <div className="mb-3 col-md-6 ">
                          <label>Hand Width</label>
                          <div className="border-bottom infoField ">
                            {this.state.player_bio_metric.hand_width}{" "}
                            {this.distanceMeasurement()}
                          </div>
                        </div>
                        <div className="mb-3 col-md-6 ">
                          <label>Hand Length</label>
                          <div className="border-bottom infoField ">
                            {this.state.player_bio_metric.hand_length}{" "}
                            {this.distanceMeasurement()}
                          </div>
                        </div>
                        <div className="mb-3 col-md-6 ">
                          <label>Forearm Length</label>
                          <div className="border-bottom infoField ">
                            {this.state.player_bio_metric.forearm_length}{" "}
                            {this.distanceMeasurement()}
                          </div>
                        </div>
                        <div className="mb-3 col-md-6 ">
                          <label>Arm Length</label>
                          <div className="border-bottom infoField ">
                            {this.state.player_bio_metric.arm_length}{" "}
                            {this.distanceMeasurement()}
                          </div>
                        </div>
                        <div className="mb-3 col-md-6 ">
                          <label>Wrist to Floor</label>
                          <div className="border-bottom infoField ">
                            {this.state.player_bio_metric.wrist_to_floor}{" "}
                            {this.distanceMeasurement()}
                          </div>
                        </div>
                        <div className="mb-3 col-md-6 ">
                          <label>Hip to Floor</label>
                          <div className="border-bottom infoField ">
                            {this.state.player_bio_metric.hip_to_floor}{" "}
                            {this.distanceMeasurement()}
                          </div>
                        </div>
                      </div>
                    </>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </>
    );
  };

  getStepContent = () => {
    var tempState = this.state;
    if (this.props.mode === "view") {
      // Fill biometrics data from state.player object
      return this.finalizeScreen();
    } else {
      switch (tempState.activeStep) {
        case 0: {
          return this.playerNameAndEmailContent();
          break;
        }
        case 1: {
          return this.playerBiometrics();
          break;
        }
        case 2: {
          return this.playerBiometrics2();
          break;
        }
        case 3: {
          return this.playerMovementScreening();
          break;
        }
        case 4: {
          return this.playerPowerTesting();
          break;
        }
        case 5: {
          return this.finalizeScreen();
          break;
        }
        default: {
          return (
            <>
              Oops! You somehow managed to escape the content? Try refreshing
              the page.
            </>
          );
          break;
        }
      }
    }
  };

  validateEmail = (email: string) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  updateCanCreatePlayer = () => {
    var tempState = this.state;
    if (
      tempState.player.first_name.length >= 2 &&
      tempState.player.last_name.length >= 2 &&
      tempState.player.dob.length > 0 &&
      tempState.player.email.length >= 5 &&
      this.validateEmail(tempState.player.email) &&
      tempState.player.range_id > -1 && tempState.player?.org_id && tempState.player?.org_id > -1
    ) {
      tempState.canCreatePlayer = true;
    } else {
      tempState.canCreatePlayer = false;
    }
    this.setState(tempState);
  };
  updateCanCreatePlayerBioMetric = () => {
    var tempState = this.state;
    const validateStepFields = () => {
      if (tempState.activeStep === 1) {
        return (
          ((tempState.player_bio_metric.weight >=
            tempState.player_bio_metric_range_min.weight &&
            tempState.player_bio_metric.weight <=
              tempState.player_bio_metric_range_max.weight) ||
            tempState.player_bio_metric.weight == 0) &&
          ((tempState.player_bio_metric.height >=
            tempState.player_bio_metric_range_min.height &&
            tempState.player_bio_metric.height <=
              tempState.player_bio_metric_range_max.height) ||
            tempState.player_bio_metric.height == 0)
        );
      } else {
        return (
          ((tempState.player_bio_metric.weight >=
            tempState.player_bio_metric_range_min.weight &&
            tempState.player_bio_metric.weight <=
              tempState.player_bio_metric_range_max.weight) ||
            tempState.player_bio_metric.weight == 0) &&
          ((tempState.player_bio_metric.height >=
            tempState.player_bio_metric_range_min.height &&
            tempState.player_bio_metric.height <=
              tempState.player_bio_metric_range_max.height) ||
            tempState.player_bio_metric.height == 0) &&
          ((tempState.player_bio_metric.arm_length >=
            tempState.player_bio_metric_range_min.arm_length &&
            tempState.player_bio_metric.arm_length <=
              tempState.player_bio_metric_range_max.arm_length) ||
            tempState.player_bio_metric.arm_length == 0) &&
          ((tempState.player_bio_metric.forearm_length >=
            tempState.player_bio_metric_range_min.forearm_length &&
            tempState.player_bio_metric.forearm_length <=
              tempState.player_bio_metric_range_max.forearm_length) ||
            tempState.player_bio_metric.forearm_length == 0) &&
          ((tempState.player_bio_metric.hand_length >=
            tempState.player_bio_metric_range_min.hand_length &&
            tempState.player_bio_metric.hand_length <=
              tempState.player_bio_metric_range_max.hand_length) ||
            tempState.player_bio_metric.hand_length == 0) &&
          ((tempState.player_bio_metric.hand_width >=
            tempState.player_bio_metric_range_min.hand_width &&
            tempState.player_bio_metric.hand_width <=
              tempState.player_bio_metric_range_max.hand_width) ||
            tempState.player_bio_metric.hand_width == 0) &&
          ((tempState.player_bio_metric.hip_to_floor >=
            tempState.player_bio_metric_range_min.hip_to_floor &&
            tempState.player_bio_metric.hip_to_floor <=
              tempState.player_bio_metric_range_max.hip_to_floor) ||
            tempState.player_bio_metric.hip_to_floor == 0) &&
          ((tempState.player_bio_metric.wrist_to_floor >=
            tempState.player_bio_metric_range_min.wrist_to_floor &&
            tempState.player_bio_metric.wrist_to_floor <=
              tempState.player_bio_metric_range_max.wrist_to_floor) ||
            tempState.player_bio_metric.wrist_to_floor == 0)
        );
      }
    };
    if (validateStepFields()) {
      tempState.canCreatePlayerBioMetric = true;
    } else {
      tempState.canCreatePlayerBioMetric = false;
    }
    this.setState(tempState);
  };
  updateCanCreatePlayerPowerTesting = () => {
    var tempState = this.state;
    if (
      ((tempState.player_power_testing.grip_strength_lh >=
        tempState.player_power_testing_range_min.grip_strength_lh &&
        tempState.player_power_testing.grip_strength_lh <=
          tempState.player_power_testing_range_max.grip_strength_lh) ||
        tempState.player_power_testing.grip_strength_lh == 0) &&
      ((tempState.player_power_testing.grip_strength_rh >=
        tempState.player_power_testing_range_min.grip_strength_rh &&
        tempState.player_power_testing.grip_strength_rh <=
          tempState.player_power_testing_range_max.grip_strength_rh) ||
        tempState.player_power_testing.grip_strength_rh == 0) &&
      ((tempState.player_power_testing.med_ball_chest >=
        tempState.player_power_testing_range_min.med_ball_chest &&
        tempState.player_power_testing.med_ball_chest <=
          tempState.player_power_testing_range_max.med_ball_chest) ||
        tempState.player_power_testing.med_ball_chest == 0) &&
      ((tempState.player_power_testing.med_ball_situp >=
        tempState.player_power_testing_range_min.med_ball_situp &&
        tempState.player_power_testing.med_ball_situp <=
          tempState.player_power_testing_range_max.med_ball_situp) ||
        tempState.player_power_testing.med_ball_situp == 0) &&
      ((tempState.player_power_testing.vertical_jump >=
        tempState.player_power_testing_range_min.vertical_jump &&
        tempState.player_power_testing.vertical_jump <=
          tempState.player_power_testing_range_max.vertical_jump) ||
        tempState.player_power_testing.vertical_jump == 0)
    ) {
      tempState.canCreatePlayerPowerTesting = true;
    } else {
      tempState.canCreatePlayerPowerTesting = false;
    }
    this.setState(tempState);
  };

  handleFirstnameChange = (e: ChangeEvent<HTMLInputElement>) => {
    var tempState = this.state;
    tempState.player.first_name = e.currentTarget.value;
    this.setState(tempState);
    this.updateCanCreatePlayer();
  };
  handleLastnameChange = (e: ChangeEvent<HTMLInputElement>) => {
    var tempState = this.state;
    tempState.player.last_name = e.currentTarget.value;
    this.setState(tempState);
    this.updateCanCreatePlayer();
  };
  handleDobChange = (e: { toDate: Function } | null) => {
    if (e != null) {
      var d = e.toDate();
      if (d instanceof Date) {
        var tempState = this.state;
        tempState.player.dob = d.toISOString();
        this.setState(tempState);
        this.updateCanCreatePlayer();
      }
    }
  };
  handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    var tempState = this.state;
    tempState.player.email = e.currentTarget.value;
    this.setState(tempState);
    this.updateCanCreatePlayer();
  };
  handleOrganizationChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    console.log(e.target.value);
    var tempState = this.state;
    if (typeof e.target.value == "string") {
      tempState.player.org_id = parseInt(e.target.value);
    }
    this.setState(tempState);
    this.updateCanCreatePlayer();
  };
  handleSkillChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    console.log(e.target.value);
    var tempState = this.state;
    if (typeof e.target.value == "string") {
      tempState.player.range_id = parseInt(e.target.value);
    }
    this.setState(tempState);
    this.updateCanCreatePlayer();
  };

  handleWeightChange = (e: ChangeEvent<HTMLInputElement>) => {
    var tempState = this.state;
    tempState.player_bio_metric.weight = parseFloat(e.currentTarget.value);
    this.setState(tempState);
    this.updateCanCreatePlayerBioMetric();
  };
  handleHeightChange = (e: ChangeEvent<HTMLInputElement>) => {
    var tempState = this.state;
    tempState.player_bio_metric.height = parseFloat(e.currentTarget.value);
    this.setState(tempState);
    this.updateCanCreatePlayerBioMetric();
  };
  handleHandWidthChange = (e: ChangeEvent<HTMLInputElement>) => {
    var tempState = this.state;
    tempState.player_bio_metric.hand_width = parseFloat(e.currentTarget.value);
    this.setState(tempState);
    this.updateCanCreatePlayerBioMetric();
  };
  handleHandLengthChange = (e: ChangeEvent<HTMLInputElement>) => {
    var tempState = this.state;
    tempState.player_bio_metric.hand_length = parseFloat(e.currentTarget.value);
    this.setState(tempState);
    this.updateCanCreatePlayerBioMetric();
  };
  handleForearmLengthChange = (e: ChangeEvent<HTMLInputElement>) => {
    var tempState = this.state;
    tempState.player_bio_metric.forearm_length = parseFloat(
      e.currentTarget.value
    );
    this.setState(tempState);
    this.updateCanCreatePlayerBioMetric();
  };
  handleArmLengthChange = (e: ChangeEvent<HTMLInputElement>) => {
    var tempState = this.state;
    tempState.player_bio_metric.arm_length = parseFloat(e.currentTarget.value);
    this.setState(tempState);
    this.updateCanCreatePlayerBioMetric();
  };
  handleWristToFloorChange = (e: ChangeEvent<HTMLInputElement>) => {
    var tempState = this.state;
    tempState.player_bio_metric.wrist_to_floor = parseFloat(
      e.currentTarget.value
    );
    this.setState(tempState);
    this.updateCanCreatePlayerBioMetric();
  };
  handleHipToFloorChange = (e: ChangeEvent<HTMLInputElement>) => {
    var tempState = this.state;
    tempState.player_bio_metric.hip_to_floor = parseFloat(
      e.currentTarget.value
    );
    this.setState(tempState);
    this.updateCanCreatePlayerBioMetric();
  };
  handleWingspanChange = (e: ChangeEvent<HTMLInputElement>) => {
    var tempState = this.state;
    tempState.player_bio_metric.wingspan = parseFloat(e.currentTarget.value);
    this.setState(tempState);
    this.updateCanCreatePlayerBioMetric();
  };

  updateBioMetric = (label: string, value: string) => {
    this.setState({
      player_bio_metric: {
        ...this.state.player_bio_metric,
        [label]: value,
      },
    });
  };

  updateMovementScreening = (label: string, value: string) => {
    this.setState({
      player_movement_screening: {
        ...this.state.player_movement_screening,
        [label]: value,
      },
    });
  };

  updatePowerTesting = (label: string, value: number) => {
    this.setState(
      {
        player_power_testing: {
          ...this.state.player_power_testing,
          [label]: value,
        },
      },
      () => this.updateCanCreatePlayerPowerTesting()
    );
  };

  render() {
    return (
      <>
        {this.getStepContent()}
        <Box className="d-flex flex-row justify-content-between align-items-center">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="footer-poweredby"
          >
            {this.props.mode !== "view" && (
              <>
                {this.state.activeStep > 0 && (
                  <Button
                    id="btnBack"
                    disabled={this.state.activeStep === 0}
                    onClick={this.goBackStep}
                    className={`btn btn-outline-darkblue btnPrevious mx-2 px-4 ${
                      (this.state.bioInput !== null ||
                        this.state.msInput !== null) &&
                      "d-none"
                    }`}
                  >
                    Back
                  </Button>
                )}
                {this.state.activeStep == 0 && (
                  <Button
                    id="btnNext"
                    disabled={!this.state.canCreatePlayer}
                    onClick={this.goForwardStep}
                    className="btn btn-darkblue text-white btnNext mx-2 px-4"
                  >
                    Next
                  </Button>
                )}

                {this.state.activeStep == 1 && (
                  <Button
                    id="btnNextStep"
                    disabled={!this.state.canCreatePlayerBioMetric}
                    onClick={this.goForwardStep}
                    className="btn btn-darkblue text-white btnNext mx-2 px-4"
                  >
                    Next
                  </Button>
                )}

                {this.state.activeStep == 2 && (
                  <Button
                    id="btnNext2"
                    disabled={!this.state.canCreatePlayerBioMetric}
                    onClick={this.goForwardStep}
                    className={`btn btn-darkblue text-white btnNext mx-2 px-4 ${
                      this.state.bioInput !== null && "d-none"
                    }`}
                  >
                    Next
                  </Button>
                )}

                {this.state.activeStep == 3 && (
                  <Button
                    id="btnNext3"
                    // disabled={!this.state.canCreatePlayerBioMetric}
                    onClick={this.goForwardStep}
                    className={`btn btn-darkblue text-white btnNext mx-2 px-4 ${
                      this.state.msInput !== null && "d-none"
                    }`}
                  >
                    Next
                  </Button>
                )}

                {this.state.activeStep == 4 && (
                  <Button
                    id="btnNext4"
                    disabled={!this.state.canCreatePlayerPowerTesting}
                    onClick={this.goForwardStep}
                    className="btn btn-darkblue text-white btnNext mx-2 px-4"
                  >
                    Next
                  </Button>
                )}

                {this.state.activeStep == 5 && (
                  <Button
                    id="btnNext5"
                    disabled={!this.state.canCreatePlayerBioMetric}
                    onClick={this.attemptProcessPlayer}
                    className="btn btn-darkblue text-white btnNext mx-2 px-4"
                  >
                    {this.props.usage === "getFitted"
                      ? "Update & Continue"
                      : this.props.mode === "edit"
                      ? "Update"
                      : "Create"}
                  </Button>
                )}
              </>
            )}
          </Grid>
        </Box>
        <div
          className="progressBar"
          style={{
            width:
              this.state.activeStep === 0
                ? "0%"
                : this.state.activeStep === 1
                ? "20%"
                : this.state.activeStep === 2
                ? "40%"
                : this.state.activeStep === 3
                ? "60%"
                : this.state.activeStep === 4
                ? "80%"
                : "100%",
          }}
        ></div>
      </>
    );
  }
}

export default NewPlayer;
