import { Box, Grid } from "@material-ui/core";
import * as React from "react";
import { LaunchAngleProps } from "../../../components/Gauges/LaunchAngleDynamic";
import { CardDisplay, useDefaultGraphStyles } from "./GridBasedGraphs";

const LaunchAngleDraw: React.FC<{ props: LaunchAngleProps }> = ({ props }) => {
  const getShadeOfRed = (count: number) => {
    const { hitCount } = props;
    const shades = ["#FFCDD2", "#FF8A80", "#FF5252", "#FF1744", "#D50000"];
    let percent = count ? (count / hitCount) * 100 : 0;
    console.log(percent, hitCount);
    if (percent === 0) {
      return "#FFEBEE";
    } else if (percent > 0 && percent < 20) {
      return shades[0];
    } else if (percent >= 20 && percent < 40) {
      return shades[1];
    } else if (percent >= 40 && percent < 60) {
      return shades[2];
    } else if (percent >= 60 && percent < 80) {
      return shades[3];
    } else if (percent >= 80 && percent <= 100) {
      return shades[4];
    }
  };

  const classes = useDefaultGraphStyles();

  return (
    <>
      <Box display="flex" flexDirection="row" paddingLeft={'10%'} p={1} m={1}>
        <Box p={1}>
          <div className={classes.pie}>
            <div
              className={classes.pointerLaunchAngle}
              style={{
                transform: `rotate( ${props.average === undefined ? 90 : 90 - props.average
                }deg)`,
              }}
            ></div>
            {[
              [0, 55, "three5_to_ninety"],
              [55, 10, "two5_to_35"],
              [65, 15, "ten_to_25"],
              [80, 10, "zero_to_ten"],
              [90, 0, "minus_ninety_to_zero"],
            ].map((val) => {
              // const rotate = `rotate(${val}deg)`;
              return (
                <span
                  key={val[2]}
                  style={{
                    transform: `rotate(${val[0]}deg) skewY(${val[1]}deg)`,
                    backgroundColor: `${getShadeOfRed(
                      props.launch_angle_spread[val[2]]
                    )}`,
                  }}
                ></span>
              );
            })}
          </div>
        </Box>
      </Box>
    </>
  );
};

const GridLaunchAngle: React.FC<{ props: LaunchAngleProps }> = ({ props }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{maxHeight: "140px", paddingTop: "3%"}}>
        <LaunchAngleDraw props={props}></LaunchAngleDraw>
      </Grid>
      <Grid item xs={12}>
        <CardDisplay
          props={{
            top: "LAUNCH",
            center: `${props.roundNumber(props.average)} deg (+/- ${props.consistency === undefined ? 0 : props.roundNumber(props.consistency)})`,
            bottom: "ANGLE",
          }}
        ></CardDisplay>
      </Grid>
    </Grid>
  );
};

export default GridLaunchAngle;
