import { Grid } from "@material-ui/core";
import * as React from "react";
import { ExitVelocityProps } from "../../../components/Gauges/ExitVelocity";
import { CardDisplay, GraphTypes, SemiCircleGraph } from "./GridBasedGraphs";

const GridExitVelocity: React.FC<{ props: ExitVelocityProps }> = ({
  props,
}) => {

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SemiCircleGraph props={{ graphType: GraphTypes.ExitVelocity, data: props }}></SemiCircleGraph>
      </Grid>
      <Grid item xs={12} style={{paddingTop: '5%'}}>
            <CardDisplay props={{top: 'EXIT', center: `${props.roundNumber(props.average ?? 0)} mph `, bottom: 'VELOCITY'}}></CardDisplay>
      </Grid>
    </Grid>
  );
};

export default GridExitVelocity;
