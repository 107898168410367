import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Form, Formik, FormikHelpers } from "formik";
import React from "react";

export type Values = {
  searchText: string;
  filterKey: string;
};

const CustomTableSearch = (props: {
  placeHolder: string | undefined;
  onSearch: (arg0: Values | undefined) => void;
}) => {
  const handleClick = (values: Values) => {
    props.onSearch(values);
  };
  const clearClick = () => {
    values = { ...values, searchText: "", filterKey: "-1" };
    props.onSearch({ ...values, searchText: "", filterKey: "-1" });
  };
  let values = {
    searchText: "",
    filterKey: "",
  };
  return (
    <div className="border border-primary">
      <Formik
        initialValues={values}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          setTimeout(() => {
            setSubmitting(false);
            handleClick(values);
          }, 500);
        }}
      >
        {({ errors, touched, resetForm }) => (
          <Form>
            <div className="row mx-auto">
              <div className="ml-1 mb-3 col-md-4">
                <label>Search by</label>
                <Field as="select" name="filterKey" className={`form-control`}>
                  <option value="-1">-- Select --</option>

                  <option key={"first_name"} value={"first_name"}>
                    First Name
                  </option>
                  <option key={"last_name"} value={"last_name"}>
                    Last Name
                  </option>
                </Field>
              </div>
              <div className="mb-3 col-md-5">
                <label htmlFor="searchText">
                  {props.placeHolder || "Search"}
                </label>
                <Field
                  className="form-control"
                  id="searchText"
                  name="searchText"
                  placeholder={"Enter name here"}
                />
              </div>
              <div className="mb-2 col-md-2">
                <button
                  className="btn btn-outline-darkblue btn-md"
                  style={{ position: "relative", top: "35%" }}
                  type="submit"
                >
                  <FontAwesomeIcon icon="search" />
                </button>
              </div>
              <div className="mb-2 col-md-12">
                <button
                  onClick={() => {
                    clearClick();
                    resetForm();
                  }}
                  className="btn btn-outline-darkblue btn-md"
                  type="button"
                >
                  Clear
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CustomTableSearch;
