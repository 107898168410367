/* 
  Isaac Hormel
  5/28/2020
  Sends a request to the NodeJS API server and to get batting session results. Can be filtered and paginated depending on the options.
*/

import BasicRequest from "../classes/BasicRequest";

import Cookies from "universal-cookie";
import PaginationAndFilterInterface from "../../interfaces/apiInterfaces/PaginationAndFilterInterface";
import PlayerInterface from "../../interfaces/apiInterfaces/PlayerInterface";
const cookies = new Cookies();

interface PlayerGetOptionsInterface extends PaginationAndFilterInterface{
  id?: number;
  withInfo?: boolean;
  recent?: boolean;
};
interface PlayerGetBodyInterface{
  
}; 

export interface PlayerGetResponseInterface{
  data?: Array<PlayerInterface>|PlayerInterface;
  message: "findAll"|"findOne";
}

export default class PlayerRequest extends BasicRequest{
  static delete(playerId: number): Promise<any> {
    const promise = new Promise((resolve, reject) => {
        if (!playerId) reject('Cannot delete player, playerId not provided');
        const PlayerDeleteRequest = fetch(`${process.env.REACT_APP_API_URL}/players/${playerId}`, {
            method: 'DELETE',
            headers: {
                "Authorization": cookies.get("user")
            }
        });
        PlayerDeleteRequest.then(rawData => rawData.json())
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
    return promise;
  }
  static get(options?: PlayerGetOptionsInterface): Promise<PlayerGetResponseInterface|any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/players`);
      if(options?.filter_key || options?.limit || options?.offset){
        if(options?.filter_key){
          destination.searchParams.append("filter_key", options.filter_key);
          destination.searchParams.append("filter_value", options?.filter_value||"");
        }
        if(options?.offset){
          destination.searchParams.append("offset", options.offset.toString());
        }
        if(options?.limit){
          destination.searchParams.append("limit", options.limit.toString());
        }
      }
      if (options?.id && options?.withInfo){
        destination.pathname += `/${options?.id}/with-info`;
      } else if (options?.id){
        destination.pathname += `/${options?.id}`;
      } else if (options?.recent){
        destination.pathname += `/recent`;
      }
      var PlayerGetRequest = fetch(destination.toString(), {
        method: "GET",
        headers: {
          "Authorization": cookies.get("user")
        }
      });
      PlayerGetRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: PlayerGetResponseInterface)=>{
        resolvePromise(requestJson);
      });
      PlayerGetRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}