import React, { Component } from "react";
import "./spray_direction.scss";

export type SprayDirectionProps = {
  hitCount: number;
  average: number;
  consistency: number;
  spray_direction_spread: any;
  roundNumber: any;
  lastHit?: any;
};

type SprayDirectionState = {};

class SprayDirection extends Component<
  SprayDirectionProps,
  SprayDirectionState
> {
  getShadeOfRed = (count: number) => {
    const { hitCount } = this.props;
    const shades = ["#FFCDD2", "#FF8A80", "#FF5252", "#FF1744", "#D50000"];
    let percent = count ? (count / hitCount) * 100 : 0;
    if (percent === 0) {
      return "#FFEBEE";
    } else if (percent >= 0 && percent < 20) {
      return shades[0];
    } else if (percent >= 20 && percent < 40) {
      return shades[1];
    } else if (percent >= 40 && percent < 60) {
      return shades[2];
    } else if (percent >= 60 && percent < 80) {
      return shades[3];
    } else if (percent >= 80 && percent <= 100) {
      return shades[4];
    }
  };

  render() {
    const { spray_direction_spread } = this.props;

    return (
      <>
        <span style={{ position: "absolute", left: 180, bottom: 50 }}>
          {this.props.average === undefined ? 0 : this.props.roundNumber(this.props.average)} {" "}
          (+/-{" "} {this.props.consistency === undefined ? 0 : this.props.roundNumber(this.props.consistency)})
        </span>
        <div id="colorWheel_2">
          <div
            className="pointer_2"
            style={{
              transform: `rotate( ${
                this.props.average === undefined ? 0 : this.props.average
              }deg)`,
            }}
          >
            {/* {" "} */}
            {/* <div className="pointerEnd_2" /> */}
          </div>

          {/* {this.props.consistency !== undefined ? (
            <>
              <div
                className="pointer"
                style={{
                  borderLeft: "none",
                  transform: `rotate(${Math.round(
                    Number(this.props.average) - Number(this.props.consistency)
                  )}deg)`,
                }}
              >
                <div
                  className="pointerEnd_2"
                  style={{ border: "2px solid red", height: 1, width: 1 }}
                />
              </div>
              <div
                className="pointer"
                style={{
                  borderLeft: "none",
                  transform: `rotate(${Math.round(
                    Number(this.props.average) + Number(this.props.consistency)
                  )}deg)`,
                }}
              >
                <div
                  className="pointerEnd_2"
                  style={{ border: "2px solid red", height: 1, width: 1 }}
                />
              </div>
            </>
          ) : null} */}

          <span
            className="color01_2"
            style={{
              borderColor: `${this.getShadeOfRed(
                spray_direction_spread["l2"]
              )} transparent transparent transparent`,
            }}
          ></span>
          <span
            className="color02_2"
            style={{
              borderColor: `${this.getShadeOfRed(
                spray_direction_spread["l1"]
              )} transparent transparent transparent`,
            }}
          ></span>
          <span
            className="color03_2"
            style={{
              borderColor: `${this.getShadeOfRed(
                spray_direction_spread["mid"]
              )} transparent transparent transparent`,
            }}
          ></span>
          <span
            className="color04_2"
            style={{
              borderColor: `${this.getShadeOfRed(
                spray_direction_spread["r1"]
              )} transparent transparent transparent`,
            }}
          ></span>
          <span
            className="color05_2"
            style={{
              borderColor: `${this.getShadeOfRed(
                spray_direction_spread["r2"]
              )} transparent transparent transparent`,
            }}
          ></span>
        </div>
        <span
          className="min-velocity"
          style={{ position: "absolute", left: 60, top: 32 }}
        >
          -45<sup>°</sup>
        </span>
        <span
          className="min-velocity"
          style={{ position: "absolute", right: 110, top: 32 }}
        >
          45<sup>°</sup>
        </span>
      </>
    );
  }
}

export default SprayDirection;
