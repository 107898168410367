import React, { Component } from "react";
import "./exit_velocity.scss";

export type ExitVelocityProps = {
  average: number;
  consistency: number;
  max_velocity: number;
  roundNumber: any;
  lastHit?: any;
};

type ExitVelocityState = {};

class ExitVelocity extends Component<ExitVelocityProps, ExitVelocityState> {
  render() {
    return (
      <>
        <span style={{ position: "absolute", left: 180, bottom: 50 }}>
          {this.props.average === undefined ? 0 : this.props.roundNumber(this.props.average)} {" "}
          (+/-{" "} {this.props.consistency === undefined ? 0 : this.props.roundNumber(this.props.consistency)})
        </span>
        <div id="colorWheel">
          <div
            className="pointer"
            style={{
              transform: `rotate( ${
                this.props.average === undefined
                  ? -90
                  : -90 + (this.props.average / this.props.max_velocity) * 180
              }deg)`,
            }}
          >
            {/* {" "}
            <div className="pointerEnd" /> */}
          </div>

          {/* {this.props.consistency !== undefined ? (
            <>
              <div
                className="pointer"
                style={{
                  borderLeft: "none",
                  transform: `rotate(${
                    -90 +
                    ((Number(this.props.average) -
                      Number(this.props.consistency)) /
                      this.props.max_velocity) *
                      180
                  }deg)`,
                }}
              >
                <div
                  className="pointerEnd"
                  style={{ border: "2px solid red", height: 1, width: 1 }}
                />
              </div>
              <div
                className="pointer"
                style={{
                  borderLeft: "none",
                  transform: `rotate(${
                    -90 +
                    ((Number(this.props.average) +
                      Number(this.props.consistency)) /
                      this.props.max_velocity) *
                      180
                  }deg)`,
                }}
              >
                <div
                  className="pointerEnd"
                  style={{ border: "2px solid red", height: 1, width: 1 }}
                />
              </div>
            </>
          ) : null} */}

          <span
            className="color01"
            style={{
              borderColor:
                "rgb(255, 255, 255) transparent transparent transparent",
            }}
          ></span>
          <span
            className="color02"
            style={{
              borderColor:
                "rgb(255, 245, 242) transparent transparent transparent",
            }}
          ></span>
          <span
            className="color03"
            style={{
              borderColor:
                "rgb(255, 233, 230) transparent transparent transparent",
            }}
          ></span>
          <span
            className="color04"
            style={{
              borderColor:
                "rgb(255, 223, 217) transparent transparent transparent",
            }}
          ></span>
          <span
            className="color05"
            style={{
              borderColor:
                "rgb(255, 210, 200) transparent transparent transparent",
            }}
          ></span>
          <span
            className="color06"
            style={{
              borderColor:
                "rgb(255, 199, 185) transparent transparent transparent",
            }}
          ></span>
          <span
            className="color07"
            style={{
              borderColor:
                "rgb(255, 189, 172) transparent transparent transparent",
            }}
          ></span>
          <span
            className="color08"
            style={{
              borderColor:
                "rgb(255, 179, 160) transparent transparent transparent",
            }}
          ></span>
          <span
            className="color09"
            style={{
              borderColor:
                "rgb(255, 169, 142) transparent transparent transparent",
            }}
          ></span>
          <span
            className="color10"
            style={{
              borderColor:
                "rgb(255,160,122) transparent transparent transparent",
            }}
          ></span>
        </div>
        <span
          className="min-velocity"
          style={{ position: "absolute", left: 30, bottom: 90 }}
        >
          0
        </span>
        <span
          className="max-velocity"
          style={{ position: "absolute", right: 30, bottom: 90 }}
        >
          {Math.round(this.props.max_velocity * 100) / 100}
        </span>
      </>
    );
  }
}

export default ExitVelocity;
