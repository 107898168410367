import * as React from "react";
import { Box, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";

export enum GraphTypes {
  ExitVelocity = "ExitVelocity",
  LaunchAngle = "LaunchAngle",
  SprayDirection = "SprayDirection",
}

const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const useDefaultGraphStyles = makeStyles({
  colorWheel_2: {
    margin: "0 0 0 40px",
    height: "40px",
    width: "40px",
    position: "relative",
    "-webkit-transform-origin": "50px 150px",
    "-moz-transform-origin": "50px 150px",
    "-ms-transform-origin": "50px 150px",
    "-o-transform-origin": "50px 150px",
    "transform-origin": "50px 150px",
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "-o-user-select": "none",
    "user-select": "none",
    "-webkit-transition": "all 0.5s linear",
    "-moz-transition": "all 0.5s linear",
    "-ms-transition": "all 0.5s linear",
    "-o-transition": "all 0.5s linear",
    transition: "all 0.5s linear",
    "& span": {
      position: "absolute",
      "-webkit-transform-origin": "50% 50%",
      "transform-origin": "50% 50%",
      "border-style": "solid",
      "border-width": "140px 24px",
      "box-sizing": "border-box",
    },
  },
  pointer_spray_direction: {
    borderLeft: "3px solid black",
    "background-color": "black",
    position: "absolute",
    height: "70px",
    top: "-2%",
    left: "30%",
    "z-index": 999,
    "transform-origin": "bottom",
  },
  cardRoot: {
    maxWidth: "200px",
    maxHeight: "100px",
  },
  pointerLaunchAngle: {
    borderLeft: "3px solid black",
    position: "absolute",
    height: "60px",
    top: "-10%",
    left: "50%",
    zIndex: 999,
    transformOrigin: "bottom",
  },
  pointer: {
    borderLeft: "3px solid black",
    "background-color": "black",
    position: "absolute",
    height: "70px",
    top: "4%",
    left: "42%",
    "z-index": 999,
    "transform-origin": "bottom",
  },
  pie: {
    backgroundColor: "#fff",
    position: "relative",
    width: "100px",
    top: "-25%",
    height: "100px",
    borderRadius: "50%",
    overflow: "hidden",
    "& span": {
      outline: "none",
      backgroundColor: "lightgreen",
      border: "thin solid white",
      position: "absolute",
      // cursor: pointer,
      width: "100%",
      height: "100%",
      left: "50%",
      bottom: "50%",
      transformOrigin: "bottom left",
      transition: "all 200ms ease-out",
    },
  },
  colorWheel: {
    margin: "0 0 0 40px",
    height: "40px",
    width: "40px",
    position: "relative",
    "-webkit-transform-origin": "50px 150px",
    "-moz-transform-origin": "50px 150px",
    "-ms-transform-origin": "50px 150px",
    "-o-transform-origin": "50px 150px",
    "transform-origin": "50px 150px",
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "-o-user-select": "none",
    "user-select": "none",
    "-webkit-transition": "all 0.5s linear",
    "-moz-transition": "all 0.5s linear",
    "-ms-transition": "all 0.5s linear",
    "-o-transition": "all 0.5s linear",
    transition: "all 0.5s linear",
    "& span": {
      position: "absolute",
      "-webkit-transform-origin": "50% 50%",
      "transform-origin": "50% 50%",
      "border-style": "solid",
      "border-width": "75px 12px",
      "box-sizing": "border-box",
    },
  },
});

export const SemiCircleGraph: React.FC<{
  props: { graphType: GraphTypes; data: any };
}> = ({ props }) => {
  const classes = useDefaultGraphStyles(props);

  return (
    <>
      <Box display="flex" flexDirection="row" paddingLeft={'12%'} p={1} m={1}>
        <Box p={1}>
          <div className={classes.colorWheel}>
            <div
              className={classes.pointer}
              style={{
                transform: `rotate( ${
                  props.data.average === undefined
                    ? -90
                    : -90 + (props.data.average / props.data.max_velocity) * 180
                }deg)`,
              }}
            ></div>
            {[
              [-81, 255, 255, 255],
              [-63, 255, 245, 242],
              [-45, 255, 233, 230],
              [-27, 255, 223, 217],
              [-9, 255, 210, 200],
              [9, 255, 199, 185],
              [27, 255, 189, 172],
              [45, 255, 179, 160],
              [63, 255, 169, 142],
              [81, 255,160,122],
            ].map((val) => {
              const rotate = `rotate(${val[0]}deg)`;
              return (
                <span
                  key={val[0].toString()}
                  style={{
                    WebkitTransform: rotate,
                    MozTransformOrigin: rotate,
                    msTransform: rotate,
                    transform: rotate,
                    borderColor: `rgb(${val[1]}, ${val[2]}, ${val[3]}) transparent transparent transparent`,
                  }}
                ></span>
              );
            })}
          </div>
        </Box>
      </Box>
      <Box p={1} style={{ position: "relative", left: "7%", paddingTop: "10%" }}>
        
      </Box>
    </>
  );
};

export const CardDisplay: React.FC<{
  props: { top: string; center: string; bottom: string };
}> = ({ props }) => {
  const classes = useDefaultGraphStyles();
  return (
    <Card className={classes.cardRoot}>
      <CardActionArea>
        <CardContent>
          <Typography align="center">{props.top}</Typography>
          <Divider />
          <Typography
            align="center"
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {props.center}
          </Typography>
          <Divider />
          <Typography align="center">{props.bottom}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
