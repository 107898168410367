import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "whatwg-fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";
import Header from "../components/Header";
import history from "../history";
import Cookies from "universal-cookie";
import NewPlayer from "./Forms/NewPlayer";
import CoachAccountInterface, {
  EmptyCoachAccountInterface,
} from "../interfaces/apiInterfaces/CoachAccountInterface";
import PlayerInterface from "../interfaces/apiInterfaces/PlayerInterface";
import PlayerRequest, {
  PlayerGetResponseInterface,
} from "../api/requests/PlayerRequest";
import AuthPingRequest, {
  AuthPingGetResponseInterface,
} from "../api/requests/AuthPingRequest";
import CustomTableSearch, { Values } from "../components/CustomTableSearch";
import { getUserOrganization } from "../utils/userUtils";
import ExportDataRequest from "../api/requests/ExportDataRequest";

// const cookies = new Cookies();
// const { SearchBar } = Search;

interface GetFittedProps { }
interface GetFittedState {
  coach: CoachAccountInterface;
  players: Array<PlayerInterface>;
  page: number;
  show: boolean;
  mode: Mode;
  playerId: number | null;
  playerFormUsage: string;
  loading: boolean;
  organization?: any;
}

type Mode = "view" | "edit" | "new";


class GetFitted extends Component<GetFittedProps, GetFittedState> {
  constructor(props: GetFittedProps) {
    super(props);
  }

  state: GetFittedState = {
    coach: EmptyCoachAccountInterface,
    players: [],
    page: 0,
    show: false,
    mode: "view",
    playerId: null,
    playerFormUsage: "",
    loading: false,
    organization: null
  };

  componentDidMount = () => {
    var tempState = this.state;
    AuthPingRequest.get().then((result: AuthPingGetResponseInterface) => {
      if (!result.email) {
        history.push("/login");
      } else {
        var tempState = this.state;
        tempState.coach = result;
        tempState.organization = getUserOrganization();
        this.setState(tempState);
        PlayerRequest.get({
          limit: 100,
          offset: 100 * tempState.page,
          // filter_key: "user_id",
          // filter_value: tempState.coach.id?.toString(),
        }).then((result: PlayerGetResponseInterface) => {
          if (result.data) {
            var tempState = this.state;
            tempState.players = Array.isArray(result.data) ? result.data : [result.data];
            this.setState(tempState);
          }
        });
      }
    });
    this.setState(tempState);
  };

  goToPlayerPage = (player: PlayerInterface) => {
    setTimeout(() => {
      history.push(`/player?id=${player.id}`);
    }, 500);
  };

  nextPage = () => {
    if (this.state.players.length >= 99 && this.state.coach) {
      var tempState = this.state;
      tempState.page++;
      this.setState(tempState);
      this.loadPlayers(tempState);
    }
  };

  backPage = () => {
    if (this.state.page > 0 && this.state.coach) {
      var tempState = this.state;
      tempState.page--;
      this.setState(tempState);
      this.loadPlayers(tempState);
    }
  };

  handleClose = () => {
    let tempState = this.state;
    tempState.show = false;
    tempState.mode = 'view';
    this.loadPlayers({...tempState});
  };

  handleTableSearch = (values: Values) => {
    values.searchText && values.filterKey ? this.loadPlayers({...this.state}, values.filterKey, values.searchText) : 
        this.loadPlayers(this.state);
  };

  loadPlayers = (tempState: GetFittedState, filterKey?: string, filterValue?: string) => {
    this.setState({...tempState, loading: true});
    PlayerRequest.get({
        limit: 100,
        offset: 100 * tempState.page,
        filter_key: filterKey || "",
        filter_value: filterValue || "",
      }).then((result: PlayerGetResponseInterface) => {
        if (result.data) {
          var tempState = this.state;
          tempState.players = Array.isArray(result.data)
            ? result.data
            : [result.data];
          tempState.players = tempState.players.sort(
            (a: PlayerInterface, b: PlayerInterface): number => {
              if (a.updatedAt && b.updatedAt) {
                var aDate = a.updatedAt;
                var bDate = b.updatedAt;
                return new Date(bDate).getTime() - new Date(aDate).getTime();
              }
              return 0;
            }
          );
          this.setState({...tempState, loading: false});
        }
      }).catch(e => {
        this.setState({...tempState, loading: false});
      });
  };

  handleSwitch = (mode: Mode) => {
    this.setState({ mode: mode });
  }

  render() {

    const nameFormatter = (cell: any, row: any) => {
      return (
        <>
          <div>
            <a>{row.first_name} {row.last_name}</a>
          </div>
        </>
      );
    }

    const actionsFormatter = (cell: any, row: any) => {
      return (
        <>
          <span className="userActions">
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id='tooltip-top'>
                  View Previous Sessions
                </Tooltip>
              }
            >
              <button
                title="Info"
                className="btn btn-outline-darkblue text-white ml-3"
                onClick={() => history.push(`/player?id=${row.id}&tab=fitting_sessions`)}
              >
                <FontAwesomeIcon icon="history" />
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id='tooltip-top'>
                  Prepare New Session
                </Tooltip>
              }
            >
              <button
                title="Info"
                className="btn btn-outline-darkblue text-white ml-3"
                onClick={() => this.setState({ show: true, playerId: row.id, playerFormUsage: "getFitted" })}
              >

                <FontAwesomeIcon icon="baseball-ball" />
              </button>
            </OverlayTrigger>
          </span>
        </>
      )
    }

    const pagination = paginationFactory({ onSizePerPageChange: (sizePerPage, page) => {
        console.log('Size per page change!!!');
        console.log('Newest size per page:' + sizePerPage);
        console.log('Newest page:' + page);
      },
      onPageChange: (page, sizePerPage) => {
        console.log('Page change!!!');
        console.log('Newest size per page:' + sizePerPage);
        console.log('Newest page:' + page);
      }});

    const columns = [
      {
        dataField: "first_name",
        text: "Name",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 400,
        },
        sort: true,
        formatter: nameFormatter
      },
      {
        dataField: "actions",
        text: "Actions",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
        align: 'center',
        formatter: actionsFormatter
      },
    ];
    return (
      <>
        <Header />
        <div className="playerLibrary">
          <div className="container-fluid">
            <ToolkitProvider
              keyField="id"
              data={this.state.players}
              columns={columns}
              search
            >
              {(props) => (
                <>
                  <div className="row py-4 pb-2">
                    <div className="col-md-12 col-lg-6">
                      <div className="row mobileMargin">
                        <div className="col-md-4 col-lg-6 col-xl-4">
                          <h3 className="mb-0"> Get Fitted</h3>
                        </div>
                        <div className="col-md-8 col-lg-6 col-xl-8">
                          <div className="searchBar">
                            <CustomTableSearch {...props.searchProps} onSearch={(val) => {
                                return setTimeout(() => {
                                    this.handleTableSearch(val);
                                }, 2000);    
                                }} placeHolder="Search for player" />
                            {/* <SearchBar
                              style={{ width: "20rem", fontSize: 23 }}
                              className="form-control"
                              placeholder="Search for player"
                              {...props.searchProps}
                              searchText={this.state.searchText}
                              onSearch={(val) => {
                                return setTimeout(() => {
                                    this.handleTableSearch(val);
                                }, 2000);    
                                }
                                }
                            /> */}
                            
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="row mobileMargin">
                        <div className="col-md-4 col-lg-6">
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-6 mb-2">
                      <button
                        className="btn export mr-2 solid d-inline-flex"
                        onClick={() => this.setState({ show: true, mode: "new", playerFormUsage: "playerLibrary" })}
                      >
                        <FontAwesomeIcon icon="user-plus" className="mr-1" />
                        Add Player
                      </button>

                      { this.state.organization && <button className="btn export solid d-inline-flex" onClick={() => {
                        ExportDataRequest.get(`organizations/${this.state.organization?.id}/report`).then((blob: any) => {
                            const url: string = window.URL.createObjectURL(new Blob([blob]));
                            let link: HTMLAnchorElement;
                            link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `organization_fitting_sessions_${this.state.organization?.id}.xlsx`);
                            link.click();
                          });
                      }}>
                        <FontAwesomeIcon icon="upload" className="mr-1" />
                        Export
                      </button>
                    }
                    </div>
                    <div className="col-lg-12">
                      <div className="table-wrapper">
                        <BootstrapTable
                          pagination={pagination}
                          wrapperClasses="table-responsive"
                          {...props.baseProps}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </ToolkitProvider>
          </div>
        </div>
        {/* Loader component */}
        <Modal show={this.state.loading} centered={true}>
                <Modal.Body className="p-0">
                    <h3>Loading...</h3>
                </Modal.Body>
            </Modal>
        {/* Player Info */}
        <Modal
          size="xl"
          show={this.state.show}
          onHide={this.handleClose}
          contentClassName="addPlayermodal"
          backdrop="static"
          centered={true}
        >
          <Modal.Body className="p-0">
            <div className="row">
              <div className="col-md-12 m-0">
                <ul
                  className="nav nav-tabs d-flex justify-content-center"
                  id="myTab"
                  role="tablist"
                  style={{ visibility: "hidden" }}
                >
                  <li role="presentation">
                    <a
                      className="nav-link p-0 active "
                      id="playerInfo-tab"
                      data-toggle="tab"
                      href="#playerInfo"
                      role="tab"
                      aria-controls="playerInfo"
                      aria-selected="false"
                    >
                      Player Info{" "}
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      className="nav-link p-0"
                      id="biometrics-tab"
                      data-toggle="tab"
                      href="#biometrics"
                      role="tab"
                      aria-controls="biometrics"
                      aria-selected="true"
                    >
                      {" "}
                      Biometrics Info{" "}
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      className="nav-link p-0"
                      id="review-tab"
                      data-toggle="tab"
                      href="#review"
                      role="tab"
                      aria-controls="review"
                      aria-selected="false"
                    >
                      {" "}
                      Review Info{" "}
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade active show"
                    id="playerInfo"
                    role="tabpanel"
                    aria-labelledby="playerInfo-tab"
                  >
                    <NewPlayer
                      mode={this.state.mode}
                      playerId={this.state.playerId}
                      handleClose={this.handleClose}
                      handleSwitch={this.handleSwitch}
                      usage={this.state.playerFormUsage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default GetFitted;
