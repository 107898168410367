import React from "react";
import { RouteProps } from "react-router-dom";
import "whatwg-fetch";
// import moment from 'moment';
import Cookies from "universal-cookie";
// import BattingSessionsBarGraph from "../components/BattingSessionsBarGraph/BattingSessionsBarGraph";
import PlayerInterface from "../interfaces/apiInterfaces/PlayerInterface";
import PlayerBiometricInterface from "../interfaces/apiInterfaces/PlayerBiometricInterface";
import BattingSessionInterface from "../interfaces/apiInterfaces/BattingSessionInterface";
import FittingSessionInterface from "../interfaces/apiInterfaces/FittingSessionInterface";
import PrescriptionMatrixInterface from "../interfaces/apiInterfaces/PrescriptionMatrixInterface";
import HitInterface, {
  HitInterfaceDto,
} from "../interfaces/apiInterfaces/HitInterface";
import BatInterface, {
  EmptyBatInterfaceDto,
} from "../interfaces/apiInterfaces/BatInterface";
import FittingSessionResultInterface from "../interfaces/apiInterfaces/FittingSessionResultInterface";
import SummaryList from "./Fitting/components/SummaryList";

const cookies = new Cookies();

interface ViewSessionProps {
  match: {
    params: {
      hash: string;
    };
  };
}
interface ViewSessionState {
  player: PlayerInterface;
  player_bio_metric: PlayerBiometricInterface;
  fitting_session: FittingSessionInterface | null;
  prescription_matrix: PrescriptionMatrixInterface;
  batting_sessions: Array<{
    batting_session: BattingSessionInterface;
    hits: Array<HitInterfaceDto>;
    bat: BatInterface;
    average: HitInterface | null;
    cns: HitInterface | null;
    currentHit: HitInterfaceDto | null;
  }>;
  bestFittingSession: {
    fitting_session_result: FittingSessionResultInterface;
    bat: BatInterface;
    batting_session: BattingSessionInterface;
  };
  fitting_session_results: Array<{
    fitting_session_result: FittingSessionResultInterface;
    bat: BatInterface;
  }>;
  bat_delivery: number;
  barrel_stat: number;
  player_preference: number;
  bat_deliveryString: string;
  barrel_statString: string;
  player_preferenceString: string;
  updated_at: string;
}

class ViewSession extends React.Component<
  ViewSessionProps & RouteProps,
  ViewSessionState
> {
  state: ViewSessionState = {
    player: {
      id: 0,
      range_id: 1,
      user_id: 0,
      first_name: "",
      last_name: "",
      email: "",
      dob:
        "" +
        new Date(
          new Date().setFullYear(new Date().getFullYear() - 10)
        ).toISOString(),
      avatar: "",
      biometrics: [],
      movement_screenings: [],
      power_testings: [],
    },
    player_bio_metric: {
      id: 0,
      player_id: 0,
      weight: 0,
      height: 0,
      hand_width: 0,
      hand_length: 0,
      forearm_length: 0,
      arm_length: 0,
      wrist_to_floor: 0,
      hip_to_floor: 0,
      wingspan: 0,
      bats: "",
      throws: "",
      writing_hand: "",
      eating_hand: "",
      dominant_eye: "",
      dominant_leg: "",
    },
    fitting_session: null,
    batting_sessions: [],
    prescription_matrix: {
      id: -1,
      fitting_session_id: -1,
      bat_delivery: -1,
      barrel_stat: -1,
      player_preference: -1,
    },
    bestFittingSession: {
      fitting_session_result: {
        id: 0,
        bat_id: -1,
        fitting_session_id: -1,
        player_id: -1,
        user_id: -1,
        bat_stat: -1,
        barrel_stat: -1,
        subjective: -1,
        overall_fit_score: -1,
      },
      bat: {
        ...EmptyBatInterfaceDto,
        id: -1,
      },
      batting_session: {
        id: -1,
        bat_id: -1,
        fitting_session_id: -1,
        player_id: -1,
        color: "",
        order: -1,
      },
    },
    fitting_session_results: [],
    bat_delivery: 2,
    barrel_stat: 4,
    player_preference: 3,
    bat_deliveryString: "2.0",
    barrel_statString: "4.0",
    player_preferenceString: "3",
    updated_at: "",
  };

  componentDidMount = async () => {
    const hash = this.props.match.params.hash;

    try {
      var lookupRequest = await (
        await fetch(`${process.env.REACT_APP_API_URL}/lookup/${hash}`, {
          method: "GET",
        })
      ).json();

      var tempState = this.state;

      tempState.fitting_session = lookupRequest.data.fitting_session;
      tempState.prescription_matrix =
        lookupRequest.data.fitting_session.prescription_matrix;
      tempState.updated_at =
        lookupRequest.data.fitting_session.prescription_matrix.updatedAt;
      tempState.player = lookupRequest.data.fitting_session.player;

      var allBats = [];

      for (let i in lookupRequest.data.fitting_session.batting_sessions) {
        var battingSession: BattingSessionInterface =
          lookupRequest.data.fitting_session.batting_sessions[i];
        var hits: Array<HitInterfaceDto> = [];
        for (let k in lookupRequest.data.fitting_session.hits) {
          if (
            lookupRequest.data.fitting_session.hits[k].bat_id ==
            battingSession.bat_id
          ) {
            hits.push(lookupRequest.data.fitting_session.hits[k]);
          }
        }
        var bat = await (
          await fetch(
            `${process.env.REACT_APP_API_URL}/lookup/bat/${battingSession.bat_id}`,
            {
              method: "GET",
            }
          )
        ).json();
        var hitResults = lookupRequest?.data?.fitting_session.batting_session_results || [];

        let resObj: any = {};
        for (const index in hitResults) {
          const item = hitResults[index];
          resObj[item.metric_name + "_" + item.metric_type] = item;
        }

        tempState.batting_sessions.push({
            currentHit: hits[hits.length - 1],
          batting_session: battingSession,
          hits,
          bat: bat.data,
          average: {
            id: Number(i),
            batting_session_id: Number(i),
            fitting_session_id: hits[hits.length - 1].fitting_session_id,
            bat_id: hits[hits.length - 1].bat_id,
            player_id: hits[hits.length - 1].player_id,
            hit_index: hits[hits.length - 1].hit_index,
            bat_velocity: resObj.bat_velocity_average?.metric_value,
            angle_of_attack: resObj.angle_of_attack_average?.metric_value,
            exit_velocity: resObj.exit_velocity_average?.metric_value,
            launch_angle: resObj.launch_angle_average?.metric_value,
            spray_direction: resObj.spray_direction_average?.metric_value,
            player_rating: resObj.player_rating_average?.metric_value,
            coach_rating: resObj.coach_rating_average?.metric_value,
            custom_metric_value:
              resObj.custom_metric_value_average?.metric_value,
            foul_miss: false,
          },
          cns: {
            id: Number(i),
            batting_session_id: Number(i),
            fitting_session_id: hits[hits.length - 1].fitting_session_id,
            bat_id: hits[hits.length - 1].bat_id,
            player_id: hits[hits.length - 1].player_id,
            hit_index: hits[hits.length - 1].hit_index,
            bat_velocity: resObj.bat_velocity_consistency?.metric_value,
            angle_of_attack: resObj.angle_of_attack_consistency?.metric_value,
            exit_velocity: resObj.exit_velocity_consistency?.metric_value,
            launch_angle: resObj.launch_angle_consistency?.metric_value,
            spray_direction: resObj.spray_direction_consistency?.metric_value,
            player_rating: resObj.player_rating_consistency?.metric_value,
            coach_rating: resObj.coach_rating_consistency?.metric_value,
            custom_metric_value:
              resObj.custom_metric_value_consistency?.metric_value,
            foul_miss: false,
          },
        });
        allBats.push(bat.data);
      }

      for (let i in lookupRequest.data.fitting_session
        .fitting_session_results) {
        var fittingSessionResult: FittingSessionResultInterface =
          lookupRequest.data.fitting_session.fitting_session_results[i];
        var hits: Array<HitInterfaceDto> = [];
        for (let k in lookupRequest.data.fitting_session.hit_results) {
          if (
            lookupRequest.data.fitting_session.hit_results[k].bat_id ==
            fittingSessionResult.bat_id
          ) {
            hits.push(lookupRequest.data.fitting_session.hit_results[k]);
          }
        }

        for (let r in allBats) {
          if (allBats[r].id == fittingSessionResult.bat_id) {
            var bat = allBats[r];

            tempState.fitting_session_results.push({
              fitting_session_result: fittingSessionResult,
              bat,
            });
          }
        }
      }

      var bestFittingSession = tempState.bestFittingSession;
      for (let i in tempState.fitting_session_results) {
        if (
          Math.abs(
            tempState.fitting_session_results[i].fitting_session_result
              .overall_fit_score
          ) >
          Math.abs(
            tempState.bestFittingSession.fitting_session_result
              .overall_fit_score
          )
        ) {
          bestFittingSession.fitting_session_result =
            tempState.fitting_session_results[i].fitting_session_result;
          bestFittingSession.bat = tempState.fitting_session_results[i].bat;
          tempState.batting_sessions.forEach(
            async (battingSessionForBFS, indexBSR: number) => {
              if (battingSessionForBFS.bat.id == bestFittingSession.bat.id) {
                bestFittingSession.batting_session =
                  battingSessionForBFS.batting_session;
              }
            }
          );
        }
      }
      tempState.bestFittingSession = bestFittingSession;

      this.setState(tempState);
    } catch (e) {
      console.error(e);
    }
    /* Weird bug occurs where state is changed but render is not called. This helps prevent that. */
    setTimeout(() => {
      this.setState(this.state);
    }, 1000);
  };

  constructor(props: ViewSessionProps) {
    super(props);
  }

  render() {
    const { fitting_session_results, fitting_session, bestFittingSession, batting_sessions } = this.state;
    return (
      <>
        <div className="playerLibrary">
        <div className="container-fluid" style={{ backgroundColor: "white", height: "calc(100vh - 78px)"}}>
        <div className="row">
        <div className="col-sm-12">
      { /* New Review session component */ }
        
            {
                fitting_session_results.length ? (
                    <SummaryList fitting_session={fitting_session} custom_metric_label={
                      fitting_session?.custom_metric_label || ""
                    }
                    best_bat={bestFittingSession}
                    batting_sessions={batting_sessions}
                    fitting_session_results={fitting_session_results}></SummaryList>
                ) : <div>No Sessions. Please login <a href="/login">Login</a></div>
            }
            </div>
            </div>
        
              </div> 
        </div>
      </>
    );
  }
}

export default ViewSession;
