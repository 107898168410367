import React, { Component } from 'react';

import { formatDateToDisplay } from "../../utils/dateUtils";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

type MovementScreeningsProps = {
  movement_screenings: Array<any>;
}

type MovementScreeningsState = {

}

class MovementScreenings extends Component<MovementScreeningsProps, MovementScreeningsState> {
	constructor(props: MovementScreeningsProps) {
    super(props);
  }
	render() {
		const pagination = paginationFactory({ sizePerPage: 5 });

    const dateFormatter = (cell: any, row: any) => {
      return formatDateToDisplay(cell, 'do MMM yyyy hh:mm aa');
    }

		const columns = [
      {
        dataField: "createdAt",
        text: "Recorded At",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 375,
        },
        sort: true,
        formatter: dateFormatter,
      },
			{
        dataField: "left_hip_internal_rotation",
        text: "Left Hip Internal Rotation",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "right_hip_internal_rotation",
        text: "Right Hip Internal Rotation",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "left_hip_external_rotation",
        text: "Left Hip External Rotation",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "right_hip_external_rotation",
        text: "Right Hip External Rotation",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "pelvic_tilt",
        text: "Pelvic Tilt",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "pelvic_rotation",
        text: "Pelvic Rotation",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "thoracic_spine_rotation",
        text: "Thoracic Spine Rotation",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "lateral_step",
        text: "Lateral Step",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "wide_squat",
        text: "Wide Squat",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "ankle_inversion",
        text: "Ankle Inversion",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "ankle_eversion",
        text: "Ankle Eversion",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "left_shoulder_er",
        text: "Left Shoulder ER",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "right_shoulder_er",
        text: "Right Shoulder ER",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "pronation",
        text: "Pronation",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "supination",
        text: "Supination",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "right_shoulder_adduction",
        text: "Right Shoulder Adduction",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "left_shoulder_adduction",
        text: "Left Shoulder Adduction",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
    ];
		return (
      <div className="container-fluid">
        <ToolkitProvider
          keyField="id"
          data={this.props.movement_screenings}
          columns={columns}
          // search
        >
          {(props) => (
            <div className="row">
              <div className="col-lg-12">
                <div className="table-wrapper">
                  <BootstrapTable
                    pagination={pagination}
                    wrapperClasses="table-responsive"
                    {...props.baseProps}
                  />
                </div>
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
		)
	}
}

export default MovementScreenings;
