/* 
  Isaac Hormel
  5/28/2020
  Sends a request to the NodeJS API server and to get the bat brands. Can be filtered and paginated depending on the options.
*/

import BasicRequest from "../classes/BasicRequest";

import Cookies from "universal-cookie";
import PaginationAndFilterInterface from "../../interfaces/apiInterfaces/PaginationAndFilterInterface";
const cookies = new Cookies();

interface BatBrandGetOptionsInterface extends PaginationAndFilterInterface{
  
};
interface BatBrandGetBodyInterface{
  
}; 

export interface BatBrandGetResponseInterface{
  data?: Array<string>;
  message: "findAllBatCategories"|"findOneBatCategories"; //This is a bug on the api where the message still incorrectly says BatCategories
}

export default class BatBrandRequest extends BasicRequest{
  static get(options?: BatBrandGetOptionsInterface): Promise<BatBrandGetResponseInterface|any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/bats/brands`);
      if(options?.filter_key || options?.limit || options?.offset){
        if(options?.filter_key){
          destination.searchParams.append("filter_key", options.filter_key);
          destination.searchParams.append("filter_value", options?.filter_value||"");
        }
        if(options?.offset){
          destination.searchParams.append("offset", options.offset.toString());
        }
        if(options?.limit){
          destination.searchParams.append("limit", options.limit.toString());
        }
      }
      var BatBrandGetRequest = fetch(destination.toString(), {
        method: "GET",
        headers: {
          "Authorization": cookies.get("user")
        }
      });
      BatBrandGetRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: BatBrandGetResponseInterface)=>{
        resolvePromise(requestJson);
      });
      BatBrandGetRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}