export default interface BatInterface{
  id: number;
  user_id: number | null;
  category: string;
  type: string;
  brand: string;
  model: string;
  matrix_id?: number | null;
  length: number;
  weight: number;
  bpi: number;
  purchase_url: string;
  bat_image_url: string|null;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  org_id: number | null;
  player_id?: number | null;
}

export interface BatInterfaceDto{
  user_id: number | null;
  category: string;
  type: string;
  brand: string;
  model: string;
  matrix_id?: number | null;
  length: number;
  weight: number;
  bpi: number;
  purchase_url: string;
  bat_image_url: string|null;
  org_id: number | null;
  player_id?: number | null;
}

export const EmptyBatInterfaceDto = {
  user_id: null,
  category: "",
  type: "",
  brand: "",
  model: "",
  matrix_id: null,
  length: 0,
  weight: 0,
  bpi: 0,
  purchase_url: "",
  bat_image_url: "",
  org_id: null
}