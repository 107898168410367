export default interface PlayerMovementScreeningInterface {
  id: number;
  player_id: number;
  left_hip_internal_rotation: string;
  right_hip_internal_rotation: string;
  left_hip_external_rotation: string;
  right_hip_external_rotation: string;
  pelvic_tilt: string;
  pelvic_stability?: string;
  pelvic_rotation: string;
  thoracic_spine_rotation: string;
  cervical_spine?: string;
  lateral_step: string;
  wide_squat: string;
  ankle_eversion?: string;
  ankle_inversion: string;
  left_shoulder_er: string;
  right_shoulder_er: string;
  pronation: string;
  supination: string;
  wrist_hinge?: string;
  right_shoulder_adduction: string;
  left_shoulder_adduction: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export const EmptyPlayerMovementScreening: PlayerMovementScreeningInterface = {
  id: -1,
  player_id: -1,
  left_hip_internal_rotation: "",
  right_hip_internal_rotation: "",
  left_hip_external_rotation: "",
  right_hip_external_rotation: "",
  pelvic_tilt: "",
  pelvic_stability: "",
  pelvic_rotation: "",
  thoracic_spine_rotation: "",
  cervical_spine: "",
  lateral_step: "",
  wide_squat: "",
  ankle_eversion: "",
  ankle_inversion: "",
  left_shoulder_er: "",
  right_shoulder_er: "",
  pronation: "",
  supination: "",
  wrist_hinge: "",
  right_shoulder_adduction: "",
  left_shoulder_adduction: ""
}

export interface PlayerMovementScreeningInterfaceDto{
  id?: number;
  player_id?: number;
  left_hip_internal_rotation: string;
  right_hip_internal_rotation: string;
  left_hip_external_rotation: string;
  right_hip_external_rotation: string;
  pelvic_tilt: string;
  pelvic_stability?: string;
  pelvic_rotation: string;
  thoracic_spine_rotation: string;
  cervical_spine?: string;
  lateral_step: string;
  wide_squat: string;
  ankle_eversion?: string;
  ankle_inversion: string;
  left_shoulder_er: string;
  right_shoulder_er: string;
  pronation: string;
  supination: string;
  wrist_hinge?: string;
  right_shoulder_adduction: string;
  left_shoulder_adduction: string;
}

export const EmptyPlayerMovementScreeningDto: PlayerMovementScreeningInterfaceDto = {
  left_hip_internal_rotation: "",
  right_hip_internal_rotation: "",
  left_hip_external_rotation: "",
  right_hip_external_rotation: "",
  pelvic_tilt: "",
  pelvic_stability: "",
  pelvic_rotation: "",
  thoracic_spine_rotation: "",
  cervical_spine: "",
  lateral_step: "",
  wide_squat: "",
  ankle_eversion: "",
  ankle_inversion: "",
  left_shoulder_er: "",
  right_shoulder_er: "",
  pronation: "",
  supination: "",
  wrist_hinge: "",
  right_shoulder_adduction: "",
  left_shoulder_adduction: ""
}