import React, { ChangeEvent } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { formatDateToDisplay } from "../utils/dateUtils";
import Papa from "papaparse";
import rp from "request-promise";
import "whatwg-fetch";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import Header from "../components/Header";
import history from "../history";
import Cookies from "universal-cookie";
import NewBat from "./Forms/NewBat";
import CoachAccountInterface, {
  EmptyCoachAccountInterface,
} from "../interfaces/apiInterfaces/CoachAccountInterface";
import BatInterface from "../interfaces/apiInterfaces/BatInterface";
import AuthPingRequest, {
  AuthPingGetResponseInterface,
} from "../api/requests/AuthPingRequest";
import BatBrandRequest, {
  BatBrandGetResponseInterface,
} from "../api/requests/BatBrandRequest";
import BatRequest, {
  BatGetResponseInterface
} from "../api/requests/BatRequest";
import ExportDataRequest from "../api/requests/ExportDataRequest";
import { getUserOrganization, getUserRole } from "../utils/userUtils";

const cookies = new Cookies();
const { SearchBar } = Search;

type BatType = {
  label: string;
  value: string;
};

interface BatsProps { }
interface BatsState {
  loading: boolean;
  coach: CoachAccountInterface;
  bats: Array<BatInterface>;
  page: number;
  show: boolean;
  mode: Mode;
  batId: number | null;
  batModalDropSearch: string;
  batModalBrandSearch: string;
  batModalTypes: Array<BatType>;
  batModalBrands: Array<string>;
  newBatModalTypes?: Array<BatType>;
}

type Mode = "edit" | "new";

class Bats extends React.Component<BatsProps, BatsState> {
  constructor(props: BatsProps) {
    super(props);
  }

  state: BatsState = {
    loading: false,
    coach: EmptyCoachAccountInterface,
    bats: [],
    page: 0,
    show: false,
    mode: "new",
    batId: null,
    batModalDropSearch: "",
    batModalBrandSearch: "",
    batModalTypes: [
      { label: "Wood", value: "wood" },
      { label: "Metal", value: "metal" },
      { label: "Soft Ball", value: "softball" },
      { label: 'Matrix', value: 'matrix' },
    ],
    batModalBrands: [],
    newBatModalTypes: [
        { label: "Wood", value: "wood" },
      { label: "Metal", value: "metal" },
      { label: "Soft Ball", value: "softball" },
    ]
  };

  getBatsWithFilterOptions(filterOptions: any, tempState: BatsState) {
    let batRequestOptions: any = {
        limit: 500,
        offset: 100 * tempState.page,
    };
    if (filterOptions) {
        for (const key in filterOptions) {
            batRequestOptions[key] = filterOptions[key];
        }
    }
    const userRole = getUserRole();
    if (!(userRole.name?.toString().toLowerCase().includes('admin'))) {
        const org = getUserOrganization()
        batRequestOptions["filter_key"] = "org_id";
        batRequestOptions["filter_value"] = org.id;
    }

    BatRequest.get(batRequestOptions).then(
    (result: BatGetResponseInterface) => {
        if (result.data) {
        var tempState = this.state;
        tempState.bats = Array.isArray(result.data)
            ? result.data
            : [result.data];
        this.setState(tempState);
        }
    });
  }

  componentDidMount = () => {
    var tempState = this.state;
    AuthPingRequest.get().then((result: AuthPingGetResponseInterface) => {
      if (!result.email) {
        history.push("/login");
      } else {
        var tempState = this.state;
        tempState.coach = result;
        this.setState(tempState);

        this.getBatsWithFilterOptions(null, tempState);
      }
    });
    BatBrandRequest.get().then((result: BatBrandGetResponseInterface) => {
      if (result.data && Array.isArray(result.data)) {
        var tempState = this.state;
        tempState.batModalBrands = result.data;
        var victusIndex = tempState.batModalBrands.indexOf("Victus");
        if (victusIndex != -1) {
          tempState.batModalBrands.splice(victusIndex, 1);
          tempState.batModalBrands.unshift("Victus");
        }
        var marucciIndex = tempState.batModalBrands.indexOf("Marucci");
        if (marucciIndex != -1) {
          tempState.batModalBrands.splice(marucciIndex, 1);
          tempState.batModalBrands.unshift("Marucci");
        }
        this.setState(tempState);
      }
    });
    this.setState(tempState);
  };

  handleShow = (mode: Mode) => {
    this.setState({ show: true, mode: mode });
  };

  handleClose = () => {
    let tempState = this.state;
    this.setState({ show: false });

    this.getBatsWithFilterOptions(null, tempState);
  };

  exportBats = () => {
    let tempState = this.state;
    ExportDataRequest.get(
      `bats/export?filter_key=user_id&filter_value=${tempState.coach.id}&limit=500`
    ).then((blob: any) => {
      const url: string = window.URL.createObjectURL(new Blob([blob]));
      let link: HTMLAnchorElement;
      link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `bats.xlsx`);
      link.click();
    });
  };

  handleBatModalDropSearchChange = (
    e: ChangeEvent<{ name?: string; value: any }>
  ) => {
    var tempState = this.state;
    tempState.batModalDropSearch = e.target.value.toString();
    this.setState(tempState);

    const filterOptions = { type: this.state.batModalDropSearch, brand: this.state.batModalBrandSearch.trim()};
    
    this.getBatsWithFilterOptions(filterOptions, tempState);
  };

  handleBatModalBrandSearchChange = (
    e: ChangeEvent<{ name?: string; value: any }>
  ) => {
    var tempState = this.state;
    tempState.batModalBrandSearch = e.target.value.toString();
    this.setState(tempState);
    const filterOptions = { type: this.state.batModalDropSearch, brand: this.state.batModalBrandSearch.trim()};
    
    this.getBatsWithFilterOptions(filterOptions, tempState);
  };

  handleFiles = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ loading: true });
    const files: any = event.target.files;
    const file: any = (files as any)[0];

    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const requestPromises: Array<any> = [];
        results.data.forEach((item: any) => {
          const requestOptions = {
            uri: process.env.REACT_APP_API_URL + "/bats",
            method: "POST",
            body: {
              bpi: parseFloat(item['BPI']),
              length: parseFloat(item['Length']),
              weight: parseFloat(item['Weight']),
              model: item['Model'],
              brand: item['Brand'],
              type: item['Bat Type'],
              category: "wood",
              user_id: this.state.coach.id,
            },
            headers: {
              "Authorization": cookies.get("user")
            },
            json: true
          };
          if (item['Length'] && item['Weight'] && item['Model'] && item['Brand'] && item['Bat Type'] && item['BPI']) {
            requestPromises.push(rp(requestOptions));
          }
        });

        Promise.all(requestPromises)
          .then(() => {
            this.setState({ loading: false });
            toast.success('Import successful')
            let tempState = this.state;
            
            this.getBatsWithFilterOptions(null, tempState);
          })
          .catch(err => {
            this.setState({ loading: false });
            toast.error('Something went wrong!')
          });
      },
      error: (error) => console.log(error),
      fastMode: true,
      skipEmptyLines: true,
    });
  };

  render() {
    const userRole = getUserRole();
    const actionsFormatter = (cell: any, row: any) => {
        if ((userRole.name?.toString().toLowerCase().includes('admin')) || row.type !== 'matrix') {
            return (
                <>
                  <button
                    title="Edit"
                    className="btn btn-outline-darkblue text-white"
                    onClick={() => {
                      this.handleShow("edit");
                      this.setState({ batId: row.id });
                    }}
                  >
                    <FontAwesomeIcon icon="pencil-alt" />
                  </button>
                </>
              );
        }
        return <> </>;
      
    };

    const pagination = paginationFactory({});

    const columns = [
      {
        dataField: "brand",
        text: "Brand",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 200,
        },
        sort: true,
      },
      {
        dataField: "model",
        text: "Model",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 200,
        },
        sort: true,
      },
      {
        dataField: "type",
        text: "Type",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 200,
        },
      },
      {
        dataField: "length",
        text: "Length",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 125,
        },
        align: "center",
        formatter: (cell: any, row: any) => Math.round(cell * 10) / 10,
      },
      {
        dataField: "weight",
        text: "Weight",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 125,
        },
        align: "center",
        formatter: (cell: any, row: any) => Math.round(cell * 10) / 10,
      },
      {
        dataField: "bpi",
        text: "BPI",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 125,
        },
        align: "center",
        formatter: (cell: any, row: any) => Math.round(cell * 10) / 10,
      },
      {
        dataField: "createdAt",
        text: "Date Added",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 200,
        },
        sort: true,
        formatter: (cell: any, row: any) => formatDateToDisplay(cell),
      },
      {
        dataField: "actions",
        text: "Actions",
        align: "center",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 200,
        },
        formatter: actionsFormatter,
      },
    ];
    return (
      <>
        <Header />
        <div className="playerLibrary">
          <div className="container-fluid">
            <ToolkitProvider
              keyField="id"
              data={this.state.bats}
              columns={columns}
              search
            >
              {(props) => (
                <>
                  <div className="row pt-4 pb-2">
                    <div className="col-md-12 col-lg-6">
                      <div className="row mobileMargin">
                        <div className="col-md-4 col-lg-6 col-xl-4">
                          <h3 className="mb-0"> Bat Database</h3>
                        </div>
                        <div className="col-md-8 col-lg-6 col-xl-8">
                          <div className="searchBar">
                            <SearchBar
                              style={{ width: 450, fontSize: 23 }}
                              className="form-control"
                              placeholder="Search for bat"
                              {...props.searchProps}
                            />
                            <FontAwesomeIcon
                              icon="search"
                              className="searcIcon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="row mobileMargin">
                        <div className="col-md-4 col-lg-6">
                          <Form.Control
                            size="lg"
                            style={{
                              border: "1px solid #487fc0",
                              borderRadius: 20,
                            }}
                            as="select"
                            value={
                              this.state.batModalDropSearch != null
                                ? this.state.batModalDropSearch
                                : undefined
                            }
                            onChange={this.handleBatModalDropSearchChange}
                          >
                            <option value="">-- Filter by Type --</option>
                            {this.state.batModalTypes.map(
                              (batType: BatType, i: number) => {
                                return (
                                  <option key={i} value={batType.value}>
                                    {batType.label}
                                  </option>
                                );
                              }
                            )}
                          </Form.Control>
                        </div>
                        <div className="col-md-4 col-lg-6">
                          <Form.Control
                            size="lg"
                            style={{
                              border: "1px solid #487fc0",
                              borderRadius: 20,
                            }}
                            as="select"
                            value={this.state.batModalBrandSearch}
                            onChange={this.handleBatModalBrandSearchChange}
                          >
                            <option value="">-- Filter by Brand --</option>
                            {this.state.batModalBrands.map((batBrand, i) => {
                              return (
                                <option key={i} value={batBrand}>
                                  {batBrand}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6 mb-2">
                      <button
                        disabled={this.state.loading}
                        className="btn export mr-2 solid d-inline-flex"
                        onClick={() => this.handleShow("new")}
                      >
                        <FontAwesomeIcon icon="plus" className="mr-1" />
                        Add Bat
                      </button>

                      <button
                        disabled={this.state.loading}
                        className="btn export solid d-inline-flex"
                        onClick={() => this.exportBats()}
                      >
                        <FontAwesomeIcon icon="upload" className="mr-1" />
                        Export
                      </button>
                      <div className="mt-2 d-inline-flex">
                        <div className="input-group">
                          <div className="custom-file">
                            <input type="file" onChange={(event) => this.handleFiles(event)} className="custom-file-input" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" />
                            <label className="custom-file-label">Choose file</label>
                          </div>
                          <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04">{this.state.loading ? 'Importing' : 'Import'}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="table-wrapper">
                        <BootstrapTable
                          pagination={pagination}
                          wrapperClasses="table-responsive"
                          {...props.baseProps}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </ToolkitProvider>
          </div>
        </div>

        <Modal
          size="lg"
          show={this.state.show}
          onHide={this.handleClose}
          contentClassName="addPlayermodal"
          backdrop="static"
          centered={true}
        >
          <Modal.Body className="p-0">
            <div className="row">
              <div className="col-md-12 m-0">
                <ul
                  className="nav nav-tabs d-flex justify-content-center"
                  id="myTab"
                  role="tablist"
                  style={{ visibility: "hidden" }}
                >
                  <li role="presentation">
                    <a
                      className="nav-link p-0 active "
                      id="playerInfo-tab"
                      data-toggle="tab"
                      href="#playerInfo"
                      role="tab"
                      aria-controls="playerInfo"
                      aria-selected="false"
                    >
                      Player Info{" "}
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      className="nav-link p-0"
                      id="biometrics-tab"
                      data-toggle="tab"
                      href="#biometrics"
                      role="tab"
                      aria-controls="biometrics"
                      aria-selected="true"
                    >
                      {" "}
                      Biometrics Info{" "}
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      className="nav-link p-0"
                      id="review-tab"
                      data-toggle="tab"
                      href="#review"
                      role="tab"
                      aria-controls="review"
                      aria-selected="false"
                    >
                      {" "}
                      Review Info{" "}
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade active show"
                    id="playerInfo"
                    role="tabpanel"
                    aria-labelledby="playerInfo-tab"
                  >
                    <div className="tabWrapper">
                      <NewBat fromScreen='bats'
                        mode={this.state.mode}
                        batId={this.state.batId}
                        batModalTypes={userRole.name?.toString().toLowerCase().includes('admin') ? this.state.batModalTypes : this.state.newBatModalTypes }
                        batModalBrands={this.state.batModalBrands}
                        handleClose={this.handleClose}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Bats;
