import BasicRequest from "../classes/BasicRequest";

type PasswordUpdatePostOptionsInterface = {
  token: string | null;
  password: string;
}

type PasswordUpdatePostBodyInterface = {
  password: string;
}

export interface PasswordUpdatePostResponseInterface{
  data?: any; //Getting a 500 atm so just leave it as a generic object. To be updated later
  message: "reset"|string;
}

export default class PasswordUpdateRequest extends BasicRequest{
  static post(options: PasswordUpdatePostOptionsInterface){
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var body: PasswordUpdatePostBodyInterface = {
        password: options.password
      };
      var authResetPostRequest = fetch(`${process.env.REACT_APP_API_URL}/users/updatePassword?token=${options.token}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      authResetPostRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: PasswordUpdatePostResponseInterface)=>{
        resolvePromise(requestJson);
      });
      authResetPostRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}