import React, { ChangeEvent } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
import Papa from "papaparse";
import rp from "request-promise";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { calculateAge } from '../utils/dateUtils';
import "whatwg-fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import Header from "../components/Header";
import history from "../history";
import NewPlayer from "./Forms/NewPlayer";
import CoachAccountInterface, {
  EmptyCoachAccountInterface,
} from "../interfaces/apiInterfaces/CoachAccountInterface";
import PlayerInterface from "../interfaces/apiInterfaces/PlayerInterface";
import PlayerRequest, {
  PlayerGetResponseInterface,
} from "../api/requests/PlayerRequest";
import AuthPingRequest, {
  AuthPingGetResponseInterface,
} from "../api/requests/AuthPingRequest";
import ExportDataRequest from "../api/requests/ExportDataRequest";
import CustomTableSearch, { Values } from "../components/CustomTableSearch";

const cookies = new Cookies();
const { SearchBar } = Search;

interface PlayersProps { }
interface PlayersState {
  loading: boolean,
  coach: CoachAccountInterface;
  players: Array<PlayerInterface>;
  page: number;
  show: boolean;
  mode: Mode;
  playerId: number | null;
}

type Mode = "view" | "edit" | "new";

class Players extends React.Component<PlayersProps, PlayersState> {
  constructor(props: PlayersProps) {
    super(props);
  }

  state: PlayersState = {
    loading: false,
    coach: EmptyCoachAccountInterface,
    players: [],
    page: 0,
    show: false,
    mode: "new",
    playerId: null,
  };

  loadPlayers = (tempState: PlayersState, filterKey?: string, filterValue?: string) => {
    PlayerRequest.get({
        limit: 100,
        offset: 100 * tempState.page,
        // filter_key: "user_id",
        // filter_value: tempState.coach.id?.toString(),
        filter_key: filterKey || "",
        filter_value: filterValue || "",
      }).then((result: PlayerGetResponseInterface) => {
        if (result.data) {
          var tempState = this.state;
          tempState.players = Array.isArray(result.data) ? result.data : [result.data];
          this.setState({...tempState, loading: false});
        }
      }).catch(e => {
        this.setState({...this.state, loading: false});
      });
  }

  componentDidMount = () => {
    var tempState = this.state;
    AuthPingRequest.get().then((result: AuthPingGetResponseInterface) => {
      if (!result.email) {
        history.push("/login");
      } else {
        var tempState = this.state;
        tempState.coach = result;
        this.setState(tempState);
        this.loadPlayers({...tempState, loading: true});
      }
    });
    this.setState(tempState);
  };

  goToPlayerPage = (player: PlayerInterface) => {
    setTimeout(() => {
      history.push(`/player?id=${player.id}`);
    }, 500);
  };

  exportPlayers = () => {
    ExportDataRequest.get('players/export').then((blob: any) => {
      const url: string = window.URL.createObjectURL(new Blob([blob]));
      let link: HTMLAnchorElement;
      link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `players.xlsx`);
      link.click();
    });
  }

  nextPage = () => {
    if (this.state.players.length >= 99 && this.state.coach) {
      var tempState = this.state;
      tempState.page++;
      this.setState(tempState);
      PlayerRequest.get({
        limit: 100,
        offset: 100 * tempState.page,
        filter_key: "user_id",
        filter_value: tempState.coach.id?.toString(),
      }).then((result: PlayerGetResponseInterface) => {
        if (result.data) {
          var tempState = this.state;
          tempState.players = Array.isArray(result.data)
            ? result.data
            : [result.data];
          tempState.players = tempState.players.sort(
            (a: PlayerInterface, b: PlayerInterface): number => {
              if (a.updatedAt && b.updatedAt) {
                var aDate = a.updatedAt;
                var bDate = b.updatedAt;
                return new Date(bDate).getTime() - new Date(aDate).getTime();
              }
              return 0;
            }
          );
          this.setState(tempState);
        }
      });
    }
  };

  backPage = () => {
    if (this.state.page > 0 && this.state.coach) {
      var tempState = this.state;
      tempState.page--;
      this.setState(tempState);
      PlayerRequest.get({
        limit: 100,
        offset: 100 * tempState.page,
        filter_key: "user_id",
        filter_value: tempState.coach.id?.toString(),
      }).then((result: PlayerGetResponseInterface) => {
        if (result.data) {
          var tempState = this.state;
          tempState.players = Array.isArray(result.data)
            ? result.data
            : [result.data];
          tempState.players = tempState.players.sort(
            (a: PlayerInterface, b: PlayerInterface): number => {
              if (a.updatedAt && b.updatedAt) {
                var aDate = a.updatedAt;
                var bDate = b.updatedAt;
                return new Date(bDate).getTime() - new Date(aDate).getTime();
              }
              return 0;
            }
          );
          this.setState(tempState);
        }
      });
    }
  };

  handleShow = (mode: Mode) => {
    this.setState({ show: true, mode: mode });
  };

  handleClose = () => {
    let tempState = this.state;
    this.setState({ show: false });
    PlayerRequest.get({
      limit: 100,
      offset: 100 * tempState.page,
      // filter_key: "user_id",
      // filter_value: tempState.coach.id?.toString(),
    }).then((result: PlayerGetResponseInterface) => {
      if (result.data) {
        var tempState = this.state;
        tempState.players = Array.isArray(result.data)
          ? result.data
          : [result.data];
        // tempState.players = tempState.players.sort(
        //   (a: PlayerInterface, b: PlayerInterface): number => {
        //     if (a.updatedAt && b.updatedAt) {
        //       var aDate = a.updatedAt;
        //       var bDate = b.updatedAt;
        //       return new Date(bDate).getTime() - new Date(aDate).getTime();
        //     }
        //     return 0;
        //   }
        // );
        this.setState(tempState);
      }
    });
  };

  handleFiles = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ loading: true });
    const files: any = event.target.files;
    const file: any = (files as any)[0];

    Papa.parse(file, {
      header: true,
      complete: (results) => {
        console.log(results.data);
        const requestPromises: Array<any> = [];
        results.data.forEach((item: any) => {
          const requestOptions = {
            uri: process.env.REACT_APP_API_URL + "/players",
            method: "POST",
            body: {
              first_name: item['First Name'],
              last_name: item['Last Name'],
              email: item['Email'],
              dob: item['DOB'],
              user_id: this.state.coach.id,
            },
            headers: {
              "Authorization": cookies.get("user")
            },
            json: true
          };
          if (item['First Name'] && item['Last Name'] && item['Email'] && item['DOB']) {
            requestPromises.push(rp(requestOptions));
          }
        });

        Promise.all(requestPromises)
          .then(() => {
            this.setState({ loading: false });
            toast.success('Import successful')
            let tempState = this.state;
            PlayerRequest.get({
              limit: 100,
              offset: 100 * tempState.page,
              // filter_key: "user_id",
              // filter_value: tempState.coach.id?.toString(),
            }).then((result: PlayerGetResponseInterface) => {
              if (result.data) {
                var tempState = this.state;
                tempState.players = Array.isArray(result.data) ? result.data : [result.data];
                this.setState(tempState);
              }
            });
          })
          .catch(err => {
            this.setState({ loading: false });
            toast.error('Something went wrong!')
          });
      },
      error: (error) => console.log(error),
      fastMode: true,
      skipEmptyLines: true,
    });
  };

  render() {
    const actionsFormatter = (cell: any, row: any) => {
      return (
        <span className="userActions">
          <button
            title="View"
            className="btn btn-outline-darkblue text-white"
            onClick={() => {
              this.handleShow("view");
              this.setState({ playerId: row.id });
            }}
          >
            <FontAwesomeIcon icon="search" />
          </button>
          <button
            title="Edit"
            className="btn btn-outline-darkblue text-white ml-3"
            onClick={() => {
              this.handleShow("edit");
              this.setState({ playerId: row.id });
            }}
          >
            <FontAwesomeIcon icon="pencil-alt" />
          </button>
          <button
            title="Info"
            className="btn btn-outline-darkblue text-white ml-3"
            onClick={() => history.push(`/player?id=${row.id}`)}
          >
            <FontAwesomeIcon icon="info-circle" />
          </button>
          <button
            title="Delete"
            className="btn btn-outline-darkblue text-white ml-3"
            onClick={async () => {
                await PlayerRequest.delete(row.id);
                toast.success(`Player ${row.last_name}, ${row.first_name} deleted successfully`);
                this.loadPlayers(this.state);
            }}
          >
            <FontAwesomeIcon icon="trash-alt" />
          </button>
        </span>
      );
    }
    const ageFormatter = (cell: any, row: any) => {
      return calculateAge(row.dob);
    };

    const handleTableSearch = (values: Values) => {
        values.searchText && values.filterKey ? this.loadPlayers({...this.state}, values.filterKey, values.searchText) : 
            this.loadPlayers({...this.state, loading: true});
      };

    const pagination = paginationFactory({});

    const columns = [
      {
        dataField: "actions",
        text: "Actions",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 225,
        },
        align: "center",
        sort: true,
        formatter: actionsFormatter
      },
      {
        dataField: "first_name",
        text: "First Name",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 200,
        },
        sort: true,
      },
      {
        dataField: "last_name",
        text: "Last Name",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 200,
        },
        sort: true,
      },
      {
        dataField: "dob",
        text: "Age",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
        formatter: ageFormatter
      },
      {
        dataField: "biometrics[0].bats",
        text: "Bats",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "biometrics[0].throws",
        text: "Throws",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "sessionCount",
        text: "Sessions",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      // {
      //   dataField: "last_active_on",
      //   text: "Last active on",
      //   style: { backgroundColor: "#fff" },
      //   headerStyle: {
      //     width: 150,
      //   },
      // },
    ];
    return (
      <>
        <Header />
        <div className="playerLibrary">
          <div className="container-fluid">
            <ToolkitProvider
              keyField="id"
              data={this.state.players}
              columns={columns}
              search
            >
              {(props) => (
                <>
                  <div className="row py-4 pb-2">
                    <div className="col-md-12 col-lg-8">
                      <div className="row mobileMargin">
                        <div className="col-md-4 col-lg-6 col-xl-4">
                          <h3 className="mb-0"> Player Library</h3>
                        </div>
                        <div className="col-md-8 col-lg-6 col-xl-8">
                          <div className="searchBar">
                            {/* <SearchBar
                              style={{ width: "20rem", fontSize: 23 }}
                              className="form-control"
                              placeholder="Search for player"
                              {...props.searchProps}
                            />
                            <FontAwesomeIcon icon="search" className="searcIcon" /> */}
                            <CustomTableSearch {...props.searchProps} onSearch={(val) => {
                                return setTimeout(() => {
                                    handleTableSearch(val);
                                }, 2000);    
                                }} placeHolder="Search for player" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="row mobileMargin">
                        <div className="col-md-4 col-lg-6">
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-6 mb-2">
                      <button
                        disabled={this.state.loading}
                        className="btn export mr-2 solid d-inline-flex"
                        onClick={() => this.handleShow("new")}
                      >
                        <FontAwesomeIcon icon="user-plus" className="mr-1" />
                        Add Player
                      </button>
                      <button
                        disabled={this.state.loading}
                        className="btn export solid d-inline-flex"
                        onClick={() => this.exportPlayers()}
                      >
                        <FontAwesomeIcon icon="upload" className="mr-1" />
                        Export
                      </button>
                      <div className="mt-2 d-inline-flex">
                        <div className="input-group">
                          <div className="custom-file">
                            <input type="file" onChange={(event) => this.handleFiles(event)} className="custom-file-input" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" />
                            <label className="custom-file-label">Choose file</label>
                          </div>
                          <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04">{this.state.loading ? 'Importing' : 'Import'}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="table-wrapper">
                        <BootstrapTable
                          pagination={pagination}
                          wrapperClasses="table-responsive"
                          {...props.baseProps}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </ToolkitProvider>
          </div>
        </div>

        {/* Loader component */}
        <Modal show={this.state.loading} centered={true}>
                <Modal.Body className="p-0">
                    <h3>Loading...</h3>
                </Modal.Body>
            </Modal>

        {/* Add Player */}
        <Modal
          size="xl"
          show={this.state.show}
          onHide={this.handleClose}
          contentClassName="addPlayermodal"
          backdrop="static"
          centered={true}
        >
          <Modal.Body className="p-0">
            <div className="row">
              <div className="col-md-12 m-0">
                <ul
                  className="nav nav-tabs d-flex justify-content-center"
                  id="myTab"
                  role="tablist"
                  style={{ visibility: "hidden" }}
                >
                  <li role="presentation">
                    <a
                      className="nav-link p-0 active "
                      id="playerInfo-tab"
                      data-toggle="tab"
                      href="#playerInfo"
                      role="tab"
                      aria-controls="playerInfo"
                      aria-selected="false"
                    >
                      Player Info{" "}
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      className="nav-link p-0"
                      id="biometrics-tab"
                      data-toggle="tab"
                      href="#biometrics"
                      role="tab"
                      aria-controls="biometrics"
                      aria-selected="true"
                    >
                      {" "}
                      Biometrics Info{" "}
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      className="nav-link p-0"
                      id="review-tab"
                      data-toggle="tab"
                      href="#review"
                      role="tab"
                      aria-controls="review"
                      aria-selected="false"
                    >
                      {" "}
                      Review Info{" "}
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade active show"
                    id="playerInfo"
                    role="tabpanel"
                    aria-labelledby="playerInfo-tab"
                  >
                    <NewPlayer
                      mode={this.state.mode}
                      playerId={this.state.playerId}
                      handleClose={this.handleClose}
                      usage="playerLibrary"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Players;
