import BasicRequest from "../classes/BasicRequest";

import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class TeamRequest extends BasicRequest{
  static get(options?: any): Promise<any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/teams`);
      if(options?.filter_key || options?.limit || options?.offset){
        if(options?.filter_key){
          destination.searchParams.append("filter_key", options.filter_key);
          destination.searchParams.append("filter_value", options?.filter_value||"");
        }
        if(options?.offset){
          destination.searchParams.append("offset", options.offset.toString());
        }
        if(options?.limit){
          destination.searchParams.append("limit", options.limit.toString());
        }
      }
      if(options?.id){
        destination.pathname = `teams/${options?.id}`;
      }
      var BatGetRequest = fetch(destination.toString(), {
        method: "GET",
        headers: {
          "Authorization": cookies.get("user")
        }
      });
      BatGetRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: any)=>{
        resolvePromise(requestJson);
      });
      BatGetRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
  static post(options: any): Promise<any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/teams`);
      var body: any = {
        ...options.team
      };
      var BatPostRequest = fetch(destination.toString(), {
        method: "POST",
        headers: {
          "Authorization": cookies.get("user"),
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      BatPostRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: any)=>{
        resolvePromise(requestJson);
      });
      BatPostRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
  static put(options: any): Promise<any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/teams`);
      destination.pathname += `/${options.id}`;
      var body: any = {
        ...options.org
      };
      var BatPutRequest = fetch(destination.toString(), {
        method: "PUT",
        headers: {
          "Authorization": cookies.get("user"),
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      BatPutRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: any)=>{
        resolvePromise(requestJson);
      });
      BatPutRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}