import React, { Component } from "react";

import "whatwg-fetch";

import { MenuItem } from "@material-ui/core";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import {
  DoubleArrow,
  ExitToApp,
  AccountCircle,
  Settings,
} from "@material-ui/icons";
import history from "../history";
import userImg from "../assets/images/userImg.png";
import playerLibrary from "../assets/images/player-library.svg";
import getFitted from "../assets/images/get-fitted.svg";
import batLibrary from "../assets/images/bat-library.svg";
import BatRXTextLogo from "../assets/images/Logo-White.svg";

import CoachAccountInterface, {
  CoachAccountInterfaceDto,
  EmptyCoachAccountInterface,
} from "../interfaces/apiInterfaces/CoachAccountInterface";
import AuthPingRequest, {
  AuthPingGetResponseInterface,
} from "../api/requests/AuthPingRequest";
import AuthLogoutRequest from "../api/requests/AuthLogoutRequest";

import Cookies from "universal-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserRole } from "../utils/userUtils";
const cookies = new Cookies();

const StyledMenu = withStyles({
  paper: {
    // border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    TransitionComponent={Fade}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

interface DashProps {}
interface DashState {
  coach: CoachAccountInterface;
  failedLogin: string;
  anchorEl: null | HTMLElement;
  role?: string;
}

class Dash extends Component<DashProps, DashState> {
  state: DashState = {
    coach: EmptyCoachAccountInterface,
    failedLogin: "",
    anchorEl: null,
    role: ''
  };

  constructor(props: DashProps) {
    super(props);
    AuthPingRequest.get().then((result: AuthPingGetResponseInterface) => {
      if (!result.email) {
        history.push("/login");
      } else {
        var tempState = this.state;
        tempState.coach = result;
        const role = getUserRole();
        tempState.role = role?.name?.toLowerCase();
        this.setState(tempState);
      }
    });
  }

  logout() {
    AuthLogoutRequest.post().then(() => {
      history.push("/login");
    });
  }

  handleClick(event: React.MouseEvent<HTMLDivElement>) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const userStr = localStorage.getItem("user");
    const userRole = getUserRole();
    const loggedIn: any = userStr ? JSON.parse(userStr) : {};
    if (!cookies.get("user")) {
      return <></>;
    }
    return (
      <>
        <div className="dashboard-user-profile position-absolute">
          <div className="dropdown" style={{ cursor: 'pointer' }}>
            <div
              className="text-white"
              onClick={(event) => this.handleClick(event)}
            >
              <img src={userImg} className="userprofileImg" />{" "}
              {loggedIn?.first_name} {loggedIn?.last_name}{" "}
              <FontAwesomeIcon className="ml-2" icon="angle-down" />
            </div>
            <StyledMenu
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={() => this.handleClose()}
            >
              {/* <MenuItem>
                <AccountCircle fontSize="small" className="mr-2" /> Profile
              </MenuItem> */}
              { this.state.role === 'admin' && <MenuItem onClick={() => history.push("/admin")}>
                <Settings fontSize="small" className="mr-2" /> Settings
              </MenuItem>
            }
              <MenuItem onClick={this.logout}>
                <ExitToApp fontSize="small" className="mr-2" /> Logout
              </MenuItem>
            </StyledMenu>
          </div>
        </div>

        <div className="dashboardBlock text-center align-items-center d-flex">
          <div className="container">
            <div className="row">
              <div className="mx-auto col-10 col-md-12 col-lg-12 col-xl-10">
                <div className="logo mb-4">
                  {" "}
                  <img
                    src={BatRXTextLogo}
                    className="img-fluid"
                    alt="BatRx Logo"
                  />
                </div>
                <br /> <br />
                <div className="col-md-12 mt-2">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-4 mb-3">
                      <button
                        type="button"
                        className="btn btn-dashboard btn-block"
                        onClick={() => history.push("players")}
                      >
                        <span>
                          {" "}
                          <img src={playerLibrary} className="img-fluid mb-3" />
                        </span>
                        <br />
                        <span> Player Library </span>
                      </button>
                    </div>
                    <div className="col-12 col-md-12 col-lg-4 mb-3">
                      <button
                        type="button"
                        className="btn btn-dashboard btn-block"
                        onClick={() => history.push("get-fitted")}
                      >
                        <span>
                          {" "}
                          <img src={getFitted} className="img-fluid mb-3" />
                        </span>
                        <br />
                        <span> Get Fitted </span>
                      </button>
                    </div>
                    <div className="col-12 col-md-12 col-lg-4 mb-3">
                      <button
                        type="button"
                        className="btn btn-dashboard btn-block"
                        onClick={() => history.push("bats")}
                      >
                        <span>
                          {" "}
                          <img src={batLibrary} className="img-fluid mb-3" />
                        </span>
                        <br />
                        <span> Bat Database </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Dash;
