import React, { Component } from "react";
import "./launch_angle_dynamic.scss";

export type LaunchAngleProps = {
  hitCount: number;
  average: number;
  consistency: number;
  launch_angle_spread: any;
  roundNumber: any;
  customClass?: string;
  lastHit?: any;
};

type LaunchAngleState = {};

class LaunchAngle2 extends Component<LaunchAngleProps, LaunchAngleState> {
  getShadeOfRed = (count: number) => {
    const { hitCount } = this.props;
    const shades = ["#FFCDD2", "#FF8A80", "#FF5252", "#FF1744", "#D50000"];
    let percent = count ? (count / hitCount) * 100 : 0;
    if (percent === 0) {
      return "#FFEBEE";
    } else if (percent > 0 && percent < 20) {
      return shades[0];
    } else if (percent >= 20 && percent < 40) {
      return shades[1];
    } else if (percent >= 40 && percent < 60) {
      return shades[2];
    } else if (percent >= 60 && percent < 80) {
      return shades[3];
    } else if (percent >= 80 && percent <= 100) {
      return shades[4];
    }
  };

  render() {
    const { launch_angle_spread, customClass } = this.props;

    return (
      <>
        <span style={{ position: "absolute", left: 90, bottom: 110, zIndex: 1 }}>
          {this.props.average === undefined ? 0 : this.props.roundNumber(this.props.average)} {" "}
          (+/-{" "}{this.props.consistency === undefined ? 0 : this.props.roundNumber(this.props.consistency)})
        </span>
        <div style={{ position: 'relative', left: '28%' }}>
          <div className={ customClass ? customClass : "pie" }>
            <div
              className={ customClass ? "pointer_1_small" : "pointer_1" }
              style={{
                transform: `rotate( ${this.props.average === undefined ? 90 : 90 - this.props.average
                  }deg)`,
              }}
            >
              {" "}
              <div className="pointerEnd_1" />
            </div>
            <span style={{ transform: "rotate(0deg) skewY(55deg)", backgroundColor: `${this.getShadeOfRed(launch_angle_spread["three5_to_ninety"])}` }}></span>
            <span style={{ transform: "rotate(55deg) skewY(10deg)", backgroundColor: `${this.getShadeOfRed(launch_angle_spread["two5_to_35"])}` }}></span>
            <span style={{ transform: "rotate(65deg) skewY(15deg)", backgroundColor: `${this.getShadeOfRed(launch_angle_spread["ten_to_25"])}` }}></span>
            <span style={{ transform: "rotate(80deg) skewY(10deg)", backgroundColor: `${this.getShadeOfRed(launch_angle_spread["zero_to_ten"])}` }}></span>
            <span style={{ transform: "rotate(90deg) skewY(0deg)", backgroundColor: `${this.getShadeOfRed(launch_angle_spread["minus_ninety_to_zero"])}` }}></span>
          </div>
        </div>
        <span
          className="min-velocity"
          style={{ position: "absolute", left: 230, top: -10 }}
        >
          90<sup>°</sup>
        </span>
        <span
          className="mid-velocity"
          style={{ position: "absolute", right: 24, bottom: 178 }}
        >
          25<sup>°</sup> - 35<sup>°</sup>
        </span>
        <span
          className="mid-velocity"
          style={{ position: "absolute", right: 24, bottom: 146 }}
        >
          10<sup>°</sup> - 25<sup>°</sup>
        </span>
        <span
          className="mid-velocity"
          style={{ position: "absolute", right: 24, bottom: 118 }}
        >
          0<sup>°</sup> - 10<sup>°</sup>
        </span>
        <span
          className="neg-velocity"
          style={{ position: "absolute", left: 220, bottom: -5 }}
        >
          -90<sup>°</sup>
        </span>
      </>
    )
  }
}

export default LaunchAngle2;