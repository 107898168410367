import BasicRequest from "../classes/BasicRequest";

import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class UserRequest extends BasicRequest{
  static delete(userId: number): Promise<any> {
    const promise = new Promise((resolve, reject) => {
        if (!userId) reject('Cannot delete user, userId not provided');
        const UserDeleteRequest = fetch(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
            method: 'DELETE',
            headers: {
                "Authorization": cookies.get("user")
            }
        });
        UserDeleteRequest.then(rawData => rawData.json())
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
    return promise;
  }

  static get(options?: any): Promise<any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/users`);
      if(options?.filter_key || options?.limit || options?.offset){
        if(options?.filter_key){
          destination.searchParams.append("filter_key", options.filter_key);
          destination.searchParams.append("filter_value", options?.filter_value||"");
        }
        if(options?.offset){
          destination.searchParams.append("offset", options.offset.toString());
        }
        if(options?.limit){
          destination.searchParams.append("limit", options.limit.toString());
        }
      }
      if(options?.id){
        destination.pathname = `users/${options?.id}`;
      }
      var UserGetRequest = fetch(destination.toString(), {
        method: "GET",
        headers: {
          "Authorization": cookies.get("user")
        }
      });
      UserGetRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: any)=>{
        resolvePromise(requestJson);
      });
      UserGetRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
  static post(options: any): Promise<any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/users`);
      var body: any = {
        ...options.user
      };
      var UserPostRequest = fetch(destination.toString(), {
        method: "POST",
        headers: {
          "Authorization": cookies.get("user"),
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      UserPostRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: any)=>{
        resolvePromise(requestJson);
      });
      UserPostRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
  static put(options: any): Promise<any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/users`);
      destination.pathname += `/${options.id}`;
      var body: any = {
        ...options
      };
      var UserPutRequest = fetch(destination.toString(), {
        method: "PUT",
        headers: {
          "Authorization": cookies.get("user"),
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      UserPutRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: any)=>{
        resolvePromise(requestJson);
      });
      UserPutRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}