import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { formatDateToDisplay } from "../../utils/dateUtils";
import { Modal } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrganizationRequest from "../../api/requests/OrganizationRequest";
import TeamRequest from "../../api/requests/TeamRequest";

const { SearchBar } = Search;

type Team = {
  id: number;
  org_id: number;
  name: string;
  createdAt?: string;
};
type Organization = {
  id: number;
  name: string;
  teams: Array<Team>;
};
type OrganizationsProps = {
  organizations: Array<Organization>;
  handleClose: any;
};
type OrganizationsState = {
  org: Organization;
  teamInput: string;
  page: number;
  show: boolean;
  mode: Mode;
  batId: number | null;
};

type Mode = "edit" | "new";

const OrgSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  team: Yup.string(),
});

class Organizations extends Component<OrganizationsProps, OrganizationsState> {
  constructor(props: OrganizationsProps) {
    super(props);
  }

  state: OrganizationsState = {
    org: {
      id: -1,
      name: "",
      teams: [],
    },
    teamInput: "",
    page: 0,
    show: false,
    mode: "new",
    batId: null,
  };

  handleShow = (mode: Mode) => {
    this.setState({ show: true, mode: mode });
  };

  clearForm = () => {
    var tempState = this.state;
    tempState.show = false;
    tempState.org = {
      id: -1,
      name: "",
      teams: [],
    };
    this.setState(tempState);
  };

  fetchOrganization(orgId: number) {
    OrganizationRequest.get({
      id: orgId,
    })
      .then((result: any) => {
        if (result.data && !Array.isArray(result.data)) {
          var tempState = this.state;
          tempState.org = result.data;
          tempState.mode = "edit";
          tempState.show = true;
          this.setState(tempState);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  createOrUpdateOrganization = (values: any) => {
    if (this.state.mode === "edit") {
      OrganizationRequest.put({
        id: this.state.org.id,
        org: {
          name: values.name,
        },
      })
        .then((result: any) => {
          if (result.data) {
            this.clearForm();
            this.props.handleClose("org");
          } else {
            toast.error(result.message, {
              autoClose: 5000,
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      OrganizationRequest.post({
        org: {
          name: values.name,
        },
      })
        .then((result: any) => {
          if (result.data) {
            this.clearForm();
            this.props.handleClose("org");
          } else {
            toast.error(result.message, {
              autoClose: 5000,
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  handleAddTeam = () => {
    const { teamInput } = this.state;
    if (teamInput.length === 0) {
      toast.error("Team name cannot be empty", {
        autoClose: 5000,
      });
    }
    TeamRequest.post({
      team: {
        org_id: this.state.org.id,
        name: teamInput,
      },
    })
      .then((result: any) => {
        if (result.data) {
          this.setState({
            org: {
              ...this.state.org,
              teams: [...this.state.org.teams, result.data],
            },
            teamInput: "",
          });
        } else {
          toast.error(result.message, {
            autoClose: 5000,
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  render() {
    const dateFormatter = (cell: any, row: any) => {
      return formatDateToDisplay(row.updatedAt);
    };
    const actionsFormatter = (cell: any, row: any) => {
      return (
        <>
          {/* <button
            className="btn btn-primary"
            // onClick={() => this.fetchOrganization(row.id)}
          >
            <FontAwesomeIcon icon="search" />
          </button> */}
          <button
            title="Edit"
            className="btn btn-outline-darkblue text-white"
            onClick={() => this.fetchOrganization(row.id)}
          >
            <FontAwesomeIcon icon="pencil-alt" />
          </button>
        </>
      );
    };

    const pagination = paginationFactory({});

    const columns = [
      {
        dataField: "id",
        text: "ID",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: "50px",
        },
        sort: true,
      },
      {
        dataField: "name",
        text: "Name",
        style: { backgroundColor: "#fff" },
        sort: true,
      },
      {
        dataField: "updatedAt",
        text: "Updated On",
        style: { backgroundColor: "#fff" },
        formatter: dateFormatter,
      },
      {
        dataField: "actions",
        text: "Actions",
        align: "center",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: "200px",
        },
        formatter: actionsFormatter,
      },
    ];
    return (
      <div className="container-fluid">
        <ToolkitProvider
          keyField="id"
          data={this.props.organizations}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div className="row py-4 d-flex align-items-center">
                <div className="col-md-12 col-lg-6">
                  <div className="row mobileMargin">
                    <div className="col-md-8 col-lg-6 col-xl-8">
                      <SearchBar
                        style={{ width: 400 }}
                        className="form-control"
                        placeholder="Search"
                        {...props.searchProps}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-lg-6">
                  <div className="float-right">
                    {/* <button className="btn export solid mr-2 d-inline-flex">
                      <FontAwesomeIcon icon="upload" className="mr-1" />
                      Export
                    </button> */}

                    <button
                      className="btn export solid d-inline-flex"
                      onClick={() => this.handleShow("new")}
                    >
                      <FontAwesomeIcon icon="plus" className="mr-1" />
                      Add Organization
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="table-wrapper">
                    <BootstrapTable
                      pagination={pagination}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              </div>
              <Modal
                size="lg"
                show={this.state.show}
                onHide={() => this.props.handleClose("org")}
                contentClassName="addPlayermodal"
                backdrop="static"
                centered={true}
              >
                <Modal.Body>
                  <div className="tabWrapper">
                    <div className="row mb-4">
                      <div className="col-md-12">
                        <div>
                          <h4 className="fw-bold">
                            {this.state.mode === "new" ? "Add New" : "Edit"}{" "}
                            Organization
                          </h4>
                        </div>
                      </div>
                    </div>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        name: this.state.org.name,
                        team: "",
                      }}
                      validationSchema={OrgSchema}
                      onSubmit={(values) => {
                        this.createOrUpdateOrganization(values);
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          <div className="row" style={{ minHeight: "60vh" }}>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label>Title</label>
                                <div className="input-group input-group-lg">
                                  <Field
                                    type="text"
                                    name="name"
                                    className={`form-control ${
                                      errors.name && touched.name
                                        ? "is-invalid"
                                        : "is-valid"
                                    }`}
                                  />
                                </div>
                                {errors.name && touched.name ? (
                                  <span className="small text-danger">
                                    {errors.name}
                                  </span>
                                ) : null}
                              </div>

                              <hr />

                              {this.state.mode === "edit" && (
                                <div className="row mb-3">
                                  <div className="col-8">
                                    <div className="input-group input-group-lg">
                                      <input
                                        type="text"
                                        name="team"
                                        value={this.state.teamInput}
                                        placeholder="Team's Name"
                                        className={`form-control input-lg`}
                                        onChange={(e) =>
                                          this.setState({
                                            teamInput: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <button
                                      className="float-right btn btn-darkblue text-white"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.handleAddTeam();
                                      }}
                                    >
                                      Create Team
                                    </button>
                                  </div>
                                </div>
                              )}

                              <table className="table table-bordered table-striped">
                                <thead>
                                  <tr>
                                    {/* <th scope="col">#</th> */}
                                    <th scope="col">Team</th>
                                    <th scope="col">Created</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.org.teams.map((item) => {
                                    return <tr>
                                      {/* <th scope="row">1</th> */}
                                      <td>{item.name}</td>
                                      <td>{item.createdAt ? formatDateToDisplay(item.createdAt) : ""}</td>
                                      {/* <td>@mdo</td> */}
                                    </tr>
                                  })}
                                </tbody>
                              </table>                              
                            </div>
                          </div>

                          <div className="text-center my-3">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                this.clearForm();
                                this.props.handleClose("org");
                              }}
                              className="btn btn-outline-darkblue px-xl-4 mr-3"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-darkblue text-white px-4"
                            >
                              {this.state.mode === "new" ? "Create" : "Update"}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

export default Organizations;
