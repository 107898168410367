/* 
  Isaac Hormel
  5/29/2020
  Sends a request to the NodeJS API server and to get hits for a batting session. Can be filtered and paginated depending on the options.
*/

import BasicRequest from "../classes/BasicRequest";

import Cookies from "universal-cookie";
import PaginationAndFilterInterface from "../../interfaces/apiInterfaces/PaginationAndFilterInterface";
import HitInterface from "../../interfaces/apiInterfaces/HitInterface";
const cookies = new Cookies();

interface HitGetOptionsInterface extends PaginationAndFilterInterface{
  id?: number;
};
interface HitPutOptionsInterface {
  id: number;
  hit: HitInterface;
};
interface HitDeleteOptionsInterface {
  id: number;
}
interface HitGetBodyInterface{

}; 

export interface HitGetResponseInterface{
  data?: Array<HitInterface>|HitInterface;
  message: "findAll"|"findOne";
}
export interface HitPutResponseInterface{
  data?: HitInterface;
  message: string;
}
export interface HitDeleteResponseInterface{
  data?: number;
  message: "deleted";
}

export default class HitRequest extends BasicRequest{
  static get(options?: HitGetOptionsInterface): Promise<HitGetResponseInterface|any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/hits`);
      if(options?.filter_key || options?.limit || options?.offset){
        if(options?.filter_key){
          destination.searchParams.append("filter_key", options.filter_key);
          destination.searchParams.append("filter_value", options?.filter_value||"");
        }
        if(options?.offset){
          destination.searchParams.append("offset", options.offset.toString());
        }
        if(options?.limit){
          destination.searchParams.append("limit", options.limit.toString());
        }
      }
      if(options?.id){
        destination.pathname += `/${options?.id}`;
      }
      var HitGetRequest = fetch(destination.toString(), {
        method: "GET",
        headers: {
          "Authorization": cookies.get("user")
        }
      });
      HitGetRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: HitGetResponseInterface)=>{
        resolvePromise(requestJson as HitGetResponseInterface);
      });
      HitGetRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }

  static put(options: HitPutOptionsInterface): Promise<HitPutResponseInterface|any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/hits`);
      destination.pathname += `/${options.id}`;
      var HitPutRequest = fetch(destination.toString(), {
        method: "PUT",
        headers: {
          "Authorization": cookies.get("user")
        },
        body: JSON.stringify(options)
      });
      HitPutRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: HitPutResponseInterface)=>{
        resolvePromise(requestJson as HitPutResponseInterface);
      });
      HitPutRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }

  static delete(options: HitDeleteOptionsInterface): Promise<HitDeleteResponseInterface|any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
        // changed /hits to /process/hit end point 03/25/2023
      var destination = new URL(`${process.env.REACT_APP_API_URL}/process/hit`);
      destination.pathname += `/${options.id}`;
      var HitDeleteRequest = fetch(destination.toString(), {
        method: "DELETE",
        headers: {
          "Authorization": cookies.get("user")
        }
      });
      HitDeleteRequest.then(rawRequest=>{
        return { data: rawRequest.status, message: rawRequest.statusText } as HitDeleteResponseInterface;
      }).then((requestJson: HitDeleteResponseInterface)=>{
        resolvePromise(requestJson as HitDeleteResponseInterface);
      });
      HitDeleteRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}