import * as React from "react";
import { AscDescSvg, SortButton } from "../../../components/Gauges/SortButton";
import HitInterface, {
  HitInterfaceDto,
} from "../../../interfaces/apiInterfaces/HitInterface";
import FittingSessionResultInterface from "../../../interfaces/apiInterfaces/FittingSessionResultInterface";
import BatInterface from "../../../interfaces/apiInterfaces/BatInterface";
import BattingSessionInterface from "../../../interfaces/apiInterfaces/BattingSessionInterface";
import { CircularProgressGuage } from "../../../components/Gauges/CircularProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {
  BattingSessionsBarGraphChartDataState,
  BattingSessionsBarGraphChartDataStateBattingSession,
} from "../../../components/BattingSessionsBarGraph/BattingSessionsBarGraph";
import {
  getConsistency,
} from "../../../utils/fittingSessionUtils";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { BpiGuage } from "../../../components/Gauges/bpi";
import GridExitVelocity from "./GridExitVelocity";
import GridLaunchAngle from "./GridLaunchAngle";
import GridSprayDirection from "./GridSprayDirection";
import FittingSessionInterface from "../../../interfaces/apiInterfaces/FittingSessionInterface";
import ExportDataRequest from "../../../api/requests/ExportDataRequest";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 2,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    circular_paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      maxWidth: "200px",
      maxHeight: "200px",
    },
  })
);

const SummaryList: React.FC<{
  fitting_session?: FittingSessionInterface | null;
  custom_metric_label: string;
  best_bat: {
    fitting_session_result: FittingSessionResultInterface;
    bat: BatInterface;
    batting_session: BattingSessionInterface;
  };
  batting_sessions: Array<{
    batting_session: BattingSessionInterface;
    hits: Array<HitInterfaceDto>;
    bat: BatInterface;
    average: HitInterface | null;
    cns: HitInterface | null;
    currentHit: HitInterfaceDto | null;
  }>;
  fitting_session_results: Array<{
    fitting_session_result: FittingSessionResultInterface;
    bat: BatInterface;
  }>;
  chart_filter?: "overall_fit_score";
}> = ({
  batting_sessions,
  custom_metric_label,
  best_bat,
  fitting_session_results,
  chart_filter,
}) => {
  const [state, setState] = React.useState(
    [] as Array<BattingSessionsBarGraphChartDataState>
  );

  const [asc, setAsc] = React.useState(true);

  const [consistencyIcon, setConsistencyIcon] = React.useState(AscDescSvg.asc);
  const [powerIcon, setPowerIcon] = React.useState(AscDescSvg.asc);
  const [subjectiveIcon, setSubjectiveIcon] = React.useState(AscDescSvg.asc);
  const [exitVelIcon, setExitVelIcon] = React.useState(AscDescSvg.asc);
  const [customMetricIcon, setCustomMetricIcon] = React.useState(AscDescSvg.asc);

  // round number upto two decimal places
  const roundNumber = (num: number) => {
    return Math.round(num * 10) / 10;
  };

  const getMaxValue = (array_of_objects: Array<any>) => {
    if (array_of_objects.length) {
      return array_of_objects.reduce(
        (max: number, p: any) =>
          Number(p.exit_velocity) > max ? Number(p.exit_velocity) : max,
        array_of_objects[0].exit_velocity
      );
    } else {
      return 300;
    }
  };

  const getLaunchAngleSpread = (items: Array<HitInterfaceDto>) => {
    let result: any = {
      three5_to_ninety: 0,
      two5_to_35: 0,
      ten_to_25: 0,
      zero_to_ten: 0,
      minus_ninety_to_zero: 0,
    };
    items.map((item) => {
      let la: number =
        typeof item["launch_angle"] === "string"
          ? parseFloat(item["launch_angle"])
          : item["launch_angle"];

      if (la >= -90 && la < 0) {
        result["minus_ninety_to_zero"] = result["minus_ninety_to_zero"] + 1;
      } else if (la >= 0 && la <= 10) {
        result["zero_to_ten"] = result["zero_to_ten"] + 1;
      } else if (la > 10 && la <= 25) {
        result["ten_to_25"] = result["ten_to_25"] + 1;
      } else if (la > 25 && la <= 35) {
        result["two5_to_35"] = result["two5_to_35"] + 1;
      } else if (la > 35 && la <= 90) {
        result["three5_to_ninety"] = result["three5_to_ninety"] + 1;
      }
    });
    return result;
  };

  const getSprayDirectionSpread = (items: Array<HitInterfaceDto>) => {
    let result: any = {
      l2: 0,
      l1: 0,
      mid: 0,
      r1: 0,
      r2: 0,
    };
    items.map((item) => {
      let sd: number =
        typeof item["spray_direction"] === "string"
          ? parseFloat(item["spray_direction"])
          : item["spray_direction"];
      if (sd >= -45 && sd < -27) {
        result["l2"] = result["l2"] + 1;
      } else if (sd >= -27 && sd < -9) {
        result["l1"] = result["l1"] + 1;
      } else if (sd >= -9 && sd < 9) {
        result["mid"] = result["mid"] + 1;
      } else if (sd >= 9 && sd < 27) {
        result["r1"] = result["r1"] + 1;
      } else if (sd >= 27 && sd <= 45) {
        result["r2"] = result["r2"] + 1;
      }
    });
    return result;
  };

  React.useEffect(() => {
    var chartData: Array<BattingSessionsBarGraphChartDataState> = [];

    try {
        for (let i in fitting_session_results) {
            /* WHY TF DO I HAVE TO CALL rawFSR A {[index: string]: any} ? */
            var rawFSR: { [index: string]: any } =
              fitting_session_results[i].fitting_session_result;
            var keys: Array<string> = Object.keys(rawFSR);
            for (let r in keys) {
              if (
                typeof rawFSR[keys[r]] == "number" ||
                isNaN(rawFSR[keys[r]]) == false
              ) {
                rawFSR[keys[r]] = Math.abs(rawFSR[keys[r]]);
              }
            }
            var batColor = "#000";
            var batModel = "";
            for (let k in batting_sessions) {
              if (
                batting_sessions[k].batting_session.bat_id ==
                fitting_session_results[i].bat.id
              ) {
                batColor = batting_sessions[k].batting_session.color;
                batModel = batting_sessions[k].bat.model;
              }
            }
            var battingSession: BattingSessionInterface = {
              id: -1,
              bat_id: -1,
              fitting_session_id: -1,
              player_id: -1,
              color: "",
              order: -1,
            };
            var hits: Array<HitInterfaceDto> = [];
            var averageBatVelocity = 0;
            var averageAngleOfAttack = 0;
            var averageExitVelocity = 0;
            var averageLaunchAngle = 0;
            for (let p in batting_sessions) {
              if (
                batting_sessions[p].batting_session.bat_id ==
                fitting_session_results[i].bat.id
              ) {
                battingSession = batting_sessions[p].batting_session;
                hits = batting_sessions[p].hits;
                for (let j in hits) {
                  averageBatVelocity += parseFloat("" + hits[j].bat_velocity);
                  averageAngleOfAttack += parseFloat("" + hits[j].angle_of_attack);
                  averageExitVelocity += parseFloat(
                    "" + (hits[j].corrected_exit_velocity || hits[j].exit_velocity)
                  );
                  averageLaunchAngle += parseFloat(
                    "" + (hits[j].corrected_launch_angle || hits[j].launch_angle)
                  );
                }
                averageBatVelocity /= hits.length;
                averageAngleOfAttack /= hits.length;
                averageExitVelocity /= hits.length;
                averageLaunchAngle /= hits.length;
              }
            }
            var battingSessionsBarGraph: BattingSessionsBarGraphChartDataStateBattingSession =
              {
                batting_session: battingSession,
                hits: hits,
              };
              console.log(rawFSR, 'rawfsr');
            chartData.push({
              bat: fitting_session_results[i].bat.brand,
              color: battingSession?.color ? battingSession.color : batColor,
              model: batModel,
              designation: `${fitting_session_results[i].bat.brand} ${parseInt(
                fitting_session_results[i].bat.id.toString()
              )}`,
              ...rawFSR,
              ...battingSessionsBarGraph,
              averageBatVelocity,
              averageAngleOfAttack,
              averageExitVelocity,
              averageLaunchAngle,
            } as unknown as BattingSessionsBarGraphChartDataState);
          }
    } catch (error) {
        console.log(error);
        return chartData;
    }

    
    if (chart_filter)
      chartData.sort((a, b) => {
        return Math.abs(a[chart_filter]) - Math.abs(b[chart_filter]);
      });
    if (chartData.length)
      setState(chartData as Array<BattingSessionsBarGraphChartDataState>);
  }, [fitting_session_results]);
  const sortByProp = (chart_filter: string) => {
    if (chart_filter) {
        console.log(state, 'current state object');
      const sorted = Array.from(
        state?.sort((a, b) => {
          return parseFloat(a[chart_filter]) > parseFloat(b[chart_filter])
            ? asc
              ? 1
              : -1
            : a[chart_filter] < b[chart_filter]
            ? asc
              ? -1
              : 1
            : 0;
        })
      );
      setConsistencyIcon(
        asc && chart_filter === "overall_fit_score"
          ? AscDescSvg.asc
          : AscDescSvg.desc
      );
      setPowerIcon(
        asc && chart_filter === "barrel_stat" ? AscDescSvg.asc : AscDescSvg.desc
      );
      setSubjectiveIcon(
        asc && chart_filter === "subjective" ? AscDescSvg.asc : AscDescSvg.desc
      );
      setExitVelIcon(
        asc && chart_filter === "averageExitVelocity"
          ? AscDescSvg.asc
          : AscDescSvg.desc
      );
      setCustomMetricIcon(
        asc && chart_filter === "custom_metric_average"
          ? AscDescSvg.asc
          : AscDescSvg.desc
      )
      setAsc(!asc);
      setState(sorted);
    }
  };
  const grid = 8;
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightblue" : "#fff",
    border: "2px solid",

    // styles we need to apply on draggables
    ...draggableStyle,
  });
  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(state, result.source.index, result.destination.index);

    setState(items as Array<BattingSessionsBarGraphChartDataState>);
  };

  const classes = useStyles();

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-1">
        {(providedDrop, snapshotDrop) => (
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            {...providedDrop.droppableProps}
            ref={providedDrop.innerRef}
          >
            <Grid
              style={{ borderStyle: "inset", textAlign: "center" }}
              item
              xs={12}
            >
              <Grid
                container
                direction="row"
                spacing={1}
                justifyContent="center"
              >
                <Grid item xs>
                    <button className="btn export solid d-inline-flex" onClick={() => {
                        ExportDataRequest.get(`fitting_sessions/${state[0].batting_session.fitting_session_id}/report`).then((blob: any) => {
                            const url: string = window.URL.createObjectURL(new Blob([blob]));
                            let link: HTMLAnchorElement;
                            link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `fitting_sessions_${state[0].batting_session.fitting_session_id}.xlsx`);
                            link.click();
                          });
                    }}>Export</button>
                </Grid>
                <Grid item xs>
                  <SortButton
                    title="Consistency"
                    icon={consistencyIcon}
                    onClick={() => sortByProp("overall_fit_score")}
                  ></SortButton>
                </Grid>
                <Grid item xs>
                  <SortButton
                    icon={powerIcon}
                    title="Power"
                    onClick={() => sortByProp("barrel_stat")}
                  ></SortButton>
                </Grid>
                <Grid item xs>
                  <SortButton
                    icon={subjectiveIcon}
                    title="Subjective"
                    onClick={() => sortByProp("subjective")}
                  ></SortButton>
                </Grid>
                {custom_metric_label && 
                    <Grid item xs>
                    <SortButton
                        title={custom_metric_label}
                        icon={customMetricIcon}
                        onClick={() => sortByProp("custom_metric_average")}
                    ></SortButton>
                    </Grid>
                }
                <Grid item xs>
                  <SortButton
                    icon={exitVelIcon}
                    title="Exit Velocity"
                    onClick={() => sortByProp("averageExitVelocity")}
                  ></SortButton>
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs></Grid>
              </Grid>
            </Grid>
            {state?.map((value, index) => {
              const subjective = getConsistency(
                value,
                best_bat,
                state,
                "subjective"
              );
              const consistency = getConsistency(
                value,
                best_bat,
                state,
                "overall_fit_score"
              );
              const power = getConsistency(
                value,
                best_bat,
                state,
                "barrel_stat"
              );
              const custom_metric_average = getConsistency(
                value,
                best_bat,
                state,
                "custom_metric_average"
              );
              const custom_metric_consistency = getConsistency(
                value,
                best_bat,
                state,
                "custom_metric_consistency"
              );
              const matching_batting_sess: any = Array.isArray(batting_sessions)
                ? batting_sessions?.find(
                    (sess) =>
                      sess.batting_session.id === value.batting_session.id
                  )
                : null;
              let battingSession = {};

              if (value.batting_session && value?.hits) {
                battingSession = {
                  roundNumber,
                  hitCount: value.hits?.length,
                  average: matching_batting_sess?.average?.exit_velocity,
                  launch_angle_spread: getLaunchAngleSpread(value.hits),
                  consistency: matching_batting_sess?.cns?.exit_velocity,
                  lastHit: value.hits?.length
                    ? value.hits[value.hits?.length - 1]
                    : null,
                  spray_direction_spread: getSprayDirectionSpread(value.hits),
                  max_velocity: getMaxValue(value.hits),
                };
              }

              return (
                <Draggable
                  key={matching_batting_sess?.batting_session?.id}
                  draggableId={
                    matching_batting_sess?.batting_session
                      ? matching_batting_sess?.batting_session?.id.toString()
                      : index.toString()
                  }
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Grid
                      item
                      xs={12}
                      key={matching_batting_sess?.batting_session?.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Grid className={classes.root} container spacing={2}>
                        <Grid item xs>
                          <BpiGuage
                            val={
                              matching_batting_sess?.bat
                                ? matching_batting_sess.bat.bpi
                                : 0
                            }
                            title="BPI"
                          />
                        </Grid>
                        <Grid item xs alignItems="center">
                          <CircularProgressGuage
                            externalTitle="Consistency"
                            val={consistency}
                            title={`${consistency} %`}
                          />
                        </Grid>
                        <Grid item xs>
                          <CircularProgressGuage
                            externalTitle="Power"
                            val={power}
                            title={`${power} %`}
                          />
                        </Grid>
                        <Grid item xs>
                          <CircularProgressGuage
                            externalTitle="Subjective"
                            val={subjective}
                            title={subjective.toString()}
                          />
                        </Grid>
                        {
                            custom_metric_label && <Grid item xs>
                            <CircularProgressGuage
                              externalTitle={custom_metric_label}
                              val={custom_metric_average}
                              title={`${custom_metric_average} (+/- ${custom_metric_consistency})`}
                            />
                          </Grid>
                        }
                        <Grid item xs alignItems="center">
                          <GridExitVelocity
                            props={{ ...battingSession }}
                          ></GridExitVelocity>
                        </Grid>
                        <Grid item xs>
                          <GridLaunchAngle
                            props={{
                              ...battingSession,
                              average:
                                matching_batting_sess?.average?.launch_angle ?? 0,
                                consistency: matching_batting_sess?.cns.launch_angle
                            }}
                          ></GridLaunchAngle>
                        </Grid>
                        <Grid item xs>
                          <GridSprayDirection
                            props={{
                              ...battingSession,
                              average:
                                matching_batting_sess?.average?.spray_direction ?? 0,
                                consistency: matching_batting_sess?.cns?.spray_direction
                            }}
                          ></GridSprayDirection>
                        </Grid>
                        <Grid item xs={12}>
                          <span
                            style={{ left: "10px", paddingTop: "10px" }}
                          >{`Bat Name: ${value.bat} - Model: ${matching_batting_sess?.bat.model}`}</span>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Draggable>
              );
            })}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SummaryList;
