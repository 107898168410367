/* 
  Isaac Hormel
  5/29/2020
  Sends a request to the NodeJS API server with an with a fittingSessionDto to create a new fitting session. 
  Also, we can edit a fitting session using the interface on the put route.
*/

import BasicRequest from "../classes/BasicRequest";

import Cookies from "universal-cookie";
import HitInterface, { HitInterfaceDto } from "../../interfaces/apiInterfaces/HitInterface";
const cookies = new Cookies();

interface ProcessHitPostOptionsInterface{
  hit: HitInterfaceDto;
};
interface ProcessHitPutOptionsInterface{
  id: number;
  hit: HitInterface;
};
interface ProcessHitPostBodyInterface extends HitInterfaceDto{
};
interface ProcessHitPutBodyInterface extends HitInterface{
};

export interface ProcessHitPostResponseInterface{
  data?: HitInterface;
  message: "created"|string;
}

export interface ProcessHitPutResponseInterface{
  data?: HitInterface;
  message: string;
}

export default class ProcessHitRequest extends BasicRequest{
  static post(options: ProcessHitPostOptionsInterface){
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/process/hit`);
      var body: ProcessHitPostBodyInterface = {
        ...options.hit
      };
      var ProcessHitPostRequest = fetch(destination.toString(), {
        method: "POST",
        headers: {
          "Authorization": cookies.get("user"),
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      ProcessHitPostRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: ProcessHitPostResponseInterface)=>{
        resolvePromise(requestJson as ProcessHitPostResponseInterface);
      });
      ProcessHitPostRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
  static put(options: ProcessHitPutOptionsInterface): Promise<ProcessHitPutResponseInterface|any>{
    // Just in case we for some weird reason do this on the JS side.
    if(options.id == undefined || options.id < 1 || typeof options.id != "number"){
      throw new Error("Property 'id' is missing on type ProcessHitPutOptionsInterface!");
    }
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/process/hit`);
      destination.pathname += `/${options.id}`;
      var body: ProcessHitPutBodyInterface = {
        ...options.hit
      };
      var ProcessHitPostRequest = fetch(destination.toString(), {
        method: "PUT",
        headers: {
          "Authorization": cookies.get("user"),
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      ProcessHitPostRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJSON: ProcessHitPutResponseInterface)=>{
        resolvePromise(requestJSON);
      });
      ProcessHitPostRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}