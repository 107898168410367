import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type FittingSummaryState = {};

type FittingSummaryProps = {
  fittingSession: any;
  battingSessions: any[];
  deleteHit: any;
  deleteBattingSession: any;
  handleOpenOpenEditHitCollapse: any;
  handleCloseOpenEditHitCollapse: any;
  editHit: any;
};

const EditHitSchema = Yup.object().shape({
  exit_velocity: Yup.number().min(0).max(300).required("Required"),
  launch_angle: Yup.number().min(-15).max(90).required("Required"),
  spray_direction: Yup.number().min(-45).max(45).required("Required"),
  player_rating: Yup.number().min(0).max(10).notRequired(),
  custom_metric_value: Yup.number().notRequired(),
});

class FittingSummary extends Component<
  FittingSummaryProps,
  FittingSummaryState
> {
  constructor(props: FittingSummaryProps) {
    super(props);
  }

  // state: FittingSummaryState = {

  // }

  render() {
    const actionsFormatter = (cell: any, row: any) => {
      return (
        <>
          <button
            className="btn btn-icon mr-2"
            onClick={() => this.props.handleOpenOpenEditHitCollapse(row)}
          >
            <FontAwesomeIcon icon="pencil-alt" />
          </button>
          <button
            className="btn btn-icon"
            onClick={(e) => {e.preventDefault(); this.props.deleteHit(row);}}
          >
            <FontAwesomeIcon icon="trash-alt" />
          </button>
        </>
      );
    };
    const columns = [
      {
        dataField: "hit_index",
        text: "S.No",
        formatter: (cell: any, row: any) => cell + 1,
      },
      {
        dataField: "exit_velocity",
        text: "EV",
        align: "center",
        headerAlign: "center",
        formatter: (cell: any, row: any) => Math.trunc(cell),
      },
      {
        dataField: "launch_angle",
        text: "LA",
        align: "center",
        headerAlign: "center",
        formatter: (cell: any, row: any) => Math.trunc(cell),
      },
      {
        dataField: "spray_direction",
        text: "SD",
        align: "center",
        headerAlign: "center",
        formatter: (cell: any, row: any) => Math.trunc(cell),
      },
      {
        dataField: "player_rating",
        text: "PR",
        align: "center",
        headerAlign: "center",
        formatter: (cell: any, row: any) => Math.trunc(cell),
      },
      {
        dataField: "actions",
        text: "Actions",
        align: "center",
        headerAlign: "center",
        formatter: actionsFormatter,
      },
    ];

    if (this.props.fittingSession.custom_metric_label !== "") {
      // insert item to table if custom_metric_label exists
      columns.splice(columns.length - 2, 0, {
        dataField: "custom_metric_value",
        text: this.props.fittingSession.custom_metric_label,
        align: "center",
        headerAlign: "center",
        formatter: (cell: any, row: any) => Math.trunc(cell),
      });
    }

    return (
      <>
        {this.props.battingSessions.map(
          (battingSession: any, index: number) => {
            if (battingSession.openEditHitCollapse) {
              return (
                <span key={index}>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      exit_velocity: Math.round(
                        battingSession.openEditHitCollapse.exit_velocity
                      ),
                      launch_angle: Math.round(
                        battingSession.openEditHitCollapse.launch_angle
                      ),
                      spray_direction: Math.round(
                        battingSession.openEditHitCollapse.spray_direction
                      ),
                      player_rating: Math.round(
                        battingSession.openEditHitCollapse.player_rating
                      ),
                      custom_metric_value: Math.round(
                        battingSession.openEditHitCollapse.custom_metric_value
                      ),
                    }}
                    validationSchema={EditHitSchema}
                    onSubmit={(values, { resetForm }) => {
                      this.props.editHit(
                        battingSession.openEditHitCollapse,
                        values
                      );
                      resetForm();
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="row mt-2">
                          <div className="col-4">
                            <div className=" px-5">
                              <label>Exit Velocity</label>
                              <Field
                                name="exit_velocity"
                                className={`form-control ${
                                  errors.exit_velocity && touched.exit_velocity
                                    ? "is-invalid"
                                    : touched.exit_velocity
                                    ? "is-valid"
                                    : ""
                                }`}
                                placeholder="Exit Velocity"
                                type="number"
                              />
                            </div>
                            {/* <div className="px-5">
                              {this.props.fittingSession?.all_swings_entry && (
                                <button
                                  className="btn btn-outline-darkblue mt-2"
                                  onClick={() => {
                                    this.submitNewHit(
                                      (batting_sessions as any)[
                                        batting_sessions.length - 1
                                      ],
                                      true,
                                      {
                                        exit_velocity: 0,
                                        launch_angle: 0,
                                        spray_direction: 0,
                                        player_rating: 0,
                                        custom_metric_value: 0,
                                      }
                                    );
                                  }}
                                >
                                  Foul Ball / Swing Miss
                                </button>
                              )}
                            </div> */}
                          </div>
                          <div className="col-4">
                            <div className=" px-5">
                              <label>Launch Angle</label>
                              <Field
                                className={`form-control ${
                                  errors.launch_angle && touched.launch_angle
                                    ? "is-invalid"
                                    : touched.launch_angle
                                    ? "is-valid"
                                    : ""
                                }`}
                                placeholder="Launch Angle"
                                type="number"
                                name="launch_angle"
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className=" px-5">
                              <label>Spray Direction</label>
                              <Field
                                className={`form-control ${
                                  errors.spray_direction &&
                                  touched.spray_direction
                                    ? "is-invalid"
                                    : touched.spray_direction
                                    ? "is-valid"
                                    : ""
                                }`}
                                placeholder="Spray Direction"
                                type="number"
                                name="spray_direction"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-4">
                            {this.props.fittingSession?.custom_metric_label && (
                              <div className="fourth_metric mt-2">
                                <div className=" px-5">
                                  <label>
                                    {
                                      this.props.fittingSession
                                        ?.custom_metric_label
                                    }
                                  </label>
                                  <Field
                                    className={`form-control ${
                                      errors.custom_metric_value &&
                                      touched.custom_metric_value
                                        ? "is-invalid"
                                        : touched.custom_metric_value
                                        ? "is-valid"
                                        : ""
                                    }`}
                                    placeholder={
                                      this.props.fittingSession
                                        ?.custom_metric_label
                                    }
                                    name="custom_metric_value"
                                    type="number"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-4">
                            {this.props.fittingSession
                              ?.player_rating_enabled && (
                              <div className="px-5">
                                <label>Player Rating</label>
                                <Field
                                  className={`form-control ${
                                    errors.player_rating &&
                                    touched.player_rating
                                      ? "is-invalid"
                                      : touched.player_rating
                                      ? "is-valid"
                                      : ""
                                  }`}
                                  placeholder="Player Rating"
                                  type="number"
                                  name="player_rating"
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-4">
                            <div className="pr-5 float-right">
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Confirm Edit
                                  </Tooltip>
                                }
                              >
                                <button
                                  type="submit"
                                  className="mt-3 btn btn-lg btn-green btn-primary"
                                >
                                  <FontAwesomeIcon icon="check" />
                                </button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip-top">Cancel Hit</Tooltip>
                                }
                              >
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.handleCloseOpenEditHitCollapse(
                                      battingSession.openEditHitCollapse
                                    );
                                  }}
                                  className="btn btn-lg btn-dark btn-primary mt-3 ml-2"
                                >
                                  <FontAwesomeIcon icon="times" />
                                </button>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </span>
              );
            } else {
              return (
                <span key={index}>
                  <div>
                    <b className="text-success">
                      {battingSession.bat.brand} {battingSession.bat.model}
                    </b>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">Delete Bat</Tooltip>}
                    >
                      <button
                        className="btn btn-icon ml-3"
                        onClick={() =>
                          this.props.deleteBattingSession(battingSession)
                        }
                      >
                        <FontAwesomeIcon icon="trash" />
                      </button>
                    </OverlayTrigger>
                  </div>
                  <BootstrapTable
                    keyField="id"
                    data={battingSession.hits}
                    columns={columns}
                  />
                </span>
              );
            }
          }
        )}
      </>
    );
  }
}

export default FittingSummary;
