/* 
  Isaac Hormel
  5/28/2020
  Sends a request to the NodeJS API server and to get batting sessions. Can be filtered and paginated depending on the options.
*/

import BasicRequest from "../classes/BasicRequest";

import Cookies from "universal-cookie";
import PaginationAndFilterInterface from "../../interfaces/apiInterfaces/PaginationAndFilterInterface";
import BattingSessionInterface from "../../interfaces/apiInterfaces/BattingSessionInterface";
const cookies = new Cookies();

interface BattingSessionGetOptionsInterface extends PaginationAndFilterInterface{
  id?: number;
};
interface BattingSessionDeleteOptionsInterface {
  id: number;
}
interface BattingSessionGetBodyInterface{
  
}; 

export interface BattingSessionGetResponseInterface{
  data?: Array<BattingSessionInterface>|BattingSessionInterface;
  message: "findAll"|"findOne";
}

export interface BattingSessionDeleteResponseInterface {
  message: "deleted";
}

export default class BattingSessionRequest extends BasicRequest{
  static get(options?: BattingSessionGetOptionsInterface): Promise<BattingSessionGetResponseInterface|any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/batting_sessions`);
      if(options?.filter_key || options?.limit || options?.offset){
        if(options?.filter_key){
          destination.searchParams.append("filter_key", options.filter_key);
          destination.searchParams.append("filter_value", options?.filter_value||"");
        }
        if(options?.offset){
          destination.searchParams.append("offset", options.offset.toString());
        }
        if(options?.limit){
          destination.searchParams.append("limit", options.limit.toString());
        }
      }
      if(options?.id){
        destination.pathname += `/${options?.id}`;
      }
      var BattingSessionGetRequest = fetch(destination.toString(), {
        method: "GET",
        headers: {
          "Authorization": cookies.get("user")
        }
      });
      BattingSessionGetRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: BattingSessionGetResponseInterface)=>{
        resolvePromise(requestJson);
      });
      BattingSessionGetRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
  static delete(options: BattingSessionDeleteOptionsInterface): Promise<BattingSessionDeleteResponseInterface|any>{
    var promise = new Promise((resolvePromise, rejectPromise)=>{
      var destination = new URL(`${process.env.REACT_APP_API_URL}/batting_sessions`);
      if(options.id){
        destination.pathname += `/${options?.id}`;
      }
      var BattingSessionDeleteRequest = fetch(destination.toString(), {
        method: "DELETE",
        headers: {
          "Authorization": cookies.get("user")
        }
      });
      BattingSessionDeleteRequest.then(rawRequest=>{
        return rawRequest.json();
      }).then((requestJson: BattingSessionDeleteResponseInterface)=>{
        resolvePromise(requestJson);
      });
      BattingSessionDeleteRequest.catch(err=>{
        if(err.message){
          rejectPromise(err.message);
        }else{
          rejectPromise(new Error(err));
        }
      });
    });
    return promise;
  }
}