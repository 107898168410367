import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Modal, Form as ReactForm, Button } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import moment from "@date-io/moment";
import ExportDataRequest, { ExportDataPostResponseInterface } from "../../api/requests/ExportDataRequest";
import UserRequest from "../../api/requests/UserRequest";
import { toast } from "react-toastify";

const { SearchBar } = Search;

export type User = {
  id: number;
  org_id: number | null;
  team_id: number | null;
  role_id: number;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  is_verified: boolean;
  is_admin_verified: boolean;
};
type UserSetting = {
  usage_type: string;
  account_enabled: boolean;
  sessions_left: number;
  end_date: string;
}
type Team = {
  id: number;
  org_id: number;
  name: string;
}
type Organization = {
  id: number;
  name: string;
  teams: Array<Team>;
};
type SelectOption = {
  label: string;
  value: number | string;
}
type Mode = "view" | "edit" | "new" | "delete";
type UsersProps = {
  users: Array<User>;
  organizations: Array<Organization>;
  handleClose: any;
};
type UsersState = {
  user: User;
  user_setting: UserSetting;
  roles: Array<SelectOption>;
  usage_types: Array<SelectOption>,
  page: number;
  show: boolean;
  mode: Mode;
  batId: number | null;
  selectedOrg: Organization | null;
};

const CreateUserSchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(4, "Password should be 4 chars minimum.")
    .required("Required"),
});

const EditUserSchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  role_id: Yup.number().nullable(),
  org_id: Yup.number().nullable(),
  team_id: Yup.number().nullable(),
  usage_type: Yup.string().required("Required"),
  sessions_left: Yup.number().min(0).max(20),
});

class Users extends Component<UsersProps, UsersState> {
  constructor(props: UsersProps) {
    super(props);
  }

  state: UsersState = {
    user: {
      id: -1,
      org_id: -1,
      team_id: -1,
      role_id: -1,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      is_verified: false,
      is_admin_verified: false,
    },
    user_setting: {
      usage_type: "",
      account_enabled: false,
      sessions_left: 0,
      end_date: "" + new Date(new Date().setFullYear(new Date().getFullYear())).toISOString(),
    },
    roles: [
      { label: "Admin", value: 1 },
      { label: "Coach", value: 3 },
      { label: "Player", value: 4 },
    ],
    usage_types: [
      { label: "Basic", value: 'basic' },
      { label: "Time Based", value: 'time_based' },
      { label: "Sessions Based", value: 'sessions_based' },
    ],
    show: false,
    page: 0,
    mode: "new",
    batId: null,
    selectedOrg: null
  };

  exportUsers() {
    ExportDataRequest.get('users/export').then((blob: any) => {
      const url: string = window.URL.createObjectURL(new Blob([blob]));
      let link: HTMLAnchorElement;
      link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `users.xlsx`);
      link.click();
    });
  }

  handleShow = (mode: Mode) => {
    this.setState({ show: true, mode: mode });
  };

  closeModal = () => {
    this.setState({ show: false, mode: "new" });
  }

  handleUsageType = (e: any) => {
    this.setState({ user_setting: { ...this.state.user_setting, usage_type: e.target.value } });
  }

  handleDateChange = (e: { toDate: Function } | null) => {
    if (e != null) {
      var d = e.toDate();
      if (d instanceof Date) {
        var tempState = this.state;
        tempState.user_setting.end_date = d.toISOString();
        this.setState(tempState);
      }
    }
  };

  deleteUser(userId: number) {
      UserRequest.delete(userId).then(result => {
        if (result.message === 'deleted') {
            
        }
      });
  }

  fetchUser(userId: number, mode: Mode) {
    UserRequest.get({
      id: userId,
    })
      .then((result: any) => {
        if (result.data && !Array.isArray(result.data)) {
          var tempState = this.state;
          tempState.user = result.data.user;
          if (result.data.user_setting) {
            tempState.user_setting = result.data.user_setting;
            tempState.user_setting.sessions_left = result.data.user_setting.sessions_left || 0; // if sessions_left is null
          }
          tempState.mode = mode;
          tempState.show = true;
          this.setState(tempState);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  clearForm = () => {
    var tempState = this.state;
    tempState.user = {
      id: -1,
      org_id: -1,
      team_id: -1,
      role_id: -1,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      is_verified: false,
      is_admin_verified: false,
    };
    tempState.user_setting = {
      usage_type: "",
      account_enabled: false,
      sessions_left: 0,
      end_date:
        "" +
        new Date(
          new Date().setFullYear(new Date().getFullYear())
        ).toISOString(),
    };
    this.setState(tempState);
  };

  createOrUpdateUser = (values: any) => {
    if (this.state.mode === "edit") {
      UserRequest.put({
        id: this.state.user.id,
        user: {
          team_id: values.team_id || null,
          org_id: values.org_id || null,
          role_id: values.role_id,
          first_name: values.first_name,
          last_name: values.last_name,
          is_admin_verified: this.state.user.is_admin_verified,
          is_verified: this.state.user.is_verified,
          email: values.email
        },
        userSetting: {
          user_id: this.state.user.id,
          usage_type: values.usage_type,
          account_enabled: this.state.user_setting.account_enabled,
          sessions_left: values.sessions_left,
          end_date: this.state.user_setting.end_date,
        },
      })
        .then((result: any) => {
          if (result.data) {
            this.clearForm();
            this.closeModal();
            this.props.handleClose("user");
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      UserRequest.post({
        user: {
          org_id: parseInt(values.org_id),
          role_id: parseInt(values.role_id),
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          is_admin_verified: this.state.user.is_admin_verified,
        },
      })
        .then((result: any) => {
          if (result.data) {
            this.clearForm();
            this.props.handleClose("user");
            toast.success(`User ${values.email} was created successfully`);
            this.setState({ mode: 'edit', user: result.data });
          }
          if (result?.message){
            toast.error(`${result.message}`, { autoClose: 5000 });
          }
        })
        .catch((err: any) => {
          console.log(err);
          toast.error(`${err.message}`, { autoClose: 5000 });
        });
    }
  };


  render() {
    const selectedOrg = this.state.user.org_id
      ? this.props.organizations.filter(t => t.id === this.state.user.org_id).length
        ? this.props.organizations.filter(t => t.id === this.state.user.org_id)[0]
        : null
      : null;

    const actionsFormatter = (cell: any, row: any) => {
      return (
        <>
          {/* <button
            className="btn btn-primary"
            onClick={() => this.fetchUser(row.id, "view")}
          >
            <FontAwesomeIcon icon="search" />
          </button> */}
          <button
            title="Edit"
            className="btn btn-outline-darkblue text-white"
            onClick={() => this.fetchUser(row.id, "edit")}
          >
            <FontAwesomeIcon icon="pencil-alt" />
          </button>
          <button
            title="Delete"
            className="btn btn-outline-darkblue text-white ml-3"
            onClick={async () => { await this.deleteUser(row.id); this.props.handleClose('user');}}
          >
            <FontAwesomeIcon icon="trash-alt" />
          </button>
        </>
      );
    };
    const checkFormatter = (cell: any, row: any) => {
      return cell ? (
        <FontAwesomeIcon color="lightgreen" icon="check-square" />
      ) : (
        <></>
      );
    };

    const pagination = paginationFactory({});

    const columns = [
      {
        dataField: "id",
        text: "ID",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 50,
        },
        sort: true
      },
      {
        dataField: "first_name",
        text: "First Name",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
        sort: true,
      },
      {
        dataField: "last_name",
        text: "Last Name",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
        sort: true,
      },
      {
        dataField: "email",
        text: "Email",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 425,
        },
        sort: true,
      },
      {
        dataField: "organization.name",
        text: "Organization",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 200,
        },
        sort: true,
      },
      {
        dataField: "role.name",
        text: "Role",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
      },
      {
        dataField: "is_verified",
        text: "Email Verified",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 100,
        },
        align: "center",
        formatter: checkFormatter,
      },
      {
        dataField: "is_admin_verified",
        text: "Admin Approved",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 125,
        },
        align: "center",
        formatter: checkFormatter,
      },
      {
        dataField: "actions",
        text: "Actions",
        align: "center",
        style: { backgroundColor: "#fff" },
        headerStyle: {
          width: 150,
        },
        formatter: actionsFormatter,
      },
    ];
    return (
      <div className="container-fluid">
        <ToolkitProvider
          keyField="id"
          data={this.props.users}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div className="row py-4 d-flex align-items-center">
                <div className="col-md-12 col-lg-6">
                  <div className="row mobileMargin">
                    <div className="col-md-8 col-lg-6 col-xl-8">
                      <SearchBar
                        style={{ width: 400 }}
                        className="form-control"
                        placeholder="Search"
                        {...props.searchProps}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-lg-6">
                  <div className="float-right">
                    <button className="btn export solid mr-2 d-inline-flex" onClick={() => this.exportUsers()}>
                      <FontAwesomeIcon icon="upload" className="mr-1" />
                      Export
                    </button>

                    <button
                      className="btn export solid d-inline-flex"
                      onClick={() => this.handleShow("new")}
                    >
                      <FontAwesomeIcon icon="plus" className="mr-1" />
                      Add User
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="table-wrapper">
                    <BootstrapTable
                      pagination={pagination}
                      wrapperClasses="table-responsive"
                      {...props.baseProps}
                    />
                  </div>
                </div>
              </div>
              <Modal
                size="lg"
                show={this.state.show}
                onHide={() => this.props.handleClose("user")}
                contentClassName="addPlayermodal"
                backdrop="static"
                centered={true}
              >
                <Modal.Body>
                  {this.state.mode === "edit" ? (
                    <div className="tabWrapper">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          first_name: this.state.user.first_name,
                          last_name: this.state.user.last_name,
                          email: this.state.user.email,
                          role_id: this.state.user.role_id,
                          org_id: this.state.user.org_id || "",
                          team_id: this.state.user.team_id || "",
                          usage_type: this.state.user_setting.usage_type || "",
                          sessions_left: this.state.user_setting.sessions_left || 0,
                        }}
                        validationSchema={EditUserSchema}
                        onSubmit={(values) => {
                          this.createOrUpdateUser(values);
                        }}
                      >
                        {({ errors, touched }) => (
                          <Form>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label>First Name</label>
                                  <Field
                                    type="text"
                                    name="first_name"
                                    className={`form-control ${errors.first_name && touched.first_name
                                        ? "is-invalid"
                                        : "is-valid"
                                      }`}
                                  />
                                  {errors.first_name && touched.first_name ? (
                                    <span className="small text-danger">
                                      {errors.first_name}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label>Last Name</label>
                                  <Field
                                    type="text"
                                    name="last_name"
                                    className={`form-control ${errors.last_name && touched.last_name
                                        ? "is-invalid"
                                        : "is-valid"
                                      }`}
                                  />
                                  {errors.last_name && touched.last_name ? (
                                    <span className="small text-danger">
                                      {errors.last_name}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label>Email</label>
                                  <Field
                                    type="text"
                                    name="email"
                                    className={`form-control ${errors.email && touched.email
                                        ? "is-invalid"
                                        : "is-valid"
                                      }`}
                                  />
                                  {errors.email && touched.email ? (
                                    <span className="small text-danger">
                                      {errors.email}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label>Role</label>
                                  <br />
                                  <Field
                                    as="select"
                                    name="role_id"
                                    className={`form-control`}
                                  >
                                    <option value="">-- Select --</option>
                                    {this.state.roles.map(
                                      (item: SelectOption, i: number) => {
                                        return (
                                          <option key={i} value={item.value}>
                                            {item.label}
                                          </option>
                                        );
                                      }
                                    )}
                                  </Field>
                                  {errors.role_id && touched.role_id ? (
                                    <span className="small text-danger">
                                      {errors.role_id}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label>Organization</label>
                                  <Field
                                    as="select"
                                    name="org_id"
                                    className={`form-control`}
                                    onChange={(e: any) =>
                                      this.setState({
                                        user: {
                                          ...this.state.user,
                                          org_id: parseInt(e.target.value),
                                        },
                                      })
                                    }
                                  >
                                    <option value="">-- Select --</option>
                                    {this.props.organizations.map(
                                      (item: Organization, i: number) => {
                                        return (
                                          <option key={i} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      }
                                    )}
                                  </Field>
                                  {errors.org_id && touched.org_id ? (
                                    <span className="small text-danger">
                                      {errors.org_id}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label>Team</label>
                                  {selectedOrg && (
                                    <Field
                                      as="select"
                                      name="team_id"
                                      className={`form-control`}
                                    >
                                      <option value="">-- No Team --</option>
                                      {selectedOrg.teams.map((item: Team, i: number) => {
                                        return (
                                          <option key={i} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                    </Field>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label>Email Verified</label>
                                  <br />
                                  {this.state.user.is_verified ? (
                                    <FontAwesomeIcon
                                      icon={
                                        this.state.user.is_verified
                                          ? "check-circle"
                                          : "times-circle"
                                      }
                                      color={
                                        this.state.user.is_verified
                                          ? "green"
                                          : "gray"
                                      }
                                      size="lg"
                                    />
                                  ) : (
                                    <BootstrapSwitchButton
                                      checked={this.state.user.is_verified}
                                      onlabel="Yes"
                                      offlabel="No"
                                      onstyle="success"
                                      offstyle="danger"
                                      width={125}
                                      onChange={(checked: boolean) =>
                                        this.setState({
                                          user: {
                                            ...this.state.user,
                                            is_verified: checked,
                                          },
                                        })
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label>Admin Verified</label>
                                  <br />
                                  {this.state.user.is_admin_verified ? (
                                    <FontAwesomeIcon
                                      icon={
                                        this.state.user.is_admin_verified
                                          ? "check-circle"
                                          : "times-circle"
                                      }
                                      color={
                                        this.state.user.is_admin_verified
                                          ? "green"
                                          : "gray"
                                      }
                                      size="lg"
                                    />
                                  ) : (
                                    <BootstrapSwitchButton
                                      checked={this.state.user.is_admin_verified}
                                      onlabel="Yes"
                                      offlabel="No"
                                      onstyle="success"
                                      offstyle="danger"
                                      width={125}
                                      onChange={(checked: boolean) =>
                                        this.setState({
                                          user: {
                                            ...this.state.user,
                                            is_admin_verified: checked,
                                          },
                                        })
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            <hr />

                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label>Usage Type</label>
                                  <Field
                                    as="select"
                                    name="usage_type"
                                    className={`form-control`}
                                    onClick={this.handleUsageType}
                                  >
                                    <option value="-1">-- Select --</option>
                                    {this.state.usage_types.map(
                                      (item: SelectOption, i: number) => {
                                        return (
                                          <option key={i} value={item.value}>
                                            {item.label}
                                          </option>
                                        );
                                      }
                                    )}
                                  </Field>
                                  {errors.usage_type && touched.usage_type ? (
                                    <span className="small text-danger">
                                      {errors.usage_type}
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              {this.state.user_setting.usage_type === "basic" && (
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label>Account Enabled</label>
                                    <br />
                                    <BootstrapSwitchButton
                                      checked={this.state.user_setting.account_enabled}
                                      onlabel="Yes"
                                      offlabel="No"
                                      onstyle="success"
                                      offstyle="danger"
                                      width={125}
                                      onChange={(checked: boolean) =>
                                        this.setState({
                                          user_setting: {
                                            ...this.state.user_setting,
                                            account_enabled: checked,
                                          },
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              )}

                              {this.state.user_setting.usage_type === "time_based" && (
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label>End Date</label>
                                    <MuiPickersUtilsProvider utils={moment}>
                                      <DatePicker
                                        value={this.state.user_setting.end_date}
                                        onChange={this.handleDateChange}
                                        disableFuture={false}
                                        labelFunc={() => {
                                          return (
                                            "" +
                                            (new Date(
                                              this.state.user_setting.end_date
                                            ).getMonth() +
                                              1) +
                                            "/" +
                                            new Date(
                                              this.state.user_setting.end_date
                                            ).getDate() +
                                            "/" +
                                            new Date(
                                              this.state.user_setting.end_date
                                            ).getFullYear()
                                          );
                                        }}
                                        className="w-100"
                                      />
                                    </MuiPickersUtilsProvider>
                                  </div>
                                </div>
                              )}

                              {this.state.user_setting.usage_type ===
                                "sessions_based" && (
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <label>Sessions Left</label>
                                      <Field
                                        type="number"
                                        name="sessions_left"
                                        className={`form-control ${errors.sessions_left && touched.sessions_left
                                            ? "is-invalid"
                                            : "is-valid"
                                          }`}
                                      />
                                    </div>
                                  </div>
                                )}
                            </div>

                            <div className="text-center my-3">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.clearForm();
                                  this.closeModal();
                                  this.props.handleClose("user");
                                }}
                                className="btn btn-outline-darkblue px-xl-4 mr-3"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn btn-darkblue text-white px-4"
                              >
                                Confirm
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : (
                    <div className="tabWrapper">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          first_name: this.state.user.first_name,
                          last_name: this.state.user.last_name,
                          email: this.state.user.email,
                          password: this.state.user.password,
                          role_id: this.state.user.role_id,
                          org_id: this.state.user.org_id,
                        }}
                        validationSchema={CreateUserSchema}
                        onSubmit={(values) => {
                          this.createOrUpdateUser(values);
                        }}
                      >
                        {({ errors, touched }) => (
                          <Form>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label>First Name</label>
                                  <Field
                                    type="text"
                                    name="first_name"
                                    className={`form-control ${errors.first_name && touched.first_name
                                        ? "is-invalid"
                                        : "is-valid"
                                      }`}
                                  />
                                  {errors.first_name && touched.first_name ? (
                                    <span className="small text-danger">
                                      {errors.first_name}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label>Last Name</label>
                                  <Field
                                    type="text"
                                    name="last_name"
                                    className={`form-control ${errors.last_name && touched.last_name
                                        ? "is-invalid"
                                        : "is-valid"
                                      }`}
                                  />
                                  {errors.last_name && touched.last_name ? (
                                    <span className="small text-danger">
                                      {errors.last_name}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label>Email</label>
                                  <Field
                                    type="text"
                                    name="email"
                                    className={`form-control ${errors.email && touched.email
                                        ? "is-invalid"
                                        : "is-valid"
                                      }`}
                                  />
                                  {errors.email && touched.email ? (
                                    <span className="small text-danger">
                                      {errors.email}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label>Password</label>
                                  <Field
                                    type="text"
                                    name="password"
                                    className={`form-control ${errors.password && touched.password
                                        ? "is-invalid"
                                        : "is-valid"
                                      }`}
                                  />
                                  {errors.password && touched.password ? (
                                    <span className="small text-danger">
                                      {errors.password}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label>Role</label>
                                  <br />
                                  <Field
                                    as="select"
                                    name="role_id"
                                    className={`form-control`}
                                  >
                                    <option value="-1">-- Select --</option>
                                    {this.state.roles.map(
                                      (item: SelectOption, i: number) => {
                                        return (
                                          <option key={i} value={item.value}>
                                            {item.label}
                                          </option>
                                        );
                                      }
                                    )}
                                  </Field>
                                  {errors.role_id && touched.role_id ? (
                                    <span className="small text-danger">
                                      {errors.role_id}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label>Organization</label>
                                  <Field
                                    as="select"
                                    name="org_id"
                                    className={`form-control`}
                                  >
                                    <option value="-1">-- Select --</option>
                                    {this.props.organizations.map(
                                      (item: Organization, i: number) => {
                                        return (
                                          <option key={i} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      }
                                    )}
                                  </Field>
                                  {errors.org_id && touched.org_id ? (
                                    <span className="small text-danger">
                                      {errors.org_id}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-6 col-md-6 text-md-right mb-3">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.clearForm();
                                    this.closeModal();
                                    this.props.handleClose("user");
                                  }}
                                  className="btn btn-outline-darkblue px-xl-4"
                                >
                                  Cancel
                                </button>
                              </div>
                              <div className="col-6 col-md-6 mb-3">
                                {" "}
                                <button
                                  type="submit"
                                  className="btn btn-darkblue text-white px-4"
                                >
                                  Confirm
                                </button>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  )}
                </Modal.Body>
              </Modal>
            </>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

export default Users;
