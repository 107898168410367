import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
// import bpiImg from '../../assets/images/bpi_review_session_ver_1.jpeg.svg';
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
      height: '150px',
      'background-position-x': '-10%'
    },
    bpiVal: {
        position: "relative",
        top: "30%",
        left: "45%",
    },
    bpiWrapper: {
        position: "relative",
        top: "20%",
        left: "-7%"
    },
    bpi: {
        font: "bold 80px sans-serif",
        fill: "black",
        "text-length": "100%",
        "length-adjust": "spacing",
        "text-anchor": "middle",
      },
      rating: {
        fill: "black",
        font: "bold 75px sans-serif",
        "text-anchor": "middle",
      }
  }));
  
export const BpiGuage: React.FC<{ val: number; title: string }> = ({
  val,
  title,
}) => {
    const classes = useStyles();
  return (
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 410 410">
            <polygon fill="transparent" stroke="black" strokeWidth="4" points="5,5,405,5,405,255,205,405,5,255"/>
            <text x="50%" y="35%" className={classes.bpi}>BPI</text>
            <text x="50%" y="55%" className={classes.rating}>{val}</text>
        </svg>
    </div>
  );
};
